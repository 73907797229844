import { ISubNavController, ISubNavRootScope } from './sub-nav.interfaces';

export class SubNavController implements ISubNavController {
  public mobileSubNavExpanded: boolean;
  public navReady: boolean;

  constructor(
    private $rootScope: ISubNavRootScope,
    private $timeout: ng.ITimeoutService,
  ) {
    'ngInject';
    this.navReady = true;
  }

  public toggleMobileSubNav(): void {
    this.mobileSubNavExpanded = !this.mobileSubNavExpanded;
    this.$rootScope.subNavOpen = this.mobileSubNavExpanded;
  }

  public $onDestroy(): void {
    this.$rootScope.subNavOpen = false;
  }
}
