import { ICurrencyAmount, IFullName, IResponse, ITimePeriod } from '../api.interfaces';

export enum AccountType {
  // Commuter Expense Reimbursement Account
  CERA = 'CERA',
  // FSA account types
  FSADC = 'FSADC',
  FSAHC = 'FSAHC',
  FSALP = 'FSALP',
  // HRA account types
  HRA = 'HRA',
  HRAAP = 'HRAAP',
  HRAPD = 'HRAPD',
  HRASD = 'HRASD',
  // HSA account types
  HSA = 'HSA',
  // JPMC account types
  DCSA = 'DCSA',
  HCSA = 'HCSA',
  MRA = 'MRA',
  // Optum managed account types
  HIA = 'HIA',
  RMSA = 'RMSA',
  PRA = 'PRA',
}

export enum AccountSystemCode {
  CAMS = 'CAMS',
  OptumBank = 'OPTUM_BANK',
  WEX = 'WEX',
}

export enum BalanceType {
  AnnualElections = 'ANNUAL_ELECTIONS',
  AvailableBalance = 'AVAILABLE_BALANCE',
  Balance = 'BALANCE',
  BeginningAmount = 'BEGINNING_AMOUNT',
  CarryoverBalance = 'CARRYOVER_BALANCE',
  CurrentBalance = 'CURRENT_BALANCE',
  EmployerContributions = 'EMPLOYER_CONTRIBUTIONS',
  InvestmentBalance = 'INVESTMENT_BALANCE',
  TotalBalance = 'TOTAL_BALANCE',
  YtdContributions = 'YTD_CONTRIBUTIONS',
  YtdPayments = 'YTD_PAYMENTS',
}

export interface ILedgerAccountFunding {
  annualElections?: ICurrencyAmount;
  employeeContribution?: ICurrencyAmount;
  employerContribution?: ICurrencyAmount;
  beginningAmount?: ICurrencyAmount;
}

export interface ILedgerAccountBalance {
  availableAmount?: ICurrencyAmount;
  currentAmount: ICurrencyAmount;
  paidAmount?: ICurrencyAmount;
  investmentAmount?: ICurrencyAmount;
  totalBalance?: ICurrencyAmount;
  accountFunding?: ILedgerAccountFunding;
  carryOverAmount?: ICurrencyAmount;
}

export interface IAccessPointInfo {
  familyAccessPointInformation: IFamilyAccessPointInfo;
  individualAccessPointInformation: IIndividualAccessPointInfo;
}

export interface IFamilyAccessPointInfo {
  familyAccessPoint: string;
  familyAccessPointAmountApplied: string;
  hraAccessibleByFamily: boolean;
}

export interface IIndividualAccessPointInfo {
  individualAccessPoint: string;
  individualMaxReimbursementAmount?: string;
  individualAccessPointAccumulators: IIndividualAccessPointAccumulator[];
}

export interface IIndividualAccessPointAccumulator extends IFullName {
  individualAccessPointApplied: string;
  individualMaxReimbursementAmountUsed?: string;
  hraAccessibleByIndividual: boolean;
}

export interface ILedgerAccount {
  accessPointInformation?: IAccessPointInfo;
  accountPeriod?: ITimePeriod;
  accountSystemCode?: AccountSystemCode;
  accountType: AccountType;
  balance?: ILedgerAccountBalance;
  isActive: boolean;
  rallypayLedgerData?: string;
}

export interface IGetAccountsResponse extends IResponse<ILedgerAccount[]> {}
