import { Observable } from 'rxjs/Observable';
import { submitClaim } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import claimsNeedAttentionTemplate from 'views/claims-and-accounts/summary/claims-need-attention.html';
import { ClaimStatus, IClaim } from '../../../../api/claims/claims.interfaces';
import { ClaimsService, IClaimsService } from '../../../../api/claims/claims.service';
import { IProfileUser } from '../../../../api/profile/profile.interfaces';
import {IProfileService} from '../../../../api/profile/profile.service';
import { IUserService } from '../../../../api/user/user.service';
import { Dictionary } from '../../../../util/constants/i18n.constants';
import { IResourceService } from '../../../../util/resource/resource.service';

export class ClaimsNeedAttentionComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = ClaimsNeedAttentionController;
    this.templateUrl = claimsNeedAttentionTemplate;
  }
}

export class ClaimsNeedAttentionController implements ng.IComponentController {
  public claims: IClaim[];
  public currentUser: IProfileUser;
  public request: Observable<IClaim[]>;
  public submitClaim: IResource;
  public hasAttentionClaims: boolean;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private claimsService: IClaimsService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.CLAIMS_NEED_ATTENTION);
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.submitClaim = submitClaim;

    this.request = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .do(profile => {
        this.currentUser = profile.currentUser;
      })
      .flatMap(profile => this.claimsService.getNeedAttention(profile))
      .map(rsp => rsp.data);
  }

  public $onInit(): void {
    this.request.subscribe(claims => {
      this.claims = claims;
      this.hasAttentionClaims = claims.some(claim => {
        const youMayOweAmount = ClaimsService.getYouMayOweAmount(claim);
        return claim.claimStatus === ClaimStatus.Denied || (youMayOweAmount && youMayOweAmount > 0);
      });
    }, console.warn);
  }
}
