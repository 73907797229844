import accountInfoTemplate from 'views/dashboard/account-info.html';
import { AccountInfoController } from './account-info.controller';

export class AccountInfoComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;

  constructor() {
    this.controller = AccountInfoController;
    this.templateUrl = accountInfoTemplate;
  }
}
