import angular from 'angular';
import 'angular-translate';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs/Observable';
import { CoverageType, ICoverageTimePeriod } from 'scripts/api/api.interfaces';
import { ClaimType, IClaimBalance } from 'scripts/api/claims/claims.interfaces';
import { IClaimsService } from 'scripts/api/claims/claims.service';
import {
  BENEFIT_TYPE,
  BenefitNetwork,
  DEDUCTIBLE,
  IBenefit,
  IBenefitAccumulators,
  IBenefitAmount,
  INDIVIDUAL,
  OOP,
} from 'scripts/api/plans/plans.interfaces';
import { IPlansService } from 'scripts/api/plans/plans.service';
import { IProfileService } from 'scripts/api/profile/profile.service';
import { IUserService } from 'scripts/api/user/user.service';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { getMoneyValue, getMoneyValueHtml } from 'scripts/util/money/money';
import mrAccountSummaryTemplate from 'views/dashboard/mr-account-summary.html';
import {
  IAccountSummaryAmount,
  IAccountSummaryMax,
  IBenefitsSpending,
  IMRAccountSummaryController,
} from '../account-summary/account-summary.interfaces';

export class MRAccountSummaryComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = MRAccountSummaryController;
    this.templateUrl = mrAccountSummaryTemplate;
  }
}

export class MRAccountSummaryController implements IMRAccountSummaryController {
  public accumulatorsLastUpdated: Moment;
  public benefitsRequest$: Observable<IBenefitsSpending[]>;
  public billedItems: IAccountSummaryAmount[];
  public billedSegments: number[];
  public claimsRequest$: Observable<IAccountSummaryAmount[]>;
  public claimsTotalsLastUpdated: Moment;
  public individual: string;
  public planPeriod: ICoverageTimePeriod;
  public spentDeductibles: IBenefitsSpending[];
  public spentOopMax: IBenefitsSpending[];
  public totalBilledIsZero: boolean;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private claimsService: IClaimsService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);
    $translatePartialLoader.addPart(Dictionary.ACCUMULATORS);
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.accumulatorsLastUpdated = moment();
    this.claimsTotalsLastUpdated = moment();

    const currentUser$ = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser);

    this.benefitsRequest$ = currentUser$
      .do(({ planCoverages, userInfo }) => {
        this.individual = userInfo.firstName;
        const medicalCoverage = this.profileService.getCoverage(CoverageType.Medical, planCoverages);
        this.planPeriod = medicalCoverage && medicalCoverage.planPeriod;
      })
      .flatMap(({ rallyId }) => this.getBenefitsSpending(rallyId));

    this.claimsRequest$ = currentUser$
      .map(currentUser => this.profileService.getCoverage(CoverageType.Medical, currentUser.planCoverages))
      .takeWhile(medicalCoverage => !!medicalCoverage)
      .flatMap(() => currentUser$)
      .flatMap(currentUser => this.claimsService.getTotals(currentUser.rallyId, null, ClaimType.Medical))
      .do(rsp => this.claimsTotalsLastUpdated = rsp.arcadeDataUpdated || moment())
      .flatMap(rsp => rsp.data && rsp.data.length ? rsp.data : [{balance: undefined}])
      .map(({balance}) => MRAccountSummaryController.getBilledItems(balance));
  }

  public $onInit(): void {
    this.benefitsRequest$
      .subscribe((benefitsSpending: IBenefitsSpending[]) => {
        this.spentDeductibles = benefitsSpending.filter(({benefit}) => benefit as string === DEDUCTIBLE);
        this.spentOopMax = benefitsSpending.filter(({benefit}) => benefit as string === OOP);
      }, console.warn);

    this.claimsRequest$.subscribe(rsp => {
      this.billedItems = rsp;
      this.totalBilledIsZero = this.getTotalBilledAmount() === 0;
      this.billedSegments = MRAccountSummaryController.getAmountArr(this.billedItems);
    }, console.warn);
  }

  // getBenefitsSpending parses the results of the plansService.getBenefits call into a manageable format
  // using initializeBenefitsSpending, then calls getAccumulators and uses that result to populate the benefit amounts
  public getBenefitsSpending(rallyId: string): Observable<IBenefitsSpending[]> {
    const benefitsSpending$ = this.plansService.getBenefits(rallyId)
      .flatMap(rsp => rsp.data && rsp.data.benefits ? rsp.data.benefits : [])
      .filter(benefit => !!(benefit.coverageType === CoverageType.Medical && benefit.maxes))
      .map(benefit => this.initializeBenefitsSpending(benefit));
    const benefitAccumulators$ = this.plansService.getAccumulators(rallyId)
      .do(rsp => this.accumulatorsLastUpdated = rsp.arcadeDataUpdated || moment())
      .flatMap(rsp => rsp.data && rsp.data.benefits ? rsp.data.benefits : [])
      .filter(benefit => !!(benefit.coverageType === CoverageType.Medical && benefit.accumulators))
      .catch(() => Observable.of({} as IBenefitAccumulators));

    return Observable.zip(benefitsSpending$, benefitAccumulators$)
      .map(([benefitsSpending, benefitAccumulators]: [IBenefitsSpending[], IBenefitAccumulators]) => {
        // Add amounts from accumulator call to benefitsSpending
        benefitsSpending.forEach(benefit => {
          if (benefitAccumulators.accumulators && benefitAccumulators.accumulators.hasOwnProperty(benefit.network)) {
            const accumulators = benefitAccumulators.accumulators[benefit.network] as IBenefitAmount[];
            accumulators.forEach(acc => (acc.type === benefit.type) && (benefit.amount = acc.amount.value));
          }
        });
        return benefitsSpending;
      });
  }

  // Get and parse benefits call to benefitsSpending object, set 'amount' to undefined
  public initializeBenefitsSpending(benefit: IBenefit): IBenefitsSpending[] {
    let benefitAmounts: IBenefitAmount[];
    const benefitNetwork = BenefitNetwork.InNetwork;
    benefitAmounts = benefit.maxes.inNetwork || [];

    return benefitAmounts
      .filter(({type}) => BENEFIT_TYPE[type].owner as string === INDIVIDUAL)
      .map(({amount, type}) => ({
        type,
        benefit: BENEFIT_TYPE[type].benefit,
        owner: BENEFIT_TYPE[type].owner,
        max: amount.value,
        amount: undefined,
        network: benefitNetwork,
      }));
  }

  public getMoneyValue(amount: number, decimal?: boolean): string {
    return getMoneyValue(amount, decimal);
  }

  public getMoneyValueHtml(amount: number, decimal?: boolean): string {
    return getMoneyValueHtml(amount, decimal);
  }

  public getPercentUsed(item: IAccountSummaryMax): string {
    const percentage = item.amount ? item.amount / item.max * 100 : 0;
    return percentage.toFixed(0) + '%';
  }

  public getTotalBilledAmount(): number {
    return this.billedItems ? this.billedItems.reduce((prev, current) => {
        return prev + current.amount;
      }, 0) : 0;
  }

  public getTotalBilled(): string {
    const amount = this.getTotalBilledAmount();
    return this.getMoneyValueHtml(amount);
  }

  public isTotalNonZero(): boolean {
    const amount = this.billedItems ? this.billedItems.reduce((prev, current) => {
        return prev + current.amount;
      }, 0) : 0;
    return amount && amount > 0;
  }

  private static getAmountArr(items: IAccountSummaryAmount[]): number[] {
    const arr = [];
    for (const item of items) {
      arr.push(item.amount);
    }
    return arr;
  }

  private static getBilledItems(balance?: IClaimBalance): IAccountSummaryAmount[] {
    return [{
      name: 'OTHER',
      amount: balance && balance.other ? balance.other.value || 0 : 0,
    }, {
      name: 'HEALTH_PLAN_PAYS',
      amount: balance && balance.healthPlanPays ? balance.healthPlanPays.value || 0 : 0,
    }, {
      name: 'PATIENT_RESPONSIBILITY',
      amount: balance && balance.patientResponsibility ? balance.patientResponsibility.value || 0 : 0,
    }];
  }
}
