import {Observable} from 'rxjs/Observable';
import { activateUris } from 'scripts/util/uri/uri';
import { CacheName } from '../api.interfaces';
import {IBaseApiService} from '../api.module';
import { getCache, getCacheKey } from '../cache';
import { IActivateStepsResponse, IActivateStepsResponseV4 } from './activate.interfaces';

export class ActivateService implements IActivateService {
  constructor(
    private baseApiService: IBaseApiService,
  ) {
    'ngInject';
    getCache(CacheName.Activate).removeAll();
  }
  public getSteps(rallyId: string): Observable<IActivateStepsResponse>  {
    const url = activateUris.steps(rallyId);
    const activateCache = getCache(CacheName.Activate);
    const cacheKey = getCacheKey(url);
    const cachedData = activateCache.get(cacheKey);
    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => activateCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getStepsV4(rallyId: string): Observable<IActivateStepsResponseV4>  {
    const url = activateUris.stepsV4(rallyId);
    const activateCache = getCache(CacheName.Activate);
    const cacheKey = getCacheKey(url);
    const cachedData = activateCache.get(cacheKey);
    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => activateCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$);
  }
}

export interface IActivateService {
  getSteps(rallyId: string): Observable<IActivateStepsResponse>;
  getStepsV4(rallyId: string): Observable<IActivateStepsResponseV4>;
}
