import { Observable } from 'rxjs/Observable';
import {
  connectDoctor,
  connectEmergent,
  connectSpecialist,
  connectUrgent,
  connectVirtualVisits,
} from 'scripts/util/resource/resource.constants';
import compareCareTemplate from 'views/modals/compare-care.html';
import { CoverageType } from '../../../api/api.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';
import { CostId } from '../cost-info/cost-info.interfaces';
import { CostInfoService } from '../cost-info/cost-info.service';

export class CompareCareComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  public bindings: any;

  constructor() {
    this.controller = CompareCareController;
    this.templateUrl = compareCareTemplate;
    this.bindings = {
      isImmediateCare: '<',
    };
  }
}

interface ICompareColumn {
  title: string;
  description: string;
  listHeader: string;
  list: string[];
  subList?: string[];
  findText: string;
  findLink: string;
}

class CompareCareController implements ng.IComponentController {
  public columns: ICompareColumn[];
  public modalTitle: string;
  public modalSubtitle: string;
  private isImmediateCare: boolean;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private costInfoService: CostInfoService,
    private Environment: IEnvironmentConstants,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.COMPARE_CARE);

    if (this.isImmediateCare) {
      this.modalTitle = 'IC_TITLE';
      this.modalSubtitle = 'IC_SUBTITLE';

      this.columns = [{
        title: 'IC_UC',
        description: 'IC_UC_DESC',
        listHeader: 'IC_UC_FOR',
        list: [
          'SPRAINS',
          'STRAINS',
          'MINOR_BROKEN_BONES',
          'MINOR_INFECTIONS',
          'SMALL_CUTS',
          'SORE_THROAT',
          'RASHES',
        ],
        findText: 'FIND_URGENT_CARE',
        findLink: this.resourceService.get(connectUrgent),
      }, {
        title: 'IC_ER',
        description: 'IC_ER_DESC',
        listHeader: 'IC_ER_FOR',
        list: [
          'HEAVY_BLEEDING',
          'OPEN_WOUNDS',
          'CHANGES_IN_VISION',
          'CHEST_PAINS',
          'TROUBLE_TALKING',
          'MAJOR_BURNS',
          'SPINAL_INJURIES',
          'HEAD_INJURIES',
          'DIFFICULTY_BREATHING',
          'MAJOR_BROKEN_BONES',
        ],
        findText: 'FIND_EMERGENCY_ROOM',
        findLink: this.resourceService.get(connectEmergent),
      }];
    } else {
      this.modalTitle = 'DV_TITLE';
      this.modalSubtitle = 'DV_SUBTITLE';

      this.columns = [{
        title: 'DV_PCP',
        description: 'DV_PCP_DESC',
        listHeader: 'DV_PCP_FOR',
        list: [
          'CHECKUPS',
          'PREVENTATIVE_SCREENINGS',
          'INITIAL_DIAGNOSES',
          'IMMUNIZATIONS',
          'MANAGEMENT_CHRONIC',
          'REFERRAL_TO_SPECIALIST',
          'COMMON_COMPLAINTS',
        ],
        subList: [
          'ALLERGIES',
          'BACK_AND_JOIN_PAIN',
          'COUGH_COLD_FLU',
          'DIARRHEA',
          'EAR_ACHE',
          'FEVER',
          'HEADACHE',
          'PINK_EYE',
          'RASHES',
          'SINUS_PROBLEMS',
          'SORE_THROAT',
          'SPRAINS',
          'STOMACH_ACHE',
          'UTI',
          'VOMITING',
        ],
        findText: 'FIND_DOCTOR',
        findLink: this.resourceService.get(connectDoctor),
      }, {
        title: 'DV_SPECIALIST',
        description: 'DV_SPECIALIST_DESC',
        listHeader: 'DV_SPECIALIST_FOR',
        list: [
          'MANAGEMENT_CHRONIC_OR_ACUTE',
          'DIAGNOSIS_AND_TREATMENT',
          'ORGAN_CONDITIONS',
          'NEWEST_TREATMENTS',
          'PROCEDURES_AND_SURGERY',
          'MORE_EXPERTISE',
          'REPRODUCTIVE_HEALTH',
          'CONDITION_NOT_IMPROVING',
        ],
        findText: 'FIND_SPECIALIST',
        findLink: this.resourceService.get(connectSpecialist),
      }];
    }

    const isVirtualEligible$: Observable<boolean> = userService.getHeartbeat()
      .let(profileService.toProfile())
      .flatMap(profile => profile.data.currentUser.planCoverages)
      .first(({coverageType, planFeatures}) => coverageType === CoverageType.Medical && planFeatures.isDVCN)
      .map(() => true);

    const hasVirtualService$: Observable<boolean> = costInfoService.getCostsForService(CostId.virtual).map(() => true);

    isVirtualEligible$.flatMap(isVirtualEligible => Observable.if(() => isVirtualEligible, hasVirtualService$, Observable.of(false)))
      .subscribe(() => {
        if (this.isImmediateCare) {
          this.modalTitle = 'IC_TITLE_VV';
          this.modalSubtitle = 'IC_SUBTITLE_VV';

          this.columns.push({
            title: 'VIRTUAL_VISITS',
            description: 'IC_VIRTUAL_DESC',
            listHeader: 'IC_VIRTUAL_FOR',
            list: [
              'ALLERGIES',
              'BLADDER_INFECTION',
              'BRONCHITIS',
              'COUGH_COLD',
              'DIARRHEA',
              'FEVER',
              'PINK_EYE',
              'RASH',
              'SEASONAL_FLU',
              'SINUS_PROBLEMS',
              'SORE_THROAT',
              'STOMACH_ACHE',
            ],
            findText: 'VISIT_DOCTOR_ONLINE',
            findLink: this.resourceService.get(connectVirtualVisits),
          });
        } else {
          this.modalTitle = 'DV_TITLE_VV';
          this.modalSubtitle = 'DV_SUBTITLE_VV';

          this.columns.push({
            title: 'VIRTUAL_VISITS',
            description: 'DV_VIRTUAL_DESC',
            listHeader: 'DV_VIRTUAL_FOR',
            list: [
              'ALLERGIES',
              'BLADDER_INFECTION',
              'BRONCHITIS',
              'COUGH_COLD',
              'DIARRHEA',
              'FEVER',
              'PINK_EYE',
              'RASH',
              'SEASONAL_FLU',
              'SINUS_PROBLEMS',
              'SORE_THROAT',
              'STOMACH_ACHE',
            ],
            findText: 'VISIT_DOCTOR_ONLINE',
            findLink: this.resourceService.get(connectVirtualVisits),
          });
        }
      }, () => false);
  }
  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }
}
