import accumulatorsTemplate from 'views/claims-and-accounts/plan-balances/accumulators.html';
import { AccumulatorsController } from './accumulators.controller';

export class AccumulatorsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = AccumulatorsController;
    this.templateUrl = accumulatorsTemplate;
  }
}
