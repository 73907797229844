import { Observable } from 'rxjs/Observable';
import { BenefitPaymentType, BenefitTypeCopyMap } from '../../../../api/plans/plans.interfaces';
import { IDropdownOption } from '../../../../ui/dropdown/dropdown.interfaces';

export const CoverageTypeMap = {
  MEDICAL: 'medical',
  DENTAL: 'dental',
};

export interface IAccumulatorsController extends ng.IComponentController {
  isMedicalTabActive: boolean;
  isEhbPlan: boolean;
  ehbLink: string;
  selectedNetwork: IDropdownOption;
  medicalNetworkOptions: IDropdownOption[];
  dentalNetworkOptions: IDropdownOption[];
  spendingData: ISpendingData;
  spendingDataRequest: Observable<any>;
  depSeqNums: string[];
  activateTab(isMedical: boolean): void;
  changeNetwork(): void;
  getMoneyValue(amount: number, decimal?: boolean): string;
  getPercentUsed(item: ISpendingItem): string;
}

export interface ISpendingData {
  medical?: INetworkSpending;
  dental?: INetworkSpending;
}

export interface INetworkSpending {
  inNetwork?: IIndividualSpending[];
  outOfNetwork?: IIndividualSpending[];
  tier1?: IIndividualSpending[];
}

export interface IIndividualSpending {
  name: string;
  isFamily: boolean;
  spending: ISpendingItem[];
}

export interface ISpendingItem {
  type: BenefitTypeCopyMap;
  paymentType: BenefitPaymentType;
  max: number;
  amount: number;
  isMaxBenefit?: boolean;
  customLabel?: string;
  link?: string;
  track?: string;
}

export interface IDepSeqNumToName {
  [depSeqNum: string]: string;
}

export enum AccumulatorModalType {
  DentalAnnualMax = 'dental-Max',
  DentalDeductible = 'dental-Deductible',
  DentalLifetimeMax = 'ortho-Max',
  MedicalCopayMax = 'copay-max',
  MedicalDeductible = 'deductible',
  MedicalLifetimeMax = 'lifetime-max',
  MedicalOopMax = 'oop-max',
  MedicalOopMax2 = 'oop-max-2',
  MedicalPreDeductible = 'pre-deductible',
}
