export class Dictionary {
  public static ACCOUNT_INFO: string = 'account-info';
  public static ACCOUNT_SELECTOR: string = 'account-selector';
  public static ACCOUNT_SUMMARY: string = 'account-summary';
  public static ACCUMULATORS: string = 'accumulators';
  public static ACTIVATE_VIDEO: string = 'activate-video';
  public static ACTIVATE_VIDEO_TRANSCRIPT: string = 'activate-video-transcript';
  public static ADDITIONAL_LINKS: string = 'additional-links';
  public static ADDITIONAL_SERVICES: string = 'additional-services';
  public static ALL_CLAIMS: string = 'all-claims';
  public static ALL_CLAIMS_FAQ: string = 'all-claims-faq';
  public static AUTO_PAYMENT: string = 'auto-payment';
  public static CLAIM: string = 'claim';
  public static CLAIM_DETAILS: string = 'claim-details';
  public static CLAIM_HELP_MENU: string = 'claim-help-menu';
  public static CLAIM_LETTERS: string = 'claim-letters';
  public static CLAIM_VIDEO: string = 'claim-video';
  public static CLAIMS_AND_ACCOUNTS: string = 'claims-and-accounts';
  public static CLAIMS_NEED_ATTENTION: string = 'claims-need-attention';
  public static COMMON: string = 'common';
  public static COMPARE_CARE: string = 'compare-care';
  public static CONTACT_US: string = 'contact-us';
  public static CONTENT_HOPPER: string = 'content-hopper';
  public static COSTS: string = 'costs';
  public static DATE_SELECTOR: string = 'date-selector';
  public static DCSA_FAQ: string = 'dcsa-faq';
  public static DIRECT_DEPOSIT: string = 'direct-deposit';
  public static EDIT_CLAIM_NOTE: string = 'edit-claim-note';
  public static ERRORS: string = 'errors';
  public static FAQ: string = 'faq';
  public static FILTER: string = 'filter';
  public static FOOTER: string = 'footer';
  public static FSA_FAQ: string = 'fsa-faq';
  public static GUIDED_SEARCH: string = 'guided-search';
  public static HEADER: string = 'header';
  public static HEALTH_CHECKLIST: string = 'health-checklist';
  public static HRA_FAQ: string = 'hra-faq';
  public static HSA_FAQ: string = 'hsa-faq';
  public static HCSA_FAQ: string = 'hcsa-faq';
  public static HOW_IT_WORKS: string = 'how-it-works';
  public static ID_CARDS: string = 'id-cards';
  public static INACTIVE: string = 'inactive';
  public static INCENTIVES: string = 'incentives';
  public static LINK_BAR: string = 'link-bar';
  public static MRA_FAQ: string = 'mra-faq';
  public static MR_RX_SPENDING_COST_SUMMARY: string = 'mr-rx-spending-cost-summary';
  public static NOTIFICATION: string = 'notification';
  public static EXPIRATION_WARNING: string = 'expiration-warning';
  public static ONBOARDING: string = 'onboarding';
  public static PAGE_TITLES: string = 'page-titles';
  public static PAY_NOW_MODAL: string = 'pay-now-modal';
  public static PCP: string = 'pcp';
  public static PHARMACY: string = 'pharmacy';
  public static PLAN_BALANCES: string = 'plan-balances';
  public static PREMIUM_PAYMENTS: string = 'premium-payments';
  public static PROGRAMS: string = 'programs';
  public static QUICK_LINKS: string = 'quick-links';
  public static RECENT_CLAIMS: string = 'recent-claims';
  public static RECOMMENDATIONS: string = 'recommendations';
  public static RESOURCES: string = 'resources';
  public static SEE_YOU_LATER: string = 'see-you-later';
  public static STATEMENTS: string = 'statements';
  public static SUBMIT_CLAIM: string = 'submit-claim';
  public static SUPER_USER_BANNER: string = 'super-user-banner';
  public static TIPS_TO_SAVE: string = 'tips-to-save';
  public static WELLNESS: string = 'wellness';
}

export const LocaleKey = 'ARCADE_LOCALE';
export const SessionLocaleKey = 'ARCADE_SESSION_LOCALE';
