import { Subscription } from 'rxjs/Subscription';
import fitbitBannerTemplate from 'views/chrome/fitbit-banner.html';
import { CampaignPlacementType } from '../../../api/targeting/targeting.interfaces';
import { ICampaign } from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { ILocaleService } from '../../../util/locale/locale.service';

export class FitbitBannerComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;

  constructor() {
    this.controller = FitbitBannerController;
    this.templateUrl = fitbitBannerTemplate;
  }
}

export interface IFitbitStateParams extends ng.ui.IStateParamsService {
  onboardingProfileUpdated: boolean;
}

export class FitbitBannerController {
  public fitbitCampaign: ICampaign;

  private localeSubscription: Subscription;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private localeService: ILocaleService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ONBOARDING);
    this.updateCampaign();
    this.localeSubscription = localeService.localeChanged.subscribe(() => this.updateCampaign());
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  private updateCampaign(): void {
    this.userService.getHeartbeat()
      .map(rsp => rsp.data.rallyId)
      .flatMap(rallyId =>
        this.targetingService.getCampaigns(rallyId, [CampaignPlacementType.ActivateInLine]))
      .map(rsp => rsp.data.placements[CampaignPlacementType.ActivateInLine])
      .filter(placements => placements && placements.length > 0)
      .map(placements => placements[0])
      .subscribe(fitbitPlacement => {
        this.fitbitCampaign = fitbitPlacement;
      });
  }
}
