import internalErrorTemplate from 'views/states/internal-error.html';
import { InternalErrorController } from './internal-error.controller';

export class InternalErrorComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = InternalErrorController;
    this.templateUrl = internalErrorTemplate;
  }
}
