import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import featureFlags from 'scripts/util/feature-flag/feature-flag';
import rootReducers from '../reducers';

const middlewares = [thunk];

if (featureFlags.isReduxLoggingOn()) {
  middlewares.push(createLogger({
    collapsed: true,
    duration: true,
  }));
}

export const configureStore = (preloadedState = {}) => createStore(rootReducers, preloadedState, applyMiddleware(...middlewares));

const store = configureStore();

export default store;
