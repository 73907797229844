import angular from 'angular';

export class InViewDirective implements ng.IDirective {
  public restrict = 'A';
  public scope = {
    inView: '=',
  };

  constructor(private $window: ng.IWindowService) {}

  public link = (scope, element: ng.IAugmentedJQuery, attrs) => {
    let scrollEvent;
    const visibilityPercentage = .75;

    scrollEvent = e => {
      const {top, bottom} = element[0].getBoundingClientRect();
      const elementHeight = bottom - top;
      const visibilityThreshold = top + (elementHeight * visibilityPercentage);
      const isVisible = visibilityThreshold < window.innerHeight && bottom >= 0;

      scope.inView = !!scope.inView || isVisible;
      if (scope.inView) {
        angular.element(this.$window)
          .off('scroll', scrollEvent);
      }
    };

    angular.element(this.$window)
      .off('scroll', scrollEvent)
      .on('scroll', scrollEvent);

    scope.$on('$destroy', () => {
      angular.element(this.$window).off('scroll', scrollEvent);
    });
  }

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($window: ng.IWindowService) => {
      'ngInject';
      return new InViewDirective($window);
    };
    return directive;
  }
}
