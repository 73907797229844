import { Subscription } from 'rxjs/Subscription';
import { getLink } from 'scripts/util/uri/uri';
import {
  AllProgramsPageCampaigns,
  CampaignPlacementType,
  ICampaign,
  ICampaignCta,
} from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { ILocaleService } from '../../../util/locale/locale.service';

export interface ILinkSection {
  headline: string;
  links: ICtaWrapper[];
}

export interface ICtaWrapper {
  cta: ICampaignCta;
}

export class ResourcesController implements ng.IComponentController {
  public messageSections: ICampaign[];
  public linkSections: ILinkSection[];
  private localeSubscription: Subscription;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private localeService: ILocaleService,
    public targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.RESOURCES);
  }

  public $onInit(): void {
    this.setResourcesCampaigns();
    this.localeSubscription = this.localeService.localeChanged.subscribe(() => {
      this.setResourcesCampaigns();
    });
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }
  public getLink(link: string): string {
    return getLink(link);
  }

  private setResourcesCampaigns(): void {
    this.linkSections = [];

    this.userService.getHeartbeat().flatMap(rsp => this.targetingService.getCampaigns(rsp.data.rallyId, [AllProgramsPageCampaigns]))
      .filter(({data}) => !!(data && data.placements))
      .map(({data: {placements}}) => placements)
      .subscribe(campaigns => {
        if (campaigns[CampaignPlacementType.ArcadeResourcesText]) {
          this.messageSections = campaigns[CampaignPlacementType.ArcadeResourcesText];
        }
        if (campaigns[CampaignPlacementType.ArcadeResourcesSection1Link]) {
          this.linkSections.push({
            headline: 'BENEFITS_AND_RESOURCES',
            links: campaigns[CampaignPlacementType.ArcadeResourcesSection1Link],
          });
        }
        if (campaigns[CampaignPlacementType.ArcadeResourcesSection2Link]) {
          this.linkSections.push({
            headline: 'RELATED_CONTENT_AND_WEBSITES',
            links: campaigns[CampaignPlacementType.ArcadeResourcesSection2Link],
          });
        }
      });
  }
}
