import { IScope } from 'angular';
import { Observable } from 'rxjs/Observable';
import activateVideoTemplate from 'views/modals/activate-video-modal.html';
import { IProviderVideo } from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IStateParams } from '../../../arcade.module.interfaces';
import { ISundaySkySessionParams } from '../../../ui/sunday-sky/sunday-sky-video.interfaces';
import { isiOSSafari } from '../../../util/browser/browser';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { ITranscriptEventDetail } from './../../../ui/sunday-sky/sunday-sky-video.component';

export class ActivateVideoComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = ActivateVideoController;
    this.templateUrl = activateVideoTemplate;
  }
}

export class ActivateVideoController implements ng.IComponentController {
  public pageName: string;
  public providerVideo: IProviderVideo;
  public request: Observable<IProviderVideo>;
  public sessionParams: ISundaySkySessionParams;
  public sundaySkyToken: string;
  public sundaySkyEndpointUrl: string;
  public showTranscriptButton: boolean;
  public transcriptUrl: string;
  public person: string;
  public posterImageUrl: string;

  constructor(
    private $stateParams: IStateParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Environment: IEnvironmentConstants,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ACTIVATE_VIDEO);

    this.sundaySkyToken = this.Environment.CONFIG.ARCADE_WEB_SUNDAY_SKY_ACTIVATE_TOKEN;
    this.sundaySkyEndpointUrl = this.Environment.CONFIG.ARCADE_WEB_SUNDAY_SKY_ACTIVATE_ENDPOINT_URL;
    this.showTranscriptButton = isiOSSafari() ? this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_SHOW_IOS_TRANSCRIPT_VIDEO : true;

    this.request = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .do(({ currentUser }) => {
        this.person = currentUser.userInfo.firstName;
        this.posterImageUrl =
          'https://sf-00000404.images.sundaysky.com/image.png?text=HI,+' +
          currentUser.userInfo.firstName;
      })
      .flatMap(({ currentUser }) =>
        this.profileService
          .getActivateProviderVideoKey(currentUser)
          .map(rsp => rsp.data),
      )
      .do(providerVideo => {
        this.providerVideo = providerVideo;
        this.sessionParams = {
          bigfive: providerVideo.providerVideoKey,
        };
      });
  }

  public handleTranscript(): (e: CustomEvent<ITranscriptEventDetail>) => void {
    return (e: CustomEvent<ITranscriptEventDetail>) => {
      this.transcriptUrl = e.detail.descriptivetranscript;
    };
  }

  public $onInit(): void {
    this.pageName =
      (this.$stateParams.fromStateName &&
        this.$stateParams.fromStateName.split('.').pop()) ||
      'overview-video';
    this.request.subscribe(() => undefined, console.warn);
  }
}
