// tslint:disable:max-classes-per-file
import { PrintController } from './print.controller';
import { IPrintController } from './print.interfaces';

export const PrintContentId = 'print-content';

export class PrintDirective implements ng.IDirective {
  public restrict = 'E';
  public controller = PrintController;
  public controllerAs = '$print';
  public link = (scope, element, attrs, $print: IPrintController) => {
    scope.$on('$destroy', () => {
      $print.destroy();
    });
  }

  public static Factory(): ng.IDirectiveFactory {
    return () => new PrintDirective();
  }
}

export class PrintButtonDirective implements ng.IDirective {
  public restrict = 'A';
  public require = '^print';
  public link = (scope, element, attrs, $print) => {
    element.on('click', () => $print.print());
  }

  public static Factory(): ng.IDirectiveFactory {
    return () => new PrintButtonDirective();
  }
}

export class PrintElementDirective implements ng.IDirective {
  public restrict = 'A';
  public require = '^print';
  public link = (scope, element, attrs, $print: IPrintController) => {
    $print.addPrintContent(element);
  }

  public static Factory(): ng.IDirectiveFactory {
    return () => new PrintElementDirective();
  }
}
