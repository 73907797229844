import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { dentalCostCalculator } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { IResourceService } from 'scripts/util/resource/resource.service';
export class OrthoMaxExplanationController implements ng.IComponentController {
  public link: IResource;
  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private resourceService: IResourceService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ACCUMULATORS);
  }
  public $onInit(): void {
    this.link = this.resourceService.get(dentalCostCalculator);
  }
}
