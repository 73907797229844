import { IProfileService } from '../../api/profile/profile.service';
import { AlertCodes } from '../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../api/targeting/targeting.service';
import { IUserService } from '../../api/user/user.service';
import { Dictionary } from '../../util/constants/i18n.constants';
import { BannerDismissedKeyBase } from '../../util/constants/storage.constants';
import { IResourceService } from '../../util/resource/resource.service';
import { IFeatureFlagService } from './../../util/feature-flag/feature-flag.interface';

import { introVideo } from 'scripts/util/resource/resource.constants';
import videoBannerTemplate from 'views/chrome/video-banner.html';

export class VideoBannerComponent implements ng.IComponentOptions {
  public templateUrl: string;
  public controller: any;
  public bindings = {
    inHeader: '<',
  };

  constructor() {
    this.templateUrl = videoBannerTemplate;
    this.controller = VideoBannerController;
  }
}

export class VideoBannerController {
  // Bindings
  public inHeader: boolean;

  public bannerHasBeenViewed: boolean;
  private showVideoBanner: boolean;
  private onboardingURL: string;
  private _dismissed: boolean = false;
  private storageBannnerKey: string;

  constructor(
    private $state: angular.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private featureFlagService: IFeatureFlagService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ONBOARDING);

    this.onboardingURL = this.resourceService.get(introVideo);
  }

  public $onInit(): void {
    this.showVideoBanner = false;
    this.userService.getHeartbeat()
      .let(this.profileService.toCurrentProfile())
      .takeWhile(profile => profile.memberFeatures.activateEligible)
      .flatMap(profile => this.targetingService.getAlerts(profile.rallyId)
      .map(rsp => rsp.data), (profile, alerts) => ({profile, alerts}))
      .do(({ profile, alerts }) => {
        const eiobAlertPresent = alerts.alerts.some(alert => alert.code === AlertCodes.EIOB);
        this.storageBannnerKey = `${BannerDismissedKeyBase}_${profile.rallyId}`;
        if (eiobAlertPresent) {
          this.showVideoBanner = true;
        }

        const previouslyDismissed = localStorage.getItem(this.storageBannnerKey);
        if (previouslyDismissed === 'true') {
          this.dismiss();
        }
      }).subscribe();
  }

  public get bannerInHeader(): boolean {
    return this.inHeader;
  }

  public get dismissable(): boolean {
    return this.bannerInHeader && !this._dismissed;
  }

  public get dismissed(): boolean {
    return this._dismissed;
  }

  public dismiss(): void {
    if (this.dismissable) {
      this._dismissed = true;
      localStorage.setItem(this.storageBannnerKey, 'true');
    }
  }

  public toVideo(): void {
    if (this.featureFlagService.isActivateVideoOn()) {
      this.$timeout(() => this.$state.go('modal.overviewVideo'));
    } else {
      window.open(this.onboardingURL, '_blank');
    }
    this.dismiss();
  }
}
