import incentivesTemplate from 'views/dashboard/incentives.html';
import { IncentivesController } from './incentives.controller';

export class IncentivesComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = IncentivesController;
    this.templateUrl = incentivesTemplate;
  }
}
