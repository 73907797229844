import { Observable } from 'rxjs/Observable';
import { ICoverageTimePeriod } from '../../../api/api.interfaces';
import { IBenefit, IIdCard, IIdCardsResponse } from '../../../api/plans/plans.interfaces';
import { IPlanCoverage, IProfile, IProfileUser } from '../../../api/profile/profile.interfaces';
import { ICarouselController } from '../../../ui/carousel/carousel.interfaces';
import { IConfig } from '../../../util/constants/environment.interfaces';

export interface ICoverageSection extends IPlanCoverage {
  cards: IIdCard[];
  flipped: boolean;
  idCardsRequest: Observable<IIdCardsResponse>;
  planName: string;
  planType: string;
  template: string;
  selectedDepSeqNum?: string;
  showGroupNumber?: boolean;
  showCarveOutLink?: boolean;
  $carousel?: ICarouselController;
}

export const IPlanTypes = {
  MEDICAL: 'MEDICAL_PLAN',
  VISION: 'VISION_PLAN',
  DENTAL: 'DENTAL_PLAN',
  BEHAVIORAL_HEALTH: 'BH_PLAN',
  RX: 'DRUG_PLAN',
};

export interface IIdCardsStateParams extends ng.ui.IStateParamsService {
  flipped?: boolean;
}
