import {IFeatureFlagService} from 'scripts/util/feature-flag/feature-flag.interface';
import { activate } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import singleStepContainerTemplate from 'views/dashboard/single-step-container.html';
import { ActivateStepStatus } from '../../../api/activate/activate.interfaces';
import { IActivatePlanStep } from '../../../api/activate/activate.interfaces';
import { IActivateService } from '../../../api/activate/activate.service';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import {IEnvironmentConstants} from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';

export enum StepName {
  basicCommunicationPreference = 'basic_communication_preference',
  basicMiniSurvey = 'basic_mini-survey',
  basicPhoneInfo = 'basic_phone_info',
  emailBounce = 'email_bounce',
}

const contentConfig = {
  [StepName.basicMiniSurvey]: {
    header: 'ACTIVATE_MINI_SURVEY_HEADER',
    body: 'ACTIVATE_MINI_SURVEY_BODY',
    cta: 'ACTIVATE_MINI_SURVEY_CTA',
  },
  [StepName.basicPhoneInfo]: {
    header: 'ACTIVATE_CONFIRM_CONTACT_HEADER',
    body: 'ACTIVATE_CONFIRM_CONTACT_BODY',
    cta: 'ACTIVATE_CONFIRM_CONTACT_CTA',
  },
  [StepName.basicCommunicationPreference]: {
    header: 'ACTIVATE_EMAIL_PAPERLESS_HEADER',
    body: 'ACTIVATE_EMAIL_PAPERLESS_BODY',
    cta: 'ACTIVATE_EMAIL_PAPERLESS_CTA',
  },
  [StepName.emailBounce]: {
    header: 'ACTIVATE_EMAIL_BOUNCE_HEADER',
    body: 'ACTIVATE_EMAIL_BOUNCE_BODY',
    cta: 'ACTIVATE_EMAIL_BOUNCE_CTA',
  },
};

const iconPaths = {
  [StepName.basicMiniSurvey]: '/images/illustrations/Survey-Banner.svg',
  [StepName.basicPhoneInfo]: '/images/illustrations/Phone-Banner.svg',
  [StepName.basicCommunicationPreference]: '/images/illustrations/Paperless-Banner.svg',
  [StepName.emailBounce]: 'images/illustrations/Email-Banner.svg',
};

export interface ISingleStepContent {
  header: string;
  body: string;
  cta: string;
}

export interface ISingleStep extends IActivatePlanStep {
  isSkipped: boolean;
  icon: string;
  content: ISingleStepContent | {};
}

export class SingleStepContainerComponent implements ng.IComponentOptions {
  public templateUrl: string;
  public controller: any;
  constructor() {
    this.templateUrl = singleStepContainerTemplate;
    this.controller = SingleStepContainerController;
  }
}

export class SingleStepContainerController {
  public activateResourceLink: IResource;
  public singleSteps: ISingleStep[];

  constructor(
    $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private activateService: IActivateService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private resourceService: IResourceService,
    private profileService: IProfileService,
    private userService: IUserService,
) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ONBOARDING);

    this.activateResourceLink = activate;
  }

  public $onInit(): void {
    if (this.featureFlagService.isActivateStepsOn()) {
      if (this.featureFlagService.isActivateStepsV6On()) {
      this.userService.getHeartbeat()
        .let(this.profileService.toCurrentProfile())
        .flatMap(profile => this.activateService.getSteps(profile.rallyId))
        .subscribe(rsp => {
          if (!!rsp.data.additional) {
            this.singleSteps = rsp.data.additional.map(plans =>
              plans.steps.filter(step => (contentConfig[step.stepName] &&
              step.stepState === ActivateStepStatus.skipped) ||
              (step.stepName === StepName.emailBounce && step.stepState === ActivateStepStatus.unstarted) &&
            step.bannerPriority).map(step => {
              return {
                ...step,
                isSkipped: step.stepState === ActivateStepStatus.skipped,
                icon: iconPaths[step.stepName],
                content: contentConfig[step.stepName] || {},
              };
            })).reduce((x, y) => x.concat(y), [])
              .sort((x, y) => x.bannerPriority - y.bannerPriority)
              .slice(0, this.Environment.CONFIG.ARCADE_WEB_MAX_NUM_OF_ACTIVATE_INLINE_BANNERS);
          }
        });
      } else {
        this.userService.getHeartbeat()
          .let(this.profileService.toCurrentProfile())
          .flatMap(profile => this.activateService.getStepsV4(profile.rallyId))
          .subscribe(rsp => {
            this.singleSteps = rsp.data.steps.filter(step => contentConfig[step.stepName] &&
              step.stepState === ActivateStepStatus.skipped).map(step => {
              return {
                ...step,
                isSkipped: step.stepState === ActivateStepStatus.skipped,
                icon: iconPaths[step.stepName],
                content: contentConfig[step.stepName] || {},
              };
            });
          });
      }
    }
  }
}
