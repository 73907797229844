import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'scripts/i18n';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

export interface IResponsiveTableProps<T = (string | JSX.Element), U = (string | JSX.Element)> {
  tableData: IResponsiveTableData<T, U>;
}

export interface IResponsiveTableData<T = (string | JSX.Element), U = (string | JSX.Element)> {
  headers: T[];
  rows: Array<IResponsiveTableRow<U>>;
}

interface IResponsiveTableRow<T> {
  columns: T[];
}

interface IBodyRowProps<T = (string | JSX.Element), U = (string | JSX.Element)> {
  headers: T[];
  columns: U[];
}

export const ResponsiveTable: FunctionComponent<IResponsiveTableProps> = ({tableData: {headers, rows}}) => (
  <table className="responsive-table">
    <thead className="hide-mobile">
      <tr>
      {headers.map((header, index) => <th key={index}>{header}</th>)}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, index) => <BodyRow headers={headers} key={index} columns={row.columns}/>)}
    </tbody>
  </table>
);

const BodyRow: FunctionComponent<IBodyRowProps> = ({headers, columns}) => {
  const { t } = useTranslation(Dictionary.COMMON, { i18n });
  const columnsToRender = columns
    .map((column, index) => {
      return (
        <td key={index}><span className="hide-desktop">{headers[index]}</span><span>{index === 7 ? t(column as string) : column}</span></td>
      );
    });

  return <tr>{columnsToRender}</tr>;
};
