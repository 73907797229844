import axios, { AxiosError } from 'axios';
import { IAxiosRequestConfig } from 'scripts/api/api.interfaces';
import { inflight } from 'scripts/util/promise-inflight/promise-inflight';

const HttpClient = {

  get<T>(url: string, config?: IAxiosRequestConfig): Promise<T> {
    return inflight<T>(url, () => HttpClient.request('GET', url, config))
    .catch((err: AxiosError) => {
      const {response: error} = err;
      // Error handling for get requests goes here
      // Added '500' as an example
      if (error.status === 500) {
        console.warn('Failed to retrieve data. Details below:\n' +
              '\tmessage: ' + (error.data.code || 'NONE') + '\n' +
              '\tstatus: ' + error.status + '\n' +
              '\turl: ' + url + '\n' +
              '\tid: ' + (error.data.correlationId || 'NONE'));
      }
      throw error;
    });
  },

  post<T>(url: string, data?: {}, config?: IAxiosRequestConfig): Promise<T> {
    const configWithData = { data, ...config };
    return HttpClient.request('POST', url, configWithData);
  },

  request(method: string, url: string, config?: IAxiosRequestConfig): Promise<any> {
    const configWithDefaults = { method, url, ...config };
    return axios.request(configWithDefaults);
  },
};

export default HttpClient;
