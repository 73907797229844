import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { IPopulationService } from 'scripts/util/population/population.service';
import { askAQuestion, chatWithAgent, memberServicesPhone } from 'scripts/util/resource/resource.constants';
import { getLink } from 'scripts/util/uri/uri';
import { CoverageType, IDateFilter, LinkTarget } from '../../../api/api.interfaces';
import { IBenefit } from '../../../api/plans/plans.interfaces';
import { IPlansService } from '../../../api/plans/plans.service';
import {
  IContactHours,
  IContactInfo,
  IProducts,
  IProductsResponse,
  LineOfBusiness,
  MembershipCategory,
  State,
 } from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { ITrackingEventRequest, TrackingTriggerType } from '../../../api/tracking/tracking.interfaces';
import { IUserService } from '../../../api/user/user.service';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary} from '../../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../../util/feature-flag/feature-flag.interface';
import { IGenesysService } from '../../../util/genesys/genesys.service';
import { IResourceService } from '../../../util/resource/resource.service';
import { sendOutOfFlowAAEvent } from '../../../util/tracking/adobe-analytics';
import { ExpatCustomPolicies, IClientConfig, INurseLineMessage } from './../../../api/targeting/targeting.interfaces';
import { ITargetingService } from './../../../api/targeting/targeting.service';
import { ILocaleService } from './../../../util/locale/locale.service';
import { IContactData, IContactUsController } from './contact-us.interfaces';

export class ContactUsController implements IContactUsController {
  public memberId: string;
  public policyNumber: string;
  public planName: string;
  public benefitsRequest: Observable<IBenefit>;
  public productsRequest: Observable<IProductsResponse>;
  public contactData: IContactData;
  public customNurseMessage: INurseLineMessage;
  public customMemberServicesMessage: string;
  public isExpatriate: boolean;
  public showIdCardLink: boolean;
  private clientConfigReq: Observable<IClientConfig>;
  private isCS: boolean;
  private isCSNewJersey: boolean;
  private isEmpire: boolean;
  private localeSubscription: Subscription;

  private referrerKey = 'arcade.helpReferrer';

  constructor(
    private $filter: IDateFilter,
    private $state: ng.ui.IStateService,
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private genesysService: IGenesysService,
    private localeService: ILocaleService,
    private plansService: IPlansService,
    private populationService: IPopulationService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.CONTACT_US);
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_INFO);
    $translatePartialLoader.addPart(Dictionary.ID_CARDS);

    const referrer = $state.params.referrer;
    if (referrer && referrer !== 'authenticated.help') {
      $window.sessionStorage.setItem(this.referrerKey, referrer);
    }

    this.contactData = {
      sections: [
        {
          title: 'MEMBER_SERVICES_PHONE',
          iconId: 'phone',
          contactItems: [
            {
              text: 'CALL_YOU',
              description: 'CALL_YOU_LONG',
              href: this.resourceService.get(memberServicesPhone),
              target: LinkTarget.Blank,
            },
            {
              text: 'CALL_US',
              description: 'CALL_US_ID_CARD',
            },
          ],
        },
        {
          title: 'MEMBER_SERVICES_ONLINE',
          iconId: 'chat',
          contactItems: [
            {
              text: 'CHAT_NOW',
              description: 'ENGLISH_ONLY',
              href: this.resourceService.get(chatWithAgent),
              target: LinkTarget.Blank,
            },
            {
              text: 'EMAIL_US',
              description: 'EMAIL_US_LONG_TWO_DAYS',
              href: this.resourceService.get(askAQuestion),
              target: LinkTarget.Blank,
            },
          ],
        },
        {
          title: 'WEB_ASSISTANCE',
          iconId: 'computer',
          contactItems: [
            {
              description: 'WEB_ASSISTANCE_LONG',
            },
          ],
        },
      ],
      nurse: {
        title: 'ASK_NURSE',
        description: 'ASK_NURSE_LONG',
        chat: 'CHAT_NOW',
        iconId: 'nurse',
      },
    };

    this.benefitsRequest = userService.getHeartbeat()
      .let(profileService.toProfile())
      .flatMap(profile => this.plansService.getBenefits(profile.data.currentUser.rallyId))
      .flatMap(benefits => benefits.data.benefits)
      .first(({coverageType}) => coverageType === CoverageType.Medical);

    this.clientConfigReq = this.userService.getHeartbeat()
      .flatMap(({data}) => this.targetingService.getClientConfig(data.rallyId))
      .do(clientConfig => {
        this.customNurseMessage = clientConfig && clientConfig.customMessaging.nurseLineMessage;
        this.customMemberServicesMessage = clientConfig && clientConfig.customMessaging.memberServicesMessage;
      });

    this.productsRequest = userService.getHeartbeat()
      .flatMap(rsp => profileService.getProducts(rsp.data.rallyId));

  }

  public $onInit(): void {
    this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .do(profile => this.isExpatriate = (profile.data.currentUser.membershipCategory === MembershipCategory.EXPATRIATE))
      .flatMap(profile => profile.data.currentUser.planCoverages)
      .first(({coverageType}) => coverageType === CoverageType.Medical)
      .subscribe(medicalPlan => {
        this.memberId = medicalPlan.memberId.id;
        this.policyNumber = medicalPlan.policyNumber;
        if (this.isExpatriate) {
          this.setExpatPhoneNums();
        }
      }, console.warn);

    this.userService.getHeartbeat().let(this.profileService.toProfile()).subscribe(rsp => {
      const {currentUser} = rsp.data;
      this.isCS = currentUser.lineOfBusiness === LineOfBusiness.CS;
      this.isCSNewJersey = this.isCS && currentUser.userInfo.state === State.NJ;
      this.isEmpire = currentUser.membershipCategory === MembershipCategory.EMPIRE;
      const hasTermedMedical = this.profileService.isTermedForCoverageType(CoverageType.Medical, currentUser.planCoverages);
      this.showIdCardLink = !this.isCS || !hasTermedMedical;
    }, console.warn);

    this.benefitsRequest.subscribe(medicalPlan => this.planName = medicalPlan.planName, console.warn);

    this.clientConfigReq.subscribe(() => undefined, console.warn);

    this.productsRequest.subscribe(rsp => this.setContactDataValues(rsp.data.products), console.warn);

    this.localeSubscription = this.localeService.localeChanged
      .flatMap(() => this.clientConfigReq)
      .subscribe(() => undefined, console.warn);
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public getPreviousHref(): string {
    const referrer = this.$window.sessionStorage.getItem(this.referrerKey);
    if (this.$state.get(referrer)) {
      return this.$state.href(referrer);
    } else if (this.isReferrerWhitelisted(referrer)) {
      return getLink(referrer);
    } else {
      return this.$state.href('authenticated.dashboard');
    }
  }

  public getViewIdLinkText(): string {
    if (this.isEmpire) {
      return 'VIEW_COVERAGE_DETAILS';
    } else if (this.isCS) {
      return this.isCSNewJersey ? 'VIEW_MEMBER_CARD' : 'PRINT_CARD';
    } else {
      return 'PRINT_CARDS';
    }
  }

  private isReferrerWhitelisted(referrerUrl: string): boolean {
    if (!referrerUrl) {
      return false;
    }

    const referrer = this.getAnchor(referrerUrl);
    const {baseUrls} = this.populationService.getPopulation();
    switch (referrer.hostname) {
      case this.$window.location.hostname:
      case this.getAnchor(baseUrls.myUhcBaseUrl).hostname:
      case 'es-' + this.getAnchor(baseUrls.myUhcBaseUrl).hostname:
      case this.getAnchor(baseUrls.connectUrl).hostname:
      case this.getAnchor(baseUrls.engageUrl).hostname:
        return true;
      default:
        return false;
    }
  }

  private getAnchor(url: string): HTMLAnchorElement {
    const anchor = document.createElement('a');
    anchor.href = url;
    return anchor;
  }

  private getTimeRange(hours: IContactHours): string {
    return this.$filter('amDateFormat')(hours.startTime, 'h:mm A') + ' - ' + this.$filter('amDateFormat')(hours.endTime, 'h:mm A');
  }

  private createPhoneHref(phoneNumber: string): string {
    return 'tel:' + phoneNumber;
  }

  private setPhoneSupportValues(products: IProducts): void {
    const phoneSupportSection = this.contactData.sections[0];
    if (products.easyConnect && products.easyConnect.contactInformation && products.easyConnect.contactInformation.hours) {
      const hours: IContactHours = products.easyConnect.contactInformation.hours[0];
      phoneSupportSection.contactItems[0].dayRange = hours.frequencyValue || 'WEEKDAY';
      phoneSupportSection.contactItems[0].timeRange = this.getTimeRange(hours);
      phoneSupportSection.contactItems[0].timeZone = this.getTimeZoneTranslationKey(hours.timeZone);
      phoneSupportSection.contactItems[0].show = true;
    }
    if (products.medicalSupport && products.medicalSupport.contactInformation && products.medicalSupport.contactInformation.hours
      && !this.isEmpire) {
      if (products.medicalSupport.contactInformation.phoneNum) {
        const contactInfo: IContactInfo = products.medicalSupport.contactInformation;
        const hours = products.medicalSupport.contactInformation.hours[0];
        if (!this.isExpatriate) {
          phoneSupportSection.contactItems[1].phoneNum = contactInfo.phoneNum;
          phoneSupportSection.contactItems[1].text = 'CALL_US_MEMBER';
          phoneSupportSection.contactItems[1].description = this.isCS ? 'CALL_US_MEMBER_PHONE_TTY' : 'CALL_US_MEMBER_PHONE';
        }
        phoneSupportSection.contactItems[1].dayRange = hours.frequencyValue || 'WEEKDAY';
        phoneSupportSection.contactItems[1].timeRange = this.getTimeRange(hours);
        phoneSupportSection.contactItems[1].timeZone = this.getTimeZoneTranslationKey(hours.timeZone);
      }
      phoneSupportSection.contactItems[1].show = true;
    }
    if (this.isEmpire) {
      phoneSupportSection.contactItems[0].show = false;
      phoneSupportSection.contactItems[1].show = true;

      phoneSupportSection.contactItems[1].text = 'CALL_US_MEMBER';
      phoneSupportSection.contactItems[1].description = 'EMPIRE_PHONE_LONG';
      phoneSupportSection.contactItems[1].phoneNum = '1-877-769-7447';
    }
    phoneSupportSection.show = phoneSupportSection.contactItems[0].show || phoneSupportSection.contactItems[1].show;
  }

  private setChatSupportValues(products: IProducts): void {
    const chatSupportSection = this.contactData.sections[1];
    if (products.clickToChat && products.clickToChat.contactInformation && products.clickToChat.contactInformation.hours) {
      const clickToChat = chatSupportSection.contactItems[0];
      const hours = products.clickToChat.contactInformation.hours[0];
      clickToChat.dayRange = hours.frequencyValue || 'WEEKDAY';
      clickToChat.timeRange = this.getTimeRange(hours);
      clickToChat.timeZone = this.getTimeZoneTranslationKey(hours.timeZone);
      clickToChat.show = true;
      if (this.featureFlagService.isGenesysChatOn()) {
        clickToChat.text = 'CHAT_UNAVAILABLE';
        clickToChat.href = '';
        this.genesysService.isChatAvailable().subscribe(isAvailable => {
          if (isAvailable) {
            this.genesysService.updatePageAndButtonClassNames();
            clickToChat.className = this.genesysService.chatButtonClassName;
            clickToChat.text = 'CHAT_NOW';
            clickToChat.href = '/openGenesysChat';
            clickToChat.action = (e: ng.IAngularEvent) => {
              e.preventDefault();
            };

            const newEvent = {
              uri: 'help',
              featureList: ['help', 'contact-us', 'genesys-chat'],
              trigger: TrackingTriggerType.Click,
              actionName: 'view',
              serviceVersion: 'xx',
              placement: '',
            } as ITrackingEventRequest;

            sendOutOfFlowAAEvent(newEvent);
          }
        });
      }
    }
    if (products.secureMessage) {
      chatSupportSection.contactItems[1].show = true;
    }
    chatSupportSection.show = chatSupportSection.contactItems[0].show || chatSupportSection.contactItems[1].show;
  }

  private setWebSupportValues(products: IProducts): void {
    const webSupportSection = this.contactData.sections[2];
    if (products.technicalSupport && products.technicalSupport.contactInformation && products.technicalSupport.contactInformation.hours) {
      const contactInfo: IContactInfo = products.technicalSupport.contactInformation;
      const hours = products.technicalSupport.contactInformation.hours[0];
      if (this.isCS) {
        // ARC-6467: Isolate actual phone number from '/TTY:711' at end of value
        // Hopefuly Optum fixes this on their side, at which point this line can be removed
        // Tech Debt ticket to track issue: ARC-6490
        const phoneNum = contactInfo.phoneNum.split('/')[0];
        webSupportSection.contactItems[0].phoneNum = phoneNum;
        webSupportSection.contactItems[0].text = 'WEB_PHONE_TTY';
      } else {
        webSupportSection.contactItems[0].text = contactInfo.phoneNum;
        webSupportSection.contactItems[0].href = this.createPhoneHref(contactInfo.phoneNum);
      }
      webSupportSection.contactItems[0].dayRange = hours.frequencyValue || 'WEEKDAY';
      webSupportSection.contactItems[0].timeRange = this.getTimeRange(hours);
      webSupportSection.contactItems[0].timeZone = this.getTimeZoneTranslationKey(hours.timeZone);
      webSupportSection.contactItems[0].show = true;
    }
    webSupportSection.show = webSupportSection.contactItems[0].show;
  }

  private setNurseSupportValues(products: IProducts): void {
    const nurseSupportSection = this.contactData.nurse;
    if (products.nurseChat && products.nurseChat.contactInformation) {
      const contactInfo = products.nurseChat.contactInformation;
      if (contactInfo.phoneNum) {
        nurseSupportSection.phone = contactInfo.phoneNum;
        nurseSupportSection.phoneHref = this.createPhoneHref(contactInfo.phoneNum);
        nurseSupportSection.showPhone = true;
      }
      if (contactInfo.phoneExt) {
        nurseSupportSection.ext = contactInfo.phoneExt;
      }
      if (contactInfo.url) {
        nurseSupportSection.chatHref = contactInfo.url;
        nurseSupportSection.showChat = true;
      }
      if (nurseSupportSection.showPhone || nurseSupportSection.showChat) {
        nurseSupportSection.show = true;
        return;
      }
    }
    if (products.nurseLine && products.nurseLine.contactInformation && products.nurseLine.contactInformation.phoneNum) {
      const phoneNum = products.nurseLine.contactInformation.phoneNum;
      nurseSupportSection.phone = phoneNum;
      nurseSupportSection.phoneHref = this.createPhoneHref(phoneNum);
      nurseSupportSection.showPhone = true;
      nurseSupportSection.show = true;
    }
  }

  private setContactDataValues(products: IProducts): void {
    this.setPhoneSupportValues(products);
    this.setChatSupportValues(products);
    this.setWebSupportValues(products);
    this.setNurseSupportValues(products);
    this.setEmailUsValues();
  }

  private getTimeZoneTranslationKey(timeZone: string): string {
    // all other possible values don't need to be translated
    return timeZone === 'LOCAL TIME' ? 'LOCAL_TIME' : timeZone;
  }

  private setExpatPhoneNums(): void {
    const callUs = this.contactData.sections[0].contactItems[1];
    callUs.description = 'CALL_US_EXPAT_DIRECT';
    callUs.phoneNum = '+1.877.844.0280';
    callUs.alternatePhoneNum = '+1.763.274.7362';
    if (ExpatCustomPolicies.Ford.indexOf(this.policyNumber) > -1) {
      callUs.phoneNum = '+1.877.294.2042';
      callUs.alternatePhoneNum = '+1.763.274.7367';
    } else if (ExpatCustomPolicies.InternationalBankOfKorea.indexOf(this.policyNumber) > -1) {
      callUs.description = 'CALL_US_EXPAT';
      callUs.phoneNum = '+1.844.231.9074';
    }
  }

  private setEmailUsValues(): void {
    const emailUs = this.contactData.sections[1].contactItems[1];
    if (this.isCS) {
      emailUs.description = 'EMAIL_US_LONG_ONE_DAY';
    }
  }
}
