import { IMelodeonController, IMelodeonItem, IMelodeonPair, MelodeonItemType } from './melodeon.interfaces';

export class MelodeonController implements IMelodeonController {
  public animateTime: number;
  public pairs: IMelodeonPair[];
  public allExpanded: boolean;
  public groupId: string;
  private numPairs: number;
  private toggles: IMelodeonItem[];
  private contents: IMelodeonItem[];

  constructor(public $scope: ng.IScope, public $timeout: ng.ITimeoutService) {
    'ngInject';
    this.animateTime = this.animateTime || 500;
    this.numPairs = 0;
    this.pairs = [];
    this.toggles = [];
    this.contents = [];
  }

  public addToggle(scope: ng.IScope, element: ng.IAugmentedJQuery): number {
    const item = { element, scope, itemType: MelodeonItemType.Toggle };
    const index = this.toggles.push(item) - 1;

    if (this.pairs[index]) {
      this.pairs[index].toggle = item;
    } else {
      this.pairs.push({ toggle: item });
    }

    return index;
  }

  public removeToggle(index: number, element: ng.IAugmentedJQuery): void {
    if (this.toggles[index] && this.toggles[index].element === element) {
      this.toggles.splice(index, 1);
    }
  }

  public addContent(scope: ng.IScope, element: ng.IAugmentedJQuery): number {
    const item = { element, scope, itemType: MelodeonItemType.Content };
    const index = this.contents.push(item) - 1;

    if (this.pairs[index]) {
      this.pairs[index].content = item;
    } else {
      this.pairs.push({ content: item });
    }

    return index;
  }

  public removeContent(index: number, element: ng.IAugmentedJQuery): void {
    if (this.contents[index] && this.contents[index].element === element) {
      this.contents.splice(index, 1);
    }
  }

  public toggle(index: number): void {
    if (this.pairs[index].toggle.scope.$animating) {
      return;
    }
    this.pairs[index].$expanded = !this.pairs[index].$expanded;
    this.pairs[index].toggle.scope.$animating = true;
    this.$timeout(() => {
      this.pairs[index].toggle.scope.$animating = false;
    }, this.animateTime);
    this.$scope.$broadcast('$melodeonExpanded', this.pairs[index]);
    for (const pair of this.pairs) {
      if (!pair.$expanded) {
        this.allExpanded = false;
        return;
      }
    }
    this.allExpanded = true;
  }

  public toggleAll(): void {
    this.pairs.forEach(pair => {
      pair.$expanded = !this.allExpanded;
      this.$scope.$broadcast('$melodeonExpanded', pair);
    });
    this.allExpanded = !this.allExpanded;
  }

  public focusToggle(i: number): void {
    const targetToggleId = `${this.groupId}-toggle-${i}`;
    const targetToggleElement = document.getElementById(targetToggleId);
    if (targetToggleElement) {
      targetToggleElement.focus();
    }
  }
}
