import { LoginController } from './login.controller';

export class LoginComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;

  constructor() {
    this.controller = LoginController;
    this.template = '';
  }
}
