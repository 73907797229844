import { CacheName } from 'scripts/api/api.interfaces';
import { getCache } from 'scripts/api/cache';
import HttpClient from 'scripts/api/http-client';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { dateStringToMoment, stringToFloat } from 'scripts/util/conversion/conversion';
import { claimUris } from 'scripts/util/uri/uri';
import {
  IClaim,
  IClaimsManageResponse,
  IClaimsSummaryResponse,
  IHealthcareClaimDetails,
  IManageClaimData,
} from './claims.interfaces';

function getSummary(profile: IProfileUser): Promise<IClaimsSummaryResponse> {
  const { dependentSeqNum, membershipCategory } = profile;
  const url = claimUris.summary(profile.rallyId, { d: dependentSeqNum, membershipCategory });
  return HttpClient.get<IClaimsSummaryResponse>(url, { cacheName: CacheName.Claims }).then(rsp => {
    for (const claim of rsp.data) {
      stringToFloat(claim, 'balance', 'copay', 'value');
      stringToFloat(claim, 'balance', 'deductible', 'value');
      stringToFloat(claim, 'balance', 'healthPlanDiscount', 'value');
      stringToFloat(claim, 'balance', 'healthPlanPays', 'value');
      stringToFloat(claim, 'balance', 'patientResponsibility', 'value');
      stringToFloat(claim, 'balance', 'totalBilledAmount', 'value');
      stringToFloat(claim, 'balance', 'youMayOweAmount', 'value');
      dateStringToMoment(claim, 'lastServiceDate');
      dateStringToMoment(claim, 'processedDate');
      dateStringToMoment(claim, 'serviceDate');
    }
    return rsp;
  });
}

function saveClaim(profile: IProfileUser, claim: IClaim | IHealthcareClaimDetails): Promise<IClaimsManageResponse> {
  const firstServiceDate = (claim as IClaim).serviceDate || (claim as IHealthcareClaimDetails).claimDates.serviceStartDate;
  const lastServiceDate = (claim as IClaim).lastServiceDate || (claim as IHealthcareClaimDetails).claimDates.lastServiceDate;
  const manageClaimData: IManageClaimData = {
    claimKey: claim.claimKey,
    manageClaim: {
      markPaid: claim.claimManagementInfo.markPaid,
      serviceDate: firstServiceDate.format('YYYY-MM-DD'),
      lastServiceDate: lastServiceDate.format('YYYY-MM-DD'),
      providerName: claim.providerName || '',
    },
  };
  const postUrl = claimUris.manage(profile.rallyId);
  return HttpClient.post<IClaimsManageResponse>(postUrl, manageClaimData).then(rsp => {
    getCache(CacheName.Claims).removeAll();
    getCache(CacheName.ClaimsNeedAttention).removeAll();
    getCache(CacheName.ClaimsHealthcare).removeAll();
    return rsp;
  });
}

export const ClaimsApi = {
  getSummary,
  saveClaim,
};
