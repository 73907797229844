import costsTemplate from 'views/dashboard/costs.html';
import { CostsController } from './costs.controller';

export class CostsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;

  constructor() {
    this.controller = CostsController;
    this.templateUrl = costsTemplate;
  }
}
