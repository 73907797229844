import communicationPreferenceTemplate from 'views/states/onboarding/communication-preference-modal.html';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { ICommunicationPreferenceService } from '../communication-preference.service';

export class CommunicationPreferenceComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = CommunicationPreferenceController;
    this.templateUrl = communicationPreferenceTemplate;
  }
}

export class CommunicationPreferenceController implements ng.IComponentController {
  public emailAddress: string;

  public emailErrorMessage: string;
  public mustCorrectEmail: boolean;
  public postInProgress: boolean;
  public showFailedPostMessage: boolean;

  constructor(
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private communicationPreferenceService: ICommunicationPreferenceService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ERRORS);
    $translatePartialLoader.addPart(Dictionary.ONBOARDING);

    this.emailAddress = communicationPreferenceService.communicationEmail;
  }

  public choosePaperless(): void {
    this.validateInput();
    if (this.isInputValid()) {
      this.mustCorrectEmail = false;
      this.doSave();
    } else {
      this.mustCorrectEmail = true;
    }
  }

  public declinePaperless(): void {
    this.postInProgress = true;
    this.communicationPreferenceService.choosePaperlessOption(false)
      .subscribe(() => undefined, err => this.handlePostFailure(err));
  }

  public hasInput(): boolean {
    return this.emailAddress != null;
  }

  public validateInput(): void {
    this.emailErrorMessage = undefined;
    const emailAddress = this.trimAndToLower(this.emailAddress);
    if (emailAddress === null || emailAddress === undefined) {
      this.emailErrorMessage = 'EMAIL_NOT_SPECIFIED';
    } else if (emailAddress.indexOf('@') < 0) {
      this.emailErrorMessage = 'EMAIL_NOT_VALID';
    }
  }

  private isInputValid(): boolean {
    return !this.emailErrorMessage;
  }

  private trimAndToLower(text: string): string {
    if (!text) {
      return text;
    }

    return text.toLowerCase().trim();
  }

  private doSave(): void {
    this.communicationPreferenceService.communicationEmail = this.trimAndToLower(this.emailAddress);
    this.postInProgress = true;
    this.communicationPreferenceService.choosePaperlessOption(true)
      .subscribe(() => undefined, err => this.handlePostFailure(err));

  }

  private handlePostFailure(err: any): void {
    this.postInProgress = false;
    this.showFailedPostMessage = true;
    this.$timeout(() => {
      this.showFailedPostMessage = false;
    }, 5000);
    console.warn(err);
  }
}
