import { LedgerServiceActionTypes } from 'scripts/actions/ledger-service-actions';
import { ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { ArcadeAction, IStateData } from 'scripts/reducers/reducer.interfaces';

export interface ILedgerState {
  accounts: IStateData<ILedgerAccount[]>;
}

export const initialState: ILedgerState = {
  accounts: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

export default (state: ILedgerState = initialState, action: ArcadeAction): ILedgerState => {
  switch (action.type) {
    case LedgerServiceActionTypes.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: {
          data: action.payload,
          error: false,
          loading: false,
        },
      };
    case LedgerServiceActionTypes.GET_ACCOUNTS_LOADING:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: true,
        },
      };
    case LedgerServiceActionTypes.GET_ACCOUNTS_ERROR:
      return {
        ...state,
        accounts: {
          data: undefined,
          error: true,
          loading: false,
        },
      };
    default:
      return state;
  }
};
