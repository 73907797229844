import angular from 'angular';
import promosTemplate from 'views/dashboard/promos.html';
import { PromosController } from './promos.controller';

export class PromosDirective implements ng.IDirective {
  public restrict = 'E';
  public templateUrl = promosTemplate;
  public scope = {};
  public controller = PromosController;
  public controllerAs = '$promos';
  public bindToController = {
    isProgramsPage: '<',
  };

  constructor(private $window: ng.IWindowService) {}
  public link = (scope, element: ng.IAugmentedJQuery, attrs, $promos: PromosController) => {
    const $window = this.$window;
    const acceptableViewableHeight = .75; // percentage from the top of the promo that counts as "in view"
    let inView: boolean = false;

    function scrollEvent(e: JQueryEventObject): void {
      const $carousel = $promos.$carousel;
      const scrollY = $window.scrollY;
      const windowHeight = $window.innerHeight;
      const promoHeight = element[0].offsetHeight;
      const promoTop = element[0].offsetTop;
      const promoBottom = promoTop + (promoHeight * acceptableViewableHeight) - windowHeight;

      inView = scrollY >= promoBottom && scrollY <= promoTop;

      if (inView && !$carousel.isPlaying && $carousel.slides.length > 1) {
        $carousel.play();
      } else if (!inView && $carousel.isPlaying) {
        $carousel.pause();
      }
    }

    scope.$watch('$promos.$carousel', $carousel => {
      if ($carousel === undefined || $carousel.slides.length < 1) {
        return;
      }

      $carousel.setPrevSlide();
      $carousel.setNextSlide();

      $carousel.pause();
      angular.element(this.$window)
        .off('scroll', scrollEvent)
        .on('scroll', scrollEvent);
    });

    scope.$on('$destroy', () => {
      angular.element(this.$window).off('scroll', scrollEvent);
    });
  }

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($window: ng.IWindowService) => {
      'ngInject';
      return new PromosDirective($window);
    };
    return directive;
  }
}
