import { LocaleActionTypes } from 'scripts/actions/locale-actions';
import { ArcadeAction } from 'scripts/reducers/reducer.interfaces';
import { getLocale } from 'scripts/util/locale/locale';
import { ILocale } from 'scripts/util/locale/locale.interfaces';
export { ILocale as ILocaleState } from 'scripts/util/locale/locale.interfaces';

export const initialState: ILocale = getLocale();

export default (state: ILocale = initialState, action: ArcadeAction): ILocale => {
  switch (action.type) {
    case LocaleActionTypes.CHANGE_LOCALE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
