import { Moment } from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { getMoneyValue } from 'scripts/util/money/money';
import dentalAccountSummaryTemplate from 'views/claims-and-accounts/summary/dental-account-summary.html';
import { CoverageType, ICoverageTimePeriod } from '../../../../api/api.interfaces';
import {
  BenefitNetwork,
  BenefitPaymentType,
  IUsefulAccumulator,
  IUsefulAccumulators,
  IUsefulBenefitsWithAccumulators,
} from '../../../../api/plans/plans.interfaces';
import { PlansService } from '../../../../api/plans/plans.service';
import { MembershipCategory } from '../../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../../api/profile/profile.service';
import { IUserService } from '../../../../api/user/user.service';
import { IAngularMoment } from '../../../../arcade.module.interfaces';
import { IStateService } from '../../../../util/state/state.service';
import { Dictionary } from './../../../../util/constants/i18n.constants';

export class DentalAccountSummaryComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = DentalAccountSummaryController;
    this.templateUrl = dentalAccountSummaryTemplate;
  }
}

export class DentalAccountSummaryController implements ng.IComponentController {
  public accumulators: IUsefulAccumulator[];
  public accumulatorsLastUpdated: Moment;
  public membershipCategory: MembershipCategory;
  public person: string;
  public planPeriod: ICoverageTimePeriod;
  public request: Observable<IUsefulBenefitsWithAccumulators>;
  private profileSubscription: Subscription;
  private useSelectedProfile: boolean;

  constructor(
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private moment: IAngularMoment,
    private plansService: PlansService,
    private profileService: IProfileService,
    private stateService: IStateService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);

    this.accumulators = [];
    this.accumulatorsLastUpdated = this.moment();
  }

  public $onInit(): void {
    this.useSelectedProfile = this.stateService.getUseSelectedProfile();

    const heartbeat = this.userService.getHeartbeat();
    const selectedUser = heartbeat.let(this.profileService.toCurrentProfile());
    const loggedInUser = heartbeat.let(this.profileService.toProfile()).map(profile => profile.data.currentUser);

    this.request = Observable.if(() => this.useSelectedProfile, selectedUser, loggedInUser)
      .do(currentUser => {
        this.person = currentUser.userInfo.firstName;
        this.membershipCategory = currentUser.membershipCategory;
        const dentalCoverage = this.profileService.getCoverage(CoverageType.Dental, currentUser.planCoverages);
        this.planPeriod = dentalCoverage && dentalCoverage.planPeriod;
      })
      .flatMap(currentUser => this.plansService.getBenefitsWithAccumulators(currentUser.rallyId, currentUser.dependentSeqNum))
      .do(usefulBenefits => this.accumulators = this.getSupportedAccumulators(usefulBenefits));

    this.request.subscribe(() => undefined, console.warn);

    this.profileSubscription = this.profileService.profileChanged
      .flatMap(() => Observable.if(() => this.useSelectedProfile, this.request, Observable.of(undefined)))
      .subscribe(() => undefined, console.warn);
  }

  public $onDestroy(): void {
    this.profileSubscription.unsubscribe();
  }

  public getPercentUsed(accumulator: IUsefulAccumulator): string {
    const percentage = accumulator.amount ? accumulator.amount.value / accumulator.max.value * 100 : 0;
    return percentage.toFixed(0) + '%';
  }

  public getMoneyValue(amount: number, decimal?: boolean): string {
    return getMoneyValue(amount, decimal);
  }

  private getSupportedAccumulators(usefulBenefits: IUsefulBenefitsWithAccumulators): IUsefulAccumulator[] {
    const accumulators: IUsefulAccumulator[] = [];
    if (usefulBenefits.DENTAL && usefulBenefits.DENTAL.inNetwork) {
      [BenefitPaymentType.IndividualDeductible, BenefitPaymentType.IndividualAnnualMax, BenefitPaymentType.IndividualLifetimeMax]
        .forEach(benefitType => {
          if (usefulBenefits.DENTAL.inNetwork[benefitType]) {
            accumulators.push(usefulBenefits.DENTAL.inNetwork[benefitType]);
          }
        });
    }
    return accumulators;
  }
}
