import angular from 'angular';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IModalController, IModalRootScope } from './modal.interfaces';

export interface IModalStateParams extends ng.ui.IStateParamsService {
  goBackOnClose?: boolean;
  goToOnClose?: string;
}

export class ModalController implements IModalController {
  public modalTitle: string;
  public modalSubtitle: string;
  public modalSecondarySubtitle: string;
  public fullscreen: boolean;
  public confirm: boolean;
  public closing: boolean;
  private previouslyFocusedElement: JQuery<Element>;
  private customCloseModal: () => void;

  constructor(
    private $element: ng.IAugmentedJQuery,
    private $location: ng.ILocationService,
    private $rootScope: IModalRootScope,
    private $state: ng.ui.IStateService,
    private $stateParams: IModalStateParams,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);

    $rootScope.modalOpen = true;

    this.modalTitle = this.modalTitle || '';
    this.modalSubtitle = this.modalSubtitle || '';
    this.modalSecondarySubtitle = this.modalSecondarySubtitle || '';
    this.fullscreen = this.fullscreen || false;
    this.confirm = this.confirm || false;
    this.closing = this.closing || false;
    this.previouslyFocusedElement = angular.element(window.document.activeElement);
    this.customCloseModal = this.customCloseModal || null;
  }

  public close(): void {
    if (this.customCloseModal) {
      this.customCloseModal();
    } else if (this.$stateParams.goBackOnClose && this.$window.history.length > 1) {
      this.$window.history.back();
    } else if (this.$stateParams.goToOnClose) {
      this.$window.location.href = this.$stateParams.goToOnClose;
    } else {
      let queryParams = this.$location.url().split('?')[1] || '';
      const url = this.$location.url().split('/modal')[0] || '';
      this.closing = true;
      this.$timeout(() => {
        queryParams = (queryParams) ? '?' + queryParams : '';
        this.$location.url(url + queryParams);
      }, 400);
    }
  }

  public destroy(): void {
    this.$rootScope.modalOpen = false;
    this.$timeout(() => {
      if (this.previouslyFocusedElement) {
        this.previouslyFocusedElement.focus();
      }
    });
  }

  public setFirstFocus(): void {
    const element = this.$element[0];
    const autoFocusElements = element.querySelectorAll('*[autofocus]');

    if (autoFocusElements.length > 0) {
      // Get the last defined autofocus element
      const lastAutoFocusElement: HTMLElement = autoFocusElements[autoFocusElements.length - 1] as HTMLElement;
      lastAutoFocusElement.focus();

      if (document.activeElement === lastAutoFocusElement) {
        return;
      }
    }

    const tabElements = this.getTabElements(element);

    if (tabElements.length > 0) {
      this.$timeout(() => tabElements[0].focus(), 0);
      return;
    }
  }

  public trapFocus(event: JQueryEventObject): void {
    const modal = this.$element[0];
    const tabElements = this.getTabElements(modal);
    const firstTab = tabElements[0];
    const lastTab = tabElements[tabElements.length - 1];

    if (event.shiftKey && event.target === firstTab) {
      lastTab.focus();
      event.preventDefault();
    } else if (!event.shiftKey && event.target === lastTab) {
      firstTab.focus();
      event.preventDefault();
    }
    /**
     * ARC-1392: The code below was preventing the default behavior of tab focus staying inside the
     * wait-for-container after hitting "try again" via keyboard. This code was just here to address a
     * potential edge case where the focus is outside of the modal, but setFirstFocus should prevent this
     * case from occurring. But just to be safe, for now it's commented out instead of removed entirely.
     */
    // else if (jQuery(modal).has(event.target).length === 0) {
    //   firstTab.focus();
    //   event.preventDefault();
    // }
  }

  private getTabElements(parent?: HTMLElement): HTMLElement[] {
    parent = parent || document.body;
    const possibilities = 'a[href], area[href], input:not([disabled]), select:not([disabled]), ' +
      'textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';
    const elements = parent.querySelectorAll(possibilities);
    const tabElements = [];

    elements.forEach(element => {
      if (angular.element(element).attr('tabindex') !== '-1') {
        tabElements.push(element);
      }
    });

    return this.filterVisibleElements(tabElements);
  }

  private filterVisibleElements(elements: HTMLElement[]): HTMLElement[] {
    const visibleElements = [];

    elements.forEach(element => {
      if (element.offsetWidth > 0 || element.offsetHeight > 0) {
        visibleElements.push(element);
      }
    });

    return visibleElements;
  }
}
