import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { getLocale } from 'scripts/util/locale/locale';

const options = {
  loadPath: '../../i18n/{{lng}}-US/{{ns}}.json',
};

i18n
  .use(Backend)
  .use(initReactI18next).init({
    backend: options,
    defaultNS: Dictionary.COMMON,
    ns: Dictionary.COMMON,
    fallbackLng: 'en',
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    lng: getLocale().language,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
