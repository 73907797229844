import { Observable } from 'rxjs/Observable';
import { isiOSSafari } from 'scripts/util/browser/browser';
import {
  appealDentalClaimsOffline,
  appealMedialClaimsOffline,
  appealMedicalClaimsOnline,
  askAQuestion,
  claimLetters,
} from 'scripts/util/resource/resource.constants';
import claimHelpMenuTemplate from '../../../views/ui/claim-help-menu.html';
import {CoverageType, RelationshipType} from '../../api/api.interfaces';
import { ICoverageInfo, IProducts, MembershipCategory } from '../../api/profile/profile.interfaces';
import {IProfileService, ProfileService} from '../../api/profile/profile.service';
import { ITargetingService } from '../../api/targeting/targeting.service';
import { ITrackingEventRequest, TrackingTriggerType } from '../../api/tracking/tracking.interfaces';
import { IUserService } from '../../api/user/user.service';
import { IDropdownOption } from '../../ui/dropdown/dropdown.interfaces';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../util/feature-flag/feature-flag.interface';
import { IGenesysService } from '../../util/genesys/genesys.service';
import { IResourceService } from '../../util/resource/resource.service';
import { sendOutOfFlowAAEvent } from '../../util/tracking/adobe-analytics';

export class ClaimHelpMenuComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  public bindings = {
    showFaqs: '<?',
    showAllClaims: '<?',
    suppressChat: '<?',
  };

  constructor() {
    this.controller = ClaimHelpMenuController;
    this.templateUrl = claimHelpMenuTemplate;
  }
}

export class ClaimHelpMenuController implements ng.IComponentController {
  public menuLinks: IDropdownOption[] = [];
  public showAllClaims: boolean;
  public showFaqs: boolean;
  public suppressChat: boolean;
  private coverageInfo: ICoverageInfo;
  private onlineMedicalAppeals: boolean;
  private showClaimLetters: boolean;
  constructor(
    private $state: angular.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private featureFlagService: IFeatureFlagService,
    private genesysService: IGenesysService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.CLAIM_HELP_MENU);
    $translatePartialLoader.addPart(Dictionary.COMMON);
  }
  public $onInit(): void {
    this.showAllClaims = !!this.showAllClaims;
    this.showFaqs = !!this.showFaqs;
    this.suppressChat = !!this.suppressChat;
    const currentUser$ = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser);
    const clientConfig$ = this.userService.getHeartbeat()
      .flatMap(({data}) => this.targetingService.getClientConfig(data.rallyId));
    const products$ = this.userService.getHeartbeat()
      .flatMap(rsp => this.profileService.getProducts(rsp.data.rallyId))
      .map(rsp => rsp.data.products);
    Observable.zip(currentUser$, clientConfig$, products$)
      .subscribe(([currentUser, clientConfig, products]) => {
        this.coverageInfo = ProfileService.getCoverageInfo(currentUser.planCoverages);
        this.showClaimLetters = currentUser.relationshipType === RelationshipType.Subscriber &&
          currentUser.membershipCategory !== MembershipCategory.OXFORD;
        this.onlineMedicalAppeals = clientConfig && clientConfig.suppressions.onlineMedicalAppeals;
        this.setLinks(products);
      });

    const chatAvailability$ = this.genesysService.isChatAvailable();
    Observable.zip(products$, chatAvailability$).subscribe(([products, chatAvailable]) => {
      this.setChatLink(products, chatAvailable);
    });
  }
  public selectHelpMenuLinks(): (option: IDropdownOption, $event: ng.IAngularEvent) => void {
    return (option, $event) => option.action && option.action($event);
  }

  private setLinks(products: IProducts): void {
    this.menuLinks.unshift({
      value: this.$state.href('modal.allClaimsFaq'),
      label: 'VIEW_FAQS',
      condition: this.showFaqs,
      action: ($event: ng.IAngularEvent) => {
        $event.preventDefault();
        this.$timeout(() => this.$state.go('modal.allClaimsFaq'));
      },
    }, {
      value: this.$state.href('authenticated.claimsAndAccounts.claims'),
      label: 'VIEW_ALL_CLAIMS',
      condition: this.showAllClaims,
    }, {
      value: this.featureFlagService.isClaimLettersOn() ?
        this.$state.href('authenticated.claimsAndAccounts.claimLetters') :
        this.resourceService.get(claimLetters),
      condition: this.showClaimLetters,
      label: 'VIEW_CLAIM_LETTERS',
    }, {
      value: this.resourceService.get(
        this.onlineMedicalAppeals ? appealMedicalClaimsOnline : appealMedialClaimsOffline,
      ),
      condition: !!this.coverageInfo.coverageTypes[CoverageType.Medical],
      label: 'SUBMIT_MEDICAL_APPEAL',
    }, {
      value: this.resourceService.get(appealDentalClaimsOffline),
      condition: !!this.coverageInfo.coverageTypes[CoverageType.Dental],
      label: 'SUBMIT_DENTAL_APPEAL',
    }, {
      value: this.resourceService.get(askAQuestion),
      condition: !!(products && products.secureMessage),
      label: 'ASK_QUESTION',
      target: '_blank',
    });
  }

  private setChatLink(products: IProducts, chatAvailable: boolean): void {
    const hasClickToChat = !!(products && products.clickToChat);
    const showChatLink = !isiOSSafari() && chatAvailable && hasClickToChat && !this.suppressChat;
    if (showChatLink) {
      this.genesysService.updatePageAndButtonClassNames();

      const newEvent = {
        uri: window.location.pathname,
        featureList: ['claims-and-accounts', 'summary', 'genesys-chat'],
        trigger: TrackingTriggerType.Click,
        actionName: 'view',
        serviceVersion: 'xx',
        placement: '',
      } as ITrackingEventRequest;

      sendOutOfFlowAAEvent(newEvent);
    }

    this.menuLinks.push({
      value: '#',
      condition: showChatLink,
      label: 'CHAT_WITH_AGENT',
      className: this.genesysService.chatButtonClassName,
      action: ($event: ng.IAngularEvent) => {
        $event.preventDefault();
      },
    });
  }
}
