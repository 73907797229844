import { formatTrackingString, getFeatureList, getPlacement } from 'scripts/util/tracking/tracking-helper';
import { angularMinimumEscape } from 'scripts/util/uri/uri';
import { LinkTarget } from '../../api/api.interfaces';
import { ICampaign } from '../../api/targeting/targeting.interfaces';
import {
  ICampaignTrackingInfo, ITrackingEventRequest, TrackingClickType, TrackingTriggerType,
} from '../../api/tracking/tracking.interfaces';
import { ITrackingService } from '../../api/tracking/tracking.service';
import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { ITrackController } from './track.interfaces';

export class TrackController implements ITrackController {
  public actionName: string;
  public clickType: TrackingClickType;
  public placement: string;
  public campaign: ICampaign;
  public isInView: boolean;
  public features: {[featureName: string]: string};

  constructor(
    private $attrs: ng.IAttributes,
    private $location: ng.ILocationService,
    private $scope: ng.IScope,
    private Environment: IEnvironmentConstants,
    private trackingService: ITrackingService,
  ) {
    'ngInject';
    this.features = {
      accordion: 'accordion',
      accountDropdown: 'account-dropdown',
      accountInfo: 'account-info',
      accountSelector: 'account-selector',
      accountSummary: 'account-summary',
      accountSummaryExplanation: 'account-summary-explanation',
      accumulators: 'accumulators',
      activateBanner: 'activate-banner',
      videoBanner: 'video-banner',
      additionalLinks: 'additional-links',
      additionalServices: 'additional-services',
      allClaims: 'all-claims',
      allClaimsRefineResults: 'all-claims-refine-results',
      allClaimsSelectDates: 'all-claims-select-dates',
      autoPayment: 'auto-payment',
      balances: 'balances',
      callToChangePcp: 'call-to-change-pcp',
      carousel: 'carousel',
      claimBalanceTypeInfo: 'claim-balance-type-info',
      claimDeniedExplanation: 'claim-denied-explanation',
      claimDetails: 'claim-details',
      claimDetailsBreakdown: 'claim-details-breakdown',
      claimDetailsSummary: 'claim-details-summary',
      claimDetailsServices: 'claim-details-services',
      claimHelpMenu: 'claim-help-menu',
      claimLetters: 'claim-letters',
      claimsAndAccounts: 'claims-and-accounts',
      claimsNeedAttention: 'claims-need-attention',
      claim: 'claim',
      claimVideo: 'claim-video',
      communicationPreference: 'communication-preference',
      compareCare: 'compare-care',
      contactUs: 'contact-us',
      contentHopper: 'content-hopper',
      costInfo: 'cost-info',
      costs: 'common-services-costs',
      csCosts: 'cs-common-services-costs',
      customFaq: 'custom-faq',
      dashboard: 'dashboard',
      dentalAccountExplanation: 'dental-account-explanation',
      dentalAccountSummary: 'dental-account-summary',
      dentalDeductibleExplanation: 'dental-deductible-explanation',
      dentalMaxExplanation: 'dental-max-explanation',
      directDeposit: 'direct-deposit',
      drugCosts: 'prescription-drug-costs',
      drugTiers: 'drug-tiers',
      editClaimNote: 'edit-claim-note',
      eobError: 'eob-error',
      expirationWarning: 'expiration-warning',
      faq: 'faq',
      financialAccounts: 'financial-accounts',
      findCare: 'find-care',
      footer: 'footer',
      fromClaimDate: 'from-claim-date',
      fsaFaq: 'fsa-faq',
      guidedSearch: 'guided-search',
      headerDesktop: 'header-desktop',
      headerMobile: 'header-mobile',
      healthResources: 'health-resources',
      help: 'help',
      hraapAmountUsed: 'hraap-amount-used',
      hraapFamily: 'hraap-family',
      hraapIndividual: 'hraap-individual',
      hraFaq: 'hra-faq',
      hraHowItWorks: 'hra-how-it-works',
      hsaFaq: 'hsa-faq',
      hsaHowItWorks: 'hsa-how-it-works',
      inactiveModal: 'inactive-modal',
      incentives: 'incentives',
      internalError: 'internal-error',
      linkBar: 'link-bar',
      localeSelector: 'locale-selector',
      markAsPaid: 'mark-as-paid',
      mainNav: 'main-nav',
      medicalAccountExplanation: 'medical-account-explanation',
      memberIdCards: 'member-id-cards',
      memberIdSelector: 'member-id-selector',
      mobileSubNav: 'mobile-sub-nav',
      modal: 'modal',
      mrCosts: 'mr-common-services-costs',
      mrMedicalExplanationOfTerms: 'mr-medical-explanation-of-terms',
      notification: 'notification',
      nav: 'nav',
      networkSelector: 'network-selector',
      orthoMaxExplanation: 'ortho-max-explanation',
      paperlessBanner: 'paperless-banner',
      planBalancesBanner: 'plan-balances-banner',
      fitbitBanner: 'fitbit-banner',
      singleStepBanner: 'single-step-banner',
      payNow: 'pay-now-modal',
      pcdConfirmation: 'pcd-confirmation',
      pcpAndSaved: 'pcp-and-saved',
      pcpChange: 'pcp-change',
      pcpChangeUnavailable: 'pcp-change-unavailable',
      pcpReferrals: 'pcp-referrals',
      pharmacy: 'pharmacy',
      planBalances: 'plan-balances',
      planReferenceList: 'plan-reference-list',
      preferredMail: 'preferred-mail-service-pharmacy',
      preferredRetail: 'preferred-retail-pharmacy',
      premiumPayments: 'premium-payments',
      printAllClaims: 'print-all-claims',
      promoPanels: 'promo-panels',
      promoTiles: 'promo-tiles',
      promos: 'promos',
      quickLinks: 'quick-links',
      recentClaims: 'recent-claims',
      recommendations: 'recommendations',
      recommendationsActive: 'recommendations-active',
      recommendationsDismissed: 'recommendations-dismissed',
      requestFailure: 'request-failure',
      resources: 'resources',
      seeYouLater: 'see-you-later',
      selectDay: 'select-day',
      selectMonth: 'select-month',
      selectYear: 'select-year',
      spendingAndCostSummary: 'spending-and-cost-summary',
      standardRetail: 'standard-retail-pharmacy',
      statements: 'statements',
      submitClaim: 'submit-claim',
      submitClaimHeader: 'submit-claim-header',
      submitClaimHipaaForms: 'submit-claim-hipaa-forms',
      submitClaimOtherForms: 'submit-claim-other-forms',
      subNav: 'sub-nav',
      summary: 'summary',
      toClaimDate: 'to-claim-date',
      unauthorizedError: 'unauthorized-error',
      utilityNav: 'utility-nav',
      wellness: 'wellness',
    };
  }

  public queueEvent(element: ng.IAugmentedJQuery, isImpression: boolean = false): void {
    if (this.actionName) {
      this.trackingService.queueEvent(this.getTrackingEventRequest(element, isImpression), isImpression);
    }
  }

  public sendAndFlushEvents(element: ng.IAugmentedJQuery): void {
    const event = this.getTrackingEventRequest(element);
    this.trackingService.queueEvent(event);
    this.trackingService.postEvents().subscribe();
  }

  private getTrackingEventRequest(element: ng.IAugmentedJQuery, isImpression: boolean = false): ITrackingEventRequest {
    const featureList = getFeatureList(element[0]);
    const placement = this.placement || featureList.indexOf(this.features.modal) > -1 ?
      'modal' : getPlacement(element[0]);

    const event = {
      trigger: (isImpression ? TrackingTriggerType.View : TrackingTriggerType.Click),
      uri: this.$location.url(),
      serviceVersion: this.Environment.CONFIG.ARCADE_WEB_VERSION,
      featureList,
      actionName: formatTrackingString(this.actionName),
      placement,
    } as ITrackingEventRequest;
    if (!isImpression) {
      this.setClickTypeAndExternalLinkProps(event);
    }

    if (this.campaign && this.campaign.campaignId) {
      const campaignInfo = this.getCampaignTrackingInfo(this.campaign);
      event.campaignTrackingInfo = campaignInfo;
      event.data = campaignInfo;
    }
    return event;
  }

  private getCampaignTrackingInfo(campaign: ICampaign): ICampaignTrackingInfo {
    return {
      campaignId: campaign.campaignId,
      placementType: campaign.placementType,
    };
  }

  private setClickTypeAndExternalLinkProps(event: ITrackingEventRequest): void {
    if (!this.$attrs.href) {
      event.clickType = this.clickType || TrackingClickType.PageEvent;
      return;
    }

    const anchor = document.createElement('a');
    anchor.href = this.$attrs.href;
    anchor.href = anchor.href; // IE11: without this, anchor.hostname will be "" when this.$attrs.href is relative
    const isInternalRedirect = anchor.pathname.split('/').pop() === 'internal-redirect';
    const isExternalLink = isInternalRedirect || this.$attrs.target === LinkTarget.Blank ||
      anchor.hostname !== window.location.hostname || this.$attrs.trackClickType === TrackingClickType.ExternalLink;

    if (isExternalLink) {
      event.clickType = TrackingClickType.ExternalLink;
      if (isInternalRedirect) {
        const decodedUrl = angularMinimumEscape(this.$attrs.href.split('deepLink=').pop());
        const redirectUrlAnchor = document.createElement('a');
        redirectUrlAnchor.href = decodedUrl;
        event.externalDomain = redirectUrlAnchor.hostname;
        event.externalUrl = decodedUrl.split('?')[0];
      } else {
        event.externalDomain = anchor.hostname;
        event.externalUrl = anchor.href.split('?')[0];
      }
    } else {
      event.clickType = TrackingClickType.StateChange;
    }
  }
}
