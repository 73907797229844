import { IPopulationService } from '../population/population.service';
import { getResource } from './resource';
import { IResource } from './resource.interfaces';

export interface IResourceService {
  get(resource: IResource): string;
}

export class ResourceService implements IResourceService {

  constructor(private populationService: IPopulationService) {
    'ngInject';
  }

  public get(resource: IResource): string {
    const population = this.populationService.getPopulation();
    return getResource(resource, population);
  }
}
