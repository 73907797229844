import { AnyClaimType } from '../../api/claims/claims.interfaces';
import { ClaimsService } from '../../api/claims/claims.service';

import FEATURE_FLAGS from '../constants/feature-flag';
import { IFeatureFlagService } from './feature-flag.interface';

const FeatureFlagService: IFeatureFlagService = {
  isActivateStepsOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_ACTIVATE_STEPS;
  },

  isActivateStepsV6On(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_ACTIVATE_STEPS;
  },

  isActivateVideoOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_ACTIVATE_VIDEO;
  },

  isAdvantageOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_ADVANTAGE;
  },

  isAllClaimsKeywordFilterOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_ALL_CLAIMS_KEYWORD_FILTER;
  },

  isAutoPaymentOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_AUTO_PAYMENT;
  },

  isClaimDetailsBreakdownOn(claimType: AnyClaimType): boolean {
    return ClaimsService.getByType(claimType, true , false) ?
      true : FEATURE_FLAGS[`ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_${claimType}`];
  },

  isClaimDetailsOn(claimType: AnyClaimType): boolean {
    // ARC-5293 only HealthCare type is enabled currently
    return ClaimsService.getByType(claimType, true , false) ?
    true : !!FEATURE_FLAGS[`ARCADE_FEATURES_CLAIM_DETAILS_${claimType}`];
  },

  isClaimLettersOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_CLAIM_LETTERS;
  },

  isDirectDepositOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_DIRECT_DEPOSIT;
  },

  isDocumentsClaimsEobOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_DOCUMENTS_CLAIMS_EOB;
  },

  isDocumentsScreenReaderLinkOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_DOCUMENTS_SCREEN_READER_LINK;
  },

  isEiRecommendationsOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_EI_RECOMMENDATIONS;
  },

  isFpcPcpForGatedOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_FPC_PCP_FOR_GATED;
  },

  isGenesysChatOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_GENESYS_CHAT;
  },

  isGenesysChatOnForClaims(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_GENESYS_CHAT_CLAIMS;
  },

  isHealthcareClaimsV3On(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_HEALTHCARE_CLAIMS_V3;
  },

   isIAQHPServiceEndingBannerOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_IAQHP_SERVICE_ENDING_BANNER;
  },

  isMRAccountSummaryOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_MR_ACCOUNT_SUMMARY;
  },

  isMRRXSpendingAndCostSummaryOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_MR_RX_SPENDING_AND_COST_SUMMARY;
  },

  isMRSpendingAndCostSummaryOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_MR_SPENDING_AND_COST_SUMMARY;
  },

  isNotificationOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_NOTIFICATION;
  },

  isPayNowModalOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_PAY_NOW_MODAL;
  },

  isPcdOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_PCD;
  },

  isPcpForPolarisOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_PCP_POLARIS;
  },

  isPcpGroupOrFacilityNameOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_PCP_GROUP_OR_FACILITY_NAME;
  },

  isPcpNonGatedOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_PCP_NON_GATED;
  },

  isPcpThroughFpcOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_PCP_THROUGH_FPC;
  },

  isOptumRxPharmacyRedesignOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_OPTUM_RX_PHARMACY_REDESIGN;
  },

  isPremiumPaymentsForExchangeOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_EXCHANGE_PREMIUM_PAYMENTS;
  },

  isQuickLinksOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_QUICK_LINKS;
  },

  isRallyRxOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_RALLY_RX;
  },

  isReduxLoggingOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_REDUX_LOGGING;
  },

  isReferralRequiredOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_REFERRAL_REQUIRED;
  },

  isSharedHeaderFooterPhaseTwoOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_SHARED_HEADER_AND_FOOTER_PHASE_TWO;
  },

  isStatementsOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_STATEMENTS;
  },

  isSubmitClaimOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_SUBMIT_CLAIM;
  },

  isTierTwoEnabled(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_TIER_TWO;
  },

  isVideoBannerOn(): boolean {
    return FEATURE_FLAGS.ARCADE_FEATURES_VIDEO_BANNER;
  },
};

export default FeatureFlagService;
