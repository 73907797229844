import programsTemplate from 'views/states/programs.html';
import { ProgramsController } from './programs.controller';

export class ProgramsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = ProgramsController;
    this.templateUrl = programsTemplate;
  }
}
