import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import {activate} from 'scripts/util/resource/resource.constants';
import activateBannerTemplate from 'views/chrome/activate-banner.html';
import { ActivateGlobalStatus, ActivatePlanKey } from '../../api/activate/activate.interfaces';
import { IActivateService } from '../../api/activate/activate.service';
import { Aco } from '../../api/profile/profile.interfaces';
import { IProfileService } from '../../api/profile/profile.service';
import { IUserService } from '../../api/user/user.service';
import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IResource } from '../../util/resource/resource.interfaces';
import { IResourceService } from '../../util/resource/resource.service';

export class ActivateBannerComponent implements ng.IComponentOptions {
  public templateUrl: string;
  public controller: any;
  constructor() {
    this.templateUrl = activateBannerTemplate;
    this.controller = ActivateBannerController;
  }
}

export class ActivateBannerController {
  public activateResourceLink: IResource;
  public showActivateBanner: boolean;
  public isCdp: boolean;

  constructor(
    $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private activateService: IActivateService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private resourceService: IResourceService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ONBOARDING);

    this.activateResourceLink = activate;
  }

  public $onInit(): void {
    this.showActivateBanner = false;
    if (this.featureFlagService.isActivateStepsV6On()) {
      this.userService.getHeartbeat()
        .let(this.profileService.toCurrentProfile())
        .takeWhile(profile => profile.memberFeatures.activateEligible)
        .do(profile => {
          this.isCdp = profile.acos.some(aco => aco === Aco.CDP || aco === Aco.DP);
        })
        .flatMap(profile => this.activateService.getSteps(profile.rallyId))
        .subscribe(rsp => {
          const activateFTUEPlanKeys: string[] = [ActivatePlanKey.EI, ActivatePlanKey.CDP,
            ActivatePlanKey.DP, ActivatePlanKey.GATED, ActivatePlanKey['NON-GATED-PCP']];
          this.showActivateBanner = !!rsp.data.additional && rsp.data.additional.some(activateSteps => {
            return activateFTUEPlanKeys.some(key => key === activateSteps.planKey) && activateSteps.status === ActivateGlobalStatus.started;
          });
          });
    } else {
      this.userService.getHeartbeat()
        .let(this.profileService.toCurrentProfile())
        .takeWhile(profile => profile.memberFeatures.activateEligible)
        .do(profile => {
          this.isCdp = profile.acos.some(aco => aco === Aco.CDP || aco === Aco.DP);
        })
        .flatMap(profile => this.activateService.getStepsV4(profile.rallyId))
        .subscribe(rsp => {
          if (rsp.data.status === ActivateGlobalStatus.started) {
            this.showActivateBanner = true;
          }
        });
    }
  }

  public toActivateInternal(): void {
    const activateLink = this.resourceService.get(this.activateResourceLink);
    this.userService.internalSimpleRedirect(activateLink);
  }
}
