import { Observable } from 'rxjs/Rx';
import { allClaims, benefitsCoverage, connect, prescriptions, viewDocs } from 'scripts/util/resource/resource.constants';
import quickLinksTemplate from 'views/dashboard/quick-links.html';
import { CoverageType, LinkTarget } from '../../../api/api.interfaces';
import { IProfileService, ProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IFilesConstant } from '../../../util/constants/files.constant';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IGridSetup } from '../../../util/grid/grid.interfaces';
import { ILocaleService } from '../../../util/locale/locale.service';
import { IResourceService } from '../../../util/resource/resource.service';
import { IQuickLinksCategory } from './quick-links.controller';

export class CsQuickLinksController implements IGridSetup, ng.IComponentController {
  public links: IQuickLinksCategory[];
  public maxColumns: number = 5;
  public showExternalLinkMessage: boolean = true;

  private LINK_URL;

  constructor(
    public $state: ng.ui.IStateService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Files: IFilesConstant,
    private localeService: ILocaleService,
    private resourceService: IResourceService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.QUICK_LINKS);

    this.LINK_URL = {
      FIND_CARE: this.resourceService.get(connect),
      BENEFITS: this.resourceService.get(benefitsCoverage),
      PRESCRIPTIONS: this.resourceService.get(prescriptions),
      CLAIMS: this.resourceService.get(allClaims),
      VIEW_DOCS: this.resourceService.get(viewDocs),
    };

    this.links = [{
      id: 'FIND_PROVIDER',
      text: 'FIND_PROVIDER',
      href: this.$state.href('internalRedirect', {deepLink: this.LINK_URL.FIND_CARE}),
      action: e => this.internalRedirect(e, this.LINK_URL.FIND_CARE),
      icon: 'icon-find-doctor',
      show: true,
    }, {
      id: 'BENEFITS',
      text: 'VIEW_YOUR_BENEFITS',
      href: this.LINK_URL.BENEFITS,
      icon: 'icon-benefits',
      show: true,
    }, {
      id: 'PRESCRIPTIONS',
      text: 'MANAGE_PRESCRIPTIONS',
      href: this.LINK_URL.PRESCRIPTIONS,
      icon: 'icon-pharmacy',
    }, {
      id: 'CLAIMS',
      text: 'VIEW_YOUR_CLAIMS_AND_VISITS',
      href: this.LINK_URL.CLAIMS,
      icon: 'icon-claims',
      show: true,
    }, {
      id: 'VIEW_DOCS',
      text: 'VIEW_DOCUMENTS',
      href: this.LINK_URL.VIEW_DOCS,
      target: LinkTarget.Blank,
      icon: 'icon-plan-documents',
      show: true,
    }];

    const hasRxRequest: Observable<boolean> = userService.getHeartbeat()
      .let(profileService.toProfile())
      .map(rsp => {
        const activeCoverageInfo = ProfileService.getCoverageInfo(rsp.data.currentUser.planCoverages);
        return activeCoverageInfo.coverageTypes.hasOwnProperty(CoverageType.Rx);
      })
      .flatMap(hasRxCoverage => Observable.if(() => hasRxCoverage,
        userService.getHeartbeat()
          .flatMap(rsp => profileService.getProducts(rsp.data.rallyId))
          .map(rsp => !!rsp.data.products.rx),
        Observable.of(false),
      ));
    hasRxRequest.subscribe(hasRx => this.links[2].show = hasRx);

    this.localeService.localeChanged.subscribe(() => {
      this.LINK_URL.FIND_CARE = this.resourceService.get(connect);
      this.links[0].href = this.$state.href('internalRedirect', {deepLink: this.LINK_URL.FIND_CARE});
      this.links[0].action = e => this.internalRedirect(e, this.LINK_URL.FIND_CARE);
    });
  }

  public getNumColumns(): number {
    const columns = this.links.reduce((columnTotal, link) => {
      return columnTotal + (link.show ? 1 : 0);
    }, 0);
    return columns <= this.maxColumns ? columns : this.maxColumns;
  }

  public getIcon(link: IQuickLinksCategory): string {
    if (link.icon) {
      return this.Files.getIcon(link.icon);
    } else if (link.iconUrl) {
      return link.iconUrl;
    }
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }
}

export class CsQuickLinksComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;

  constructor() {
    this.controller = CsQuickLinksController;
    this.templateUrl = quickLinksTemplate;
  }
}
