import submitClaimTemplate from 'views/claims-and-accounts/submit/submit-claim.html';
import { Dictionary } from '../../../util/constants/i18n.constants';

export class SubmitClaimComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = SubmitClaimController;
    this.templateUrl = submitClaimTemplate;
  }
}

export class SubmitClaimController implements ng.IComponentController {
  constructor(private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.SUBMIT_CLAIM);
  }
}
