import { Observable, Subscription } from 'rxjs';
import CONFIG from 'scripts/util/constants/config';
import FeatureFlagService from 'scripts/util/feature-flag/feature-flag';
import { getLocale } from 'scripts/util/locale/locale';
import { ILocale } from 'scripts/util/locale/locale.interfaces';
import { ILocaleService } from 'scripts/util/locale/locale.service';
import { IBaseUrls, IPopulation } from 'scripts/util/population/population.interfaces';
import { IPopulationService } from 'scripts/util/population/population.service';
import { getBaseUrls } from 'scripts/util/uri/uri';
import { IProducts, IProfile } from '../../api/profile/profile.interfaces';
import { IProfileService } from '../../api/profile/profile.service';
import { IUserService } from '../../api/user/user.service';

export interface ISharedHeaderFooterFeatureFlags {
  isHeaderFooterPhaseTwoOn: boolean;
  isRallyRxOn: boolean;
}

export interface ISharedHeaderFooterFeatureConfig {
  rallyRxPolicies?: string;
  useA11ySkipToMain?: boolean;
}

export class SharedHeaderFooterController implements ng.IComponentController {
  public authenticated: boolean;
  public baseUrls: IBaseUrls;
  public currentLocale: ILocale['id'];
  public featureFlags: ISharedHeaderFooterFeatureFlags;
  public featureConfig: ISharedHeaderFooterFeatureConfig;
  public profileData: IProfile | {};
  public productData: IProducts | {};
  private localeSubscription: Subscription;

  constructor(
    private localeService: ILocaleService,
    public populationService: IPopulationService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';

    this.authenticated = (this.authenticated === undefined) ? true : this.authenticated;
    this.currentLocale = getLocale().id;
  }

  public $onInit(): void {
    if (this.authenticated) {
      const heartbeat = this.userService.getHeartbeat();
      const profile$ = heartbeat.let(this.profileService.toProfile()).map(rsp => rsp.data).catch(error => Observable.of({}));
      const currentUser$ = heartbeat.let(this.profileService.toProfile()).map(rsp => rsp.data.currentUser);
      const products$ = currentUser$
        .flatMap(currentUser => this.profileService.getProducts(currentUser.rallyId))
        .map(rsp => rsp.data.products)
        .catch(error => {
          console.warn(error);
          return Observable.of({});
        });
      Observable.zip(profile$, products$).subscribe(([profile, products]) => {
          const population = this.populationService.getPopulation();
          const arcadeWebBaseUrlWithPopulation = this.getArcadeWebBaseUrlWithPopulation(population);
          this.baseUrls = {
            arcadeUrl: arcadeWebBaseUrlWithPopulation,
            ...population.baseUrls,
          };

          this.featureConfig = {
            rallyRxPolicies: CONFIG.ARCADE_WEB_RALLY_RX_POLICIES,
            useA11ySkipToMain: true,
          };

          this.featureFlags = {
            isHeaderFooterPhaseTwoOn: FeatureFlagService.isSharedHeaderFooterPhaseTwoOn(),
            isRallyRxOn: FeatureFlagService.isRallyRxOn(),
          };

          this.profileData = profile;
          this.productData = products;
      }, console.warn);
    }

    this.localeSubscription = this.localeService.localeChanged.subscribe(() => {
      this.currentLocale = getLocale().id;
    });
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  private getArcadeWebBaseUrlWithPopulation(pop: IPopulation): string {
    const arcadeWebBaseUrl = getBaseUrls().web;
    const populationPath = pop.uri;

    return arcadeWebBaseUrl + populationPath;
  }
}
