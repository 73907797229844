import angular from 'angular';
import { Observable } from 'rxjs/Observable';
import { sendCampaignsLoadedEvent } from 'scripts/util/tracking/adobe-analytics';
import { LineOfBusiness } from '../../api/profile/profile.interfaces';
import { IProfileService } from '../../api/profile/profile.service';
import {
  AllProgramsPageCampaigns, CampaignPlacementType,
  ICampaignPlacements,
} from '../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../api/targeting/targeting.service';
import { IUserService } from '../../api/user/user.service';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../util/feature-flag/feature-flag.interface';

export class ProgramsController {
  public campaignsRequest: Observable<ICampaignPlacements>;
  public showCampaignsErrorState: boolean;
  public showIncentives: boolean;
  public showWellness: boolean;
  public showBenefits: boolean;
  public showPanels: boolean;
  public showPromos: boolean;
  public incentivesRequest: Observable<boolean>;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private featureFlagService: IFeatureFlagService,
    private profileService: IProfileService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.PROGRAMS);
  }

  public $onInit(): void {
    const isEIPlan$ = this.userService.getHeartbeat().let(this.profileService.toProfile())
      .map(profileRsp => typeof profileRsp.data.currentUser.lineOfBusiness === 'undefined' ||
        profileRsp.data.currentUser.lineOfBusiness === LineOfBusiness.EI);

    let incentivesPlanInfo;
    const incentives$ = this.userService.getHeartbeat()
      .flatMap(rsp => this.targetingService.getIncentives(rsp.data.rallyId))
      .filter(({data}) => !!(data && data.planInfo))
      .map(({data: {planInfo}}) => planInfo)
      .first()
      .do(planInfo => incentivesPlanInfo = planInfo)
      .map(planInfo => planInfo.numberOfIncentives > 0);

    const engage$ = this.userService.getHeartbeat()
      .flatMap(rsp => this.profileService.getProducts(rsp.data.rallyId))
      .map(rsp => rsp.data.products.hasOwnProperty('rallyEngage'));

    this.incentivesRequest = Observable.of(this.featureFlagService.isAdvantageOn())
      .flatMap(isAdvantage => isAdvantage ? Observable.of(false) : isEIPlan$)
      .flatMap(isEI => isEI ? engage$ : Observable.of(false))
      .flatMap(hasEngage => hasEngage ? incentives$ : Observable.of(false));
    this.incentivesRequest.subscribe(showIncentives => {
      this.showIncentives = showIncentives;
    }, () => this.showIncentives = false);

    this.getCampaigns();

    Observable.zip(this.campaignsRequest.catch(() => Observable.of(undefined)),
      this.incentivesRequest.catch(() => Observable.of(undefined)))
      .subscribe(([campaigns, showIncentives]: [ICampaignPlacements, boolean]) => {
        sendCampaignsLoadedEvent(campaigns, showIncentives ? incentivesPlanInfo : undefined);
      });
  }

  public getCampaigns(): void {
    this.showCampaignsErrorState = null;

    this.campaignsRequest = this.userService.getHeartbeat().flatMap(rsp => {
      return this.targetingService.getCampaigns(rsp.data.rallyId, [AllProgramsPageCampaigns]);
    }).filter(({data}) => !!(data && data.placements))
      .map(({data: {placements}}) => placements);

    this.campaignsRequest.subscribe(campaigns => {
      this.showCampaignsErrorState = false;
      if (campaigns[CampaignPlacementType.ArcadeResourcesPrimaryWellness] ||
        campaigns[CampaignPlacementType.ArcadeResourcesSecondaryWellness]
      ) {
        this.showWellness = true;
      }
      if (campaigns[CampaignPlacementType.ArcadeResourcesText] ||
        campaigns[CampaignPlacementType.ArcadeResourcesSection1Link] ||
        campaigns[CampaignPlacementType.ArcadeResourcesSection2Link]
      ) {
        this.showBenefits = true;
      }
      if (campaigns[CampaignPlacementType.ArcadeResourcesPrograms]) {
        this.showPanels = true;
      }
      if (campaigns[CampaignPlacementType.ArcadeResourcesGeneralPromo]) {
        this.showPromos = true;
      }
    }, () => {
      this.showCampaignsErrorState = true;
      this.showWellness = false;
      this.showBenefits = false;
      this.showPromos = false;
      this.showPanels = false;
    });
  }

  public showNoCampaignsMessage(): boolean {
    return this.showIncentives === false && !(this.showWellness || this.showBenefits || this.showPromos || this.showPanels);
  }
}
