import { IUserService } from '../../../api/user/user.service';
import { IModalController } from '../../../ui/modal/modal.interfaces';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IInactiveController } from './inactive.interfaces';

export class InactiveController implements IInactiveController {
  public $modal: IModalController;
  public timeLeft: number;
  private interval;

  constructor(
    private $interval: ng.IIntervalService,
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private Environment: IEnvironmentConstants,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.INACTIVE);
    $translatePartialLoader.addPart(Dictionary.HEADER);

    const warningTime = Environment.CONFIG.ARCADE_WEB_HEARTBEAT_BUFFER_MS;

    userService.getInfo().subscribe(rsp => {
      const expiresAt = new Date(rsp.data.expiresAt.toString()).getTime();
      const serverTime = new Date(rsp.data.serverTime.toString()).getTime();
      this.timeLeft = Math.round((expiresAt - serverTime) / 1000);

      if (this.timeLeft * 1000 > warningTime) {
        this.$modal.close();
      } else {
        this.interval = $interval(() => {
          if (this.timeLeft > 0) {
            this.timeLeft --;
          } else {
            this.$interval.cancel(this.interval);
            this.$timeout(() => {
              this.$state.go('logout');
            });
          }
        }, 1000);
      }
    }, console.warn);
  }

  public $onDestroy(): void {
    this.userService.getHeartbeat(false).do(() => {
      this.$interval.cancel(this.interval);
    }).subscribe();
  }

  public continueSession(e?: any): void {
    if (e && e.hasOwnProperty('preventDefault')) {
      e.preventDefault();
    }
    this.$modal.close();
  }
}
