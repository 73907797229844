import ngRedux from 'ng-redux';
import { Observable } from 'rxjs/Observable';
import { changeLocale } from 'scripts/actions/locale-actions';
import i18n from 'scripts/i18n';
import { LocaleChangeError, LocaleChangeSuccess } from '../../util/constants/event.constants';
import { getLocale, getSanitizedLocale } from './locale';
import { ILocale } from './locale.interfaces';

export interface ILocaleService {
  localeChanged: Observable<ILocale>;
  set(l: string): void;
}

export class LocaleService implements ILocaleService {
  public defaultLocale: ILocale;
  public localeChanged: Observable<ILocale>;

  constructor(
    private $ngRedux: ngRedux.INgRedux,
    private $rootScope: ng.IRootScopeService,
    private $translate: ng.translate.ITranslateService,
  ) {
    'ngInject';
    this.localeChanged = Observable
      .fromEventPattern(h => $rootScope.$on(LocaleChangeSuccess, (_, locale) => h(locale)),
        (h, disposer) => disposer(), locale => getLocale(locale))
      .share();
  }

  public set(locale: string): void {
    this.$translate.use(getSanitizedLocale(locale)).then(loc => {
      const newLocale = getLocale(loc);
      this.$rootScope.$emit(LocaleChangeSuccess, loc);
      this.$ngRedux.dispatch(changeLocale(newLocale));
      i18n.changeLanguage(newLocale.language);
    }, err => {
      this.$rootScope.$emit(LocaleChangeError, err);
    });
  }
}
