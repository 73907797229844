import { Observable } from 'rxjs/Observable';
import claimLettersTemplate from 'views/claims-and-accounts/summary/claim-letters.html';
import { IClaimLetter } from '../../../api/documents/documents.interfaces';
import { IDocumentsService } from '../../../api/documents/documents.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';

export class ClaimLettersComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = ClaimLettersController;
    this.templateUrl = claimLettersTemplate;
  }
}

export interface IClaimLettersByYear {
  [year: number]: IClaimLetter[];
}

export class ClaimLettersController implements ng.IComponentController {
  public currentRallyId: string;
  public claimLettersByYear: IClaimLettersByYear;
  public claimLettersRequest: Observable<IClaimLetter[]>;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private documentsService: IDocumentsService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.CLAIM_LETTERS);

    this.claimLettersByYear = {};
    this.claimLettersRequest = this.userService.getHeartbeat()
      .map(rsp => rsp.data.rallyId)
      .do(rallyId => this.currentRallyId = rallyId)
      .flatMap(rallyId => this.documentsService.getClaimLetters(rallyId))
      .map(rsp => rsp.data.memberClaimLetter);
  }

  public $onInit(): void {
    this.claimLettersRequest.subscribe(claimLetters => {
      claimLetters.sort((a, b) => a.stopDate.isAfter(b.stopDate) ? -1 : 1)
        .forEach(claimLetter => {
          if (claimLetter.stopDate.isValid()) {
            const year = claimLetter.stopDate.get('year');
            if (!this.claimLettersByYear[year]) {
              this.claimLettersByYear[year] = [];
            }
            this.claimLettersByYear[year].push(claimLetter);
          }
        });
    }, console.warn);
  }

  public getYears(claimLettersByYear: IClaimLettersByYear): number[] {
    return Object.keys(claimLettersByYear).map(year => parseInt(year, 10)).sort((a, b) => b - a);
  }

  public getPdfLink(claimLetter: IClaimLetter): string {
    return this.documentsService.getClaimLetterPdfLink(this.currentRallyId, claimLetter);
  }

  public goBack(): void {
    this.$window.history.back();
  }

  public print(): void {
    window.print();
  }
}
