import angular from 'angular';
import { Observable } from 'rxjs/Observable';
import { getMoneyValue } from 'scripts/util/money/money';
import allClaimsPrintTemplate from 'views/modals/all-claims-print.html';
import { AnyClaimType, ClaimType, IAnyClaim, IClaim, IFinancialClaim } from '../../../api/claims/claims.interfaces';
import { ClaimsService } from '../../../api/claims/claims.service';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { FilterStateKey, IGenericFilter, ISelectedFilters } from '../../../ui/filter/filter.interfaces';
import { IFilterService } from '../../../ui/filter/filter.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';
import { AllClaimsService } from '../../claims-and-accounts/claims/all-claims.service';

export class AllClaimsPrintComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = AllClaimsPrintController;
    this.templateUrl = allClaimsPrintTemplate;
  }
}

export class AllClaimsPrintController implements ng.IComponentController {
  public allClaims: IAnyClaim[];
  public claims: IAnyClaim[];
  public filters: Array<IGenericFilter<IClaim, any>>;
  public request: Observable<IAnyClaim[]>;
  public selectedFilters: ISelectedFilters<any>;
  public selectedSortByLabel: string;
  public type: AnyClaimType;
  private printTimeout: ng.IPromise<void>;

  constructor(
    private $element: ng.IAugmentedJQuery,
    private $scope: ng.IScope,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private claimsService: ClaimsService,
    private filterService: IFilterService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ALL_CLAIMS);
    $translatePartialLoader.addPart(Dictionary.CLAIM);
  }

  public $onInit(): void {
    this.type = this.filterService.getStateValue(FilterStateKey.Type) || ClaimType.Medical;

    this.filters = AllClaimsService.getFilters(this.type);
    this.selectedFilters = this.filterService.getStateValue() || AllClaimsService.getDefaultSelectedFilters(this.type);

    const period = AllClaimsService.getPeriod(this.filters, this.selectedFilters);

    this.request = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .flatMap(profile => this.claimsService.getAll(profile, this.type, period))
      .map(rsp => rsp.data);

    this.request.subscribe(claims => {
      this.allClaims = claims;
      const isSmartSortEligible = this.type === ClaimType.Medical && AllClaimsService.getSmartSortEligible(this.allClaims as IClaim[]);
      const sortByOptions = AllClaimsService.getSortByOptions(this.type, isSmartSortEligible);
      this.selectedSortByLabel = AllClaimsService.getSelectedSortOption(sortByOptions, this.selectedFilters.sort).label;
      this.filterService.setSelectedFilterValues(this.filters, this.selectedFilters, claims);
      this.updateSelectedSortByLabel();
      this.claims = this.filterService.sort(
        this.filterService.filter(this.allClaims, this.filters),
        AllClaimsService.getSort(this.selectedFilters.sort),
      );

      // watch for the claims to be added to the DOM before calling the browser print
      const cancelWatch = this.$scope.$watch(() => angular.element('.claim', this.$element).length, claimElementsLength => {
        if (this.claims.length === claimElementsLength) {
          // add a timeout equal to the css transition time of the .fade-in class
          this.printTimeout = this.$timeout(() => this.print(), 1500);
          cancelWatch();
        }
      });
    }, console.warn);
  }

  public $onDestroy(): void {
    this.$timeout.cancel(this.printTimeout);
  }

  public getYouMayOweAmount(claim: IClaim): number | undefined {
    return claim.claimManagementInfo.markPaid ? 0 : ClaimsService.getYouMayOweAmount(claim);
  }

  public getYouPaidAmount(claim: IClaim): number {
    return ClaimsService.getYouPaidAmount(claim);
  }

  public getMoneyValue(amount: number, decimal?: boolean): string {
    return getMoneyValue(amount, decimal);
  }

  public getAccountType(claim: IAnyClaim): string {
    return ClaimsService.getClaimAccountAbbreviation(this.type);
  }

  public getClaimId(claim: IAnyClaim): string {
    if (this.isHealthCareType()) {
      return (claim as IClaim).claimId;
    } else {
      return (claim as IFinancialClaim).claimNumber;
    }
  }

  public isHealthCareType(): boolean {
    return ClaimsService.getByType(this.type, true, false);
  }

  public print(): void {
    this.$window.print();
  }

  private updateSelectedSortByLabel(): void {
    const isSmartSortEligible = this.type === ClaimType.Medical ? AllClaimsService.getSmartSortEligible(this.allClaims as IClaim[]) : false;
    this.selectedFilters = this.filterService.getStateValue() ||
     AllClaimsService.getDefaultSelectedFilters(this.type, isSmartSortEligible);
    this.selectedSortByLabel = AllClaimsService.getSelectedSortOption(
        AllClaimsService.getSortByOptions(this.type, isSmartSortEligible),
        this.selectedFilters.sort).label;
  }
}
