import dashboardTemplate from 'views/states/dashboard.html';
import { DashboardController } from './dashboard.controller';

export class DashboardComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = DashboardController;
    this.templateUrl = dashboardTemplate;
  }
}
