import wellnessTemplate from 'views/programs/wellness.html';
import { WellnessController } from './wellness.controller';

export class WellnessComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = WellnessController;
    this.templateUrl = wellnessTemplate;
  }
}
