import seeYouLaterTemplate from 'views/modals/see-you-later.html';
import { MembershipCategory } from '../../../api/profile/profile.interfaces';
import { IStateParams } from '../../../arcade.module.interfaces';
import { IModalController } from '../../../ui/modal/modal.interfaces';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IPopulation } from '../../../util/population/population.interfaces';
import { IPopulationService } from '../../../util/population/population.service';

export class SeeYouLaterComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = SeeYouLaterController;
    this.templateUrl = seeYouLaterTemplate;
  }
}

export class SeeYouLaterController {
  public $modal: IModalController;
  public url: string;
  public pop: IPopulation;

  constructor(
    private $stateParams: IStateParams,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Environment: IEnvironmentConstants,
    private populationService: IPopulationService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.SEE_YOU_LATER);

    this.pop = populationService.getPopulation();
    this.url = this.$stateParams.destination;

    this.$timeout(() => {
      if (!this.$modal.closing) {
        this.goToDestination();
      }
    }, Environment.CONFIG.ARCADE_WEB_LINK_TIMER_MS);
  }

  public $onDestroy(): void {
    window.close();
  }

  public goToDestination(): void {
    window.location.replace(this.url);
  }

  public getMemberCatDisplayName(): string {
    const membershipCategory = this.pop.membershipCategory;
    switch (membershipCategory) {
      case MembershipCategory.RETIREE:
        return 'UHC';
      case MembershipCategory.MEDICA:
        return 'Medica';
      default:
        return membershipCategory;
    }
  }
}
