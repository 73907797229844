import { ProfileServiceActionTypes } from 'scripts/actions/profile-service-actions';
import { IClaimsPreferences, IProducts, IProfile, IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { ArcadeAction, IStateData } from 'scripts/reducers/reducer.interfaces';

export interface IProfileState {
  profile: IStateData<IProfile>;
  selectedProfile: IProfileUser;
  claimsPreferences: IStateData<IClaimsPreferences>;
  products: IStateData<IProducts>;
}

export const initialState: IProfileState = {
  profile: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  selectedProfile: undefined,
  claimsPreferences: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  products: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

export default (state: IProfileState = initialState, action: ArcadeAction): IProfileState => {
  switch (action.type) {
    case ProfileServiceActionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.payload.profile,
          error: false,
          loading: false,
        },
        selectedProfile: action.payload.selectedProfile,
      };
    case ProfileServiceActionTypes.GET_PROFILE_LOADING:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: true,
        },
      };
    case ProfileServiceActionTypes.GET_PROFILE_ERROR:
      return {
        ...state,
        profile: {
          data: undefined,
          error: true,
          loading: false,
        },
        selectedProfile: undefined,
      };
    case ProfileServiceActionTypes.CHANGE_SELECTED_PROFILE:
      return {
        ...state,
        selectedProfile: action.payload,
      };
    case ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_SUCCESS:
      return {
        ...state,
        claimsPreferences: {
          data: action.payload,
          error: false,
          loading: false,
        },
      };
    case ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_LOADING:
      return {
        ...state,
        claimsPreferences: {
          ...state.claimsPreferences,
          loading: true,
        },
      };
    case ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_ERROR:
      return {
        ...state,
        claimsPreferences: {
          data: undefined,
          error: true,
          loading: false,
        },
      };
    case ProfileServiceActionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          data: action.payload,
          error: false,
          loading: false,
        },
      };
    case ProfileServiceActionTypes.GET_PRODUCTS_LOADING:
      return {
        ...state,
        products: {
          ...state.products,
          loading: true,
        },
      };
    case ProfileServiceActionTypes.GET_PRODUCTS_ERROR:
      return {
        ...state,
        products: {
          data: undefined,
          error: true,
          loading: false,
        },
      };
    default:
      return state;
  }
};
