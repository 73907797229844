import angular from 'angular';
import 'angular-translate';
import { Observable } from 'rxjs/Observable';
import {
  ancillaryBenefits,
  benefitsCoverage,
  connectDoctor,
  connectDurable,
  connectInpatient,
  connectOutpatient,
  connectSpecialist,
} from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import costsTemplate from 'views/dashboard/mr-costs.html';
import { CoverageStatus, CoverageType, CoverageTypeCode } from '../../../api/api.interfaces';
import { IBenefitAmountService, IBenefitService, IPlanBenefitsResponse } from '../../../api/plans/plans.interfaces';
import { IPlansService } from '../../../api/plans/plans.service';
import { IPlanCoverage, ProgramType } from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IConfig, IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../../util/feature-flag/feature-flag.interface';
import { IResourceService } from '../../../util/resource/resource.service';
import { CostCopy, CostId } from '../../modals/cost-info/cost-info.interfaces';
import { CostInfoService } from '../../modals/cost-info/cost-info.service';
import { CostIdCodes, ICost } from '../costs/costs.interfaces';

export class MRCostsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;

  constructor() {
    this.controller = MRCostsController;
    this.templateUrl = costsTemplate;
  }
}

export interface IMRCosts {
  title?: string;
  coverageTypeCode?: CoverageTypeCode;
  showAncillaryLink?: boolean;
  showBenefitsLink?: boolean;
  costItems: IMRCostItem[];
}

export interface IMRCostItem extends ICost {
  costType?: string;
  show?: boolean;
}

export interface IServiceCodeMap {
  [serviceCode: string]: boolean;
}

export interface ITypeCodeMap {
  [coverageTypeCode: string]: boolean;
}

export class MRCostsController {
  public costs: IMRCosts[] = [];
  public config: IConfig;
  public request: Observable<any>;
  public benefitsCoverage: IResource;
  public ancillaryBenefits: IResource;
  public coverageKeyToCostSection: {[coverageKey: string]: IMRCosts} = {};

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private costInfoService: CostInfoService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COSTS);
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.TIPS_TO_SAVE);

    this.config = Environment.CONFIG;
    this.benefitsCoverage = benefitsCoverage;
    this.ancillaryBenefits = ancillaryBenefits;

    const showSectionTypeCodes: ITypeCodeMap = {
      [CoverageTypeCode.MA]: true,
      [CoverageTypeCode.MAPD]: true,
      [CoverageTypeCode.SSP]: true,
      [CoverageTypeCode.HospitalIndemnity]: true,
      [CoverageTypeCode.HospitalIndemnityRider]: true,
      [CoverageTypeCode.MedicareSupplement]: true,
      [CoverageTypeCode.MedicareSupplementRider]: true,
      [CoverageTypeCode.PHIP]: true,
    };
    const showServicesTypeCodes: ITypeCodeMap = {
      [CoverageTypeCode.MA]: true,
      [CoverageTypeCode.MAPD]: true,
      [CoverageTypeCode.SSP]: true,
    };

    const genericCostSection: IMRCosts = {
      costItems: [{
        id: CostId.mrDoctor,
        name: CostCopy.costMRDoctor.title,
        guidedSearch: {
          id: 'medical',
          text: CostCopy.costMRDoctor.link,
          href: this.resourceService.get(connectDoctor),
        },
      }, {
        id: CostId.mrSpecialist,
        name: CostCopy.costMRSpecialist.title,
        guidedSearch: {
          id: 'specialist',
          text: CostCopy.costMRSpecialist.link,
          href: this.resourceService.get(connectSpecialist),
        },
      }, {
        id: CostId.outpatient,
        name: CostCopy.costOutpatient.title,
        guidedSearch: {
          id: 'outpatient',
          text: CostCopy.costOutpatient.link,
          href: this.resourceService.get(connectOutpatient),
        },
      }, {
        id: CostId.inpatient,
        name: CostCopy.costInpatient.title,
        guidedSearch: {
          id: 'inpatient',
          text: CostCopy.costInpatient.link,
          href: this.resourceService.get(connectInpatient),
        },
      }, {
        id: CostId.equipment,
        name: CostCopy.costEquipment.title,
        guidedSearch: {
          id: 'equipment',
          text: CostCopy.costEquipment.link,
          href: this.resourceService.get(connectDurable),
        },
      }],
    };

    let rallyId: string;
    let depSeqNum: string;
    let currentCostSection: IMRCosts;
    let hasMAorMAPD: boolean = false;
    this.request = userService.getHeartbeat()
      .let(profileService.toProfile())
      .do(({data: {currentUser}}) => {
        rallyId = currentUser.rallyId;
        depSeqNum = currentUser.dependentSeqNum;
      })
      .flatMap(profile => {
        const coverages = profile.data.currentUser.planCoverages;
        hasMAorMAPD = coverages.some(coverage => {
          const code = coverage.coverageTypeCode;
          return code === CoverageTypeCode.MA || code === CoverageTypeCode.MAPD;
        });
        return coverages;
      })
      .filter(coverage => {
        const code = coverage.coverageTypeCode;
        return (
          coverage.planPeriod.status === CoverageStatus.Active &&
          showSectionTypeCodes[code] &&
          (code !== CoverageTypeCode.SSP || !hasMAorMAPD)
        );
      })
      .flatMap(coverage => {
        const benefitsObservable = this.plansService.getBenefits(rallyId, depSeqNum, coverage.coverageTypeCode);
        return Observable.if(() => coverage.planFeatures.programType === ProgramType.Ship,
          benefitsObservable
          .catch(() => {
            return Observable.of(this.getDefaultResponseData(coverage));
          }), benefitsObservable);
      }, (coverage, benefitsRsp) => ({coverage, benefitsRsp}))
      .do(({coverage, benefitsRsp}) => {
        const coverageKey = this.getCoverageKey(coverage);
        if (this.coverageKeyToCostSection[coverageKey]) {
          currentCostSection = this.coverageKeyToCostSection[coverageKey];
        } else {
          const medicalPlan = benefitsRsp.data.benefits[0];
          if (showServicesTypeCodes[coverage.coverageTypeCode]) {
            currentCostSection = medicalPlan.services.length > 0 ? angular.copy(genericCostSection) : undefined;
            if (currentCostSection) {
              currentCostSection.showAncillaryLink = medicalPlan.ancillaryBenefits.length > 0;
              currentCostSection.showBenefitsLink = false;
              const serviceCodeMap = this.getServiceCodeMap(medicalPlan.services);
              currentCostSection.costItems = currentCostSection.costItems.filter(item => serviceCodeMap[CostIdCodes[item.id]]);
            }
          } else {
            currentCostSection = {
              showAncillaryLink: false,
              showBenefitsLink: true,
              costItems: [],
            };
          }
          if (currentCostSection) {
            currentCostSection.coverageTypeCode = coverage.coverageTypeCode;
            currentCostSection.title = medicalPlan.planName || 'Medical Plan';
            this.coverageKeyToCostSection[coverageKey] = currentCostSection;
            this.costs.push(currentCostSection);
          }
        }
      })
      .flatMap(() => Observable.if(() => currentCostSection && currentCostSection.costItems.length > 0,
        Observable.from(currentCostSection.costItems)
          .flatMap(cost => costInfoService.getCostsForService(cost.id, currentCostSection.coverageTypeCode)
            .map(({inNetwork, tier1, type1}) => {
              if (tier1 && tier1.length > 0) {
                cost.isTier1 = true;
                cost.costType = 'TIER_ONE_COST';

                const tier1WithReferralRequired = this.getWithReferralCosts(cost, tier1);
                if (tier1WithReferralRequired.length > 0 && this.featureFlagService.isReferralRequiredOn()) {
                  cost.showWithReferralDesc = true;

                  return costInfoService.getNonZeroCostMap(tier1WithReferralRequired);
                }

                return costInfoService.getNonZeroCostMap(tier1);
              } else if (type1 && type1.length > 0) {
                cost.isTier1 = true;
                cost.costType = 'TYPE_ONE_COST';

                const type1WithReferralRequired = this.getWithReferralCosts(cost, type1);
                if (type1WithReferralRequired.length > 0 && this.featureFlagService.isReferralRequiredOn()) {
                  cost.showWithReferralDesc = true;

                  return costInfoService.getNonZeroCostMap(type1WithReferralRequired);
                }

                return costInfoService.getNonZeroCostMap(type1);
              } else {
                cost.isTier1 = false;
                cost.costType = 'IN_NETWORK_COST';

                const inNetworkWithReferralRequired = this.getWithReferralCosts(cost, inNetwork);
                if (inNetworkWithReferralRequired.length > 0 && this.featureFlagService.isReferralRequiredOn()) {
                  cost.showWithReferralDesc = true;

                  return costInfoService.getNonZeroCostMap(inNetworkWithReferralRequired);
                }

                return costInfoService.getNonZeroCostMap(inNetwork);
              }
            })
            .catch(() => Observable.of({})), (cost, costMap) => ({cost, costMap}))
          .do(({cost, costMap}) => {
            cost.costInfoCopy = this.costInfoService.chooseCostInfoCopy(costMap, true);
            cost.amounts = costMap;
          }),
        Observable.of(false),
      ));
  }

  public $onInit(): void {
    this.request.subscribe(() => undefined, console.warn);
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  private getServiceCodeMap(services: IBenefitService[]): IServiceCodeMap {
    const serviceCodeMap = {};
    for (const service of services) {
      serviceCodeMap[service.serviceCode.code] = this.serviceHasAmount(service);
    }
    return serviceCodeMap;
  }

  private getCoverageKey(coverage: IPlanCoverage): string {
    return `${coverage.coverageTypeCode}_${coverage.policyNumber}_${coverage.planPeriod.endDate}`;
  }

  private getDefaultResponseData(coverage: IPlanCoverage): IPlanBenefitsResponse {
    const defaultShipResponseData: any = {
      data: {
        benefits: [
          {
            coverageType: coverage.coverageType,
            shortPlanName: `AARP Supplemental or Personal Health Plan`,
            planName:  `AARP Supplemental or Personal Health Plan`,
            maxes: {
              inNetwork: [],
            },
            services: [],
            planFeatures: {
              isTieredPlan: false,
              isCspGspPlan: false,
              knownFeatures: [],
            },
            ancillaryBenefits: [],
            hideUHPD: false,
          },
        ],
      },
    };
    return defaultShipResponseData as IPlanBenefitsResponse;
  }

  private serviceHasAmount(service: IBenefitService): boolean {
    return (service.inNetwork && service.inNetwork.length > 0) ||
           (service.outOfNetwork && service.outOfNetwork.length > 0) ||
           (service.tier1 && service.tier1.length > 0) ||
           (service.tier2 && service.tier2.length > 0) ||
           (service.type1 && service.type1.length > 0) ||
           (service.type2 && service.type2.length > 0);
  }

  private getWithReferralCosts(cost: ICost, currentCosts: IBenefitAmountService[]): IBenefitAmountService[] {
    if (cost.id === CostId.mrSpecialist) {
      return currentCosts.filter(c => !!c.referralRequired);
    }

    return [];
  }
}
