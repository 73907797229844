import angular from 'angular';
import { Observable } from 'rxjs/Observable';
import { ClaimType, IClaimNote, IHealthcareClaimDetails } from 'scripts/api/claims/claims.interfaces';
import { IClaimsService } from 'scripts/api/claims/claims.service';
import { IProfileService } from 'scripts/api/profile/profile.service';
import { IUserService } from 'scripts/api/user/user.service';
import { IModalController } from 'scripts/ui/modal/modal.interfaces';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import editClaimNoteTemplate from 'views/modals/edit-claim-note.html';

export interface IEditClaimNoteParams extends ng.ui.IStateParamsService {
  claimNoteClaimKey: string;
  claimNoteType: ClaimType;
}

export class EditClaimNoteComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = EditClaimNoteController;
    this.templateUrl = editClaimNoteTemplate;
  }
}

export class EditClaimNoteController implements ng.IComponentController {
  public $modal: IModalController;
  public claimDetails: IHealthcareClaimDetails;
  public claimNote: IClaimNote;
  public claimRequests: Observable<IClaimNote>;

  constructor(
    private $stateParams: IEditClaimNoteParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private claimsService: IClaimsService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';

    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.EDIT_CLAIM_NOTE);
  }

  public $onInit(): void {
    const { claimNoteClaimKey: claimKey, claimNoteType } = this.$stateParams;

    this.claimRequests = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.rallyId)
      .flatMap(rallyId => this.setClaimDetails(rallyId, claimKey, claimNoteType), rallyId => rallyId)
      .flatMap(rallyId => this.setClaimNote(rallyId, claimKey));

    this.claimRequests.subscribe(() => undefined, console.warn);
  }

  public save(noteContents: string): void {
    this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.rallyId)
      .flatMap(rallyId => this.claimsService.setClaimNote(rallyId, this.claimDetails, noteContents))
      .subscribe(() => {
        this.claimNote.note = noteContents;
        this.$modal.close();
      }, console.warn);

  }

  public claimNoteExists(): boolean  {
    return !!(this.claimNote && this.claimNote.note && this.claimNote.note.trim());
  }

  public getClaimNote(): string {
    return (this.claimNote && this.claimNote.note) || '';
  }

  public getTitle(): string {
    if (this.claimNoteExists()) {
      return 'UPDATE_NOTE';
    }
    return 'MAKE_NOTE';
  }

  public cancel(): void {
    this.$modal.close();
  }

  private setClaimDetails(rallyId: string, claimKey: string, type: ClaimType): Observable<IHealthcareClaimDetails> {
    return this.claimsService.getHealthcareDetails(rallyId, claimKey, type)
      .map(rsp => rsp.data)
      .do(claimDetails => this.claimDetails = claimDetails);
  }

  private setClaimNote(rallyId: string, claimKey: string): Observable<IClaimNote> {
    return this.claimsService.getClaimNote(rallyId, claimKey)
      .map(rsp => rsp.data)
      .do(claimNote => this.claimNote = claimNote);
  }

}
