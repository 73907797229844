import idx from 'idx';
import { createSelector } from 'reselect';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';

const selectPopulation = (state: IReduxState) => state.population;

export const selectLineOfBusiness = createSelector(
  selectPopulation,
  population => idx(population, _ => _.lineOfBusiness),
);

export const selectMembershipCategory = createSelector(
  selectPopulation,
  population => idx(population, _ => _.membershipCategory),
);
