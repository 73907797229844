import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { getResource } from 'scripts/util/resource/resource';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { Anchor, IAnchorProps } from './anchor';

export interface IResourceAnchorProps extends IAnchorProps {
  resourceValue: IResource;
  population: IPopulation;
}

export const RawResourceAnchor: FunctionComponent<IResourceAnchorProps> = ({resourceValue, population, ...props}) => {
  const href = getResource(resourceValue, population);
  return (
    <Anchor
      href={href}
      {...props}
    />
  );
};

export const ResourceAnchor = connect(
  (state: IReduxState) => ({
    population: state.population,
  }),
  {},
)(RawResourceAnchor);
