import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { SharedHeaderFooterEventsController } from './shared-header-footer-events.controller';

export class SharedHeaderFooterEventsDirective implements ng.IDirective {
  public restrict = 'A';
  public scope = false;
  public controller = SharedHeaderFooterEventsController;
  public controllerAs = '$sharedHeaderFooterEvents';

  constructor(private Environment: IEnvironmentConstants) {}

  public link = (scope, element, attrs, $sharedHeaderFooterEvents) => {
    if (this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_SHARED_HEADER_AND_FOOTER) {
      $sharedHeaderFooterEvents.addSharedHeaderFooterListeners(element[0]);
    }
  }

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = (Environment: IEnvironmentConstants) => {
      'ngInject';
      return new SharedHeaderFooterEventsDirective(Environment);
    };
    return directive;
  }
}
