import { claimForms, documents, explanationOfBenefits, secureClaimsAccount, statements } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import claimsAndAccountsTemplate from 'views/states/claims-and-accounts.html';
import { RelationshipType } from '../../api/api.interfaces';
import { IProfileUser, LineOfBusiness, MembershipCategory } from '../../api/profile/profile.interfaces';
import { IProfileService } from '../../api/profile/profile.service';
import { IContentSuppressions } from '../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../api/targeting/targeting.service';
import { IUserService } from '../../api/user/user.service';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IPopulationService } from '../../util/population/population.service';
import { IResourceService } from '../../util/resource/resource.service';

export class ClaimsAndAccountsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = ClaimsAndAccountsController;
    this.templateUrl = claimsAndAccountsTemplate;
  }
}

export class ClaimsAndAccountsController {
  public claimFormsResource: IResource;
  public claimsSummaryResource: IResource;
  public documentsResource: IResource;
  public eobResource: IResource;
  public lineOfBusiness: LineOfBusiness;
  public showDocuments: boolean;
  public showStatements: boolean;
  public statementsResource: IResource;
  public stateToSubNavLabel: {[state: string]: string};

  constructor(
    public $state: ng.ui.IStateService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private populationService: IPopulationService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    public targetingService: ITargetingService,
    public userService: IUserService,
  ) {
    'ngInject';
    this.$translatePartialLoader.addPart(Dictionary.CLAIMS_AND_ACCOUNTS);

    this.claimFormsResource = claimForms;
    this.documentsResource = documents;
    this.statementsResource = statements;
    this.claimsSummaryResource = secureClaimsAccount;
    this.eobResource = explanationOfBenefits;

    this.stateToSubNavLabel = {
      'authenticated.claimsAndAccounts.summary': 'SUMMARY',
      'authenticated.claimsAndAccounts.planBalances': 'PLAN_BALANCES',
      'authenticated.claimsAndAccounts.claims': 'CLAIMS',
      'authenticated.claimsAndAccounts.claimDetails': 'CLAIMS',
      'authenticated.claimsAndAccounts.submitClaim': 'SUBMIT_CLAIM',
      'authenticated.claimsAndAccounts.directDeposit': 'PLAN_BALANCES',
      'authenticated.claimsAndAccounts.autoPayment': 'PLAN_BALANCES',
      'authenticated.claimsAndAccounts.statements': 'STATEMENTS',
      'authenticated.claimsAndAccounts.spendingAndCostSummary': 'SPENDING_AND_COST_SUMMARY',
    };
  }

  public $onInit(): void {
    const profile$ = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data);

    profile$.map(profile => profile.currentUser.lineOfBusiness)
      .subscribe(lineOfBusiness => this.lineOfBusiness = lineOfBusiness, console.warn);

    profile$
      .flatMap(({ rallyId }) => {
        return this.targetingService.getClientConfig(rallyId)
          .map(clientConfig => clientConfig.suppressions);
      }, (profile, suppressions) => ({user: profile.currentUser, suppressions}))
      .subscribe(({user, suppressions}) => {
        this.showDocuments = this.determineShowDocuments(suppressions);
        this.showStatements = this.determineShowStatements(user, suppressions);
      }, err => {
        this.showDocuments = this.showDocumentsByPopulation();
        this.showStatements = this.showStatementsByPopulation();
        console.warn(err);
      });
  }

  public getClaimsClass(): string {
    switch (this.$state.current.name) {
      case 'authenticated.claimsAndAccounts.claims':
        return 'active';
      case 'authenticated.claimsAndAccounts.claimDetails':
        return 'active active-clickable';
    }
  }

  public getPlanBalancesClass(): string {
    switch (this.$state.current.name) {
      case 'authenticated.claimsAndAccounts.planBalances':
        return 'active';
      case 'authenticated.claimsAndAccounts.autoPayment':
      case 'authenticated.claimsAndAccounts.directDeposit':
        return 'active active-clickable';
    }
  }

  private determineShowDocuments(suppressions: IContentSuppressions): boolean {
    return !(suppressions && suppressions.suppressClaimDocuments) && this.showDocumentsByPopulation();
  }

  private showDocumentsByPopulation(): boolean {
    const {membershipCategory} = this.populationService.getPopulation();
    return membershipCategory !== MembershipCategory.OXFORD;
  }

  private determineShowStatements(user: IProfileUser, suppressions: IContentSuppressions): boolean {
    const isSubscriber = user.relationshipType === RelationshipType.Subscriber;
    return !(suppressions && suppressions.suppressClaimStatements) && this.showStatementsByPopulation() && isSubscriber;
  }

  private showStatementsByPopulation(): boolean {
    const {membershipCategory} = this.populationService.getPopulation();
    return [MembershipCategory.OXFORD, MembershipCategory.EXPATRIATE].indexOf(membershipCategory) === -1;
  }
}
