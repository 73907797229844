import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { getMoneyValue } from 'scripts/util/money/money';
import {
  automaticPaymentSettings,
  directDepositSettings,
  manageOptumBankAccount,
  manageYourAccount,
  submitClaim,
} from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { ICurrencyAmount } from '../../../../api/api.interfaces';
import { AccountSystemCode, AccountType, ILedgerAccount } from '../../../../api/ledger/ledger.interfaces';
import { ILedgerService } from '../../../../api/ledger/ledger.service';
import { IProfileService, ProfileService } from '../../../../api/profile/profile.service';
import { IClientConfig } from '../../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../../api/targeting/targeting.service';
import { IUserService } from '../../../../api/user/user.service';
import { IStateParams } from '../../../../arcade.module.interfaces';
import { Dictionary } from '../../../../util/constants/i18n.constants';
import { ILocaleService } from '../../../../util/locale/locale.service';
import { IResourceService } from '../../../../util/resource/resource.service';

export class FinancialAccountsController implements ng.IComponentController {
  // bindings
  public showCurrentYear: boolean;
  public setYearOptions: ({options: IYearOptions}: any) => void;

  public accountsExpanded: boolean;
  public automaticPaymentSettings: IResource;
  public currentYearAccounts: ILedgerAccount[];
  public directDepositSettings: IResource;
  public hasHSA: boolean = false;
  public manageOptumBankAccount: IResource;
  public manageYourAccount: IResource;
  public previousYearAccounts: ILedgerAccount[];
  public submitClaim: IResource;
  private clientConfig: IClientConfig;
  private localeSubscription: Subscription;

  constructor(
    private $stateParams: IStateParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private ledgerService: ILedgerService,
    private localeService: ILocaleService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.PLAN_BALANCES);
    $translatePartialLoader.addPart(Dictionary.FAQ);
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.DCSA_FAQ);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);
    $translatePartialLoader.addPart(Dictionary.HRA_FAQ);
    $translatePartialLoader.addPart(Dictionary.HSA_FAQ);
    $translatePartialLoader.addPart(Dictionary.HCSA_FAQ);
    $translatePartialLoader.addPart(Dictionary.FSA_FAQ);
    $translatePartialLoader.addPart(Dictionary.MRA_FAQ);
    $translatePartialLoader.addPart(Dictionary.HOW_IT_WORKS);

    this.automaticPaymentSettings = automaticPaymentSettings;
    this.directDepositSettings = directDepositSettings;
    this.manageOptumBankAccount = manageOptumBankAccount;
    this.manageYourAccount = manageYourAccount;
    this.submitClaim = submitClaim;
    this.accountsExpanded = this.$stateParams.accountsExpanded;
  }

  public $onInit(): void {
    const clientConfig$ = this.userService.getHeartbeat()
      .flatMap(({data: {rallyId}}) => this.targetingService.getClientConfig(rallyId))
      .do(clientConfig => this.clientConfig = clientConfig);

    const hasHSA$ = this.userService.getHeartbeat()
      .flatMap(() => clientConfig$, heartbeat => heartbeat)
      .flatMap(rsp => this.ledgerService.getAccounts(rsp.data.rallyId))
      .map(({data}) => data.filter(account => account.balance))
      .do(accounts => {
        this.currentYearAccounts = accounts.filter(account => account.isActive);
        this.previousYearAccounts = accounts.filter(account => !account.isActive);
        if (typeof this.setYearOptions === 'function') {
          this.setYearOptions({options: {
            hasCurrentYear: this.currentYearAccounts.length > 0,
            hasPreviousYear: this.previousYearAccounts.length > 0,
          }});
        }
      })
      .flatMap(accounts => accounts.filter(account => account.isActive))
      .count(account => account.accountType === AccountType.HSA)
      .map(hsaCount => hsaCount > 0);

    const hasLedgerPermission$ = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(({data: {currentUser}}) => ProfileService.hasLedgerAccess(currentUser));

    hasLedgerPermission$.flatMap(hasLedgerPermission => hasLedgerPermission ? hasHSA$ : Observable.of(false))
      .subscribe(hasHSA => this.hasHSA = hasHSA, console.warn);

    this.localeSubscription = this.localeService.localeChanged
      .flatMap(() => hasLedgerPermission$)
      .flatMap(hasLedgerPermission => hasLedgerPermission ? clientConfig$ : Observable.of(false))
      .subscribe(() => undefined, console.warn);
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public getMoneyValue(amount: ICurrencyAmount): string {
    const val = amount ? amount.value : 0;
    return getMoneyValue(val || 0, true);
  }

  public getSign(amount: ICurrencyAmount): string {
    let val = amount ? amount.value : 0;
    val = val || 0;
    if (val < 0) {
      return '-';
    } else if (val > 0) {
      return '+';
    }
  }

  public getContentTemplate(accountType: AccountType | string): string {
    switch (accountType) {
      case AccountType.HSA:
        return 'hsa-account';
      case AccountType.HRA:
      case AccountType.HRASD:
      case AccountType.HRAPD:
        return 'hra-account';
      case AccountType.HRAAP:
        return 'hraap-account';
      case AccountType.FSAHC:
      case AccountType.FSADC:
      case AccountType.FSALP:
        return 'fsa-account';
      case AccountType.DCSA:
        return 'dcsa-account';
      case AccountType.HCSA:
        return 'hcsa-account';
      case AccountType.MRA:
        return 'mra-account';
      case AccountType.HIA:
      case AccountType.PRA:
      case AccountType.RMSA:
        return 'optum-account';
      default:
        return 'unknown-account';
    }
  }

  public hasCustomFaq(accountType: AccountType): boolean {
    return !!(this.clientConfig && this.clientConfig.contentOverrides.customFaq
      && this.clientConfig.contentOverrides.customFaq.hasOwnProperty(accountType));
  }

  public getAccountNameAbbreviation(accountType: AccountType): string | undefined {
    return this.clientConfig && this.clientConfig.customLabels.customFinancialAccountLabels &&
      this.clientConfig.customLabels.customFinancialAccountLabels.hasOwnProperty(accountType) ?
      this.clientConfig.customLabels.customFinancialAccountLabels[accountType].abbreviation : undefined;
  }

  public showLink(accountType: AccountType, link: string): boolean {
    return !(this.clientConfig && this.clientConfig.suppressions.suppressFinancialAccountLinks &&
      this.clientConfig.suppressions.suppressFinancialAccountLinks.hasOwnProperty(accountType) &&
      this.clientConfig.suppressions.suppressFinancialAccountLinks[accountType].indexOf(link) > -1);
  }

  public isWex(account: ILedgerAccount): boolean {
    return account.accountSystemCode === AccountSystemCode.WEX;
  }

  public getAllClaimsAccountType(accountType: AccountType): AccountType {
    switch (accountType) {
      case AccountType.DCSA:
      case AccountType.FSADC:
      case AccountType.FSAHC:
      case AccountType.FSALP:
      case AccountType.HCSA:
      case AccountType.HRA:
      case AccountType.HRAAP:
      case AccountType.HRAPD:
      case AccountType.HRASD:
      case AccountType.MRA:
        return accountType;
      default:
        return;
    }
  }
}
