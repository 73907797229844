import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import {
  dcsaPdfForm,
  dentalOnlineForm,
  fsahcPdfForm,
  fsaOnlineForm,
  hraOnlineForm,
  hraPdfForm,
  jpmcOnlineForm,
  medicalOnlineForm,
  mentalHealthOnlineForm,
  mraHcsaPdfForm,
  reviewDosAndDonts,
  reviewEligibleExpenses,
} from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import submitClaimHeaderTemplate from 'views/claims-and-accounts/submit/submit-claim-header.html';
import { CoverageStatus, CoverageType, ILink, LinkTarget } from '../../../../api/api.interfaces';
import { ILedgerAccount } from '../../../../api/ledger/ledger.interfaces';
import { ILedgerService, LedgerService } from '../../../../api/ledger/ledger.service';
import { IPlansService } from '../../../../api/plans/plans.service';
import { IProfileService, ProfileService } from '../../../../api/profile/profile.service';
import { IClientConfig } from '../../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../../api/targeting/targeting.service';
import { IUserService } from '../../../../api/user/user.service';
import { IFilesConstant } from '../../../../util/constants/files.constant';
import { Dictionary } from '../../../../util/constants/i18n.constants';
import { ILocaleService } from '../../../../util/locale/locale.service';
import { IResourceService } from '../../../../util/resource/resource.service';

export interface ISubmitClaimHeaderLink extends ILink {
  getHref?: () => string;
  icon?: string;
  show?: boolean;
  targetingKey?: string;
  title: string;
}

export class SubmitClaimHeaderComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = SubmitClaimHeaderController;
    this.templateUrl = submitClaimHeaderTemplate;
  }
}

export class SubmitClaimHeaderController implements ng.IComponentController {
  public submitClaimCustomMessage: string;
  public links: ISubmitClaimHeaderLink[];
  public linkRows: ISubmitClaimHeaderLink[][];
  public reviewDosAndDonts: IResource;
  public reviewEligibleExpenses: IResource;
  public beforeYouSubmitAccounts: string[];
  public reviewEligibleExpensesCopy: string;
  public reviewDosAndDontsCopy: string;
  private clientConfigReq: Observable<IClientConfig>;
  private localeSubscription: Subscription;
  private suppressOnlineFSAHRASubmission: boolean;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Files: IFilesConstant,
    private ledgerService: ILedgerService,
    private localeService: ILocaleService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.SUBMIT_CLAIM);

    this.reviewDosAndDonts = reviewDosAndDonts;
    this.reviewEligibleExpenses = reviewEligibleExpenses;

    this.links = [{
      getHref: () => this.resourceService.get(medicalOnlineForm),
      icon: 'icon-medical',
      show: true,
      target: LinkTarget.Blank,
      targetingKey: 'MEDICAL_PDF',
      text: 'PDF_SUBMISSION_FORM',
      title: 'MEDICAL_CLAIMS',
    }, {
      getHref: () => this.resourceService.get(dentalOnlineForm),
      icon: 'icon-dental',
      target: LinkTarget.Blank,
      text: 'ONLINE_SUBMISSION',
      title: 'DENTAL_CLAIMS',
    }, {
      getHref: () => this.resourceService.get(mentalHealthOnlineForm),
      icon: 'icon-mental-health',
      target: LinkTarget.Blank,
      text: 'ONLINE_SUBMISSION',
      title: 'MENTAL_HEALTH_CLAIMS',
    }, {
      icon: 'icon-mental-health',
      target: LinkTarget.Blank,
      targetingKey: 'MENTAL_HEALTH_PDF',
      text: 'PDF_SUBMISSION_FORM',
      title: 'MENTAL_HEALTH_CLAIMS',
    }, {
      getHref: () => this.resourceService.get(fsaOnlineForm),
      icon: 'icon-claims',
      target: LinkTarget.Blank,
      text: 'ONLINE_SUBMISSION',
      title: 'FSA_CLAIMS',
    }, {
      getHref: () => this.resourceService.get(fsahcPdfForm),
      icon: 'icon-claims',
      target: LinkTarget.Blank,
      targetingKey: 'FSA_HC_PDF',
      text: 'PDF_SUBMISSION_FORM',
      title: 'FSA_HC_CLAIMS',
    }, {
      icon: 'icon-claims',
      target: LinkTarget.Blank,
      targetingKey: 'FSA_DC_PDF',
      text: 'PDF_SUBMISSION_FORM',
      title: 'FSA_DC_CLAIMS',
    }, {
      getHref: () => this.resourceService.get(hraOnlineForm),
      icon: 'icon-claims',
      target: LinkTarget.Blank,
      text: 'ONLINE_SUBMISSION',
      title: 'HRA_CLAIMS',
    }, {
      getHref: () => this.resourceService.get(hraPdfForm),
      icon: 'icon-claims',
      target: LinkTarget.Blank,
      targetingKey: 'HRA_PDF',
      text: 'PDF_SUBMISSION_FORM',
      title: 'HRA_CLAIMS',
    }, {
      getHref: () => this.resourceService.get(jpmcOnlineForm),
      icon: 'icon-claims',
      target: LinkTarget.Blank,
      text: 'ONLINE_SUBMISSION',
      title: 'JPMC_CLAIMS',
    }, {
      getHref: () => this.resourceService.get(dcsaPdfForm),
      icon: 'icon-claims',
      target: LinkTarget.Blank,
      text: 'PDF_SUBMISSION_FORM',
      title: 'DCSA_CLAIMS',
    }, {
      getHref: () => this.resourceService.get(mraHcsaPdfForm),
      icon: 'icon-claims',
      target: LinkTarget.Blank,
      text: 'PDF_SUBMISSION_FORM',
      title: 'MRA_HCSA_CLAIMS',
    }, {
      icon: 'icon-pharmacy',
      target: LinkTarget.Blank,
      targetingKey: 'RX_PDF',
      text: 'PDF_SUBMISSION_FORM',
      title: 'RX_CLAIMS',
    }];

    this.clientConfigReq = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .flatMap(user => this.targetingService.getClientConfig(user.rallyId))
      .do(clientConfig => {
        this.submitClaimCustomMessage = clientConfig.customMessaging.submitClaimCustomMessage;
        const customPdfClaimForms = clientConfig.contentOverrides.customPdfClaimForms;
        if (customPdfClaimForms) {
          Object.keys(customPdfClaimForms).forEach(key =>
            this.links.filter(link => link.targetingKey === key)
              .forEach(link => {
                link.getHref = () => customPdfClaimForms[key];
              }),
          );
        }
        this.suppressOnlineFSAHRASubmission = clientConfig.suppressions.suppressOnlineFSAHRASubmission;
        this.setLinks();
      });

  }

  public $onInit(): void {
    this.setLinks();

    this.clientConfigReq.flatMap(() => this.userService.getHeartbeat())
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .do(currentUser => {
        const activeOrTermedCoverageTypes = ProfileService.getCoverageTypes(currentUser.planCoverages,
          [CoverageStatus.Active, CoverageStatus.Termed]);
        this.links.filter(link => link.title === 'DENTAL_CLAIMS')
          .forEach(link => link.show = activeOrTermedCoverageTypes.some(coverageType => coverageType === CoverageType.Dental));
        this.links.filter(link => link.title === 'MENTAL_HEALTH_CLAIMS' && link.getHref)
          .forEach(link => link.show = activeOrTermedCoverageTypes.some(coverageType => coverageType === CoverageType.BehavioralHealth));
        this.links.filter(link => link.title === 'RX_CLAIMS' && link.getHref)
          .forEach(link => link.show = activeOrTermedCoverageTypes.some(coverageType => coverageType === CoverageType.Rx));
        this.updateRows();
      })
      .takeWhile(currentUser => ProfileService.hasLedgerAccess(currentUser))
      .flatMap(currentUser => this.ledgerService.getAccounts(currentUser.rallyId))
      .map(rsp => rsp.data)
      .subscribe(accounts => {
        this.links.filter(link => link.title === 'FSA_CLAIMS')
          .forEach(link => link.show = accounts.some(account => LedgerService.isFsaAccount(account) &&
            !(this.suppressOnlineFSAHRASubmission && (link.text === 'ONLINE_SUBMISSION'))));
        this.links.filter(link => link.title === 'FSA_HC_CLAIMS')
          .forEach(link => link.show = accounts.some(account => LedgerService.isFsaHcAccount(account)));
        this.links.filter(link => link.title === 'FSA_DC_CLAIMS' && link.getHref)
          .forEach(link => link.show = accounts.some(account => LedgerService.isFsaDcAccount(account)));
        this.links.filter(link => link.title === 'HRA_CLAIMS')
          .forEach(link => link.show = accounts.some(account => LedgerService.isHraAccount(account) &&
            !(this.suppressOnlineFSAHRASubmission && (link.text === 'ONLINE_SUBMISSION'))));
        this.links.filter(link => link.title === 'JPMC_CLAIMS')
          .forEach(link => link.show = accounts.some(account => LedgerService.isJpmcAccount(account)));
        this.links.filter(link => link.title === 'DCSA_CLAIMS')
          .forEach(link => link.show = accounts.some(account => LedgerService.isDcsaAccount(account)));
        this.links.filter(link => link.title === 'MRA_HCSA_CLAIMS')
          .forEach(link => link.show = accounts.some(account => LedgerService.isMraAccount(account) ||
            LedgerService.isHcsaAccount(account)));
        this.updateRows();
        this.updateTitles(accounts);
        this.setBeforeYouSubmitAccounts(accounts);
        this.setBeforeYouSubmitLinkCopy(accounts);
      }, console.warn);

    this.localeSubscription = this.localeService.localeChanged
      .flatMap(() => this.clientConfigReq)
      .subscribe();
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public getIcon(link: ISubmitClaimHeaderLink): string {
    return this.Files.getIcon(link.icon);
  }

  private setBeforeYouSubmitAccounts(accounts: ILedgerAccount[]): void {
    this.beforeYouSubmitAccounts = [];
    const accountStrings: string[] = [];
    if (accounts.some(account => LedgerService.isFsaAccount(account))) {
      accountStrings.push('FSA');
    }
    if (accounts.some(account => LedgerService.isHraAccount(account))) {
      accountStrings.push('HRA');
    }
    if (accounts.some(account => LedgerService.isMraAccount(account))) {
      accountStrings.push('MRA');
    }
    if (accounts.some(account => LedgerService.isHcsaAccount(account))) {
      accountStrings.push('HCSA');
    }
    if (accounts.some(account => LedgerService.isDcsaAccount(account))) {
      accountStrings.push('DCSA');
    }
    if (accountStrings.length === 1) {
      this.beforeYouSubmitAccounts.push(accountStrings.pop());
    } else if (accountStrings.length > 1) {
      this.beforeYouSubmitAccounts.push(accountStrings.slice(0, accountStrings.length - 1).join(', '));
      this.beforeYouSubmitAccounts.push(accountStrings.pop());
    }
  }

  private setBeforeYouSubmitLinkCopy(accounts: ILedgerAccount[]): void {
    const hasFsaAccount = accounts.some(account => LedgerService.isFsaAccount(account));
    const hasHraAccount = accounts.some(account => LedgerService.isHraAccount(account));
    const hasHcsaAccount = accounts.some(account => LedgerService.isHcsaAccount(account));
    const hasDcsaAccount = accounts.some(account => LedgerService.isDcsaAccount(account));
    if (hasHcsaAccount && hasDcsaAccount) {
      this.reviewEligibleExpensesCopy = 'VIEW_ELIGIBLE_HCSA_OR_DCSA_EXPENSES';
    } else if (hasDcsaAccount) {
      this.reviewEligibleExpensesCopy = 'VIEW_ELIGIBLE_DCSA_EXPENSES';
    } else if (hasHcsaAccount) {
      this.reviewEligibleExpensesCopy = 'VIEW_ELIGIBLE_HCSA_EXPENSES';
    } else if (hasFsaAccount) {
      this.reviewEligibleExpensesCopy = 'VIEW_ELIGIBLE_FSA_EXPENSES';
    }
    if (!hasFsaAccount && hasHraAccount) {
      this.reviewDosAndDontsCopy = 'REVIEW_DOS_AND_DONTS_HRA';
    } else {
      this.reviewDosAndDontsCopy = 'REVIEW_DOS_AND_DONTS';
    }
  }

  private setLinks(): void {
    this.links.filter(link => link.getHref).forEach(link => link.href = link.getHref());
  }

  private updateRows(): void {
    // Supports a maximum of 15 links
    const availableLinks = this.links.filter(link => link.show).slice(0, 15);
    this.linkRows = [];
    if (availableLinks.length <= 5) {
      this.linkRows.push(availableLinks);
    } else if (availableLinks.length <= 10) {
      const split = Math.ceil(availableLinks.length / 2);
      this.linkRows.push(availableLinks.slice(0, split));
      this.linkRows.push(availableLinks.slice(split, availableLinks.length));
    } else {
      const split1 = Math.ceil(availableLinks.length / 3);
      const split2 = Math.ceil(2 * availableLinks.length / 3);
      this.linkRows.push(availableLinks.slice(0, split1));
      this.linkRows.push(availableLinks.slice(split1, split2));
      this.linkRows.push(availableLinks.slice(split2, availableLinks.length));
    }
  }

  private updateTitles(accounts: ILedgerAccount[]): void {
    const hasFsaDcAccount = accounts.some(account => LedgerService.isFsaDcAccount(account));
    const hasFsaHcAccount = accounts.some(account => LedgerService.isFsaHcAccount(account));
    this.links.filter(link => link.text === 'ONLINE_SUBMISSION' && link.title === 'FSA_CLAIMS').forEach(link => {
      if (hasFsaDcAccount && hasFsaHcAccount) {
        link.title = 'FSA_HC_AND_DC_CLAIMS';
      } else if (hasFsaDcAccount) {
        link.title = 'FSA_DC_CLAIMS';
      } else if (hasFsaHcAccount) {
        link.title = 'FSA_HC_CLAIMS';
      }
    });
  }
}
