import resourcesTemplate from 'views/programs/resources.html';
import { ResourcesController } from './resources.controller';

export class ResourcesComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = ResourcesController;
    this.templateUrl = resourcesTemplate;
  }
}
