import {
  getSummaryError,
  getSummaryLoading,
  getSummarySuccess,
  saveClaimSuccess,
} from 'scripts/actions/claims-service-actions';
import { ClaimsApi } from 'scripts/api/claims/claims-api';
import { IClaim, IHealthcareClaimDetails } from 'scripts/api/claims/claims.interfaces';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { selectedUser } from 'scripts/selectors/profile-service-selectors';

export function getSummary(): ArcadeThunkAction<void> {
  return async (dispatch, getState) => {
    try {
      dispatch(getSummaryLoading());
      const profile = selectedUser.selectProfile(getState());
      const { data } = await ClaimsApi.getSummary(profile);
      dispatch(getSummarySuccess(data));
    } catch (error) {
      dispatch(getSummaryError());
    }
  };
}

export function saveClaim(claim: IClaim | IHealthcareClaimDetails): ArcadeThunkAction<void> {
  return async (dispatch, getState) => {
    try {
      const profile = selectedUser.selectProfile(getState());
      await ClaimsApi.saveClaim(profile, claim);
      const { claimKey } = claim;
      dispatch(saveClaimSuccess(claimKey));
    } catch (error) {
      console.warn(error);
    }
  };
}
