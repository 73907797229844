import { benefitsCoverageMedical } from 'scripts/util/resource/resource.constants';
import { BenefitNetwork, IBenefitService } from '../../../api/plans/plans.interfaces';
import { LineOfBusiness } from '../../../api/profile/profile.interfaces';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../../util/feature-flag/feature-flag.interface';
import { IPopulationService } from '../../../util/population/population.service';
import { IResourceService } from '../../../util/resource/resource.service';
import { CostIdCodes } from '../../dashboard/costs/costs.interfaces';
import {
  ICostCopyItem,
  ICostInfo,
  ICostInfoController,
  ICostInfoItem,
  ICostInfoStateParams,
} from './cost-info.interfaces';
import { CostInfoService } from './cost-info.service';

export class CostInfoController implements ICostInfoController {
  public copy: ICostCopyItem;
  public urlEscapedDeeplink: string;
  public isMR: boolean;
  public hideInNetwork: boolean = false;
  public costInfo: ICostInfo = {} as ICostInfo;
  public totalNetworks: number = 0;
  public disclaimerLink: string;

  private networksMap: {[benefitNetwork: string]: boolean} = {};

  constructor(
    private $stateParams: ICostInfoStateParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private costCopy: ICostCopyItem,
    private costInfoService: CostInfoService,
    private featureFlagService: IFeatureFlagService,
    private populationService: IPopulationService,
    public resourceService: IResourceService,
    private serviceCosts: IBenefitService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COSTS);
    $translatePartialLoader.addPart(Dictionary.TIPS_TO_SAVE);
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.copy = this.costCopy;
    this.urlEscapedDeeplink = encodeURIComponent(this.$stateParams.deepLink);
    this.disclaimerLink = this.resourceService.get(benefitsCoverageMedical);
  }

  public $onInit(): void {
    this.isMR = this.populationService.getPopulation().lineOfBusiness === LineOfBusiness.MR;
    this.setNetworkInfo(BenefitNetwork.InNetwork);
    if (this.serviceCosts.serviceCode.code === CostIdCodes.costSpecialist ||
        this.serviceCosts.serviceCode.code === CostIdCodes.costCSSpecialist) {
      this.setNetworkInfo(BenefitNetwork.InNetwork, true);
      this.setNetworkInfo(BenefitNetwork.Tier1, true);
    }
    if (this.isMR) {
      // ARC-3069: tiers only apply to PCP and Specialist
      if (this.serviceCosts.serviceCode.code === CostIdCodes.costDoctor ||
          this.serviceCosts.serviceCode.code === CostIdCodes.costSpecialist) {
        this.setNetworkInfo(BenefitNetwork.Tier1);
        this.setNetworkInfo(BenefitNetwork.Tier2);
      } else {
        this.setNetworkInfo(BenefitNetwork.Type1);
        this.setNetworkInfo(BenefitNetwork.Type2);
      }
      this.setTieredMRCopy(this.serviceCosts.serviceCode.code);
      this.hideInNetwork = !this.featureFlagService.isTierTwoEnabled() &&
        (this.costInfo.tier1 !== undefined || this.costInfo.type1 !== undefined);
    } else {
      this.setNetworkInfo(BenefitNetwork.Tier1);
      this.setNetworkInfo(BenefitNetwork.OutOfNetwork);
    }

    this.totalNetworks = Object.keys(this.networksMap).length;
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  private setNetworkInfo(network: BenefitNetwork, referralRequired: boolean = false): void {
    if (this.serviceCosts[network] && this.serviceCosts[network].length > 0) {
      const relevantCosts = this.serviceCosts[network].filter(c => !!c.referralRequired === referralRequired ||
        !this.featureFlagService.isReferralRequiredOn());
      if (relevantCosts.length > 0) {
        this.networksMap[network] = true;
        const networkKey = referralRequired && this.featureFlagService.isReferralRequiredOn() ? network + 'WithReferral' : network;
        this.costInfo[networkKey] = {} as ICostInfoItem;
        this.costInfo[networkKey].amounts = this.costInfoService.getNonZeroCostMap(relevantCosts);
        this.costInfo[networkKey].copy = this.costInfoService.chooseCostInfoCopy(this.costInfo[networkKey].amounts, this.isMR);
        this.costInfo[networkKey].bigNum = this.costInfoService.getBigNum(this.costInfo[networkKey].amounts, this.isMR);
      }
    }
  }

  private setTieredMRCopy(serviceCode: string): void {
    if ((this.costInfo[BenefitNetwork.Tier1] || this.costInfo[BenefitNetwork.Tier2]) &&
        (serviceCode === CostIdCodes.costDoctor || serviceCode === CostIdCodes.costSpecialist)) {
      this.copy.description = 'MR_PCP_SPECIALIST_TIERED_DESC';
    } else if ((this.costInfo[BenefitNetwork.Type1] || this.costInfo[BenefitNetwork.Type2]) &&
        serviceCode === CostIdCodes.costOutpatient) {
      this.copy.description = 'OUTPATIENT_SURGERY_TIERED_DESC';
    }
  }
}
