import { IConfig, IFeatureFlags } from './environment.interfaces';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    CONFIG: IConfig;
    FEATURE_FLAGS: IFeatureFlags;
  }
}

const DEFAULT_FEATURE_FLAGS: IFeatureFlags = {
  ARCADE_FEATURES_ACTIVATE_STEPS: false,
  ARCADE_FEATURES_ACTIVATE_STEPS_V6: false,
  ARCADE_FEATURES_ACTIVATE_VIDEO: true,
  ARCADE_FEATURES_ADDITIONAL_SERVICES: true,
  ARCADE_FEATURES_ADVANTAGE: false,
  ARCADE_FEATURES_ALL_CLAIMS_KEYWORD_FILTER: true,
  ARCADE_FEATURES_ANALYTICS_DEBUG: false,
  ARCADE_FEATURES_AUTO_PAYMENT: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_DCSA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_FSADC: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_FSAHC: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_FSALP: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HCSA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HRA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HRAAP: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HRAPD: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HRASD: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_MRA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_DCSA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_FSADC: true,
  ARCADE_FEATURES_CLAIM_DETAILS_FSAHC: true,
  ARCADE_FEATURES_CLAIM_DETAILS_FSALP: true,
  ARCADE_FEATURES_CLAIM_DETAILS_HCSA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_HRA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_HRAAP: true,
  ARCADE_FEATURES_CLAIM_DETAILS_HRAPD: true,
  ARCADE_FEATURES_CLAIM_DETAILS_HRASD: true,
  ARCADE_FEATURES_CLAIM_DETAILS_MRA: true,
  ARCADE_FEATURES_CLAIM_HELP_MENU_ALL_CLAIMS: true,
  ARCADE_FEATURES_CLAIM_LETTERS: true,
  ARCADE_FEATURES_CLAIM_MORE_OPTIONS_DROPDOWN: true,
  ARCADE_FEATURES_DIRECT_DEPOSIT: true,
  ARCADE_FEATURES_DOCUMENTS_CLAIMS_EOB: true,
  ARCADE_FEATURES_DOCUMENTS_SCREEN_READER_LINK: true,
  ARCADE_FEATURES_EI_RECOMMENDATIONS: true,
  ARCADE_FEATURES_EXCHANGE_PREMIUM_PAYMENTS: true,
  ARCADE_FEATURES_FPC_PCP_FOR_GATED: true,
  ARCADE_FEATURES_GENESYS_CHAT: true,
  ARCADE_FEATURES_GENESYS_CHAT_CLAIMS: true,
  ARCADE_FEATURES_HEALTHCARE_CLAIMS_V3: true,
  ARCADE_FEATURES_IAQHP_SERVICE_ENDING_BANNER: true,
  ARCADE_FEATURES_MR_ACCOUNT_SUMMARY: true,
  ARCADE_FEATURES_MR_PRE_EFF_PREMIUM_PAYMENTS: true,
  ARCADE_FEATURES_MR_RX_SPENDING_AND_COST_SUMMARY: true,
  ARCADE_FEATURES_MR_SPENDING_AND_COST_SUMMARY: true,
  ARCADE_FEATURES_NOTIFICATION: false,
  ARCADE_FEATURES_PAY_NOW_MODAL: true,
  ARCADE_FEATURES_PCD: true,
  ARCADE_FEATURES_PCP_GROUP_OR_FACILITY_NAME: false,
  ARCADE_FEATURES_PCP_NON_GATED: true,
  ARCADE_FEATURES_PCP_POLARIS: true,
  ARCADE_FEATURES_PCP_THROUGH_FPC: true,
  ARCADE_FEATURES_OPTUM_RX_PHARMACY_REDESIGN: true,
  ARCADE_FEATURES_QUICK_LINKS: true,
  ARCADE_FEATURES_RALLY_RX: false,
  ARCADE_FEATURES_REACT_TEST: true,
  ARCADE_FEATURES_REDUX_LOGGING: true,
  ARCADE_FEATURES_REFERRAL_REQUIRED: false,
  ARCADE_FEATURES_SHARED_HEADER_AND_FOOTER: true,
  ARCADE_FEATURES_SHARED_HEADER_AND_FOOTER_PHASE_TWO: true,
  ARCADE_FEATURES_SHOW_IOS_TRANSCRIPT_VIDEO: false,
  ARCADE_FEATURES_STATEMENTS: true,
  ARCADE_FEATURES_SUBMIT_CLAIM: true,
  ARCADE_FEATURES_TIER_TWO: true,
  ARCADE_FEATURES_TIERED_BENEFITS: false,
  ARCADE_FEATURES_VIDEO_BANNER: false,
};

const envFeatureFlags = window.FEATURE_FLAGS;
const useDefaultFeatureFlags = !envFeatureFlags ||
(envFeatureFlags.constructor === Object && Object.keys(envFeatureFlags).length === 0);

const FEATURE_FLAGS: IFeatureFlags = useDefaultFeatureFlags ? DEFAULT_FEATURE_FLAGS : envFeatureFlags;

export default FEATURE_FLAGS;
