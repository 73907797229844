import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { getMoneyValue } from 'scripts/util/money/money';
import { benefitsCoverage } from 'scripts/util/resource/resource.constants';
import { CoverageType, RelationshipType } from '../../../../api/api.interfaces';
import {
  ANNUAL_MAX_BENEFIT,
  BENEFIT_TYPE,
  BenefitNetwork,
  BenefitPaymentType,
  BenefitTypeCopyMap,
  FAMILY,
  IBenefitAccumulators,
  IBenefitAmount,
  IBenefitsSpendingList,
  IFamilyBenefits,
  INDIVIDUAL,
  IPlanFamilyAccumulatorsResponse,
  IPlanFamilyBenefitsResponse,
  LIFETIME_MAX_BENEFIT,
} from '../../../../api/plans/plans.interfaces';
import { IPlansService } from '../../../../api/plans/plans.service';
import { IProfile, IProfileUserInfo, MembershipCategory } from '../../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../../api/profile/profile.service';
import { IClientConfig } from '../../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../../api/targeting/targeting.service';
import { IUserService } from '../../../../api/user/user.service';
import { IDropdownOption } from '../../../../ui/dropdown/dropdown.interfaces';
import { Dictionary } from '../../../../util/constants/i18n.constants';
import { ContentOverrideFilter } from '../../../../util/content-override/content-override.filter';
import { ContentOverrideCondition } from '../../../../util/content-override/content-override.interfaces';
import { ILocaleService } from '../../../../util/locale/locale.service';
import { IResourceService } from '../../../../util/resource/resource.service';
import {
  AccumulatorModalType,
  CoverageTypeMap,
  IAccumulatorsController,
  IDepSeqNumToName,
  IIndividualSpending,
  INetworkSpending,
  ISpendingData,
  ISpendingItem,
} from './accumulators.interfaces';

declare let angular;

export class AccumulatorsController implements IAccumulatorsController {
  public isMedicalTabActive: boolean;
  public isEhbPlan: boolean;
  public ehbLink: string;
  public spendingData: ISpendingData = {medical: {}, dental: {}};
  public spendingDataRequest: Observable<any>;
  public depSeqNums: string[];
  public currentNetworkOptions: IDropdownOption[];
  public selectedNetwork: IDropdownOption;
  public medicalNetworkOptions: IDropdownOption[];
  public membershipCategory: MembershipCategory;
  public dentalNetworkOptions: IDropdownOption[];
  private networkOptions: IDropdownOption[];
  private depSeqNumToName: IDepSeqNumToName;
  private clientConfig: IClientConfig;
  private clientConfigReq: Observable<IClientConfig>;
  private localeSubscription: Subscription;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private localeService: ILocaleService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ACCUMULATORS);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);

    this.ehbLink = this.resourceService.get(benefitsCoverage);

    this.clientConfigReq = this.userService.getHeartbeat()
      .flatMap(({data}) => this.targetingService.getClientConfig(data.rallyId))
      .do(clientConfig => this.clientConfig = clientConfig);

    this.userService.getHeartbeat()
      .flatMap(() => this.clientConfigReq, heartbeat => heartbeat)
      .let(this.profileService.toProfile())
      .do(({data: {currentUser}}) => {
        this.membershipCategory = currentUser.membershipCategory;
        this.createNetworkOptions();
        const medicalCoverage = this.profileService.getCoverage(CoverageType.Medical, currentUser.planCoverages);
        this.isEhbPlan = medicalCoverage ? medicalCoverage.planFeatures.isEssentialHealthBenefitsPlan : false;
      })
      .flatMap(({data}) => {
        this.depSeqNums = AccumulatorsController.getFamilyDepSeqNums(data);
        this.depSeqNumToName = AccumulatorsController.mapDepSeqNumToName(data);
        const benefits = this.userService.getHeartbeat()
          .flatMap(({data: {rallyId}}) => this.plansService.getFamilyBenefits(rallyId, this.depSeqNums));
        const accumulators = this.userService.getHeartbeat()
          .flatMap(({data: {rallyId}}) => this.plansService.getFamilyAccumulators(rallyId, this.depSeqNums));
        this.spendingDataRequest = Observable.zip(benefits, accumulators);
        return this.spendingDataRequest;
      }).subscribe(([benefitsRsp, accumulatorsRsp]) => {
        this.initializeSpendingData(benefitsRsp, accumulatorsRsp);
        this.applyCustomLabels();
        this.reorderDentalData();
        this.setNetworkOptions();
        this.applyNetworkCustomLabels();
        this.activateTab(this.medicalNetworkOptions.length > 0);
      }, console.warn);

    this.localeSubscription = localeService.localeChanged
      .flatMap(() => this.clientConfigReq, locale => locale)
      .subscribe(() => {
        this.ehbLink = this.resourceService.get(benefitsCoverage);
        this.applyCustomLabels();
        this.applyNetworkCustomLabels();
      });
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public activateTab(isMedical: boolean): void {
    this.isMedicalTabActive = isMedical;
    this.currentNetworkOptions = (isMedical ? this.medicalNetworkOptions : this.dentalNetworkOptions);
    this.selectedNetwork = this.currentNetworkOptions[0];
  }

  public changeNetwork(): (option: IDropdownOption) => void {
    return (option: IDropdownOption) => {
      this.selectedNetwork = option;
    };
  }

  public getMoneyValue(amount: number, decimal?: boolean): string {
    return getMoneyValue(amount, decimal);
  }

  public getPercentUsed(item: ISpendingItem): string {
    const percentage = item.amount ? item.amount / item.max * 100 : 0;
    return percentage.toFixed(0) + '%';
  }

  private initializeSpendingData(benefitsRsp: IPlanFamilyBenefitsResponse, accumulatorsRsp: IPlanFamilyAccumulatorsResponse): void {
    for (const depSeqNum of this.depSeqNums) {
      if (benefitsRsp.data.hasOwnProperty(depSeqNum)) {
        for (const benefit of benefitsRsp.data[depSeqNum].benefits) {
          const coverage = benefit.coverageType;
          if (coverage === CoverageType.Medical && benefit.planFeatures.isCspGspPlan) {
            this.networkOptions.filter(option => option.label === 'TIER_ONE').forEach(option => option.label = 'CUSTOMER_SPECIFIC_NETWORK');
          }
          const mappedCoverage = CoverageTypeMap[benefit.coverageType];
          for (const network of this.networkOptions) {
            const benefitNetwork = network.value as BenefitNetwork;
            if (benefit.maxes[benefitNetwork] && benefit.maxes[benefitNetwork].length > 0) {
              this.spendingData[mappedCoverage][benefitNetwork] = this.spendingData[mappedCoverage][benefitNetwork] || [];
              const networkSpendingData: IIndividualSpending[] = this.spendingData[mappedCoverage][benefitNetwork];
              const hasFamily = networkSpendingData[0] && networkSpendingData[0].isFamily;
              const familySpendingData = hasFamily ? networkSpendingData[0] : { name: 'FAMILY_ACCOUNTS', isFamily: true, spending: [] };
              const individualSpendingData: IIndividualSpending = { name: this.depSeqNumToName[depSeqNum], isFamily: false, spending: [] };
              for (const max of benefit.maxes[benefitNetwork] as IBenefitAmount[]) {
                // create network spending
                const newSpendingItems = this.createNetworkSpendingItems(depSeqNum, coverage, benefitNetwork, max, accumulatorsRsp);
                if (BENEFIT_TYPE[max.type].owner === FAMILY) {
                  this.pushSpendingItems(familySpendingData, newSpendingItems);
                }
                if (BENEFIT_TYPE[max.type].owner === INDIVIDUAL) {
                  this.pushSpendingItems(individualSpendingData, newSpendingItems);
                }
              }
              // create preDeductibleMax spending
              if (benefit.maxes.predeductibleMax) {
                const newSpendingItems = this.createPreDeductibleSpendingItems(depSeqNum, coverage, benefitNetwork,
                  benefit.maxes.predeductibleMax, accumulatorsRsp);
                this.pushSpendingItems(individualSpendingData, newSpendingItems);
              }
              // create lifetimeMax spending
              if (benefit.maxes.lifetimeMax) {
                const newSpendingItems = this.createLifetimeSpendingItems(depSeqNum, coverage, benefitNetwork,
                  benefit.maxes.lifetimeMax, accumulatorsRsp);
                this.pushSpendingItems(individualSpendingData, newSpendingItems);
              }
              // Sort and push into spending array
              if (familySpendingData.spending.length > 0) {
                if (coverage === CoverageType.Medical) {
                  this.sortMedicalSpendingItems(familySpendingData.spending);
                }
                if (!hasFamily) {
                  networkSpendingData.unshift(familySpendingData);
                }
              }
              if (individualSpendingData.spending.length > 0) {
                coverage === CoverageType.Medical ? this.sortMedicalSpendingItems(individualSpendingData.spending) :
                  this.sortDentalSpendingItems(individualSpendingData.spending);
                this.insertFillerSpendingItem(coverage, individualSpendingData.spending);
                networkSpendingData.push(individualSpendingData);
              }
            }
          }
        }
      }
    }
  }

  private createNetworkSpendingItems(depSeqNum: string, coverage: CoverageType, network: BenefitNetwork, max: IBenefitAmount,
                                     accumulatorsRsp: IPlanFamilyAccumulatorsResponse): ISpendingItem[] | undefined {
    if (this.isAccumulatorSuppressed(coverage, network, max.type)) {
      return undefined;
    }
    const benefitType: string = BENEFIT_TYPE[max.type].benefit;
    const newSpendingItem: ISpendingItem = {
      type: BenefitTypeCopyMap[benefitType],
      paymentType: max.type,
      max: max.amount.value,
      amount: AccumulatorsController.getCorrespondingSpentValue(accumulatorsRsp, depSeqNum, coverage, network, max.type),
      isMaxBenefit: (benefitType === ANNUAL_MAX_BENEFIT || benefitType === LIFETIME_MAX_BENEFIT),
      link: this.getSpendingItemLink(coverage, network, max),
      track: this.getSpendingItemTrack(coverage, network, max),
    };
    return [newSpendingItem];
  }

  private createPreDeductibleSpendingItems(depSeqNum: string, coverage: CoverageType, network: BenefitNetwork, max: IBenefitAmount,
                                           accumulatorsRsp: IPlanFamilyAccumulatorsResponse): ISpendingItem[] | undefined {
    if (coverage !== CoverageType.Medical || [BenefitNetwork.InNetwork, BenefitNetwork.OutOfNetwork].indexOf(network) === -1) {
      return undefined;
    }
    let preDeductibleAccumulators: IBenefitAmount[];
    if (accumulatorsRsp.data && accumulatorsRsp.data[depSeqNum]) {
      const benefits = accumulatorsRsp.data[depSeqNum].benefits;
      const benefit: IBenefitAccumulators = AccumulatorsController.findObjectWithPropertyValue(benefits, 'coverageType', coverage);
      preDeductibleAccumulators = benefit && benefit.predeductibleAccumulators;
    }
    if (!preDeductibleAccumulators) {
      return undefined;
    }

    const spendingItems: ISpendingItem[] = [];
    for (const accumulator of preDeductibleAccumulators) {
      if (!this.isAccumulatorSuppressed(coverage, network, accumulator.type)) {
        const newSpendingItem: ISpendingItem = {
          type: BenefitTypeCopyMap[accumulator.type],
          paymentType: accumulator.type,
          max: max.amount.value,
          amount: accumulator.amount.value,
          isMaxBenefit: true,
          link: this.getSpendingItemLink(coverage, network, accumulator),
          track: this.getSpendingItemTrack(coverage, network, accumulator),
        };
        spendingItems.push(newSpendingItem);
      }
    }
    spendingItems.sort(item1 => item1.paymentType === BenefitPaymentType.PreDeductibleAllowanceRoutineServices ? -1 : 1);
    return spendingItems.length > 0 ? spendingItems : undefined;
  }

  private createLifetimeSpendingItems(depSeqNum: string, coverage: CoverageType, network: BenefitNetwork, max: IBenefitAmount,
                                      accumulatorsRsp: IPlanFamilyAccumulatorsResponse): ISpendingItem[] | undefined {
    if (coverage !== CoverageType.Medical || [BenefitNetwork.InNetwork, BenefitNetwork.OutOfNetwork].indexOf(network) === -1) {
      return undefined;
    }
    let lifetimeAccumulator: IBenefitAmount;
    if (accumulatorsRsp.data && accumulatorsRsp.data[depSeqNum]) {
      const benefits = accumulatorsRsp.data[depSeqNum].benefits;
      const benefit: IBenefitAccumulators = AccumulatorsController.findObjectWithPropertyValue(benefits, 'coverageType', coverage);
      lifetimeAccumulator = benefit && benefit.lifetimeMaxAccumulator;
    }
    if (!lifetimeAccumulator || this.isAccumulatorSuppressed(coverage, network, max.type)) {
      return undefined;
    }

    const spendingItem: ISpendingItem = {
      type: BenefitTypeCopyMap[max.type],
      paymentType: max.type,
      max: max.amount.value,
      amount: lifetimeAccumulator.amount.value,
      isMaxBenefit: true,
      link: this.getSpendingItemLink(coverage, network, max),
      track: this.getSpendingItemTrack(coverage, network, max),
    };
    return [spendingItem];
  }

  private pushSpendingItems(spendingData: IIndividualSpending, spendingItems: ISpendingItem[]): void {
    if (spendingItems) {
      for (const spendingItem of spendingItems) {
        const contains = spendingData.spending.filter(spending => spending.type === spendingItem.type).length > 0;
        if (!contains) {
          spendingData.spending.push(spendingItem);
        }
      }
    }
  }

  private reorderDentalData(): void {
    const dentalData = this.spendingData.dental;
    for (const network of this.networkOptions) {
      const networkData = dentalData[network.value];
      if (networkData && networkData[0] && networkData[0].isFamily) {
        networkData.push(networkData.shift());
      }
    }
  }

  private sortDentalSpendingItems(spendingItems: ISpendingItem[]): void {
    spendingItems.sort((item1, item2) => {
      if (item1.type === BenefitTypeCopyMap.ANNUAL_MAX_BENEFIT || item2.type === BenefitTypeCopyMap.DEDUCTIBLE) {
        return -1;
      } else if (item1.type === BenefitTypeCopyMap.DEDUCTIBLE || item2.type === BenefitTypeCopyMap.ANNUAL_MAX_BENEFIT) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  private sortMedicalSpendingItems(spendingItems: ISpendingItem[]): void {
    const sortOrder = [BenefitTypeCopyMap.COPAY_MAX, BenefitTypeCopyMap.OOP_MAX_2, BenefitTypeCopyMap.OOP, BenefitTypeCopyMap.DEDUCTIBLE];
    spendingItems.sort((item1, item2) => {
      const item1Index = sortOrder.indexOf(item1.type);
      const item2Index = sortOrder.indexOf(item2.type);
      if (item1Index > item2Index) {
        return -1;
      } else if (item1Index < item2Index) {
        return 1;
      }
      return 0;
    });
  }

  private createNetworkOptions(): void {
    this.networkOptions = [{
      label: ContentOverrideFilter.do('IN_NETWORK', ContentOverrideCondition.Expatriate, this.membershipCategory),
      value: 'inNetwork',
    }, {
      label: ContentOverrideFilter.do('OUT_OF_NETWORK', ContentOverrideCondition.Expatriate, this.membershipCategory),
      value: 'outOfNetwork',
    }, {
      label: ContentOverrideFilter.do('TIER_ONE', ContentOverrideCondition.Expatriate, this.membershipCategory),
      value: 'tier1',
    }];
  }

  private setNetworkOptions(): void {
    this.medicalNetworkOptions = angular.copy(this.networkOptions);
    this.dentalNetworkOptions = angular.copy(this.networkOptions);
    for (let i = this.networkOptions.length - 1; i >= 0; i--) {
      if (!this.spendingData.medical[this.networkOptions[i].value] ||
          this.spendingData.medical[this.networkOptions[i].value].length === 0) {
        this.medicalNetworkOptions.splice(i, 1);
      }
      if (!this.spendingData.dental[this.networkOptions[i].value] ||
          this.spendingData.dental[this.networkOptions[i].value].length === 0) {
        this.dentalNetworkOptions.splice(i, 1);
      }
    }
    // re-order the medical network options if the tier1 accumulators are shown on the dashboard
    if (this.clientConfig.suppressions.showTier1OnDashboard) {
      const index = this.medicalNetworkOptions.map(option => option.value).indexOf('tier1');
      if (index > -1) {
        this.medicalNetworkOptions.unshift(this.medicalNetworkOptions[index]);
        this.medicalNetworkOptions.splice(index + 1, 1);
      }
    }
  }

  private isAccumulatorSuppressed(coverage: CoverageType, network: BenefitNetwork, type: BenefitPaymentType): boolean {
    if (coverage === CoverageType.Medical) {
      const accSuppression = this.clientConfig.suppressions.accumulatorSuppression || {};
      return accSuppression.hasOwnProperty(network) && accSuppression[network].indexOf(type) !== -1;
    }
    return false;
  }

  private applyCustomLabels(): void {
    if (this.clientConfig.customLabels.customAccumulatorLabels) {
      const medicalCoverage = CoverageTypeMap[CoverageType.Medical];
      for (const network of this.networkOptions) {
        const networkSpending: IIndividualSpending[] = (this.spendingData.hasOwnProperty(medicalCoverage) &&
          this.spendingData[medicalCoverage][network.value]) || [];
        for (const individualSpending of networkSpending) {
          const spending = individualSpending.spending || [];
          for (const item of spending) {
            item.customLabel = this.getCustomLabel(network.value as BenefitNetwork, item.paymentType);
          }
        }
      }
    }
  }

  private applyNetworkCustomLabels(): void {
    if (this.clientConfig.customLabels.customNetworkLabels) {
      for (const network of this.medicalNetworkOptions) {
        if (this.clientConfig.customLabels.customNetworkLabels.hasOwnProperty(network.value)) {
          network.label = this.clientConfig.customLabels.customNetworkLabels[network.value];
        }
      }
    }
  }

  private getCustomLabel(network: BenefitNetwork, type: BenefitPaymentType): string {
    const customLabels = this.clientConfig.customLabels.customAccumulatorLabels || {};
    return customLabels.hasOwnProperty(network) && customLabels[network].hasOwnProperty(type) ?
      customLabels[network][type] : null;
  }

  private getSpendingItemLink(coverage: CoverageType, network: BenefitNetwork, max: IBenefitAmount): string {
    const modalType = this.getModalType(coverage, network, max);
    if (modalType && coverage === CoverageType.Dental) {
      return `modal.${modalType.replace('-', '')}Explanation`;
    }
    if (modalType && coverage === CoverageType.Medical) {
      return `modal.medicalAccountExplanation({ network: $ctrl.selectedNetwork.value, paymentType: item.paymentType })`;
    }
  }

  private getSpendingItemTrack(coverage: CoverageType, network: BenefitNetwork, max: IBenefitAmount): string {
    const modalType = this.getModalType(coverage, network, max);
    if (modalType && coverage === CoverageType.Dental) {
      return `${modalType.toLowerCase()}-explanation`;
    }
    if (modalType && coverage === CoverageType.Medical) {
      return `medical-${modalType}-explanation`;
    }
  }

  private getModalType(coverage: CoverageType, network: BenefitNetwork, max: IBenefitAmount): AccumulatorModalType | undefined {
    if (coverage === CoverageType.Dental) {
      switch (max.type) {
        case BenefitPaymentType.FamilyDeductible:
        case BenefitPaymentType.IndividualDeductible:
          return AccumulatorModalType.DentalDeductible;
        case BenefitPaymentType.IndividualLifetimeMax:
          return AccumulatorModalType.DentalLifetimeMax;
        case BenefitPaymentType.IndividualAnnualMax:
          return AccumulatorModalType.DentalAnnualMax;
      }
    }
    if (coverage === CoverageType.Medical) {
      switch (max.type) {
        case BenefitPaymentType.FamilyDeductible:
        case BenefitPaymentType.IndividualDeductible:
          return AccumulatorModalType.MedicalDeductible;
        case BenefitPaymentType.FamilyOop:
        case BenefitPaymentType.IndividualOop:
          return AccumulatorModalType.MedicalOopMax;
        case BenefitPaymentType.PreDeductibleAllowanceCombined:
        case BenefitPaymentType.PreDeductibleAllowanceEmergencyServices:
        case BenefitPaymentType.PreDeductibleAllowanceRoutineServices:
          return AccumulatorModalType.MedicalPreDeductible;
        case BenefitPaymentType.PlanLifetimeMax:
          return AccumulatorModalType.MedicalLifetimeMax;
        case BenefitPaymentType.FamilyOopMax2:
        case BenefitPaymentType.IndividualOopMax2:
          return AccumulatorModalType.MedicalOopMax2;
        case BenefitPaymentType.FamilyCopayMax:
        case BenefitPaymentType.IndividualCopayMax:
          return AccumulatorModalType.MedicalCopayMax;
      }
    }
  }

  private insertFillerSpendingItem(coverage: CoverageType, spendingItems: ISpendingItem[]): void {
    if (coverage === CoverageType.Dental && spendingItems[0].isMaxBenefit &&
        (spendingItems.length === 1 || !spendingItems[1].isMaxBenefit)) {
      // insert a filler spending item if there is only 1 dental max, which is helpful for things like borders on the page
      spendingItems.splice(1, 0, undefined);
    }
  }

  /**
   * This function slots in the spent value from the accumulators response into the benefits spending data
   * (which contains the max value but not the spent amount). Having to make two separate calls and then "zip"
   * them together like this is kind of a pain so might be worth moving this logic to the BE at some point.
   */
  private static getCorrespondingSpentValue(accumulatorsRsp: IPlanFamilyAccumulatorsResponse,
                                            depSeqNum: string, coverage: CoverageType, networkType: string, benefitType: string): number {
    if (accumulatorsRsp.data && accumulatorsRsp.data[depSeqNum]) {
      const benefits = accumulatorsRsp.data[depSeqNum].benefits;
      const benefit = AccumulatorsController.findObjectWithPropertyValue(benefits, 'coverageType', coverage);
      if (benefit && benefit.accumulators && benefit.accumulators[networkType]) {
        const networkSpending = benefit.accumulators[networkType];
        const benefitSpending = AccumulatorsController.findObjectWithPropertyValue(networkSpending, 'type', benefitType);
        if (benefitSpending && benefitSpending.amount) {
          return benefitSpending.amount.value;
        }
      }
    }
  }

  private static getFamilyDepSeqNums(profile: IProfile): string[] {
    const depSeqNums = [profile.currentUser.dependentSeqNum];
    if (profile.currentUser.relationshipType === RelationshipType.Subscriber) {
      for (const d of profile.dependents) {
        depSeqNums.push(d.dependentSeqNum);
      }
    }
    return depSeqNums.sort();
  }

  private static getFullName(userInfo: IProfileUserInfo): string {
    const firstPlusLast = userInfo.firstName + ' ' + userInfo.lastName;
    return userInfo.suffix ? (firstPlusLast + ' ' + userInfo.suffix) : firstPlusLast;
  }

  private static mapDepSeqNumToName(profile: IProfile): IDepSeqNumToName {
    const map = {};
    map[profile.currentUser.dependentSeqNum] = AccumulatorsController.getFullName(profile.currentUser.userInfo);
    if (profile.currentUser.relationshipType === RelationshipType.Subscriber) {
      for (const d of profile.dependents) {
        map[d.dependentSeqNum] = AccumulatorsController.getFullName(d.userInfo);
      }
    }
    return map;
  }

  private static findObjectWithPropertyValue(objects: any[], property: string, targetValue: string): any {
    for (const obj of objects) {
      if (obj[property] === targetValue) {
        return obj;
      }
    }
  }
}
