import moment from 'moment';
import featureFlagService from 'scripts/util/feature-flag/feature-flag';
import { ICurrencyAmount, ITimePeriod } from '../../../api/api.interfaces';
import { AnyClaimType, ClaimType, IAnyClaim, IClaim, IFinancialClaim} from '../../../api/claims/claims.interfaces';
import { ClaimsService } from '../../../api/claims/claims.service';
import { AccountType } from '../../../api/ledger/ledger.interfaces';
import { IDropdownOption, ISortDropdownOption } from '../../../ui/dropdown/dropdown.interfaces';
import {
  FilterType,
  IAvailableFilterValue,
  IGenericFilter,
  ISelectedFilters,
  ISelectedSort,
  ISort,
} from '../../../ui/filter/filter.interfaces';
import { IFilterService } from '../../../ui/filter/filter.service';

export enum SortBy {
  AmountPaid = 'amountPaid',
  AmountPending = 'amountPending',
  AmountSubmitted = 'amountSubmitted',
  HealthPlanPays = 'healthPlanPays',
  PatientResponsibility = 'patientResponsibility',
  ProcessedDate = 'processedDate',
  ProviderName = 'providerName',
  ServiceDate = 'serviceDate',
  SmartSort = 'smartSort',
  TotalBilledAmount = 'totalBilledAmount',
  YouMayOweAmount = 'youMayOweAmount',
}

export class AllClaimsService {
  public static getDefaultSelectedFilters<U>(type: AnyClaimType, isSmartSortEligible?: boolean): ISelectedFilters<U> {
    const healthcareFilterSort = isSmartSortEligible ? SortBy.SmartSort : SortBy.ProcessedDate;
    return {
      sort: {by: ClaimsService.getByType(type, healthcareFilterSort, SortBy.ProcessedDate), reverse: true},
      values: {},
    };
  }

  public static getFilters(type: AnyClaimType): Array<IGenericFilter<IClaim | IFinancialClaim, any>> {
    return ClaimsService.getByType(type, () => this.getClaimFilters(), () => this.getFinancialClaimFilters());
  }

  public static getSelectedSortOption(options: ISortDropdownOption[], selectedSort: ISelectedSort): IDropdownOption {
    const {by, reverse} = selectedSort;
    for (const option of options) {
      if (option.value === by && option.reverse === reverse) {
        return option;
      }
    }
  }

  public static getSortByOptions(type: AnyClaimType, smartSortEligible?: boolean): ISortDropdownOption[] {
    const sortByOptions = [{
      label: 'AMOUNT_PAID_LOWEST_TO_HIGHEST',
      value: SortBy.AmountPaid,
      applies: this.financialClaimTypes(),
    }, {
      label: 'AMOUNT_PAID_HIGHEST_TO_LOWEST',
      value: SortBy.AmountPaid,
      reverse: true,
      applies: this.financialClaimTypes(),
    }, {
      label: 'AMOUNT_PENDING_LOWEST_TO_HIGHEST',
      value: SortBy.AmountPending,
      applies: this.financialClaimTypes(),
    }, {
      label: 'AMOUNT_PENDING_HIGHEST_TO_LOWEST',
      value: SortBy.AmountPending,
      reverse: true,
      applies: this.financialClaimTypes(),
    }, {
      label: 'AMOUNT_SUBMITTED_LOWEST_TO_HIGHEST',
      value: SortBy.AmountSubmitted,
      applies: this.financialClaimTypes(),
    }, {
      label: 'AMOUNT_SUBMITTED_HIGHEST_TO_LOWEST',
      value: SortBy.AmountSubmitted,
      reverse: true,
      applies: this.financialClaimTypes(),
    }, {
      label: 'PROCESSED_DATE_OLDEST_TO_NEWEST',
      value: SortBy.ProcessedDate,
      applies: [...this.financialClaimTypes(), ...this.healthClaimTypes()],
    }, {
      label: 'PROCESSED_DATE_NEWEST_TO_OLDEST',
      value: SortBy.ProcessedDate,
      reverse: true,
      applies: [...this.financialClaimTypes(), ...this.healthClaimTypes()],
    }, {
      label: 'SERVICE_DATE_OLDEST_TO_NEWEST',
      value: SortBy.ServiceDate,
      applies: [...this.financialClaimTypes(), ...this.healthClaimTypes()],
    }, {
      label: 'SERVICE_DATE_NEWEST_TO_OLDEST',
      value: SortBy.ServiceDate,
      reverse: true,
      applies: [...this.financialClaimTypes(), ...this.healthClaimTypes()],
    }, {
      label: 'PLAN_PAID_LOWEST_TO_HIGHEST',
      value: SortBy.HealthPlanPays,
      applies: this.healthClaimTypes(),
    }, {
      label: 'PLAN_PAID_HIGHEST_TO_LOWEST',
      value: SortBy.HealthPlanPays,
      reverse: true,
      applies: this.healthClaimTypes(),
    }, {
      label: 'YOU_PAID_LOWEST_TO_HIGHEST',
      value: SortBy.PatientResponsibility,
      applies: this.healthClaimTypes(),
    }, {
      label: 'YOU_PAID_HIGHEST_TO_LOWEST',
      value: SortBy.PatientResponsibility,
      reverse: true,
      applies: this.healthClaimTypes(),
    }, {
      label: 'AMOUNT_BILLED_LOWEST_TO_HIGHEST',
      value: SortBy.TotalBilledAmount,
      applies: this.healthClaimTypes(),
    }, {
      label: 'AMOUNT_BILLED_HIGHEST_TO_LOWEST',
      value: SortBy.TotalBilledAmount,
      reverse: true,
      applies: this.healthClaimTypes(),
    }, {
      label: 'YOU_MAY_OWE_LOWEST_TO_HIGHEST',
      value: SortBy.YouMayOweAmount,
      applies: this.healthClaimTypes(),
    }, {
      label: 'YOU_MAY_OWE_HIGHEST_TO_LOWEST',
      value: SortBy.YouMayOweAmount,
      reverse: true,
      applies: this.healthClaimTypes(),
    }, {
      label: 'PROVIDER_NAME_A_Z',
      value: SortBy.ProviderName,
      applies: [...this.financialClaimTypes(), ...this.healthClaimTypes()],
    }, {
      label: 'PROVIDER_NAME_Z_A',
      value: SortBy.ProviderName,
      reverse: true,
      applies: [...this.financialClaimTypes(), ...this.healthClaimTypes()],
    }].filter(by => by.applies.indexOf(type) > -1);
    if (smartSortEligible) {
      sortByOptions.unshift({
        label: 'SMART_SORT_PAY_NOW_FIRST',
        value: SortBy.SmartSort,
        reverse: true,
        applies: this.healthClaimTypes(),
      });
    }
    return sortByOptions;
  }

  public static getSort(selectedSort: ISelectedSort): ISort<IAnyClaim> {
    const properties = [
      (claim: IClaim) => claim.providerName,
      (claim: IClaim) => claim.claimId,
    ];
    let by: Array<(claim: IAnyClaim) => any>;
    switch (selectedSort.by) {
      case SortBy.SmartSort:
        by = [
          (claim: IClaim) => ClaimsService.showPayNow(claim) && !claim.claimManagementInfo.markPaid,
          claim => claim.processedDate,
        ];
        break;
      case SortBy.AmountPaid:
        by = [(claim: IFinancialClaim) => claim.balance.amountPaid.value];
        break;
      case SortBy.AmountPending:
        by = [(claim: IFinancialClaim) => claim.balance.amountPending.value];
        break;
      case SortBy.AmountSubmitted:
        by = [(claim: IFinancialClaim) => claim.balance.amountSubmitted.value];
        break;
      case SortBy.ProcessedDate:
        by = [claim => claim.processedDate];
        break;
      case SortBy.ServiceDate:
        by = [claim => claim.serviceDate];
        break;
      case SortBy.HealthPlanPays:
        by = [(claim: IClaim) => this.getValOrZero(claim.balance.healthPlanPays)];
        break;
      case SortBy.PatientResponsibility:
        by = [(claim: IClaim) => ClaimsService.getYouPaidAmount(claim)];
        break;
      case SortBy.TotalBilledAmount:
        by = [(claim: IClaim) => claim.balance.totalBilledAmount.value];
        break;
      case SortBy.YouMayOweAmount:
        by = [(claim: IClaim) => this.getYouMayOweFilterValue(claim, selectedSort.reverse)];
        break;
      case SortBy.ProviderName:
        by = [claim => claim.providerName];
        break;
      default:
        by = [];
    }
    properties.unshift(...by);
    return {
      properties,
      reverse: selectedSort.reverse,
    };
  }

  public static setIsMerp(claims: IAnyClaim[], type: AnyClaimType, showMerp: boolean): void {
    if (ClaimsService.getByType(type, true, false)) {
      const healthCareClaims = claims as IClaim[];
      healthCareClaims.forEach(claim => {
          if (claim.claimManagementInfo) {
            claim.claimManagementInfo.isMerp = claim.claimManagementInfo.isMerp && showMerp;
          }
        },
      );
    }
  }

  public static getPeriod(filters: Array<IGenericFilter<IClaim, any>>, selectedFilters: ISelectedFilters<any>): ITimePeriod {
    const period = {} as ITimePeriod;
    const defaultPeriod = filters.filter(filter => filter.name === 'by-date-range')[0].defaultValues;
    const selectedPeriod = selectedFilters.values && selectedFilters.values['by-date-range'];
    if (selectedPeriod && selectedPeriod.length === 2) {
      period.startDate = moment(selectedPeriod[0]);
      period.endDate = moment(selectedPeriod[1]);
    } else if (defaultPeriod && defaultPeriod.length === 2) {
      period.startDate = moment(defaultPeriod[0]);
      period.endDate = moment(defaultPeriod[1]);
    }
    return period;
  }

  public static isDateRangeValid(startDate: moment.Moment, endDate: moment.Moment): boolean {
    return startDate.isSameOrBefore(endDate, 'day') && startDate.isSameOrBefore(moment(), 'day');
  }

  public static getSmartSortEligible(claims: IClaim[]): boolean {
    return claims ? claims.some(claim => this.getYouOweAmount(claim) > 3) : false;
  }

  private static healthClaimTypes(): AnyClaimType[] {
    return [ClaimType.Medical, ClaimType.Dental, ClaimType.Rx];
  }
  private static financialClaimTypes(): AnyClaimType[] {
    return [
      AccountType.DCSA,
      AccountType.FSADC,
      AccountType.FSAHC,
      AccountType.FSALP,
      AccountType.HCSA,
      AccountType.HRA,
      AccountType.HRAAP,
      AccountType.HRAPD,
      AccountType.HRASD,
      AccountType.MRA,
    ];
  }
  private static getClaimFilters(): Array<IGenericFilter<IClaim, any>> {
    const filters: Array<IGenericFilter<IClaim, any>> = [];
    if (featureFlagService.isAllClaimsKeywordFilterOn()) {
      filters.push({
        name: 'keyword',
        title: 'KEYWORD',
        displayFunction: () => undefined,
        mapFunc: (filterData: IClaim) => [
          filterData.claimId,
          filterData.claimType === ClaimType.Rx ? 'PHARMACY' : filterData.claimType,
          filterData.providerName,
          filterData.serviceRecipient.firstName,
          filterData.serviceRecipient.lastName,
          filterData.serviceDate,
          filterData.lastServiceDate,
          filterData.processedDate,
        ],
        type: FilterType.Keyword,
      });
    }
    filters.push(...[{
      name: 'coverage-type',
      title: 'BY_COVERAGE_TYPE',
      displayFunction: (_, filterValue: ClaimType): string => {
        return filterValue === ClaimType.Rx ? 'PHARMACY' : filterValue;
      },
      mapFunc: (filterData: IClaim): ClaimType => {
        return filterData.claimType;
      },
      type: FilterType.Checkbox,
    }, {
      name: 'member',
      title: 'BY_MEMBER',
      displayFunction: (filterData: IClaim, _): string => {
        return `${filterData.serviceRecipient.firstName} ${filterData.serviceRecipient.lastName}`;
      },
      mapFunc: (filterData: IClaim): string => {
        return filterData.serviceRecipient.dependentSeqNbr;
      },
      type: FilterType.Checkbox,
    }, {
      name: 'year',
      title: 'BY_YEAR',
      defaultValues: [moment().format('YYYY')],
      displayFunction: (filterData: IClaim, _): string => {
        return `${moment(filterData.serviceDate).format('YYYY')}`;
      },
      mapFunc: (filterData: IClaim): string => {
        return moment(filterData.serviceDate).format('YYYY');
      },
      filterOnStateFunc: (availableFilters: IAvailableFilterValue<string>, filterService: IFilterService): boolean => {
        const selectedFilters = filterService.getStateValue();

        if (selectedFilters && selectedFilters.values && selectedFilters.values.hasOwnProperty('by-date-range')) {
          const startDate = moment(selectedFilters.values['by-date-range'][0]).year();
          const endDate = moment(selectedFilters.values['by-date-range'][1]).year();
          const yearToBeChecked = moment( availableFilters.value, 'YYYY').year();
          return (startDate <= yearToBeChecked && yearToBeChecked <= endDate);
        }
        return true;
      },
      sortFunc: (a: IAvailableFilterValue<string>, b: IAvailableFilterValue<string>): number => a.value < b.value ? 1 : -1,

      type: FilterType.Dropdown,
    }, {
      name: 'provider',
      title: 'BY_PROVIDER',
      displayFunction: (filterData: IClaim, _): string => {
        return `${filterData.providerName}`;
      },
      mapFunc: (filterData: IClaim): string => {
        return filterData.providerHash;
      },
      type: FilterType.Dropdown,
    }, {
      name: 'by-date-range',
      title: 'BY_DATE_RANGE',
      displayFunction: (_, filterValue: moment.Moment | string): string => {
        return (moment.isMoment(filterValue) ? filterValue : moment(filterValue)).format('MM/DD/YYYY');
      },
      mapFunc: (filterData: IClaim): moment.Moment | string => {
        return filterData.serviceDate;
      },
      isValid(): boolean {
        if (this.selectedValues && this.selectedValues.length === 2) {
          const [startDate, endDate]: moment.Moment[] = this.selectedValues.map(date => moment.isMoment(date) ? date : moment(date));
          return AllClaimsService.isDateRangeValid(startDate, endDate);
        }
        return true;
      },
      defaultValues: [moment().startOf('date').subtract(ClaimsService.getClaimsSearchMonthsAgo(), 'M'), moment().startOf('date')],
      type: FilterType.DateRange,
    }, {
      name: 'refine',
      title: 'REFINE',
      displayFunction: (_, filterValue: string): string => {
        const translationKeys = {
          claimNote: 'NOTED',
          isMerp: 'MERP_EXECUTIVE_CLAIM',
          isSaved: 'SAVED',
          markPaid: 'MARKED_AS_PAID',
          youOwe: 'YOU_MAY_OWE',
          notMarkedAsPaid: 'NOT_MARKED_AS_PAID',
        };
        return translationKeys[filterValue];
      },
      mapFunc: (filterData: IClaim): string[] => {
        const {claimManagementInfo} = filterData;
        claimManagementInfo.youOwe = this.getYouOweAmount(filterData) > 0 && !claimManagementInfo.markPaid;
        const result =  Object.keys(claimManagementInfo).filter(key => claimManagementInfo[key]);
        if (!claimManagementInfo.markPaid && this.getYouOweAmount(filterData) !== 0) {
          result.push('notMarkedAsPaid');
        }
        return result;
      },
      type: FilterType.Checkbox,
    }]);
    return filters;
  }

  private static getFinancialClaimFilters(): Array<IGenericFilter<IFinancialClaim, any>> {
    return [{
      name: 'by-date-range',
      title: 'BY_DATE_RANGE',
      displayFunction: (_, filterValue: moment.Moment | string): string => {
        return (moment.isMoment(filterValue) ? filterValue : moment(filterValue)).format('MM/DD/YYY');
      },
      mapFunc: (filterData: IFinancialClaim): moment.Moment | string => {
        return filterData.serviceDate;
      },
      defaultValues: [moment().startOf('date').subtract(ClaimsService.getClaimsSearchMonthsAgo(), 'M'), moment().startOf('date')],
      type: FilterType.DateRange,
    }];
  }

  private static getYouOweAmount(claim: IClaim): number {
    return ClaimsService.getYouMayOweAmount(claim) || 0;
  }

  private static getValOrZero(money: ICurrencyAmount): number {
    if (typeof money !== 'undefined') {
      return money.value;
    } else {
      return Number(0.00);
    }
  }

  private static getYouMayOweFilterValue(claim: IClaim, reverse: boolean): number {
    if (claim.claimManagementInfo.markPaid) {
      return 0;
    } else {
      const youMayOweAmount = ClaimsService.getYouMayOweAmount(claim);
      if (!!reverse) {
        return (typeof youMayOweAmount !== 'undefined') ?  youMayOweAmount : Number.NEGATIVE_INFINITY;
      } else {
        return (typeof youMayOweAmount !== 'undefined') ?  youMayOweAmount : Number.POSITIVE_INFINITY;
      }
    }
  }
}
