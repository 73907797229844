import React, { Component, createRef, KeyboardEvent, ReactElement, RefObject } from 'react';
import { DropdownListItem } from './dropdown-list-item';
import { IDropdownOption } from './dropdown.interfaces';

interface IDropdownListProps {
  dictionary: string;
  firstOptionRef: RefObject<HTMLLIElement | HTMLAnchorElement>;
  onSelect: (option: IDropdownOption) => void;
  options: IDropdownOption[];
  optionsId: string;
  nav?: boolean;
  selected?: IDropdownOption;
  toggleId: string;
}

export class DropdownList extends Component<IDropdownListProps> {
  private optionRefs: Array<RefObject<HTMLLIElement | HTMLAnchorElement>>;

  public render(): ReactElement<IDropdownListProps> {
    const { dictionary, nav, options, optionsId, selected, toggleId, firstOptionRef } = this.props;
    this.optionRefs = [];
    const dropdownListItems = options.filter(option => option.condition === undefined || option.condition).map((option, i) => {
      const isSelected = selected && selected === option;
      const optionRef = i > 0 ? createRef<HTMLLIElement | HTMLAnchorElement>() : firstOptionRef;
      this.optionRefs.push(optionRef);
      return (
        <DropdownListItem
          dictionary={dictionary}
          ref={optionRef}
          key={option.value + option.label}
          nav={nav}
          onClick={this.handleClick(option)}
          option={option}
          selected={isSelected}
        />
      );
    });
    return (
      <ul
        aria-expanded="true"
        aria-labelledby={toggleId}
        className="dropdown-options"
        data-testid="dropdown-options"
        id={optionsId}
        onKeyDown={this.handleKeyDown}
        role="group"
      >
        {dropdownListItems}
      </ul>
    );
  }

  private handleClick(selectedOption: IDropdownOption): () => void {
    const { onSelect } = this.props;
    return () => onSelect(selectedOption);
  }

  private handleKeyDown = (event: KeyboardEvent<HTMLUListElement>): void => {
    switch (event.keyCode) {
      case 40: // down arrow
        this.nextOption();
        event.preventDefault();
        break;
      case 38: // up arrow
        this.prevOption();
        event.preventDefault();
        break;
    }
  }

  private nextOption(): void {
    const currentFocus = document.activeElement;
    const currentOptionRefIndex = this.optionRefs.findIndex(ref => ref.current === currentFocus);
    if (currentOptionRefIndex > -1 && currentOptionRefIndex < this.optionRefs.length - 1) {
      this.optionRefs[currentOptionRefIndex + 1].current.focus();
    }
  }

  private prevOption(): void {
    const currentFocus = document.activeElement;
    const currentOptionRefIndex = this.optionRefs.findIndex(ref => ref.current === currentFocus);
    if (currentOptionRefIndex > 0) {
      this.optionRefs[currentOptionRefIndex - 1].current.focus();
    }
  }
}
