import jQuery from 'jquery';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IContentHopperController, IHopSpot, IHopSpotScope } from './content-hopper.interfaces';

export class ContentHopperController implements IContentHopperController {
  public spots: IHopSpot[];
  public hopText: string;
  public isHopping: boolean;
  public isScrolling: boolean;
  public currentIndex: number;
  public top: number;

  constructor(
    private $filter: ng.IFilterService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.CONTENT_HOPPER);

    this.spots = [];
    this.currentIndex = -1;
    this.hopText = 'Scroll down for more content...';
    this.isHopping = false;
    this.top = this.top || 0;
  }

  public addSpot(scope: IHopSpotScope, element: ng.IAugmentedJQuery, id: number): void {
    const spot = {
      id,
      scope,
      element,
      belowFold: true,
      yPos: 0,
    };

    this.spots.push(spot);
  }

  public removeSpot(id: number): void {
    const index = this.getSpotById(id).index;

    this.spots.splice(index, 1);
    if (index === this.currentIndex) {
      this.setHopText(index);
    }
  }

  public hop(): void {
    const desiredSpot: IHopSpot = this.sortSpots()[this.getNextSpotIndex()];

    if (desiredSpot) {
      this.hopTo(desiredSpot);
    }
  }

  public addVisited(id: number): void {
    const target = this.getSpotById(id);
    const spot = target.spot;
    const index = target.index;

    if (spot.id === id && spot.belowFold) {
      spot.belowFold = false;
      this.currentIndex = index;
      this.setHopText(this.getNextSpotIndex());
    }
  }

  public removeVisited(id: number): void {
    const target = this.getSpotById(id);
    const spot = target.spot;
    const index = target.index;

    if (spot.id === id && !spot.belowFold) {
      spot.belowFold = true;
      this.currentIndex = index - 1;
      this.setHopText(this.getNextSpotIndex());
    }
  }

  public isHidden(): boolean {
    return this.isHopping || this.isScrolling || this.currentIndex === this.spots.length - 1;
  }

  public updateYPos(id: number, y: number): void {
    const spot = this.getSpotById(id).spot;
    spot.yPos = y;
    this.sortSpots();
  }

  private getSpotById(id: number): {spot: IHopSpot, index: number} {
    for (let i = 0; i < this.sortSpots().length; i ++) {
      if (this.spots[i].id === id) {
        return {
          spot: this.spots[i],
          index: i,
        };
      }
    }
  }

  private sortSpots(): IHopSpot[] {
    this.spots = this.$filter('orderBy')(this.spots, 'yPos');
    if (this.currentIndex === -1) {
      this.setHopText(0);
    }
    return this.spots;
  }

  private getNextSpotIndex(): number {
    return this.currentIndex + 1;
  }

  private setHopText(index: number): void {
    const nextSpot = this.spots[index];
    if (nextSpot) {
      this.hopText = nextSpot.scope.text;
    }
  }

  private hopTo(spot: IHopSpot): void {
    const hopper = this;
    hopper.isHopping = true;

    jQuery('html, body').animate({
      scrollTop: jQuery(spot.element).offset().top - hopper.top,
    }, 'slow', (): void => {
      hopper.isHopping = false;
    });
  }
}
