import { ILocale } from 'scripts/util/locale/locale.interfaces';
import { CoverageStatus } from '../api.interfaces';
import { LineOfBusiness, MembershipCategory } from '../profile/profile.interfaces';

export enum TrackingTriggerType {
  Click = 'CLICK',
  PageLoad = 'PAGE_LOAD',
  SectionFailed = 'SECTION_FAILED',
  SectionLoad = 'SECTION_LOAD',
  View = 'VIEW',
}

export enum TrackingClickType {
  ExternalLink = 'EXTERNAL_LINK',
  PageEvent = 'PAGE_EVENT',
  StateChange = 'STATE_CHANGE',
}

export interface ITrackingEventBase {
  featureList: string[];
  actionName: string;
}

export interface ITrackingEventRequest extends ITrackingEventBase {
  uri: string;
  trigger: TrackingTriggerType;
  clickType?: TrackingClickType;
  serviceVersion: string;
  placement: string;
  data?: any;
  campaignTrackingInfo?: ICampaignTrackingInfo;
  // for external link clicks only:
  externalDomain?: string;
  externalUrl?: string;
}

export interface IVideoData {
  videoName: string;
  videoSource?: string;
  videoProgress?: number;
  playStartTriggered?: boolean;
}

export interface IVideoTrackingEvent extends ITrackingEventRequest {
  videoData: IVideoData;
}

export interface ICampaignTrackingInfo {
  campaignId: string;
  placementType: string;
}

export interface ITrackingInfo {
  featureList: string[];
  actionName: string;
  placement: string;
  data?: string;
}

export interface IAASatellite {
  track(event?: string): void;
  pageBottom(): void;
}

export interface IAAEvent {
  uri: string;
  featureList: string[];
  action: string;
  eventType: TrackingTriggerType;
  fullName: string;
}

export interface IAAContent {
  pageName: string;
  siteSectionL1?: string;
  siteSectionL2?: string;
  siteErrorType?: string;
  locale?: ILocale['id'];
  mostRecentEvent?: IAAEvent;
}

export interface IAAPostPageData {
  content: IAAContent;
}

export interface IAADataLayer {
  content: IAAContent;
  rallyId: string;
  loginStatus: string;
  lineOfBusiness: LineOfBusiness;
  membershipCategory?: MembershipCategory;
  groupId?: string;
  externalId?: string;
  dependentSeqNum?: string;
  memberStatus?: CoverageStatus | string;
  zipcode: string;
  clientId: string;
  clientName: string;
  memberPlansInfo: IAAMemberPlan[];
  userFeedback: IAAUserFeedback;
}

export interface IAAMemberPlan {
  aco: string;
  coverageTypeCode: string;
  fundingArrangementCode: string;
  govtProgramTypeCode: string;
  memberId: string;
  policyNumber: string;
  status: string;
}

export interface IAACampaignsData {
  campaigns: IAACampaigns;
}

export interface IAACampaigns {
  id: string;
  placement: string;
  title: string;
  offerCode?: string;
}

export interface IAAUserFeedback {
  clientId: string;
  depSeqNumber: string;
  lob: LineOfBusiness;
  memberId: string; // this is actually externalId from heartbeat
  memberType: MembershipCategory;
  path: string;
  policyNumber: string;
  rallyId: string;
  status: CoverageStatus | string;
}
