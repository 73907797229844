import { Observable } from 'rxjs/Observable';
import { ResourceLink } from 'scripts/util/resource/resource.interfaces';
import { IClaim, IClaimManagementInfo, IGetMatchingClaimParams, IHealthcareClaimDetails } from '../../../api/claims/claims.interfaces';
import { IClaimsService } from '../../../api/claims/claims.service';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IModalController } from '../../../ui/modal/modal.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';

export interface IMarkAsPaidExplanationParams extends IGetMatchingClaimParams {
  explanationFrom: string;
}

export class MarkAsPaidExplanationController implements ng.IComponentController {
  public claimId: string;
  public claim: IClaim | IHealthcareClaimDetails;
  public link: ResourceLink;
  public $modal: IModalController;
  public request: Observable<IClaim | IHealthcareClaimDetails>;

  constructor(
    private $state: ng.ui.IStateService,
    private $stateParams: IMarkAsPaidExplanationParams,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private claimsService: IClaimsService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.CLAIM);

    this.request = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .flatMap(profile => this.claimsService.getMatchingClaim(profile, this.$stateParams));
    const { claimId } = this.$stateParams;
    this.claimId = claimId;
  }

  public $onInit(): void {
    this.request.subscribe(claim => this.claim = claim, console.warn);
  }

  public accept(): void {
    if (this.claim.claimManagementInfo) {
      this.claim.claimManagementInfo.markPaid = !this.claim.claimManagementInfo.markPaid;
    } else {
      this.claim.claimManagementInfo = { markPaid: true } as IClaimManagementInfo;
    }
    this.userService.getHeartbeat()
      .map(rsp => rsp.data.rallyId)
      .flatMap(rallyId => this.profileService.setClaimsPreferences(rallyId, { markAsPaid: { showDisclaimer: false } }))
      .subscribe(() => undefined, console.warn);

    this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .flatMap(currentUser => this.claimsService.togglePaid(currentUser, this.claim))
      .subscribe(
        () => {
          this.$timeout(() => {
            if (this.$stateParams.explanationFrom) {
              if (this.$stateParams.explanationFrom.indexOf('dashboard') > -1) {
                // Using nocache=true here is not ideal - see ARC-5194
                this.$window.location.href = this.$stateParams.explanationFrom + '?nocache=true';
              } else {
                this.$window.location.href = this.$stateParams.explanationFrom;
              }
            } else {
              this.$state.go('authenticated.claimsAndAccounts.summary');
            }
          });
        }, err => {
          console.warn(err);
          this.$modal.close();
        },
      );
  }

  public cancel(): void {
    this.$modal.close();
  }
}
