import angular from 'angular';
import 'rxjs/add/observable/defer';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/share';
import { Observable } from 'rxjs/Observable';
import { NgStore } from 'scripts/store/ng-store';
import { dateStringToMoment, stringToFloat, timeStringToMoment } from 'scripts/util/conversion/conversion';
import { inflight } from 'scripts/util/promise-inflight/promise-inflight';
import { userUris } from 'scripts/util/uri/uri';
import { IAngularMoment } from '../arcade.module.interfaces';
import '../util/constants/environment.constants';
import { IEnvironmentConstants } from '../util/constants/environment.interfaces';
import { LocaleKey } from '../util/constants/i18n.constants';
import { IPopulationService } from '../util/population/population.service';
import { IBadUrl, IBadUrls, IRequestShortcutConfig } from './api.interfaces';
import { ClaimsService } from './claims/claims.service';
import { LedgerService } from './ledger/ledger.service';
import { PlansService } from './plans/plans.service';
import { ProfileService } from './profile/profile.service';
import { TargetingService } from './targeting/targeting.service';
import { AmplitudeService } from './tracking/amplitude.service';
import { TrackingService } from './tracking/tracking.service';

export interface IBaseApiService {
  get(url: string, config?: IRequestShortcutConfig): Observable<any>;
  post(url: string, data?: {}, redirectOnError?: boolean, config?: IRequestShortcutConfig): Observable<any>;
  put(url: string, data: {}, config?: IRequestShortcutConfig): Observable<any>;

  dateStringToMoment(parent: object, ...leaves: string[]): void;
  timeStringToMoment(parent: object, ...leaves: string[]): void;
  stringToFloat(parent: object, ...leaves: string[]): void;
  getLocalizedCacheKey(url: string): string;
}

export interface ILoginParams {
  redirect: string;
  lob: string;
  membershipCategory: string;
  locale?: string;
  loginLocale?: string;
}

export class BaseApiService implements IBaseApiService {

  // This map is meant to track all REST calls that fail by their urls
  private badUrls: IBadUrls = {};
  private retryError = 'UNDERLYING_UNAUTHORIZED_ERROR';
  private requestIdCounter = 0;

  private requestCache: {[key: string]: Observable<any>} = {};

  constructor(
    private $http: ng.IHttpService,
    private $location: ng.ILocationService,
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private Environment: IEnvironmentConstants,
    private moment: IAngularMoment,
    private populationService: IPopulationService,
  ) {
    'ngInject';
  }

  public get(url: string, config?: IRequestShortcutConfig): Observable<any> {
    const singleInstance = this.requestCache[this.getLocalizedCacheKey(url)];

    if (singleInstance) {
      return singleInstance;
    } else {
      const request$ = Observable.defer(() => inflight(url, () => this.request('GET', url, config))).share()
        .retryWhen(e => {
          return e.flatMap(err => {
            if (err.status === 401 && err.data !== undefined && err.data.code === this.retryError) {
              return this.refreshAuth();
            } else {
              return Observable.throw(err);
            }
          });
        })
        .catch(err => {
          if (err.status === 401 && !this.badUrls[url]) {
            // If the user is unauthenticated and we haven't already seen the same REST call fail, redirect them.
            this.$timeout(() => {
              if (!this.$state.is('login')) {
                const pop = this.populationService.getPopulation() || {} as any;
                const loginLocale = this.$location.search().loginLocale;
                const locale = this.$location.search().locale;

                const params: ILoginParams = {
                  redirect: this.$location.absUrl(),
                  lob: pop.lineOfBusiness,
                  membershipCategory: pop.membershipCategory,
                };

                if (loginLocale) {
                  params.loginLocale = loginLocale;
                }

                if (locale) {
                  params.locale = locale;
                }

                this.$state.go('login', params);
              }
            });
          } else if (err.status === 500 && !this.badUrls[url]) {
            // If the user receives a 500 error the first time for a REST call, log it in their browser.
            console.warn('Failed to retrieve data. Details below:\n' +
              '\tmessage: ' + (err.data.code || 'NONE') + '\n' +
              '\tstatus: ' + err.status + '\n' +
              '\turl: ' + url + '\n' +
              '\tid: ' + (err.data.correlationId || 'NONE'));
          } else if (err.status === 403 && !this.badUrls[url]) {
            // 403 is usually only returned when a user tried to do something they don't have permission to (like
            // trying to SSO to engage as a super user), so we redirect them to a special error page.
            this.$timeout(() => {
              const data = err.data !== undefined ? {
                errorUID: err.data.correlationId,
                errorReason: err.data.code,
              } : undefined;
              this.$state.go('unauthenticated.unauthorizedError', data);
            });
          }
          const requestId = (err.config && err.config.$$id) ? err.config.$$id : -1;
          err.data = err.data || {};
          err.data.errorCount = this.updateBadUrls(url, requestId);
          return Observable.throw(err);
        });

      this.requestCache[this.getLocalizedCacheKey(url)] = request$;
      return request$.map(rsp => {
        rsp.arcadeDataUpdated = rsp.headers('arcade-data-updated');
        return rsp;
      });
    }
  }

  public post(url: string, data?: {}, redirectOnError: boolean = true, config?: IRequestShortcutConfig): Observable<any> {
    const configWithData = angular.merge({ data }, config);
    return Observable.defer(() => this.request('POST', url, configWithData)
      .catch(err => {
        if (redirectOnError && err.status === 403) {
          this.$timeout(() => {
            const params = err.data !== undefined ? {
              errorUID: err.data.correlationId,
              errorReason: err.data.code,
            } : undefined;
            this.$state.go('unauthenticated.unauthorizedError', params);
          });
        } else if (redirectOnError && err.status === 401) {
          this.$timeout(() => {
            if (!this.$state.is('login')) {
              const pop = this.populationService.getPopulation() || {} as any;
              this.$state.go('login', {
                redirect: this.$location.absUrl(),
                lob: pop.lineOfBusiness,
                membershipCategory: pop.membershipCategory,
              });
            }
          });
        }
        throw err;
      }));
  }

  public put(url: string, data: {}, config?: IRequestShortcutConfig): Observable<any> {
    // const Observable = this.Observable;
    const configWithData = angular.merge({ data }, config);
    return Observable.defer(() => this.request('PUT', url, configWithData));
  }

  public timeStringToMoment(parent: object, ...leaves: string[]): void {
    timeStringToMoment(parent, ...leaves);
  }

  public dateStringToMoment(parent: object, ...leaves: string[]): void {
    dateStringToMoment(parent, ...leaves);
  }

  public stringToFloat(parent: object, ...leaves: string[]): void {
    stringToFloat(parent, ...leaves);
  }

  public getLocalizedCacheKey(url: string): string {
    return url + '__' + localStorage.getItem(LocaleKey);
  }

  /**
   * Common request method that returns an $http promise.
   * @param method - GET, POST, and PUT are currently supported.
   * @param url - Domain unnecessary. url should be constructed via uri module
   * @param config - $http request config object.
   * @returns {IHttpPromise<any>}
   */
  private request(method: string, url: string, config?: IRequestShortcutConfig): angular.IHttpPromise<any> {
    this.requestIdCounter++;
    // Creates a deep copy and merges defaults with defined config options, passed into request().
    const configWithDefaults = angular.merge({ method, url, $$id: this.requestIdCounter }, config);
    return this.$http(configWithDefaults);
  }

  private refreshAuth(): Observable<any> {
    const url = userUris.idpRefresh();
    return this.get(url);
  }

  private updateBadUrls(url: string, id: number): number {
    const badUrl = this.badUrls[url] = this.badUrls[url] || {lastId: id, errorCount: 1} as IBadUrl;
    if (badUrl.lastId !== id) {
      badUrl.lastId = id;
      badUrl.errorCount++;
    }
    return badUrl.errorCount;
  }
}

angular
  .module('arcade.api', [
    'angular-google-analytics',
    'angularMoment',
    'arcade.environment',
    'pascalprecht.translate',
    'ui.router',
    'ngRedux',
  ])
  .config(NgStore)
  .service('baseApiService', BaseApiService)
  .service('profileService', ProfileService)
  .service('ledgerService', LedgerService)
  .service('claimsService', ClaimsService)
  .service('plansService', PlansService)
  .service('targetingService', TargetingService)
  .service('amplitudeService', AmplitudeService)
  .service('trackingService', TrackingService);
