import { Observable } from 'rxjs/Observable';
import CONFIG from 'scripts/util/constants/config';
import { contactUs, idCardsByMail } from 'scripts/util/resource/resource.constants';
import { IResource, ResourceLink } from 'scripts/util/resource/resource.interfaces';
import {
  CoverageStatus,
  CoverageType,
  ICoverageTimePeriod,
  IDateFilter,
  RelationshipType,
} from '../../../api/api.interfaces';
import { IBenefit } from '../../../api/plans/plans.interfaces';
import { IPlansService } from '../../../api/plans/plans.service';
import {
  FundingType,
  IPlanCoverage,
  IPrimaryCarePhysician,
  IProducts,
  IProfile,
  IProfileUser,
  LineOfBusiness,
  MembershipCategory,
  ProgramType,
  State,
} from '../../../api/profile/profile.interfaces';
import { IProfileService, ProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';
import { ICoverageSection, IIdCardsStateParams, IPlanTypes } from './id-cards.interfaces';

export const CoverageStatusEnded: string = 'COVERAGE_STATUS_ENDED';
export const CoverageStatusNotStarted: string = 'COVERAGE_STATUS_NOT_STARTED';
export const CoverageStatusActive: string = 'COVERAGE_STATUS_ACTIVE';

export class IdCardsController {
  public request: Observable<{profile: IProfile, benefits: IBenefit[], products: IProducts}>;
  public users: IProfileUser[];
  public currentUser: IProfileUser;
  public coverages: ICoverageSection[];
  public today: Date;
  public providerMap: {};
  public isGatedPlan: boolean;
  public isCS: boolean;
  public isCSNewJersey: boolean;
  public showReportingCodeAsGroupId: boolean;
  public isEmpire: boolean;
  public activePcpPerMember: {[depSeqNum: string]: string};
  public csMedicalSupportPhoneNum: string;
  public otherCoverageTypes: CoverageType[];
  public ssoTrackingLink: string;
  public mrHelp: IResource;
  private reportingCodePlanIds: string[] = ['FLDSNP', 'OHDSNP'];

  constructor(
    private $filter: IDateFilter,
    private $scope: ng.IScope,
    private $stateParams: IIdCardsStateParams,
    private $translate: ng.translate.ITranslateService,
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    public profileService: IProfileService,
    private plansService: IPlansService,
    public resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_INFO);
    $translatePartialLoader.addPart(Dictionary.ID_CARDS);

    this.today = new Date();
    this.providerMap = profileService.getRxProviderMap();
    this.mrHelp = contactUs;
    this.ssoTrackingLink = CONFIG.ARCADE_WEB_RALLY_AUTH_URL + '/sso/v1/vendor/MYUHCIDCARD/completed';
  }

  public $onInit(): void {
    this.request = this.userService.getHeartbeat().let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .flatMap(({currentUser}) => {
        return Observable.if(() => currentUser.lineOfBusiness === LineOfBusiness.MR,
          Observable.of(undefined),
          this.plansService.getBenefits(currentUser.rallyId, currentUser.dependentSeqNum).map(rsp => rsp.data.benefits)
            .catch(() => Observable.of([])),
        );
    }, (profile, benefits) => ({profile, benefits}))
      .flatMap(({profile}) => {
        return Observable.if(() => profile.currentUser.lineOfBusiness === LineOfBusiness.CS,
          this.profileService.getProducts(profile.rallyId).map(rsp => rsp.data.products),
          Observable.of(undefined as IProducts));
      }, ({profile, benefits}, products) => ({profile, benefits, products}));

    this.request.subscribe(({profile, benefits, products}) => {
      const dependents: IProfileUser[] = profile.dependents || [];
      let coverages: IPlanCoverage[] = [];
      const coverageTypes: {[type: string]: boolean} = {};
      this.coverages = [];
      this.users = [profile.currentUser].concat(dependents);
      this.currentUser = profile.currentUser;
      this.isCS = this.currentUser.lineOfBusiness === LineOfBusiness.CS;
      this.isCSNewJersey = this.currentUser.userInfo.state === State.NJ && this.isCS;
      this.showReportingCodeAsGroupId = this.reportingCodePlanIds.indexOf(this.currentUser.userInfo.primaryCustomerId) !== -1 && this.isCS;
      this.isEmpire = this.currentUser.membershipCategory === MembershipCategory.EMPIRE;
      this.isGatedPlan = profile.currentUser.memberFeatures.pcpEligible;
      if (this.isGatedPlan) {
        this.getPrimaryCare(this.currentUser.rallyId);
      }
      // Retrieve a full list of plan coverages from all users
      for (const user of this.users) {
        coverages = coverages.concat(user.planCoverages);
      }

      if (this.currentUser.lineOfBusiness === LineOfBusiness.EI) {
        // We want a list of all possible coverages among all users, so here we remove duplicates by coverageType
        for (let i = 0; i < coverages.length; i++) {
          const coverageType = coverages[i].coverageType;

          if (coverageTypes[coverageType]) {
            coverages.splice(i, 1);
            i--;
          } else {
            coverageTypes[coverageType] = true;
          }
        }
      }

      if (this.isCS) {
        const { medicalSupport } = products;
        if (medicalSupport && medicalSupport.contactInformation && medicalSupport.contactInformation.phoneNum) {
          this.csMedicalSupportPhoneNum = medicalSupport.contactInformation.phoneNum;
        }

        const [medicalCoverage, nonMedicalCoverage] = coverages.reduce((result, cov) => {
          result[cov.coverageType === CoverageType.Medical ? 0 : 1].push(cov);
          return result;
        }, [[], []]);

        if (medicalCoverage.length > 0) {
          // filter out coverage types that are already in the medical coverage's additionalCoverageTypes
          const uniqueNonMedicalCoverageTypes = nonMedicalCoverage
            .filter(cov => medicalCoverage[0].additionalCoverageTypes.indexOf(cov.coverageType) === -1)
            .map(cov => cov.coverageType);
          // combine other coverages with additional coverages
          this.otherCoverageTypes = medicalCoverage[0].additionalCoverageTypes.concat(uniqueNonMedicalCoverageTypes);
          // filter out behavioral health coverage because, for C&S only, we get this from the plan summary response
          this.otherCoverageTypes = this.otherCoverageTypes.filter(cov => cov !== CoverageType.BehavioralHealth);
          // add behavioral health coverage if applicable
          if (!!(products && products.liveAndWorkWell)) {
            this.otherCoverageTypes.push(CoverageType.BehavioralHealth);
          }
          this.otherCoverageTypes.sort();
        }
        coverages = medicalCoverage;
      }

      // Get id card for each coverage and set coverages array
      for (let j = 0; j < coverages.length; j++) {
        const coverage = coverages[j] as ICoverageSection;
        this.coverages[j] = coverage;
        this.coverages[j].planType = IPlanTypes[this.coverages[j].coverageType];
        this.coverages[j].template = ProfileService.isRxCarveOut(coverage) ?
          'rx-carve-out-coverage' : 'typical-coverage';

        if (benefits) {
          // Use medical plan name for vision as well
          const planNameType = (this.coverages[j].coverageType === CoverageType.Vision)
            ? CoverageType.Medical : this.coverages[j].coverageType;
          benefits.filter(benefit => benefit.coverageType === planNameType)
            .forEach(benefit => this.coverages[j].planName = benefit.planName);
        } else {
          // for M&R we need to make a request per coverage in order to get the planNames
          this.plansService.getBenefits(this.currentUser.rallyId, this.currentUser.dependentSeqNum, coverage.coverageTypeCode)
            .map(rsp => rsp.data.benefits[0].planName)
            .catch(() => Observable.of(''))
            .subscribe(planName => coverage.planName = planName);
        }

        if (coverage.planFeatures.hasIdCard) {
          if (coverage.planFeatures.isIndividualIdCard) {
            this.selectFirstUserWithCoverage(this.coverages[j]);
            this.$scope.$watch(() => coverage.$carousel && coverage.$carousel.active, index => {
              if (typeof index !== 'undefined') {
                const selectedUser = this.getUsersWithCoverage(coverage)[index];
                coverage.selectedDepSeqNum = selectedUser.dependentSeqNum;
                if (!this.hasTermedCoverage(coverage, selectedUser)) {
                  this.requestIdForCoverage(coverage);
                }
              }
            });
          }
          this.requestIdForCoverage(this.coverages[j]);
        }
        coverage.showGroupNumber = !!this.getGroupNumber(coverage);

        if (this.currentUser.lineOfBusiness === LineOfBusiness.MR) {
          coverage.showGroupNumber =  coverage.showGroupNumber && coverage.planFeatures.fundingArrangementType !== FundingType.Individual;
        }
      }
      this.setShowCarveOutLink();
    }, console.warn);
  }

  public getCoverageInfo(coverage: IPlanCoverage, user: IProfileUser): ICoverageTimePeriod {
    if (user.planCoverages && user.planCoverages.length > 0) {
      for (const plan of user.planCoverages) {
        if (plan.coverageType === coverage.coverageType && plan.coverageTypeCode === coverage.coverageTypeCode && plan.planPeriod) {
          return plan.planPeriod;
        }
      }
    }
  }

  public hasActiveUser(coverage: IPlanCoverage): boolean {
    return this.users.map(user => this.getCoverageInfo(coverage, user))
      .some(coverageStatus => coverageStatus.status === CoverageStatus.Active);
  }

  public hasActiveOrFutureUser(coverage: IPlanCoverage): boolean {
    return this.users.map(user => this.getCoverageInfo(coverage, user))
      .some(coverageStatus => coverageStatus.status !== CoverageStatus.Termed);
  }

  public hasTermedUser(coverage: IPlanCoverage): boolean {
    return this.users.map(user => this.getCoverageInfo(coverage, user))
      .some(coverageStatus => coverageStatus.status === CoverageStatus.Termed);
  }

  public hasActiveCoverage(coverage: IPlanCoverage, user: IProfileUser): boolean {
    const coverageStatus = this.getCoverageInfo(coverage, user);
    return coverageStatus && coverageStatus.status === CoverageStatus.Active;
  }

  public hasTermedCoverage(coverage: IPlanCoverage, user: IProfileUser): boolean {
    const coverageStatus = this.getCoverageInfo(coverage, user);
    return coverageStatus && coverageStatus.status === CoverageStatus.Termed;
  }

  public hasCoverage(coverage: IPlanCoverage, user: IProfileUser): boolean {
    return (typeof this.getCoverageInfo(coverage, user) !== 'undefined');
  }

  public getCoverageStatusIcon(coverage: IPlanCoverage): string {
    const activeOrFuture = this.hasActiveOrFutureUser(coverage);

    if (activeOrFuture) {
      return '/images/icons/icon-circle-check.svg';
    } else {
      return '/images/icons/icon-circle-minus.svg';
    }
  }

  public getCoverageStatusAltText(coverage: IPlanCoverage): string {
    return this.hasActiveOrFutureUser(coverage) ? 'PLAN_ACTIVE_ALT_TEXT' : 'PLAN_INACTIVE_ALT_TEXT';
  }

  public getCoverageStatus(coverage: IPlanCoverage, user: IProfileUser): string {
    switch (this.getCoverageInfo(coverage, user).status) {
      case CoverageStatus.Active: return CoverageStatusActive;
      case CoverageStatus.Future: return CoverageStatusNotStarted;
      case CoverageStatus.Termed: return CoverageStatusEnded;
    }
  }

  public getLabelFromCoverage(coverage: IPlanCoverage): string {
    if (coverage.planFeatures.isSupplemental) {
      return coverage.planFeatures.programType === ProgramType.Ship ? 'HEALTH_INSURANCE_CARD' : 'SUPPLEMENTAL_MEDICAL';
    } else {
      return this.getLabelFromCoverageType(coverage.coverageType);
    }
  }

  public getLabelFromCoverageType(coverageType: CoverageType): string {
    return coverageType === CoverageType.Rx ? 'PRESCRIPTION_DRUG' : coverageType;
  }

  public getFormattedCoverageEndDate(coverage: IPlanCoverage, user: IProfileUser): string {
    const coverageStatus = this.getCoverageInfo(coverage, user);
    return this.$filter('amDateFormat')(coverageStatus.endDate, 'MM/DD/YY');
  }

  public getUsersWithCoverage(coverage: ICoverageSection): IProfileUser[] {
    return this.users.filter(user => this.hasCoverage(coverage, user));
  }

  public selectUser(user: IProfileUser, coverage: ICoverageSection): void {
    if (coverage.selectedDepSeqNum !== user.dependentSeqNum) {
      coverage.selectedDepSeqNum = user.dependentSeqNum;
      this.requestIdForCoverage(coverage);
    }
  }

  public selectUserWithKeyboard(user: IProfileUser, coverage: ICoverageSection, event: KeyboardEvent): void {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this.selectUser(user, coverage);
    }
  }

  public getMailLink(user: IProfileUser, coverage: IPlanCoverage): string {
    const mailLink = this.resourceService.get(idCardsByMail);
    return user.lineOfBusiness === LineOfBusiness.EI ?
      `${mailLink}?cardType=${coverage.coverageType}&depSqrNbr=${user.dependentSeqNum}` : mailLink;
  }

  public getRelationshipType(user: IProfileUser, coverage: IPlanCoverage): string | IProfileUser['relationshipType'] {
    if (coverage.planFeatures.programType === ProgramType.Ship) {
      return 'INSURED_MEMBER';
    } else {
      return this.profileService.getRelationshipTypeText(user);
    }
  }

  public hasGeneratedIdCards(coverage: ICoverageSection): boolean {
    return coverage.cards.every(c => c.generatedImage);
  }

  public getGroupNumber(coverage: ICoverageSection): string | undefined {
    if (this.showReportingCodeAsGroupId) {
      return this.currentUser.pvrc ? this.currentUser.pvrc.reportingCode : undefined;
    } else {
      return coverage.policyNumber;
    }
  }

  private requestIdForCoverage(coverage: ICoverageSection): void {
    coverage.idCardsRequest = this.userService.getHeartbeat().let(this.profileService.toProfile())
    .map(rsp => rsp.data).flatMap(profile => {
      return coverage.planFeatures.isIndividualIdCard ?
        this.plansService.getIdCards(profile.currentUser, coverage, coverage.selectedDepSeqNum) :
        this.plansService.getIdCards(profile.currentUser, coverage);
    })
    .do(rsp => {
      coverage.cards = rsp.data;
      coverage.flipped = !!this.$stateParams.flipped;
    });
    coverage.idCardsRequest.subscribe(() => undefined, console.warn);
  }

  private getPrimaryCare(rallyId: string): void {
    this.profileService.getPrimaryCare(rallyId)
      .map(pcpInfo => pcpInfo.data.perMemberResults)
      .subscribe(perMemberPcpInfo => {
        const activePcpMap = {};
        for (const depSeqNum in perMemberPcpInfo) {
          if (perMemberPcpInfo.hasOwnProperty(depSeqNum)) {
            activePcpMap[depSeqNum] = this.getActivePcp(perMemberPcpInfo[depSeqNum].primaryCarePhysicians);
          }
        }
        this.activePcpPerMember = activePcpMap;
      }, console.warn);
  }

  private getActivePcp(pcpList: IPrimaryCarePhysician[]): string {
    for (const pcp of pcpList) {
      if (pcp.status === CoverageStatus.Active) {
        return this.profileService.getFullName(pcp);
      }
    }
  }

  private selectFirstUserWithCoverage(coverage: ICoverageSection): void {
    for (const user of this.getUsersWithCoverage(coverage)) {
      if (!this.hasTermedCoverage(coverage, user)) {
        coverage.selectedDepSeqNum = user.dependentSeqNum;
        break;
      }
    }
  }

  private setShowCarveOutLink(): void {
    let eiRxCarveOut;
    for (const coverage of this.coverages) {
      if (ProfileService.isRxCarveOut(coverage)) {
        if (this.currentUser.lineOfBusiness === LineOfBusiness.EI) {
          eiRxCarveOut = coverage;
        } else {
          coverage.showCarveOutLink = true;
        }
        break;
      }
    }

    // if LoB is E&I and carve out RX exists, check the products response to determine whether to show the link
    if (eiRxCarveOut) {
      this.userService.getHeartbeat().flatMap(({data}) => this.profileService.getProducts(data.rallyId))
        .subscribe(productsRsp => {
            eiRxCarveOut.showCarveOutLink = !!productsRsp.data.products.rx;
        }, err => {
          eiRxCarveOut.showCarveOutLink = false;
          console.warn(err);
        });
    }
  }
}
