import { ClaimsServiceActionTypes } from 'scripts/actions/claims-service-actions';
import { ProfileServiceActionTypes } from 'scripts/actions/profile-service-actions';
import { IClaim } from 'scripts/api/claims/claims.interfaces';
import { ArcadeAction, IStateData } from './reducer.interfaces';

export interface IClaimsState {
  summary: IStateData<IClaim[]>;
}

export const initialState: IClaimsState = {
  summary: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

export default (state: IClaimsState = initialState, action: ArcadeAction): IClaimsState => {
  switch (action.type) {
    case ClaimsServiceActionTypes.GET_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: {
          ...state.summary,
          data: action.payload,
          error: false,
          loading: false,
        },
      };
    case ClaimsServiceActionTypes.GET_SUMMARY_LOADING:
      return {
        ...state,
        summary: {
          ...state.summary,
          loading: true,
        },
      };
    case ClaimsServiceActionTypes.GET_SUMMARY_ERROR:
      return {
        ...state,
        summary: {
          data: undefined,
          error: true,
          loading: false,
        },
      };
    case ProfileServiceActionTypes.CHANGE_SELECTED_PROFILE:
      return {
        ...state,
        summary: {
          ...state.summary,
          data: undefined,
        },
      };
    // The following is a placeholder case. Once All Claims are stored in Redux state, this case should
    // be re-written to find the claim with the matching claimKey and update its markAsPaid boolean
    // (either by doing so directly or prompting another getAllClaims call)
    case ClaimsServiceActionTypes.SAVE_CLAIM_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
};
