import moment from 'moment';
import { Subscription } from 'rxjs';
import { CoverageStatus, LinkTarget } from 'scripts/api/api.interfaces';
import { LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { IProfileService, ProfileService } from 'scripts/api/profile/profile.service';
import { IUserService } from 'scripts/api/user/user.service';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { FeatureFlagService } from 'scripts/util/feature-flag/feature-flag.service';
import { openSurvey } from 'scripts/util/iperceptions/iperceptions';
import { ILocaleService } from 'scripts/util/locale/locale.service';
import { IPopulationService } from 'scripts/util/population/population.service';
import {
  accessibility,
  accountProfile,
  accountSettings,
  connectHealthChecklist,
  connectSaved,
  contactUs,
  facebook,
  healthRecord,
  legalEntities,
  legalNotices,
  messages,
  nonDiscriminationChinese,
  nonDiscriminationEnglish,
  nonDiscriminationSpanish,
  preLoginCompanyInfo,
  preLoginExternalUri,
  preLoginLegalLayout,
  preLoginPrivacyLayout,
  providerDataInfo,
} from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { IResourceService } from 'scripts/util/resource/resource.service';
import footerTemplate from 'views/chrome/footer.html';
import { IFooterItem, IFooterSection } from './footer.interfaces';

export class FooterComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;
  public bindings: {[binding: string]: string};

  constructor() {
    this.controller = FooterController;
    this.templateUrl = footerTemplate;
    this.bindings = {
      authenticated: '<',
    };
  }
}

export class FooterController implements ng.IComponentController {
  public sections: IFooterSection[];
  public authenticated: boolean;
  public lastUpdatedDate: moment.Moment;
  public isMR: boolean;
  public preLoginCompanyInfo: IResource;
  public preLoginExternalUri: IResource;
  public preLoginPrivacyLayout: IResource;
  public preLoginLegalLayout: IResource;
  public nonDiscriminationEnglish: IResource;
  public nonDiscriminationSpanish: IResource;
  public nonDiscriminationChinese: IResource;
  private isCS: boolean;
  private mrSections: IFooterSection[];
  private csSections: IFooterSection[];
  private localeSubscription: Subscription;

  constructor(
    private $state: ng.ui.IStateService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private featureFlagService: FeatureFlagService,
    private localeService: ILocaleService,
    private populationService: IPopulationService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.FOOTER);

    this.preLoginCompanyInfo = preLoginCompanyInfo;
    this.preLoginExternalUri = preLoginExternalUri;
    this.preLoginPrivacyLayout = preLoginPrivacyLayout;
    this.preLoginLegalLayout = preLoginLegalLayout;
    this.nonDiscriminationEnglish = nonDiscriminationEnglish;
    this.nonDiscriminationSpanish = nonDiscriminationSpanish;
    this.nonDiscriminationChinese = nonDiscriminationChinese;

    const { baseUrls } = this.populationService.getPopulation();
    this.authenticated = (this.authenticated === undefined) ? true : this.authenticated;
    this.sections = [{
      header: 'FTR_MEMBER_SUPPORT',
      content: [[{
        text: 'FTR_HELP',
        type: 'link',
        href: this.$state.href('authenticated.help', {referrer: $state.current.name}),
      }, {
        text: 'FTR_NURSE_LINE',
        type: 'text',
      }, {
        text: 'FTR_SHARE_FEEDBACK',
        type: 'button',
        action: () => {
          this.openIperceptionsSurvey();
        },
      }, {
        text: 'FTR_ACCESSIBILITY',
        type: 'link',
        href: this.resourceService.get(accessibility),
        target: LinkTarget.Blank,
      }]],
    }, {
      header: 'FTR_MEMBER_INFO',
      content: [[{
        text: 'FTR_PROVIDER_DATA_INFO',
        type: 'link',
        getLink: () => this.resourceService.get(providerDataInfo),
        target: LinkTarget.Blank,
      }, {
        text: 'FTR_LEGAL_ENTITIES',
        type: 'link',
        getLink: () => this.resourceService.get(legalEntities),
        target: LinkTarget.Blank,
      }]],
    }, {
      header: 'FTR_QUICK_LINKS',
      content: [[{
        text: 'ACCOUNT_SETTINGS',
        type: 'link',
        getLink: () => this.resourceService.get(accountSettings),
        desktopOnly: true,
      }, {
        text: 'HEALTH_RECORD',
        type: 'link',
        getLink: () => this.$state.href('internalRedirect', {
          deepLink: this.resourceService.get(healthRecord),
        }),
        action: e => {
          e.preventDefault();
          const url = this.resourceService.get(healthRecord);
          this.userService.internalSSORedirect(url);
        },
        desktopOnly: true,
      }, {
        text: this.featureFlagService.isEiRecommendationsOn() ? 'RECOMMENDATIONS' : 'HEALTH_CHECKLIST',
        type: 'link',
        getLink: () => this.$state.href('internalRedirect', {
          deepLink: this.resourceService.get(connectHealthChecklist),
        }),
        action: e => {
          e.preventDefault();
          const url = this.resourceService.get(connectHealthChecklist);
          this.userService.internalSSORedirect(url);
        },
        desktopOnly: true,
      }, {
        text: 'MESSAGES',
        type: 'link',
        getLink: () => this.resourceService.get(messages),
        target: LinkTarget.Self,
        desktopOnly: true,
      }], [{
        text: 'SAVED',
        type: 'link',
        getLink: () => this.$state.href('internalRedirect', {
          deepLink: this.resourceService.get(connectSaved),
        }),
        action: e => {
          e.preventDefault();
          const url = this.resourceService.get(connectSaved);
          this.userService.internalSSORedirect(url);
        },
        desktopOnly: true,
      }, {
        text: 'REWARDS',
        type: 'link',
        getLink: () => this.$state.href('internalRedirect', {
          deepLink: baseUrls.rewardsUrl,
        }),
        target: LinkTarget.Blank,
        desktopOnly: true,
      }, {
        text: 'LOGOUT',
        type: 'link',
        href: this.$state.href('logout'),
        desktopOnly: true,
      }]],
    }];

    this.mrSections = [{
      header: 'FTR_MEMBER_SUPPORT',
      content: [[{
        text: 'FTR_HELP',
        type: 'link',
        href: this.resourceService.get(contactUs),
      }, {
        text: 'FTR_LEGAL_NOTICES',
        type: 'link',
        href: this.resourceService.get(legalNotices),
      }, {
        text: 'FTR_ACCESSIBILITY',
        type: 'link',
        href: this.resourceService.get(accessibility),
        target: LinkTarget.Blank,
      }]],
    }, {
      header: 'FTR_QUICK_LINKS',
      content: [[{
        text: 'ACCOUNT_SETTINGS',
        type: 'link',
        href: this.resourceService.get(accountProfile),
        desktopOnly: true,
      }, {
        text: 'MESSAGES',
        type: 'link',
        href: this.resourceService.get(contactUs),
        target: LinkTarget.Blank,
        desktopOnly: true,
      }, {
        text: 'SAVED',
        type: 'link',
        href: this.$state.href('internalRedirect', {
          deepLink: this.resourceService.get(connectSaved),
        }),
        action: e => {
          e.preventDefault();
          const url = this.resourceService.get(connectSaved);
          this.userService.internalSSORedirect(url);
        },
        desktopOnly: true,
      }, {
        text: 'LOGOUT',
        type: 'link',
        href: this.$state.href('logout'),
        desktopOnly: true,
      }]],
    }];

    this.csSections = [{
      header: 'FTR_IMPORTANT_INFO',
      content: [[{
        text: 'FTR_PROVIDER_DATA_INFO',
        type: 'link',
        getLink: () => this.resourceService.get(providerDataInfo),
        target: LinkTarget.Blank,
      }, {
        text: 'FTR_NOTICES',
        type: 'link',
        href: this.resourceService.get(legalNotices),
      }, {
        text: 'FTR_LEGAL_ENTITIES',
        type: 'link',
        getLink: () => this.resourceService.get(legalEntities),
        target: LinkTarget.Blank,
      }]],
    }, {
      header: 'FTR_SUPPORT',
      content: [[{
        text: 'FTR_HELP',
        type: 'link',
        href: this.$state.href('authenticated.help', {referrer: $state.current.name}),
      }, {
        text: 'FTR_SHARE_FEEDBACK',
        type: 'button',
        action: () => {
          this.openIperceptionsSurvey();
        },
      }]],
    }, {
      header: 'FTR_ACCESSIBILITY',
      content: [[{
        text: 'FTR_ACCESSIBILITY_STATEMENT',
        type: 'link',
        href: this.resourceService.get(accessibility),
        target: LinkTarget.Blank,
      }, {
        text: 'FTR_LANGUAGE_ENG',
        type: 'link',
        href: this.resourceService.get(nonDiscriminationEnglish),
        target: LinkTarget.Blank,
      }, {
        text: 'FTR_LANGUAGE_SPA',
        type: 'link',
        href: this.resourceService.get(nonDiscriminationSpanish),
        target: LinkTarget.Blank,
      }, {
        text: 'FTR_LANGUAGE_CHI',
        type: 'link',
        href: this.resourceService.get(nonDiscriminationChinese),
        target: LinkTarget.Blank,
        }]],
    }, {
      header: 'FTR_FOLLOW_US',
      content: [[{
        text: 'FTR_FACEBOOK',
        type: 'link',
        href: this.resourceService.get(facebook),
        target: LinkTarget.Blank,
    }]],
  }];
  }

  public $onInit(): void {
    if (this.authenticated) {
      const heartbeat = this.userService.getHeartbeat();
      const currentUser$ = heartbeat.let(this.profileService.toProfile()).map(rsp => rsp.data.currentUser);
      const products$ = currentUser$
        .takeWhile(currentUser => currentUser.lineOfBusiness !== LineOfBusiness.CS)
        .flatMap(currentUser => this.profileService.getProducts(currentUser.rallyId))
        .map(rsp => rsp.data.products);

      // If IHR doesn't exist, or if the products request fails, remove the link
      products$.map(products => products.individualHealthRecord.productId)
        .subscribe(() => undefined, () => {
          if (this.sections[2]) {
            this.removeLink(this.sections[2].content[0], 'HEALTH_RECORD');
          }
        });

      // Set the contact values for Nurse Chat/Line if it exists, otherwise remove the link
      products$.map(products => products.nurseChat || products.nurseLine)
        .map(nurse => nurse.contactInformation)
        .do(({url, phoneNum}) => {
          this.sections[0].content[0][1].config = { phone: phoneNum, url };
          if (url) {
            this.sections[0].content[0][1].text = phoneNum ? 'FTR_NURSE_CHAT_WITH_PHONE' : 'FTR_NURSE_CHAT';
          }
        })
        .subscribe(() => undefined, () => {
          this.removeLink(this.sections[0].content[0], 'FTR_NURSE_LINE');
        });

      // If Secure Message doesn't exist, or if the products request fails, remove the link
      products$.map(products => products.secureMessage.productId)
        .subscribe(() => undefined, () => {
          if (this.sections[2]) {
            this.removeLink(this.sections[2].content[0], 'MESSAGES');
          }
          this.removeLink(this.mrSections[1].content[0], 'MESSAGES');
        });

      // If Engage doesn't exist, or if the products request fails, remove the link
      products$.map(products => products.rallyEngage.productId)
        .subscribe(() => undefined, () => {
          if (this.sections[2]) {
            this.removeLink(this.sections[2].content[1], 'REWARDS');
          }
        });

      // If Connect doesn't exist, or if the products request fails, hide these two links
      products$.map(products => products.rallyConnect.productId)
        .subscribe(() => undefined, () => {
          if (this.sections[2]) {
            const healthChecklistLabel = this.featureFlagService.isEiRecommendationsOn() ? 'RECOMMENDATIONS' : 'HEALTH_CHECKLIST';
            this.removeLink(this.sections[2].content[0], healthChecklistLabel);
          }
          if (this.sections[2]) {
            this.removeLink(this.sections[2].content[1], 'SAVED');
          }
          this.removeLink(this.mrSections[1].content[0], 'SAVED');
        });

      currentUser$.filter(currentUser => currentUser.lineOfBusiness === LineOfBusiness.MR)
        .subscribe(currentUser => {
          this.isMR = true;
          const coverageInfo = ProfileService.getCoverageInfo(currentUser.planCoverages, [CoverageStatus.Active, CoverageStatus.Future]);
          const planIsMaOrMapd: boolean = ProfileService.isPlanMaOrMapd(currentUser);
          if (coverageInfo.numCoverages <= 0 || !planIsMaOrMapd) {
            this.removeLink(this.mrSections[1].content[0], 'SAVED');
          }
          this.sections = this.mrSections;
        }, console.warn, () => this.isMR = !!this.isMR);
      currentUser$.filter(currentUser => currentUser.lineOfBusiness === LineOfBusiness.CS)
        .subscribe(currentUser => {
          this.isCS = true;
          this.sections = this.csSections;
        }, console.warn, () => this.isCS = !!this.isCS);
    }

    this.setLinks();

    this.localeSubscription = this.localeService.localeChanged.subscribe(() => {
      this.setLinks();
    });

    // Hard-coded to '01/01/2018 12:01 AM CT'. Will update to use build time as part of ARC-3464
    this.lastUpdatedDate = moment.parseZone('2019-01-01T00:01:00-06:00');
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public getCurrentYear(): number {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  public openIperceptionsSurvey(): void {
    openSurvey();
  }

  private removeLink(items: IFooterItem[], linkText: string): void {
    for (let i = 0; i < items.length; i++) {
      if (items[i].text === linkText) {
        items.splice(i, 1);
        break;
      }
    }
  }

  private setLinks(): void {
    for (const section of this.sections) {
      for (const content of section.content) {
        for (const item of content) {
          if (item.getLink) {
            item.href = item.getLink();
          }
        }
      }
    }
  }
}
