import { Cache, CacheFactory, CacheOptions } from 'cachefactory';
import CONFIG from 'scripts/util/constants/config';
import { getLocale } from 'scripts/util/locale/locale';
import { CacheName } from './api.interfaces';

export const cacheFactory = new CacheFactory();

const cacheOptions: CacheOptions = {
  cacheFlushInterval: CONFIG.ARCADE_WEB_CACHE_TTL_MS * 4,
  maxAge: CONFIG.ARCADE_WEB_CACHE_TTL_MS,
  deleteOnExpire: 'aggressive',
  storageMode: 'sessionStorage',
  storagePrefix: 'arcade.cache.',
};

export function getCache(name: CacheName): Cache {
  return cacheFactory.exists(name) ? cacheFactory.get(name) : cacheFactory.createCache(name, cacheOptions);
}

export function getCacheKey(url: string, localizedCacheKey: boolean = false): string {
  return localizedCacheKey ? `${url}__${getLocale().id}` : url;
}

export function destroyAll(): void {
  cacheFactory.destroyAll();
}
