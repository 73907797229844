import { Observable } from 'rxjs/Observable';
import { ILocale } from '../../../util/locale/locale.interfaces';
import { IGuidedSearchCategory } from '../../find-care/guided-search/guided-search.component';
import { INonZeroCostMap } from '../../modals/cost-info/cost-info.interfaces';

export interface ICost {
  id: string;
  name: string;
  amounts?: INonZeroCostMap;
  costInfoCopy?: string;
  isTier1?: boolean;
  showWithReferralDesc?: boolean;
  guidedSearch: IGuidedSearchCategory;
}
export interface ICostColumn {
  title: string;
  helpLinkId: string;
  helpLinkText: string;
  helpLinkUrl: string;
  costItems: ICost[];
}

export interface ICostsController {
  costs: ICostColumn[];
  locale: ILocale;
  request: Observable<any>;
  internalRedirect($event: ng.IAngularEvent, url: string): void;
}

export const CostIdCodes = {
  costDoctor: '98',
  costSpecialist: '03',
  costUrgent: 'UC',
  costEmergency: '52',
  costVirtual: '09',
  costMRDoctor: '98',
  costMRSpecialist: '03',
  costOutpatient: '13',
  costInpatient: '48',
  costEquipment: 'EquipLens',
  costCSDoctor: '98',
  costCSSpecialist: '96',
  costCSMental: 'A8',
  costCSUrgent: 'UC',
  costCSHospital: '48',
};
