import rolodexCardTemplate from 'views/ui/rolodex-card.html';
import rolodexTemplate from 'views/ui/rolodex.html';
import { RolodexController } from './rolodex.controller';

export class RolodexDirective implements ng.IDirective {
  public restrict = 'E';
  public transclude = true;
  public controller = RolodexController;
  public templateUrl = rolodexTemplate;
  public scope = {};
  public controllerAs = '$rolodex';
  public bindToController = {
    cardsPerPage: '<?',
    remoteCtrl: '=?',
    titleKey: '@',
  };

  public static Factory(): ng.IDirectiveFactory {
    return () => new RolodexDirective();
  }
}

export class RolodexCardDirective implements ng.IDirective {
  public restrict = 'E';
  public require = '^rolodex';
  public replace = true;
  public transclude = true;
  public templateUrl = rolodexCardTemplate;
  public scope = {};
  public link = (scope, element, attrs, $rolodex) => {
    $rolodex.add({
      scope,
      element,
    });
  }

  public static Factory(): ng.IDirectiveFactory {
    return () => new RolodexCardDirective();
  }
}
