import angular from 'angular';
import 'angular-translate';
import { Moment } from 'moment';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/reduce';
import 'rxjs/add/operator/toArray';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { getLocale } from 'scripts/util/locale/locale';
import { getMoneyValue, getMoneyValueHtml } from 'scripts/util/money/money';
import { optumPlanBalances } from 'scripts/util/resource/resource.constants';
import { CoverageType, ICoverageTimePeriod, ICurrencyAmount, IEmptyResponse, RelationshipType } from '../../../api/api.interfaces';
import { ClaimType, IClaimBalance } from '../../../api/claims/claims.interfaces';
import { IClaimsService } from '../../../api/claims/claims.service';
import { AccountType, IGetAccountsResponse, ILedgerAccount } from '../../../api/ledger/ledger.interfaces';
import { LedgerService } from '../../../api/ledger/ledger.service';
import {
  BENEFIT_TYPE,
  BenefitNetwork,
  BenefitPaymentType,
  DEDUCTIBLE,
  IBenefit,
  IBenefitAccumulators,
  IBenefitAmount,
  IBenefitLinkReplacement,
  INDIVIDUAL,
  IPlanBenefitsResponse,
  OOP,
} from '../../../api/plans/plans.interfaces';
import { IPlansService } from '../../../api/plans/plans.service';
import { IPlanCoverage, IProfileUser, MembershipCategory } from '../../../api/profile/profile.interfaces';
import { IProfileService, ProfileService } from '../../../api/profile/profile.service';
import { IClientConfig } from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { IAngularMoment } from '../../../arcade.module.interfaces';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IGridSetup } from '../../../util/grid/grid.interfaces';
import { ILocaleService } from '../../../util/locale/locale.service';
import { IResourceService } from '../../../util/resource/resource.service';
import { IStateService } from '../../../util/state/state.service';
import { AccountSummaryService, IAccountSummaryService } from './account-summary-explanation.service';
import {
  IAccountSummaryAmount,
  IAccountSummaryController,
  IAccountSummaryMax,
  IBenefitsSpending,
} from './account-summary.interfaces';

export class AccountSummaryController implements IAccountSummaryController, IGridSetup {
  public membershipCategory: MembershipCategory;
  public accumulatorsLastUpdated: Moment;
  public claimsTotalsLastUpdated: Moment;
  public spentDeductibles: IBenefitsSpending[];
  public spentOopMax: IBenefitsSpending[];
  public billedItems: IAccountSummaryAmount[];
  public billedSegments: number[];
  public balanceItems: ILedgerAccount[];
  public individual: string;
  public maxColumns: number;
  public balancesRequest: Observable<IGetAccountsResponse>;
  public benefitsRequest: Observable<IBenefitsSpending[]>;
  public claimsRequest: Observable<IAccountSummaryAmount[]>;
  public hasLedgerPermission: boolean;
  public showBalancesSection: boolean;
  public phiRestricted: boolean;
  public totalBilledIsZero: boolean;
  public language: string;
  public spentDeductibleLabel: string;
  public spentOopMaxLabel: string;
  public planPeriod: ICoverageTimePeriod;
  public networkLabel: string;
  public isAdvantage: boolean;
  private clientConfig: IClientConfig;
  private clientConfigReq: Observable<IClientConfig>;
  private localeSubscription: Subscription;
  private profileSubscription: Subscription;
  private oopMaxLinkLabel: string;
  private deductibleLinkLabel: string;
  private useSelectedProfile: boolean;
  private networkStringIdMap = {
    [BenefitNetwork.InNetwork]: 'IN_NETWORK',
    [BenefitNetwork.OutOfNetwork]: 'OUT_OF_NETWORK',
    [BenefitNetwork.Tier1]: 'TIER_ONE',
  };

  constructor(
    private $state: ng.ui.IStateService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Environment: IEnvironmentConstants,
    private accountSummaryService: IAccountSummaryService,
    private claimsService: IClaimsService,
    private ledgerService: LedgerService,
    private localeService: ILocaleService,
    private moment: IAngularMoment,
    private plansService: IPlansService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private stateService: IStateService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);
    $translatePartialLoader.addPart(Dictionary.ACCUMULATORS);
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.useSelectedProfile = this.stateService.getUseSelectedProfile();

    this.maxColumns = 4;
    this.language = getLocale().language;
    this.accumulatorsLastUpdated = this.moment();
    this.claimsTotalsLastUpdated = this.moment();
    this.isAdvantage = Environment.FEATURE_FLAGS.ARCADE_FEATURES_ADVANTAGE;

    this.clientConfigReq = this.userService.getHeartbeat()
      .flatMap(({data}) => this.targetingService.getClientConfig(data.rallyId))
      .do(clientConfig => this.clientConfig = clientConfig);

    this.accountSummaryService
      .getHeaderWithLabelsTranslated()
      .subscribe(labelReplacements => {
        this.setBenefitSummaryExplanationLinkValues(labelReplacements);
      },
    );

    this.initBenefits();
    this.initClaims();

    if (this.useSelectedProfile) {
      this.profileSubscription = profileService.profileChanged.subscribe(() => {
        this.showBalancesSection = false;
        this.initBenefits();
        this.initClaims();
      });
    }

    this.localeSubscription = localeService.localeChanged
      .do(locale => this.language = locale.language)
      .flatMap(() => this.clientConfigReq)
      .flatMap(() => this.accountSummaryService.getHeaderWithLabelsTranslated())
      .subscribe(labelReplacements => {
        this.setBenefitSummaryExplanationLinkValues(labelReplacements);
        this.applyCustomLabels();
      });
  }

  public $onDestroy(): void {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    this.localeSubscription.unsubscribe();
  }

  public getPercentUsed(item: IAccountSummaryMax): string {
    const percentage = item.amount ? item.amount / item.max * 100 : 0;
    return percentage.toFixed(0) + '%';
  }

  public getTotalBilledAmount(): number {
    return this.billedItems ? this.billedItems.reduce((prev, current) => {
        return prev + current.amount;
      }, 0) : 0;
  }

  public getTotalBilled(): string {
    const amount = this.getTotalBilledAmount();
    return this.getMoneyValueHtml(amount);
  }

  public isTotalNonZero(): boolean {
    const amount = this.billedItems ? this.billedItems.reduce((prev, current) => {
        return prev + current.amount;
      }, 0) : 0;
    return amount && amount > 0;
  }

  public getAccountBalance(account: ILedgerAccount): string {
    const { accountType, balance } = account;
    let amount: ICurrencyAmount;
    switch (accountType) {
      case AccountType.DCSA:
      case AccountType.HCSA:
      case AccountType.MRA:
        amount = balance.currentAmount;
        break;
      default:
        amount = balance.totalBalance;
    }
    return this.getMoneyValue(amount ? amount.value : 0);
  }

  public getMoneyValue(amount: number, decimal?: boolean): string {
    return getMoneyValue(amount, decimal);
  }

  public getMoneyValueHtml(amount: number, decimal?: boolean): string {
    return getMoneyValueHtml(amount, decimal);
  }

  public getNumColumns(items: any[]): number {
    const numColumns = items.length;

    if (items.length > 0 && (this.spentDeductibles.length === 0 || this.spentOopMax.length === 0)) {
      // If we only have 1 of these lists populated then use the number of items passed in
      return numColumns > this.maxColumns ? this.maxColumns : numColumns;
    } else {
      // We have two lists shown horizontally in-line. Each list should be half of the max allowed.
      return numColumns > Math.floor(this.maxColumns / 2) ? Math.floor(this.maxColumns / 2) : numColumns;
    }
  }

  public getAccountUrl(accountType: AccountType): string {
    if (accountType === AccountType.HSA) {
      return this.$state.href('authenticated.claimsAndAccounts.planBalances');
    } else {
      switch (accountType) {
        case AccountType.FSADC:
        case AccountType.FSAHC:
        case AccountType.FSALP:
        case AccountType.HRA:
        case AccountType.HRAAP:
        case AccountType.HRAPD:
        case AccountType.HRASD:
        case AccountType.MRA:
        case AccountType.DCSA:
        case AccountType.HCSA:
          return this.$state.href('authenticated.claimsAndAccounts.planBalances');
        case AccountType.HIA:
        case AccountType.PRA:
        case AccountType.RMSA:
          return this.resourceService.get(optumPlanBalances);
      }
    }
  }

  public isExternalAccountUrl(accountType: AccountType): boolean {
    switch (accountType) {
      case AccountType.HIA:
      case AccountType.PRA:
      case AccountType.RMSA:
        return true;
      default:
        return false;
    }
  }

  // BenefitsSpending refers to the aggregation of the benefits and accumulators APIs
  public getBenefitsSpending(rallyId: string, dependentOffset: string): Observable<IBenefitsSpending[]> {
    const benefitsSpending$ = this.plansService.getBenefits(rallyId, dependentOffset)
      .flatMap(rsp => rsp.data && rsp.data.benefits ? rsp.data.benefits : [])
      .filter(benefit => !!(benefit.coverageType === CoverageType.Medical && benefit.maxes))
      .map(benefit => this.initializeBenefitsSpending(benefit));
    const benefitAccumulators$ = this.plansService.getAccumulators(rallyId, dependentOffset)
      .do(rsp => this.accumulatorsLastUpdated = rsp.arcadeDataUpdated || this.moment())
      .flatMap(rsp => rsp.data && rsp.data.benefits ? rsp.data.benefits : [])
      .filter(benefit => !!(benefit.coverageType === CoverageType.Medical && benefit.accumulators))
      .catch(() => Observable.of({} as IBenefitAccumulators));

    return Observable.zip(benefitsSpending$, benefitAccumulators$)
      .map(([benefitsSpending, benefitAccumulators]: [IBenefitsSpending[], IBenefitAccumulators]) => {
        // Add accumulator amounts to benefitsSpending
        benefitsSpending.forEach(benefit => {
          if (benefitAccumulators.accumulators && benefitAccumulators.accumulators.hasOwnProperty(benefit.network)) {
            const accumulators = benefitAccumulators.accumulators[benefit.network] as IBenefitAmount[];
            accumulators.forEach(acc => (acc.type === benefit.type) && (benefit.amount = acc.amount.value));
          }
        });
        return benefitsSpending;
      });
  }

  public getMedicalClaimsTotals(): Observable<IAccountSummaryAmount[]> {
    const heartbeat = this.userService.getHeartbeat();
    const selectedUser = heartbeat.let(this.profileService.toCurrentProfile());
    const loggedInUser = heartbeat.let(this.profileService.toProfile()).map(profile => profile.data.currentUser);

    return Observable.zip(loggedInUser, Observable.if(() => this.useSelectedProfile, selectedUser, loggedInUser))
      .do(([currentUser, selectedProfile]) => {
        const medicalCoverage = this.profileService.getCoverage(CoverageType.Medical, selectedProfile.planCoverages);
        this.phiRestricted = medicalCoverage && medicalCoverage.planFeatures.phiRestricted &&
          (currentUser.dependentSeqNum !== selectedProfile.dependentSeqNum);
      }).flatMap(([currentUser, selectedProfile]) => {
        return Observable.if(() => this.phiRestricted,
          Observable.of(AccountSummaryController.getBilledItems()),
          this.getBilledItemsFromRallyId(selectedProfile.rallyId,
            selectedProfile.dependentSeqNum)) as Observable<IAccountSummaryAmount[]>;
      })
      .last();
  }

  public getBilledItemsFromRallyId(rallyId: string, dependentOffset: string): Observable<IAccountSummaryAmount[]> {
    return this.claimsService.getTotals(rallyId, dependentOffset, ClaimType.Medical)
      .do(rsp => this.claimsTotalsLastUpdated = rsp.arcadeDataUpdated || this.moment())
      .flatMap(rsp => rsp.data ? rsp.data : [])
      .map(({balance}) => AccountSummaryController.getBilledItems(balance));
  }

  private initBenefits(): void {
    const heartbeat = this.userService.getHeartbeat();
    const selectedUser = heartbeat.let(this.profileService.toCurrentProfile());
    const loggedInUser = heartbeat.let(this.profileService.toProfile())
      .map(profile => profile.data.currentUser)
      .do(currentUser => {
        this.membershipCategory = currentUser.membershipCategory;
      });

    this.benefitsRequest = this.clientConfigReq
      .flatMap(() => Observable.zip(loggedInUser, Observable.if(() => this.useSelectedProfile, selectedUser, loggedInUser)))
      .do(([currentUser, selectedProfile]) => {
        this.individual = selectedProfile.userInfo.firstName;
        this.hasLedgerPermission = currentUser.dependentSeqNum === selectedProfile.dependentSeqNum &&
          ProfileService.hasLedgerAccess(currentUser);
        const medicalCoverage = this.profileService.getCoverage(CoverageType.Medical, selectedProfile.planCoverages);
        this.planPeriod = medicalCoverage && medicalCoverage.planPeriod;
        this.initBalances();
      })
      .flatMap(([_, selectedProfile]) => this.getBenefitsSpending(selectedProfile.rallyId, selectedProfile.dependentSeqNum));

    this.benefitsRequest
      .subscribe((benefitsSpending: IBenefitsSpending[]) => {
        this.spentDeductibles = benefitsSpending
          .filter(({benefit}) => benefit as string === DEDUCTIBLE)
          .filter(benefitSpending => !this.isAccumulatorSuppressed(benefitSpending.type, benefitSpending.network))
          .sort((a, b) => a.owner === INDIVIDUAL ? -1 : 1);
        this.spentOopMax = benefitsSpending
          .filter(({benefit}) => benefit as string === OOP)
          .filter(benefitSpending => !this.isAccumulatorSuppressed(benefitSpending.type, benefitSpending.network))
          .sort((a, b) => a.owner === INDIVIDUAL ? -1 : 1);
        this.applyCustomLabels();
        this.setNetworkLabel();
      }, console.warn);
  }

  private initClaims(): void {
    const heartbeat = this.userService.getHeartbeat();
    const selectedUser = heartbeat.let(this.profileService.toCurrentProfile());
    const loggedInUser = heartbeat.let(this.profileService.toProfile()).map(profile => profile.data.currentUser);
    this.claimsRequest = Observable.if(() => this.useSelectedProfile, selectedUser, loggedInUser)
    .map(profile => {
      return this.profileService.getCoverage(CoverageType.Medical, profile.planCoverages);
    })
    .takeWhile(medicalCoverage => !!medicalCoverage)
    .flatMap(() => this.getMedicalClaimsTotals());

    this.claimsRequest.subscribe(rsp => {
      this.billedItems = rsp;
      this.totalBilledIsZero = this.getTotalBilledAmount() === 0;
      this.billedSegments = AccountSummaryController.getAmountArr(this.billedItems);
    }, console.warn);
  }

  private initBalances(): void {
    if (this.hasLedgerPermission) {
      const heartbeat = this.userService.getHeartbeat();
      const selectedUser = heartbeat.let(this.profileService.toCurrentProfile());
      const loggedInUser = heartbeat.let(this.profileService.toProfile()).map(profile => profile.data.currentUser);

      this.balancesRequest = Observable.if(() => this.useSelectedProfile, selectedUser, loggedInUser)
        .flatMap(profile => this.ledgerService.getAccounts(profile.rallyId));
      this.balancesRequest.subscribe(rsp => {
        this.balanceItems = rsp.data.filter(account => account.isActive && account.balance);
        this.showBalancesSection = this.balanceItems.length > 0;
      }, err => {
        this.showBalancesSection = false;
        console.warn(err);
      });
    } else {
      this.balancesRequest = Observable.of({} as IGetAccountsResponse);
    }
  }

  // Get and parse benefits call to benefitsSpending object, set 'amount' to undefined
  private initializeBenefitsSpending(benefit: IBenefit): IBenefitsSpending[] {
    let benefitAmounts: IBenefitAmount[];
    let benefitNetwork = BenefitNetwork.InNetwork;
    if (this.clientConfig.suppressions.showTier1OnDashboard && benefit.maxes.tier1) {
      benefitNetwork = BenefitNetwork.Tier1;
      benefitAmounts = benefit.maxes.tier1;
    } else {
      benefitAmounts = benefit.maxes.inNetwork || [];
    }
    return benefitAmounts.map(({amount, type}) => ({
        type,
        benefit: BENEFIT_TYPE[type].benefit,
        owner: BENEFIT_TYPE[type].owner,
        max: amount.value,
        amount: undefined,
        network: benefitNetwork,
      }),
    );
  }

  private isAccumulatorSuppressed(type: BenefitPaymentType, network: BenefitNetwork): boolean {
    if (this.clientConfig) {
      const accSuppression = this.clientConfig.suppressions.accumulatorSuppression || {};
      return accSuppression.hasOwnProperty(network) && accSuppression[network].indexOf(type) !== -1;
    }
    return false;
  }

  private applyCustomLabels(): void {
    if (this.clientConfig && this.clientConfig.customLabels.customAccumulatorLabels) {
      for (const item of this.spentDeductibles) {
        item.customLabel = this.getCustomLabel(item.type, item.network);
        this.spentDeductibleLabel = item.customLabel ? item.customLabel : this.spentDeductibleLabel;
      }

      for (const item of this.spentOopMax) {
        item.customLabel = this.getCustomLabel(item.type, item.network);
        this.spentOopMaxLabel = item.customLabel ? item.customLabel : this.spentOopMaxLabel;
      }
    }
  }

  private getCustomLabel(type: BenefitPaymentType, network: BenefitNetwork): string {
    if (this.clientConfig) {
      const customLabels = this.clientConfig.customLabels.customAccumulatorLabels || {};
      return customLabels.hasOwnProperty(network) && customLabels[network].hasOwnProperty(type) ?
        customLabels[network][type] : null;
    }
    return null;
  }

  private setBenefitSummaryExplanationLinkValues(labelReplacements: IBenefitLinkReplacement): void {
    this.deductibleLinkLabel =  labelReplacements[AccountSummaryService.DEDUCTIBLE_HEADER_KEY].customLabel || undefined;
    this.oopMaxLinkLabel =  labelReplacements[AccountSummaryService.OOP_MAX_HEADER_KEY].customLabel || undefined;
  }

  private setNetworkLabel(): void {
    const network = (this.spentDeductibles[0] && this.spentDeductibles[0].network) ||
      (this.spentOopMax[0] && this.spentOopMax[0].network) || BenefitNetwork.InNetwork;
    if (this.clientConfig.customLabels.customNetworkLabels && this.clientConfig.customLabels.customNetworkLabels.hasOwnProperty(network)) {
      this.networkLabel = this.clientConfig.customLabels.customNetworkLabels[network];
    } else {
      this.networkLabel = this.networkStringIdMap[network];
    }
  }

  private static getAmountArr(items: IAccountSummaryAmount[]): number[] {
    const arr = [];
    for (const item of items) {
      arr.push(item.amount);
    }
    return arr;
  }

  private static getBilledItems(balance?: IClaimBalance): IAccountSummaryAmount[] {
    return [{
      name: 'HEALTH_PLAN_DISCOUNT',
      amount: balance && balance.healthPlanDiscount ? balance.healthPlanDiscount.value || 0 : 0,
    }, {
      name: 'HEALTH_PLAN_PAYS',
      amount: balance && balance.healthPlanPays ? balance.healthPlanPays.value || 0 : 0,
    }, {
      name: 'PATIENT_RESPONSIBILITY',
      amount: balance && balance.patientResponsibility ? balance.patientResponsibility.value || 0 : 0,
    }];
  }
}
