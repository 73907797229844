import { Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { IProfileService } from '../../api/profile/profile.service';
import { CampaignPlacementType, ICampaign } from '../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../api/targeting/targeting.service';
import { IUserService } from '../../api/user/user.service';
import { IFeatureFlagService } from '../../util/feature-flag/feature-flag.interface';
import { IOnboardingStateParams } from '../header/onboarding-banner.component';
import { RelationshipType } from './../../api/api.interfaces';
import { IOnboardingSteps } from './../../api/profile/profile.interfaces';

export interface IOnboardingStatusService {
  get(): Observable<boolean>;
}

export class OnboardingStatusService implements IOnboardingStatusService {
  constructor(
    private featureFlagService: IFeatureFlagService,
    private profileService: IProfileService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
  }

  public get(): Observable<boolean> {
    return this.userService.getHeartbeat()
      .map(rsp => rsp.data.rallyId)
      .flatMap(rallyId => Observable.zip(
        this.profileService.get(rallyId).map(rsp => rsp.data),
        this.profileService.getCurrentProfile(rallyId)),
      )
      .flatMap(([profile, profileUser]) => {
        return this.targetingService.getCampaigns(
          profile.rallyId,
          [CampaignPlacementType.ArcadeOnboardingPaperless],
        ).map(rsp => rsp.data.placements);
      }, ([profile, profileUser], placements) => ({profile, profileUser, placements}))
      .map(({profile, profileUser, placements}) => {
        let showOnboardingBanner = false;
        if (profile.onboardingSteps) {
          const paperlessCampaigns = placements[CampaignPlacementType.ArcadeOnboardingPaperless];
          if (paperlessCampaigns) {
            const paperlessCampaign = paperlessCampaigns[0];
            const stepNeeded = profile.onboardingSteps.communicationPreference.stepNeeded;
            const isSubscriber = profileUser.relationshipType === RelationshipType.Subscriber;
            showOnboardingBanner = isSubscriber && stepNeeded && !!paperlessCampaign;
          }
        }
        return showOnboardingBanner;
      })
      .defaultIfEmpty(false);
  }
}
