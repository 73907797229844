import angular from 'angular';
import { IBrand } from '../../util/population/population.interfaces';
import { IPopulationService } from '../../util/population/population.service';
import { PrintContentId } from './print.directives';
import { IPrintController } from './print.interfaces';

export class PrintController implements IPrintController {
  public printContent: HTMLElement;
  public printGroup: ng.IAugmentedJQuery[];
  public brand: IBrand;

  constructor(private populationService: IPopulationService) {
    'ngInject';
    this.printGroup = [];
    this.printContent = document.getElementById(PrintContentId);
    this.brand =  populationService.getPopulation().brand;
  }

  public print(): void {
    if (!this.printContent) {
      this.printContent = document.createElement('div');
      this.printContent.id = PrintContentId;
      document.body.insertBefore(this.printContent, document.body.firstChild);
    }

    // Clear out the contents of #print-content
    while (this
      .printContent.hasChildNodes()) {
      this.printContent.removeChild(this.printContent.lastChild);
    }

    // Add our scoped content into #print-content before printing
    for (const item of this.printGroup) {
      const clone = item.clone();
      angular.element(this.printContent).append(clone);
    }

    window.print();
  }

  public addPrintContent(element: ng.IAugmentedJQuery): void {
    this.printGroup.push(element);
  }

  public destroy(): void {
    if (this.printContent && this.printContent.parentNode === document.body) {
      document.body.removeChild(this.printContent);
    }
  }
}
