import { ITrackingEventRequest, TrackingTriggerType } from 'scripts/api/tracking/tracking.interfaces';
import CONFIG from '../../util/constants/config';
import FEATURE_FLAGS from '../constants/feature-flag';
import { stringifyData } from './tracking-helper';

let queuedEvents = [];

export function getQueuedEvents(): ITrackingEventRequest[] {
  return queuedEvents;
}

export function resetQueuedEvents(): void {
  queuedEvents = [];
}

export function updateQueuedEvents(eventsOrEventToQueue: ITrackingEventRequest[] | ITrackingEventRequest): void {
  if (Array.isArray(eventsOrEventToQueue)) {
    queuedEvents = queuedEvents.concat(eventsOrEventToQueue);
  } else {
    queuedEvents.push(eventsOrEventToQueue);
  }
}

export function queuePageLoadEvent(uri: string, featureList: string[], data: any): void {
  const event = {
    trigger: TrackingTriggerType.PageLoad,
    actionName: 'page-load',
    uri,
    serviceVersion: CONFIG.ARCADE_WEB_VERSION,
    featureList,
    placement:  'page-load',
    data,
  };

  stringifyData(event);
  updateQueuedEvents(event);
}

export function logEvent(event: ITrackingEventRequest): void {
  // If logging the analytics events out to the console is turned on, display it.
  if (FEATURE_FLAGS.ARCADE_FEATURES_ANALYTICS_DEBUG) {
    console.warn(`Analytics event triggered:
        \turi: ${event.uri}
        \ttrigger: ${event.trigger}
        \tfeatureList: ${event.featureList}
        \taction: ${event.actionName}
        \tfullName: ${[...event.featureList, event.actionName].join('.')}
        \tplacement: ${event.placement}
        \tdata: ${event.data || 'NONE'}`,
    );
  }
}
