import { IEnvironmentConstants } from '../util/constants/environment.interfaces';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    iperceptionskey: string;
    iPerceptions: any;
  }
}

export class IPerceptions {
  constructor($timeout: ng.ITimeoutService, Environment: IEnvironmentConstants) {
    'ngInject';
    {
      window.iperceptionskey = Environment.CONFIG.ARCADE_WEB_IPERCEPTIONS_KEY;

      const body = document.getElementsByTagName('body')[0];
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.async = true;
      script.src = '//universal.iperceptions.com/wrapper.js';
      body.appendChild(script);

      // ARC-6653: Manually trigger the iPerceptions smileys for C&S
      // ARC-7069: Manually trigger the iPerceptions for OV modal
      // 15 second delay should be long enough that if the smileys are going to show up
      // without having to manually trigger them, they (usually) will have already done so
      // (calling iPerceptions.Wrapper.exec when smileys are present makes them go away)
      $timeout(() => {
        const communityPath = '/community/';
        const overviewVideo = '/modal/overview-video';
        if (window.location.pathname.indexOf(communityPath) > -1 || window.location.pathname.indexOf(overviewVideo) > -1) {
          const smileySurvey = document.getElementById('artEXPOiFrame');
          if (!smileySurvey) {
            const execUrl = window.location.hostname + communityPath;
            window.iPerceptions.Wrapper.exec(execUrl, false);
          }
        }
      }, 15000);
    }
  }
}
