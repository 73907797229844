import { Subscription } from 'rxjs/Subscription';
import { getLocale } from 'scripts/util/locale/locale';
import {
  accountSettings,
  benefitsCoverage,
  connect,
  connectHealthChecklist,
  connectSaved,
  contactUs,
  documentsOverview,
  health,
  healthRecord,
  messages,
  premiumPayments,
  prescriptions,
  recommendedCare,
  secureClaimsAccount,
} from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import headerTemplate from 'views/chrome/header.html';
import { CoverageStatus, CoverageType } from '../../api/api.interfaces';
import { IArcadeClient, LineOfBusiness, MembershipCategory } from '../../api/profile/profile.interfaces';
import { IProfileService, ProfileService } from '../../api/profile/profile.service';
import { ITargetingService } from '../../api/targeting/targeting.service';
import { IUserService } from '../../api/user/user.service';
import { IRootScope } from '../../arcade.module.interfaces';
import { IDropdownOption } from '../../ui/dropdown/dropdown.interfaces';
import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../util/feature-flag/feature-flag.interface';
import { LocaleCountry, LocaleLanguage } from '../../util/locale/locale.interfaces';
import { ILocale } from '../../util/locale/locale.interfaces';
import { ILocaleService } from '../../util/locale/locale.service';
import { IBrand } from '../../util/population/population.interfaces';
import { IPopulationService } from '../../util/population/population.service';
import { IResourceService } from '../../util/resource/resource.service';
import { IOnboardingStateParams } from './onboarding-banner.component';

export class HeaderComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;
  public bindings: {[binding: string]: string};

  constructor() {
    this.controller = HeaderController;
    this.templateUrl = headerTemplate;
    this.bindings = {
      authenticated: '<',
    };
  }
}

export class HeaderController implements ng.IComponentController {
  public locales: IDropdownOption[];
  public locale: ILocale;
  public accountLinks: IDropdownOption[];
  public findCareHref: string;
  public findCareClick: (e: any) => void;
  public authenticated: boolean;
  public profileLoaded: boolean;
  public profileFailed: boolean;
  public showFindCare: boolean;
  public showPremiumPayment: boolean;
  public showRx: boolean;
  public isMR: boolean;
  public isEmpire: boolean;
  public isActiveMR: boolean;
  public isFutureMR: boolean;
  public isTermedCS: boolean;
  public planIsMaOrMapd: boolean;
  public client: IArcadeClient;
  public lineOfBusiness: LineOfBusiness;
  public brand: IBrand;
  public help: IResource;
  public secureClaimsAccount: IResource;
  public benefitsCoverage: IResource;
  public prescriptions: IResource;
  public health: IResource;
  public documentsOverview: IResource;
  public premiumPayments: IResource;
  private isCS: boolean;
  private localeSubscription: Subscription;
  private isEI: boolean;

  constructor(
    private $location: ng.ILocationService,
    private $rootScope: IRootScope,
    public $state: ng.ui.IStateService,
    private $stateParams: IOnboardingStateParams,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private localeService: ILocaleService,
    private populationService: IPopulationService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private userService: IUserService,
    private targetingService: ITargetingService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.HEADER);

    this.help = contactUs;
    this.secureClaimsAccount = secureClaimsAccount;
    this.benefitsCoverage = benefitsCoverage;
    this.prescriptions = prescriptions;
    this.health = health;
    this.documentsOverview = documentsOverview;
    this.premiumPayments = premiumPayments;

    this.locale = getLocale();
    this.brand =  populationService.getPopulation().brand;
    this.locales = [{
      value: `${LocaleLanguage.English}-${LocaleCountry.UnitedStates}`,
      label: 'English',
    }, {
      value: `${LocaleLanguage.Spanish}-${LocaleCountry.UnitedStates}`,
      label: 'Español',
    }];

    this.authenticated = (this.authenticated === undefined) ? true : this.authenticated;
    this.profileLoaded = false;
  }

  public $onInit(): void {
    this.setLinks();

    this.userService.getHeartbeat()
    .map(rsp => rsp.data.rallyId)
    .flatMap(rallyId => this.profileService.get(rallyId))
    .map(rsp => ({
      profile: rsp.data,
    }))
    .subscribe(({profile}) => {
      this.client = profile.clientInfo;
      this.lineOfBusiness = profile.currentUser.lineOfBusiness;
      if (this.lineOfBusiness === LineOfBusiness.CS) {
        this.locales = this.locales.concat([{
          value: `${LocaleLanguage.Ilocano}-${LocaleCountry.Philippines}`,
          label: 'Ilocano',
        }, {
          value: `${LocaleLanguage.ChineseTraditional}-${LocaleCountry.Taiwan}`,
          label: '中文',
        }, {
          value: `${LocaleLanguage.Vietnamese}-${LocaleCountry.Vietnam}`,
          label: 'Tiếng Việt',
        }, {
          value: `${LocaleLanguage.Korean}-${LocaleCountry.UnitedStates}`,
          label: '한국어',
        }, {
          value: `${LocaleLanguage.HaitianCreole}-${LocaleCountry.Haiti}`,
          label: 'Creole',
        }, {
          value: `${LocaleLanguage.Tagalog}-${LocaleCountry.Philippines}`,
          label: 'Tagalog',
        }]);
      }
      if (profile.currentUser.membershipCategory === MembershipCategory.EMPIRE) {
        this.locales = [{
          value: `${LocaleLanguage.English}-${LocaleCountry.UnitedStates}`,
          label: 'English',
        }];
      }
    });

    this.localeSubscription = this.localeService.localeChanged.subscribe(locale => {
      this.locale = locale;
      this.setLinks();
    });
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public setLocale(): () => void;
  public setLocale(locale: IDropdownOption): void;
  public setLocale(locale?: IDropdownOption): any {
    // Handle overloading possibilities
    if (locale === undefined) {
      return (option: IDropdownOption) => {
        this.localeService.set(option.value);
      };
    } else {
      this.localeService.set(locale.value);
    }
  }

  public isLocale(locale: IDropdownOption): boolean {
    return locale.value === this.$rootScope.locale;
  }

  public getFirstLink(): IDropdownOption {
    return this.accountLinks[0];
  }

  public toggleNav($event: JQueryEventObject): void {
    // when a keyboard user selects a link with the enter key, a keydown event fires
    // in addition to a click event, but we only want to toggle the nav once
    if ($event.type === 'click') {
      this.$rootScope.navOpen = !this.$rootScope.navOpen;
    }
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  public selectAccountOption(): (option: IDropdownOption, $event: ng.IAngularEvent) => void {
    return (option, $event) => option.action && option.action($event);
  }

  // this function exists so that we can use one-time binding in the template (ng-if="::!$ctrl.isMR" would be problematic)
  public isNotMR(): boolean {
    return this.isMR === undefined ? undefined : !this.isMR;
  }

  // this function exists so that we can use one-time binding in the template
  // (ng-if="::!($ctrl.isMR || $ctrl.isEmpire)" would be problematic to say the least)
  public isNotMROrEmpire(): boolean {
    return (this.isMR === undefined ? undefined : (this.isEmpire === undefined ? undefined : !(this.isMR || this.isEmpire)));
  }

  private setLinks(): void {
    const healthChecklistUrl = this.resourceService.get(connectHealthChecklist);
    const healthRecordUrl = this.resourceService.get(healthRecord);
    const savedUrl = this.resourceService.get(connectSaved);
    this.accountLinks = [{
      value: this.$state.href('internalRedirect', { deepLink: healthRecordUrl }),
      label: 'HEALTH_RECORD',
      condition: this.authenticated,
      action: e => this.internalRedirect(e, healthRecordUrl),
    }, {
      value: this.$state.href('internalRedirect', { deepLink: healthChecklistUrl }),
      label: this.featureFlagService.isEiRecommendationsOn() ? 'RECOMMENDATIONS' : 'HEALTH_CHECKLIST',
      condition: this.authenticated,
      action: e => this.internalRedirect(e, healthChecklistUrl),
    }, {
      value: this.$state.href('internalRedirect', { deepLink: savedUrl }),
      label: 'SAVED',
      condition: this.authenticated,
      action: e => this.internalRedirect(e, savedUrl),
    }, {
      value: this.$state.href('internalRedirect', {
        deepLink: this.Environment.CONFIG.ARCADE_WEB_RALLY_REWARDS_URL,
      }),
      label: 'REWARDS',
      target: '_blank',
    }, {
      value: this.resourceService.get(accountSettings),
      label: 'ACCOUNT_SETTINGS',
    }, {
      value: this.resourceService.get(messages),
      label: 'MESSAGES',
      target: '_self',
    }, {
      value: this.$state.href('logout'),
      label: 'LOGOUT',
    }];

    if (this.authenticated) {
      const heartbeat = this.userService.getHeartbeat();

      const currentUserRequest = heartbeat.let(this.profileService.toProfile())
        .map(rsp => rsp.data.currentUser);

      currentUserRequest
        .subscribe(currentUser => {
          this.isEmpire = currentUser.membershipCategory === MembershipCategory.EMPIRE;
          this.isMR = currentUser.lineOfBusiness === LineOfBusiness.MR;
          this.isCS = currentUser.lineOfBusiness === LineOfBusiness.CS;
          this.isEI = currentUser.lineOfBusiness === LineOfBusiness.EI;
          const futureCoverageInfo = ProfileService.getCoverageInfo(currentUser.planCoverages, [CoverageStatus.Future]);
          this.isActiveMR = this.isMR && ProfileService.getCoverageInfo(currentUser.planCoverages).numCoverages > 0;
          this.isFutureMR = this.isMR && !this.isActiveMR && futureCoverageInfo.numCoverages > 0;
          this.isTermedCS = this.isCS && this.profileService.isTermedForCoverageType(CoverageType.Medical, currentUser.planCoverages);
          this.showFindCare = !this.isTermedCS && this.profileService.hasFindCare(currentUser);
          this.planIsMaOrMapd = ProfileService.isPlanMaOrMapd(currentUser);
          if (!this.isMR) {
            const deepLink = this.resourceService.get(connect);
            this.findCareHref = this.$state.href('internalRedirect', { deepLink });
            this.findCareClick = e => this.internalRedirect(e, deepLink);
          } else {
            this.findCareHref = this.$state.href('authenticated.findCare');
          }

          if (this.isMR) {
            this.showPremiumPayment = currentUser.memberFeatures.premiumPaymentEligible &&
              (this.isActiveMR || (this.isFutureMR && this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_PRE_EFF_PREMIUM_PAYMENTS));
            this.removeLink('HEALTH_CHECKLIST');
            this.removeLink('REWARDS');
            if (!this.isFutureMR) {
              this.addLink(2, {
                value: this.$state.href('authenticated.recommendations'),
                label: 'RECOMMENDATIONS',
                condition: this.authenticated,
              });
            }
            if (!this.isActiveMR && !this.isFutureMR || !this.planIsMaOrMapd) {
              this.removeLink('SAVED');
            }
          } else if (this.isCS) {
            this.showPremiumPayment = !this.isTermedCS && currentUser.memberFeatures.premiumPaymentEligible;
            this.removeLink('MESSAGES');
            this.removeLink('REWARDS');
            this.removeLink('HEALTH_CHECKLIST');
            const recommendationUrl = this.resourceService.get(recommendedCare);
            this.addLink(2, {
              value: recommendationUrl,
              label: 'RECOMMENDATIONS',
              condition: this.authenticated,
            });
            if (this.isTermedCS) {
              this.removeLink('SAVED');
            }
          } else if (this.isEI) {
            this.showPremiumPayment = currentUser.memberFeatures.premiumPaymentEligible &&
              (currentUser.membershipCategory === MembershipCategory.EXCHANGE ||
                currentUser.membershipCategory === MembershipCategory.PUBLIC_EXCHANGE) &&
              this.featureFlagService.isPremiumPaymentsForExchangeOn();
          }

          this.profileLoaded = true;
        }, err => {
          console.warn(err);
          this.profileFailed = true;
          this.isMR = this.populationService.getPopulation().lineOfBusiness === LineOfBusiness.MR;
        });

      const productsRequest = currentUserRequest
        .flatMap(currentUser => this.profileService.getProducts(currentUser.rallyId),
          (currentUser, productsRsp) => ({currentUser, products: productsRsp.data.products}));

      productsRequest.subscribe(({currentUser, products}) => {
        const isEI = currentUser.lineOfBusiness === LineOfBusiness.EI;
        if (!isEI || !products.individualHealthRecord) {
          this.removeLink('HEALTH_RECORD');
        }

        if (!products.secureMessage) {
          this.removeLink('MESSAGES');
        }
        if (!products.rallyEngage) {
          this.removeLink('REWARDS');
        }
        if (!products.rallyConnect) {
          this.removeLink('HEALTH_CHECKLIST');
          this.removeLink('SAVED');
        }
        const activeCoverageInfo = ProfileService.getCoverageInfo(currentUser.planCoverages);
        this.showRx = !!activeCoverageInfo.coverageTypes
          && activeCoverageInfo.coverageTypes.hasOwnProperty(CoverageType.Rx)
          && !!products.rx
          && !this.isTermedCS;
      }, err => {
        this.showRx = false;
        this.removeLink('HEALTH_RECORD');
        this.removeLink('MESSAGES');
        this.removeLink('REWARDS');
        this.removeLink('HEALTH_CHECKLIST');
        this.removeLink('SAVED');
      });
    }
  }

  private removeLink(label: string): void {
    if (label === 'HEALTH_CHECKLIST' && this.featureFlagService.isEiRecommendationsOn()) {
      label = 'RECOMMENDATIONS';
    }
    for (let i = 0; i < this.accountLinks.length; i++) {
      if (this.accountLinks[i].label === label) {
        this.accountLinks.splice(i, 1);
        break;
      }
    }
  }

  private addLink(index: number, obj: IDropdownOption): void {
    this.accountLinks.splice(index, 0, obj);
  }
}
