import { useEffect, useState } from 'react';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IHeartbeat } from 'scripts/api/user/user.interfaces';

export interface IUseDependenciesState {
  loaded: boolean;
}

export interface IUseDependenciesProps {
  getHeartbeat: () => void;
  getProfile: () => void;
  heartbeat: IHeartbeat;
  profile: IProfileUser;
}

export function useDependencies(props: IUseDependenciesProps): IUseDependenciesState {
  const { heartbeat, getHeartbeat, profile, getProfile } = props;
  const isLoaded = !!(heartbeat && profile);
  const [loaded, setLoaded] = useState(isLoaded);
  async function loadDependencies(): Promise<void> {
    if (!heartbeat) {
      await getHeartbeat();
    }
    if (!profile) {
      await getProfile();
    }
    setLoaded(true);
  }
  useEffect(() => {
    if (!loaded) {
      loadDependencies();
    }
  }, []);
  return {
    loaded,
  };
}
