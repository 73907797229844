import { IClaimsInfoPacket, IMessageEventData } from '@rally/rallypay';
import { Observable } from 'rxjs/Observable';
import {
  ClaimType,
  IClaim,
  IGetMatchingClaimParams,
  IHealthcareClaimDetails,
} from 'scripts/api/claims/claims.interfaces';
import { ClaimsService, IClaimsService } from 'scripts/api/claims/claims.service';
import { AccountType, ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { ILedgerService } from 'scripts/api/ledger/ledger.service';
import { MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import { IProfileService } from 'scripts/api/profile/profile.service';
import { IUserService } from 'scripts/api/user/user.service';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { instamedPayNow } from 'scripts/util/resource/resource.constants';
import { IResourceService } from 'scripts/util/resource/resource.service';
import { parse } from 'scripts/util/uri/uri';
import payNowTemplate from 'views/modals/pay-now-modal.html';

export interface IGetPayNowParams extends IGetMatchingClaimParams {
  claimPayKey: string;
}

export class PayNowComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

   constructor() {
    this.controller = PayNowController;
    this.templateUrl = payNowTemplate;
  }
}

export class PayNowController implements ng.IComponentController {
  public claim: IClaim | IHealthcareClaimDetails;
  public claimsInfoPacket: IClaimsInfoPacket;
  public modalTitle: string;
  public paymentMessage: IMessageEventData;
  public rallyPayToken: string;
  public request: Observable<IClaimsInfoPacket>;
  public sendToUrl: string;
  public toPayAmount: number;

   constructor(
    private $state: ng.ui.IStateService,
    private $stateParams: IGetPayNowParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private claimsService: IClaimsService,
    private ledgerService: ILedgerService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.PAY_NOW_MODAL);

    const claimAndProfile$ = this.userService.getHeartbeat()
       .let(this.profileService.toProfile())
       .map(rsp => rsp.data)
       .flatMap(profile => this.claimsService.getMatchingClaim(profile, this.$stateParams), (profile, claim) => ({
         profile,
         claim,
       }))
       .do(({claim}) => {
         this.claim = claim;
         this.modalTitle = this.getModalTitle(claim.claimType);
         this.toPayAmount = ClaimsService.getYouMayOweAmount(claim);
       });

    const accounts$ = this.userService.getHeartbeat()
       .let(this.profileService.toProfile())
       .map(rsp => rsp.data)
       .flatMap(({rallyId}) => this.ledgerService.getAccounts(rallyId))
       .map(rsp => rsp.data);

    const profileInfoForRallyPay$ = this.userService.getHeartbeat()
       .let(this.profileService.toProfile())
       .map(rsp => rsp.data)
       .flatMap(({rallyId}) => this.profileService.getProfileInfoForRallyPay(rallyId))
       .map(rsp => rsp.data);

    const token$ = this.userService.getRallyPayToken()
      .map(rsp => rsp.data.token)
      .do(token => this.rallyPayToken = token);

    this.request = Observable.zip(claimAndProfile$, accounts$, profileInfoForRallyPay$, token$)
      .flatMap(([{claim, profile}, accounts, {encryptedProfileInfo}]) => {
        const {rallyId, currentUser: { membershipCategory }} = profile;

        const hsaAccount: ILedgerAccount = accounts && accounts.find(act => act.accountType === AccountType.HSA);
        // At this point, we know the user has an HSA, but in case the call fails for some reason
        const encryptedAccountInfo = hsaAccount && hsaAccount.rallypayLedgerData;

        return this.claimsService
          .getRallyPayClaimDetails(
            rallyId, claim.claimKey, claim.claimType, encryptedAccountInfo, encryptedProfileInfo, membershipCategory,
          );
      })
      .map(rsp => rsp.data)
      .do(claimsInfoPacket => this.claimsInfoPacket = claimsInfoPacket);
  }

   public $onInit(): void {
    this.request.subscribe(() => undefined, console.warn);
    const { filters, claimPayKey, fromStateName } = this.$stateParams;
    this.sendToUrl = this.generateSendToUrl(filters, claimPayKey, fromStateName);
  }

  public onSubmit = (message: IMessageEventData): void => {
     // TODO: use this to drive the logic for success/error in arcade
     this.paymentMessage = message;
  }

  private generateSendToUrl(stateFilters: string, claimPayKey: string, fromStateName: string): string {
    const initialUrl = this.resourceService.get(instamedPayNow);

    // Remove filters and store them in session storage because of the Instamed requirements that the RETURN_URL cannot be "too" long
    let params;
    if (stateFilters) {
      params = {filters: undefined, useSessionFilters: true};
      this.$window.sessionStorage.setItem('arcade.claim.filters', stateFilters);
    }

    const filterlessCurrentUrl = this.$state.href(fromStateName, params);
    const returnStateUrl = this.$state.href('authenticated.claimsAndAccounts.claimPaid', {goTo: filterlessCurrentUrl});
    const returnUrl = parse(returnStateUrl).href;
    return `${initialUrl}${claimPayKey}&RETURN_URL=${encodeURIComponent(returnUrl)}`;
  }

  private getModalTitle(claimType: ClaimType): string {
    switch (claimType) {
      case ClaimType.Medical:
        return 'MEDICAL_CLAIM_NUMBER';
      case ClaimType.Dental:
        return 'DENTAL_CLAIM_NUMBER';
      case ClaimType.Rx:
        return 'RX_CLAIM_NUMBER';
      default:
      return 'CLAIM_CLAIM_NUMBER';
    }
  }
}
