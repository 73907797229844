import { activateStart } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import singleStepBannerTemplate from 'views/dashboard/single-step-banner.html';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';
import { ISingleStep } from './single-step-container.component';

export class SingleStepBannerComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;
  public bindings = {
    step: '<',
  };

  constructor() {
    this.controller = SingleStepBannerController;
    this.templateUrl = singleStepBannerTemplate;
  }
}

export class SingleStepBannerController {
  public activateResourceLink: IResource;
  public toActivateHref: string;
  public step: ISingleStep;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private resourceService: IResourceService,
    private $window: ng.IWindowService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ONBOARDING);

    this.activateResourceLink = activateStart;
  }

  public toActivate(): void {
    this.$window.location.href = this.resourceService.get(this.activateResourceLink) + `&stepId=${this.step.stepName}&isModal=true`;
  }
}
