import contactUsTemplate from 'views/help/contact-us.html';
import { ContactUsController } from './contact-us.controller';

export class ContactUsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = ContactUsController;
    this.templateUrl = contactUsTemplate;
  }
}
