// matching the order from arcade-ui-chrome ButtonAction:
// tslint:disable-next-line:max-line-length
// https://github.com/AudaxHealthInc/arcade-ui-chrome/blob/af0754b81569d84475ca88175d578d49657e975b/packages/components/src/footer/links/link-data.ts#L24
export enum ISharedHeaderFooterButtonAction {
  openIPerceptionsSurvey,
  localeChanged,
  toggleNav,
}

export interface ISharedHeaderFooterActionEventProps {
  locale?: string;
  event?: MouseEvent;
  isNavOpen?: boolean;
}

export interface ISharedHeaderFooterActionEvent extends Event {
  detail: {
    action: ISharedHeaderFooterButtonAction;
    props: ISharedHeaderFooterActionEventProps;
  };
}

export interface ISharedHeaderFooterTrackingEvent extends Event {
  detail: {
    event: MouseEvent;
    features: string[];
    track: string;
    href?: string;
  };
}

export interface ISharedHeaderFooterEventsController {
  addSharedHeaderFooterListeners(sharedHeaderOrFooterElement: Element): void;
}
