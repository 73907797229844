import { IPopulationService } from '../../util/population/population.service';

export class ShowForLobDirective implements ng.IDirective {
  public restrict = 'A';
  public priority = 100;
  public scope = false;
  public transclude = 'element' as 'element';

  constructor(private $animate: ng.animate.IAnimateService, private populationService: IPopulationService) {}

  public link = (scope, element, attrs, ctrl, transclude) => {
    const showForlinesOfBusiness = attrs.showForLob.replace(/ /g, '').split(',');
    const pop = this.populationService.getPopulation();
    const lineOfBusiness = pop && pop.lineOfBusiness;

    if (showForlinesOfBusiness.indexOf(lineOfBusiness) > -1) {
      transclude(clone => this.$animate.enter(clone, element.parent(), element));
    }
  }

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($animate: ng.animate.IAnimateService, populationService: IPopulationService) => {
      'ngInject';
      return new ShowForLobDirective($animate, populationService);
    };
    return directive;
  }
}
