import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import withClickTracking, { Feature } from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Anchor } from 'scripts/ui/anchor/anchor';
import CONFIG from 'scripts/util/constants/config';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

export function MrRxSpendingCostSummary(): ReactElement {
  const TrackedResourceAnchor = withClickTracking(Anchor, 'view-details');
  const link = CONFIG.ARCADE_WEB_OPTUM_RX_SPENDING_AND_COST_SUMMARY;
  const { t } = useTranslation([Dictionary.COMMON, Dictionary.MR_RX_SPENDING_COST_SUMMARY]);
  return (
    <Feature featureId="mr-rx-spending-cost-summary">
      <div className="mr-rx-spending-cost-summary container">
        <header className="section-header">
          <h2 className="mr-rx-spending-cost-summary-title">
            {t(`${Dictionary.MR_RX_SPENDING_COST_SUMMARY}:MR_RX_SPENDING_COST_SUMMARY_HEADER`)}
          </h2>
          <p>{t(`${Dictionary.MR_RX_SPENDING_COST_SUMMARY}:MR_RX_SPENDING_COST_SUMMARY_BODY`)}</p>
        </header>
        <div className="mr-rx-spending-cost-summary-link">
            <TrackedResourceAnchor href={link} target="_blank">
              {t(`${Dictionary.MR_RX_SPENDING_COST_SUMMARY}:MR_RX_SPENDING_COST_SUMMARY_LINK`)}
              <i className="icon-external-link" aria-label={t(`${Dictionary.COMMON}:OPENS_IN_NEW_WINDOW`)} />
            </TrackedResourceAnchor>
        </div>
      </div>
    </Feature>
  );
}
