import React, { Component, ReactElement } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IClaim } from 'scripts/api/claims/claims.interfaces';
import { LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import { withWaitFor } from 'scripts/hoc/with-wait-for/with-wait-for';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectSummaryData, selectSummaryError, selectSummaryLoading } from 'scripts/selectors/claims-service-selectors';
import { selectLanguage } from 'scripts/selectors/locale-selectors';
import { selectLineOfBusiness } from 'scripts/selectors/population-selectors';
import { selectedUser } from 'scripts/selectors/profile-service-selectors';
import { getSummary } from 'scripts/thunks/claims-service-thunks';
import { ResourceAnchor } from 'scripts/ui/anchor/resource-anchor';
import { IResponsiveTableData, ResponsiveTable } from 'scripts/ui/responsive-table/responsive-table';
import CONFIG from 'scripts/util/constants/config';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import FeatureFlagService from 'scripts/util/feature-flag/feature-flag';
import { LocaleLanguage } from 'scripts/util/locale/locale.interfaces';
import { getMoneyValue } from 'scripts/util/money/money';
import { benefitsCoverage } from 'scripts/util/resource/resource.constants';
import withClickTracking, { Feature } from '../../hoc/with-click-tracking/with-click-tracking';

/**
 * This is a temporary test component that is just being used as a proof-of-concept that we can have React code
 * running in AngularJS. It is FF off in all environments but local and Dev. This will be removed when we
 * migrate our first AngularJS component to React.
 */

export interface IReactTestProps extends WithTranslation {
  dependentSeqNum: string;
  error: boolean;
  firstName: string;
  getSummary: () => void;
  language: LocaleLanguage;
  lastName: string;
  lineOfBusiness: LineOfBusiness;
  loading: boolean;
  summary: IClaim[];
}

const FeatureWithWaitFor = withWaitFor(Feature);

export class RawReactTest extends Component<IReactTestProps> {
  public async componentDidMount(): Promise<void> {
    if (!this.props.summary) {
      await this.props.getSummary();
    }
  }

  public async componentDidUpdate(prevProps: IReactTestProps): Promise<void> {
    if (this.props.dependentSeqNum !== prevProps.dependentSeqNum && !this.props.summary) {
      await this.props.getSummary();
    }
  }

  public handleRetry = () => {
    this.props.getSummary();
  }

  public render(): ReactElement<IReactTestProps> {
    const { error, firstName, lastName, language, lineOfBusiness, loading, summary, t } = this.props;
    const TrackedResourceAnchor = withClickTracking(ResourceAnchor, 'benefits-test-link');
    // tslint:disable-next-line:no-console
    const clickHandler = () => console.log('benefits test link clicked.');
    const tableData: IResponsiveTableData = {
      headers: [
        'ENVIRONMENT',
        'AUTO PAYMENT FF',
        'NAME',
        'LANGUAGE',
        'LOB',
        'LINK',
        'HOC TRANSLATION',
        'HOOKS TRANSLATION',
        'ADDITIONAL NAMESPACE TRANSLATION',
        'RECENT CLAIMS TOTAL',
      ],
      rows: [{
        columns: [
          CONFIG.ARCADE_WEB_ENVIRONMENT_NAME,
          FeatureFlagService.isAutoPaymentOn().toString(),
          firstName ? `${firstName} ${lastName}` : 'well, I\'m not sure yet',
          language,
          lineOfBusiness,
          (<TrackedResourceAnchor resourceValue={benefitsCoverage} onClick={clickHandler}>Benefits</TrackedResourceAnchor>),
          t('NAME'),
          'NAME',
          t(`${Dictionary.COSTS}:DOCTOR_VISITS`),
          summary ? this.getClaimsTotal(summary) : '¯\\_(ツ)_/¯',
        ],
      }],
    };
    return (
      <Feature featureId="react-test">
        <FeatureWithWaitFor
          classes={['container']}
          error={error}
          featureId="react-test-inner"
          loading={loading}
          onRetry={this.handleRetry}
        >
          <div className="hide-mobile">
            <Board pixelSize={30} />
          </div>
          <div className="hide-desktop">
            <Board pixelSize={10} />
          </div>
          <ResponsiveTable tableData={tableData} />
        </FeatureWithWaitFor>
      </Feature>
    );
  }
  private getClaimsTotal(claims: IClaim[]): string {
    const total = claims.map(claim => claim.balance.totalBilledAmount.value).reduce((curr, acc) => acc += curr, 0);
    return getMoneyValue(total);
  }
}

const RawReactTestWithTranslations = withTranslation([Dictionary.COMMON, Dictionary.COSTS])(RawReactTest);

export default withProvider(connect(
  (state: IReduxState) => ({
    dependentSeqNum: selectedUser.selectDependentSeqNum(state),
    firstName: selectedUser.selectFirstName(state),
    language: selectLanguage(state),
    lastName: selectedUser.selectLastName(state),
    lineOfBusiness: selectLineOfBusiness(state),
    summary: selectSummaryData(state),
    loading: selectSummaryLoading(state),
    error: selectSummaryError(state),
  }), {
    getSummary,
  })(RawReactTestWithTranslations));

/**
 * The rest of this file is just for the fun arcade themed visual
 */

const LetterSize = 5;
const NumberOfLetters = 6;
const Padding = 1;

interface IBoardProps {
  pixelSize: number;
}

interface IBoardState {
  word?: ILetterPixel[];
  eater?: IPosition;
}

interface IPosition {
  x: number;
  y: number;
  pixelSize?: number;
}

interface ILetterPixel extends IPosition {
  active: boolean;
}

class Board extends Component<IBoardProps, IBoardState> {
  public interval;

  constructor(props: IBoardProps) {
    super(props);
    const { pixelSize } = this.props;
    this.state = {
      word: getWord({x: (Padding * pixelSize), y: (Padding * pixelSize), pixelSize}),
    };
  }

  public componentDidMount(): void {
    this.interval = setInterval(() => this.turn(), 1000);
  }

  public componentWillUnmount(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  public getBoardStyle(): object {
    const { pixelSize } = this.props;
    const height = pixelSize * LetterSize + (Padding * 2 * pixelSize);
    const width = pixelSize * LetterSize * NumberOfLetters + (Padding * pixelSize);
    return {
      height: `${height}px`,
      margin: 'auto',
      position: 'relative',
      width: `${width}px`,
    };
  }

  public getWord(pixel: ILetterPixel, pixelSize: number): ReactElement<undefined> {
    return <WordPixel x={pixel.x} y={pixel.y} active={pixel.active} pixelSize={pixelSize} key={`${pixel.x},${pixel.y}`}/>;
  }

  public render(): ReactElement<{}> {
    const { word, eater } = this.state;
    const { pixelSize } = this.props;
    return (
      <div style={this.getBoardStyle()}>
        {word && word.map(pixel => this.getWord(pixel, pixelSize))}
        {eater && <Eater x={eater.x} y={eater.y} pixelSize={pixelSize} />}
      </div>
    );
  }

  private turn(): void {
    const { pixelSize } = this.props;
    if (this.state.word) {
      const randomX = Math.floor(Math.random() * (LetterSize * NumberOfLetters + (Padding)));
      const randomY = Math.floor(Math.random() * (LetterSize + (Padding * 2)));
      this.setState({
        eater: {x: (randomX * pixelSize), y: (randomY * pixelSize)},
      });
      let found = false;
      const newWord = this.state.word.slice();
      newWord.forEach(pixel => {
        if (!found && pixel.active && pixel.x === (randomX * pixelSize) && pixel.y === (randomY * pixelSize)) {
          pixel.active = false;
          found = true;
        }
      });
      if (found) {
        this.setState({
          word: newWord,
        });
      }
    }
  }
}

function WordPixel(props: ILetterPixel): ReactElement<ILetterPixel> {
  const { x, y, active, pixelSize } = props;
  const style = {
    backgroundColor: active ? '#202020' : 'transparent',
    height: `${pixelSize}px`,
    left: `${x}px`,
    position: 'absolute' as 'absolute',
    top: `${y}px`,
    width: `${pixelSize}px`,
  };
  return (<div style={style} />);
}

function Eater(props: IPosition): ReactElement<IPosition> {
  const { x, y, pixelSize } = props;
  const style = {
    fontSize: `${pixelSize}px`,
    height: `${pixelSize}px`,
    left: `${x}px`,
    position: 'absolute' as 'absolute',
    top: `${y}px`,
    width: `${pixelSize}px`,
  };
  return (<div style={style}>👾</div>);
}

export function getWord(start: IPosition): ILetterPixel[] {
  const { x: startX, y: startY, pixelSize } = start;
  const word: ILetterPixel[] = [];
  word.push(...getA({x: startX + (0 * LetterSize * pixelSize), y: startY}, pixelSize));
  word.push(...getR({x: startX + (1 * LetterSize * pixelSize), y: startY}, pixelSize));
  word.push(...getC({x: startX + (2 * LetterSize * pixelSize), y: startY}, pixelSize));
  word.push(...getA({x: startX + (3 * LetterSize * pixelSize), y: startY}, pixelSize));
  word.push(...getD({x: startX + (4 * LetterSize * pixelSize), y: startY}, pixelSize));
  word.push(...getE({x: startX + (5 * LetterSize * pixelSize), y: startY}, pixelSize));
  return word;
}

function getA(start: IPosition, size: number): ILetterPixel[] {
  const { x: startX, y: startY } = start;
  return [
    {x: startX + (0 * size), y: startY + (1 * size), active: true},
    {x: startX + (0 * size), y: startY + (2 * size), active: true},
    {x: startX + (0 * size), y: startY + (3 * size), active: true},
    {x: startX + (0 * size), y: startY + (4 * size), active: true},
    {x: startX + (1 * size), y: startY + (0 * size), active: true},
    {x: startX + (1 * size), y: startY + (3 * size), active: true},
    {x: startX + (2 * size), y: startY + (0 * size), active: true},
    {x: startX + (2 * size), y: startY + (3 * size), active: true},
    {x: startX + (3 * size), y: startY + (1 * size), active: true},
    {x: startX + (3 * size), y: startY + (2 * size), active: true},
    {x: startX + (3 * size), y: startY + (3 * size), active: true},
    {x: startX + (3 * size), y: startY + (4 * size), active: true},
  ];
}

function getR(start: IPosition, size: number): ILetterPixel[] {
  const { x: startX, y: startY } = start;
  return [
    {x: startX + (0 * size), y: startY + (0 * size), active: true},
    {x: startX + (0 * size), y: startY + (1 * size), active: true},
    {x: startX + (0 * size), y: startY + (2 * size), active: true},
    {x: startX + (0 * size), y: startY + (3 * size), active: true},
    {x: startX + (0 * size), y: startY + (4 * size), active: true},
    {x: startX + (1 * size), y: startY + (0 * size), active: true},
    {x: startX + (1 * size), y: startY + (3 * size), active: true},
    {x: startX + (2 * size), y: startY + (0 * size), active: true},
    {x: startX + (2 * size), y: startY + (3 * size), active: true},
    {x: startX + (3 * size), y: startY + (1 * size), active: true},
    {x: startX + (3 * size), y: startY + (2 * size), active: true},
    {x: startX + (3 * size), y: startY + (4 * size), active: true},
  ];
}

function getC(start: IPosition, size: number): ILetterPixel[] {
  const { x: startX, y: startY } = start;
  return [
    {x: startX + (0 * size), y: startY + (1 * size), active: true},
    {x: startX + (0 * size), y: startY + (2 * size), active: true},
    {x: startX + (0 * size), y: startY + (3 * size), active: true},
    {x: startX + (1 * size), y: startY + (0 * size), active: true},
    {x: startX + (1 * size), y: startY + (4 * size), active: true},
    {x: startX + (2 * size), y: startY + (0 * size), active: true},
    {x: startX + (2 * size), y: startY + (4 * size), active: true},
    {x: startX + (3 * size), y: startY + (1 * size), active: true},
    {x: startX + (3 * size), y: startY + (3 * size), active: true},
  ];
}

function getD(start: IPosition, size: number): ILetterPixel[] {
  const { x: startX, y: startY } = start;
  return [
    {x: startX + (0 * size), y: startY + (0 * size), active: true},
    {x: startX + (0 * size), y: startY + (1 * size), active: true},
    {x: startX + (0 * size), y: startY + (2 * size), active: true},
    {x: startX + (0 * size), y: startY + (3 * size), active: true},
    {x: startX + (0 * size), y: startY + (4 * size), active: true},
    {x: startX + (1 * size), y: startY + (0 * size), active: true},
    {x: startX + (1 * size), y: startY + (4 * size), active: true},
    {x: startX + (2 * size), y: startY + (0 * size), active: true},
    {x: startX + (2 * size), y: startY + (4 * size), active: true},
    {x: startX + (3 * size), y: startY + (1 * size), active: true},
    {x: startX + (3 * size), y: startY + (2 * size), active: true},
    {x: startX + (3 * size), y: startY + (3 * size), active: true},
  ];
}

function getE(start: IPosition, size: number): ILetterPixel[] {
  const { x: startX, y: startY } = start;
  return [
    {x: startX + (0 * size), y: startY + (0 * size), active: true},
    {x: startX + (0 * size), y: startY + (1 * size), active: true},
    {x: startX + (0 * size), y: startY + (2 * size), active: true},
    {x: startX + (0 * size), y: startY + (3 * size), active: true},
    {x: startX + (0 * size), y: startY + (4 * size), active: true},
    {x: startX + (1 * size), y: startY + (0 * size), active: true},
    {x: startX + (1 * size), y: startY + (2 * size), active: true},
    {x: startX + (1 * size), y: startY + (4 * size), active: true},
    {x: startX + (2 * size), y: startY + (0 * size), active: true},
    {x: startX + (2 * size), y: startY + (2 * size), active: true},
    {x: startX + (2 * size), y: startY + (4 * size), active: true},
    {x: startX + (3 * size), y: startY + (0 * size), active: true},
    {x: startX + (3 * size), y: startY + (4 * size), active: true},
  ];
}
