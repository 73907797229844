import CONFIG from 'scripts/util/constants/config';

export const openSurvey = (): void => {
  const surveyId = CONFIG.ARCADE_WEB_IPERCEPTIONS_SURVEY_ID;
  const iperceptions = `iperceptions_${surveyId}`;

  if (window[iperceptions] && typeof window[iperceptions].ipeCC === 'function') {
    const config = {
      langID: 1,
      surveyID: parseInt(surveyId, 10),
    };

    window[iperceptions].ipeCC(config);
  } else {
    console.warn('The iPerceptions feedback survey could not be opened.');
  }
};
