import costInfoTemplate from 'views/modals/cost-info.html';
import { CostInfoController } from './cost-info.controller';

export class CostInfoComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = CostInfoController;
    this.templateUrl = costInfoTemplate;
  }
}
