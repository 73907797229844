import { Dictionary } from '../../../util/constants/i18n.constants';

export class AccountSummaryExplanationController {

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);
  }
}
