import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { IProfileResponse, IProfileUser } from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IModalController } from '../../../ui/modal/modal.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';

export class AccountSelectorController implements ng.IComponentController {
  public profileRequest: Observable<IProfileResponse>;
  public currentUser: IProfileUser;
  public dependents: IProfileUser[];
  public selectedId: string;
  public $modal: IModalController;
  private profileSubscription: Subscription;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    public profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SELECTOR);

    this.profileRequest = this.userService.getHeartbeat().let(this.profileService.toProfile());
  }

  public $onInit(): void {
    this.profileRequest.subscribe(rsp => {
      this.currentUser = rsp.data.currentUser;
      this.dependents = rsp.data.dependents;
    }, console.warn);

    this.userService.getHeartbeat().let(this.profileService.toCurrentProfile()).subscribe(rsp => {
      this.selectedId = rsp.dependentSeqNum;
    }, console.warn);

    this.profileSubscription = this.profileService.profileChanged.subscribe(profile => {
      this.selectedId = profile.dependentSeqNum;
    });
  }

  public $onDestroy(): void {
    this.profileSubscription.unsubscribe();
  }

  public setAccount(account: IProfileUser): void {
    this.profileService.setCurrentProfile(account);
    this.$modal.close();
  }

  public setAccountViaKeyboard(account: IProfileUser, event: KeyboardEvent): void {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.setAccount(account);
    }
  }
}
