export enum PieChartColor {
  black = 'black',
  blue = 'blue',
  green = 'green',
  red = 'red',
  teal = 'teal',
}

export interface IPieChartData {
  border?: PieChartColor | undefined;
  rotation?: number;
  slices: IPieChartSlice[];
}

export interface IPieChartSlice {
  color?: PieChartColor;
  percent: number;
}

export interface IPieChartSegment {
  shape: string;
  color?: PieChartColor;
}
