import { getLocale } from 'scripts/util/locale/locale';
import { LocaleLanguage } from 'scripts/util/locale/locale.interfaces';
import { IBaseUrls, IPopulation } from 'scripts/util/population/population.interfaces';
import { IResource, IResourceLinkParameters } from './resource.interfaces';

export function getResource(resource: IResource, population: IPopulation): string {
  if (typeof resource === 'object') {
    const lineOfBusiness = population && population.lineOfBusiness;
    resource = resource[lineOfBusiness] ? resource[lineOfBusiness] : resource.default;
  }
  if (typeof resource === 'string') {
    return resource;
  }
  if (typeof resource === 'function') {
    const params = getResourceParams(population);
    return resource(params);
  }
  console.error('Invalid resource:', resource);
  return '';
}

function getResourceParams({ baseUrls }: IPopulation): IResourceLinkParameters {
  const locale = getLocale();
  const myUhcLegacyBaseUrl = getBaseUrlWithLanguage(baseUrls, locale.language);
  return { ...baseUrls, ...locale, myUhcLegacyBaseUrl };
}

function getBaseUrlWithLanguage(baseUrls: IBaseUrls, language: string): string {
  switch (language) {
    case LocaleLanguage.Spanish:
      return baseUrls.myUhcLegacyEsBaseUrl;
    case LocaleLanguage.Ilocano:
    case 'ilo':
      return baseUrls.myUhcLegacyIlBaseUrl;
    case LocaleLanguage.ChineseTraditional:
      return baseUrls.myUhcLegacyZhBaseUrl;
    case LocaleLanguage.Vietnamese:
      return baseUrls.myUhcLegacyViBaseUrl;
    case LocaleLanguage.Korean:
    case 'kr':
      return baseUrls.myUhcLegacyKoBaseUrl;
    case LocaleLanguage.HaitianCreole:
      return baseUrls.myUhcLegacyHtBaseUrl;
    case LocaleLanguage.Tagalog:
    case 'tag':
      return baseUrls.myUhcLegacyTlBaseUrl;
    default:
      return baseUrls.myUhcLegacyEnBaseUrl;
  }
}
