import classNames from 'classnames';
import React, { AnchorHTMLAttributes, ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import withClickTracking, { Feature } from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { LoadingDots } from 'scripts/ui/loading-dots/loading-dots';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

export interface IWaitForProps {
  classes?: string[];
  error: boolean | (() => boolean);
  errorMessage?: string;
  loading: boolean | (() => boolean);
  onRetry: () => void;
}

export function withWaitFor<T>(Component: ComponentType<T>): ComponentType<T & IWaitForProps> {
  const Button = (props: AnchorHTMLAttributes<HTMLButtonElement>) => <button {...props} />;
  const TryAgainButton = withClickTracking(Button, 'try-again');
  return props => {
    const { classes, error, errorMessage = 'WAIT_FOR_FAILURE_MESSAGE', loading, onRetry, ...rest } = props;
    const { t } = useTranslation(Dictionary.ERRORS);
    const isLoading = typeof loading === 'function' ? loading() : loading;
    const isError = typeof error === 'function' ? error() : error;
    if (isLoading) {
      return (
        <div className={classNames(classes)}>
          <LoadingDots />
        </div>
      );
    }
    if (isError) {
      return (
        <Feature featureId="request-failure">
          <div className={classNames('fade-in', 'wait-for-failure', classes)} data-testid="wait-for-failure-container" >
            <p>{t(errorMessage)}</p>
            <TryAgainButton className="wait-for-failure-retry-btn" onClick={onRetry} >
              {t('TRY_AGAIN')}
            </TryAgainButton>
          </div>
        </Feature>
      );
    }
    return (
      <div className={classNames('fade-in', classes)}>
        <Component {...rest as T} />
      </div>
    );
  };
}
