import { Dictionary } from '../../util/constants/i18n.constants';
import { INTERVAL } from './carousel.constants';
import { ICarouselController, ISlide, ISlideScope } from './carousel.interfaces';

export class CarouselController implements ICarouselController {
  public remoteCtrl: ICarouselController;
  public slides: ISlide[];
  public active: number;
  public autoPlay: boolean;
  public slideMargin: number;
  public isPlaying: boolean;
  public prevSlide: number;
  public nextSlide: number;

  private currentInterval: any;
  private destroyed: boolean;
  constructor(
    private $animate: ng.animate.IAnimateService,
    private $interval: ng.IIntervalService,
    private $scope: ng.IScope,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);

    // Public & available to the template
    this.remoteCtrl = this;
    this.slides = this.slides || [];
    this.active = this.active || 0;
    this.prevSlide = this.prevSlide || null;
    this.nextSlide = this.nextSlide || null;
    this.autoPlay = this.autoPlay === undefined ? true : this.autoPlay;
    this.slideMargin = this.slideMargin || 15;

    // Private
    this.currentInterval = null;
    this.isPlaying = true;
    this.destroyed = false;

    $scope.$on('$destroy', () => {
      this.destroyed = true;
    });

    this.restartTimer();
  }

  public next(): void {
    const index = (this.active + 1) % this.slides.length;
    this.showSlide(this.slides[index], 'next');
  }

  public prev(): void {
    const index = (this.active - 1 < 0) ? this.slides.length - 1 : this.active - 1;
    this.showSlide(this.slides[index], 'prev');
  }

  public select(slide: ISlide): void {
    if (slide.scope.index !== this.active) {
      this.showSlide(slide);
    }
  }

  public pause(): void {
    this.isPlaying = false;
    this.resetTimer();
  }

  public play(): void {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.restartTimer();
    }
  }

  public addSlide(scope: ISlideScope, element: ng.IAugmentedJQuery): void {
    this.slides.push({scope, element});
    if (scope.index === this.active) {
      this.setActive(scope.index);
    }
  }

  public clearSlides(): void {
    this.slides = [];
  }

  public isActive(slide: ISlide): boolean {
    return this.active === slide.scope.index;
  }

  public getMobileSlidePosition(): string {
    const index = this.active;
    const offset = this.slideMargin * 3 * index;
    return index === 0 ? '0px' : 'calc(' + this.active * (-100) + 'vw' + ' + ' + offset + 'px)';
  }

  public setPrevSlide(): void {
    const total = this.slides.length;
    this.prevSlide = this.active === 0 ? total : this.active;
  }

  public setNextSlide(): void {
    const total = this.slides.length;
    this.nextSlide = (this.active + 2) > total ? 1 : this.active + 2;
  }

  private setActive(index: number): void {
    this.active = index;
    for (let i = 0; i < this.slides.length; i++) {
      this.slides[i].scope.active = i === index;
    }
  }

  private resetTimer(): void {
    if (this.currentInterval) {
      this.$interval.cancel(this.currentInterval);
      this.currentInterval = null;
    }
  }

  private restartTimer(): void {
    const $carousel = this;

    if (!$carousel.autoPlay) {
      return;
    }

    this.resetTimer();
    this.currentInterval = this.$interval((): void => {
      if ($carousel.isPlaying && $carousel.slides.length > 1) {
        $carousel.next();
      } else {
        $carousel.pause();
      }
    }, INTERVAL);
  }

  private showSlide(slide: ISlide, direction?: string): void {
    if (this.destroyed || slide.scope.index === this.active) {
      return;
    }

    if (!direction && slide.scope.index > this.active || direction === 'next') {
      this.$animate.addClass(slide.element, 'animate-left');
      this.$animate.removeClass(slide.element, 'animate-right');
    } else if (!direction && slide.scope.index < this.active || direction === 'prev') {
      this.$animate.addClass(slide.element, 'animate-right');
      this.$animate.removeClass(slide.element, 'animate-left');
    }

    this.setActive(slide.scope.index);
    this.setPrevSlide();
    this.setNextSlide();
    this.restartTimer();
  }
}
