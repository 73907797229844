import accountSelectorTemplate from 'views/modals/account-selector.html';
import { AccountSelectorController } from './account-selector.controller';

export class AccountSelectorComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = AccountSelectorController;
    this.templateUrl = accountSelectorTemplate;
  }
}
