import angular from 'angular';
import { Observable } from 'rxjs/Observable';
import { IBenefitExplanation } from '../../../api/plans/plans.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import {
  AccountSummaryService,
  IAccountSummaryService,
} from '../../dashboard/account-summary/account-summary-explanation.service';

export class AccountSummaryExplanationController implements ng.IComponentController {

  public customExplanation: IBenefitExplanation[];
  public deductibleLinkLabel: string;
  public oopMaxLinkLabel: string;

  constructor(private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
              private accountSummaryService: IAccountSummaryService) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);
  }

  public $onInit(): void {
    Observable
      .zip(this.accountSummaryService.getAccountSummaryExplanations(), this.accountSummaryService.getHeaderWithLabelsTranslated())
      .subscribe(([explanations, labelReplacements]) => {
        this.customExplanation = explanations;
        this.deductibleLinkLabel =  labelReplacements[AccountSummaryService.DEDUCTIBLE_HEADER_KEY].customLabel || undefined;
        this.oopMaxLinkLabel =  labelReplacements[AccountSummaryService.OOP_MAX_HEADER_KEY].customLabel || undefined;
      }, console.warn);
  }

}
