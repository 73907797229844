import idCardsTemplate from 'views/modals/id-cards.html';
import { IdCardsController } from './id-cards.controller';

export class IdCardsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = IdCardsController;
    this.templateUrl = idCardsTemplate;
  }
}
