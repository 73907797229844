import 'angular-google-analytics';
import { IEnvironmentConstants } from '../util/constants/environment.interfaces';

export class GoogleAnalytics {
  constructor(
    AnalyticsProvider: angular.google.analytics.AnalyticsProvider,
    Environment: IEnvironmentConstants,
  ) {
    'ngInject';
    AnalyticsProvider
      .setAccount({
        tracker: Environment.CONFIG.ARCADE_WEB_GOOGLE_ANALYTICS_ID,
        trackEvent: true,
        enhancedLinkAttribution: true,
      })
      .logAllCalls(true)
      .trackPages(true)
      .trackUrlParams(true)
      .setPageEvent('$stateChangeSuccess');
  }
}

export class AdobeAnalytics {
  constructor(
    Analytics: angular.google.analytics.AnalyticsService, // Initialize Google Analytics tracking just by injecting Analytics
    Environment: IEnvironmentConstants,
  ) {
    'ngInject';
    const adobeAnalyticsUrl = Environment.CONFIG.ARCADE_WEB_ADOBE_ANALYTICS_URL;

    if (adobeAnalyticsUrl) {
      const d = document;
      const id = 'adobe-analytics';
      const s = 'script';
      let js = d.getElementsByTagName(s)[0];
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s);
      js.id = id;
      js.src = adobeAnalyticsUrl;
      fjs.parentNode.insertBefore(js, fjs);
    }
  }
}
