import angular from 'angular';
import moment = require('moment');
import { object } from 'prop-types';
import { Observable } from 'rxjs/Observable';
import { documentUris } from 'scripts/util/uri/uri';
import { CacheName } from '../api.interfaces';
import { IBaseApiService } from '../api.module';
import { getCache, getCacheKey } from '../cache';
import { ClaimType } from '../claims/claims.interfaces';
import { IHealthcareStatementsResponse } from './documents.interfaces';
import { IClaimLetter, IClaimLettersResponse } from './documents.interfaces';

export interface IDocumentsService {
  getHealthcareStatements(rallyId: string): Observable<IHealthcareStatementsResponse>;
  getHealthcareStatementPdfLink(rallyId: string, documentId: string): string;
  getClaimLetters(rallyId: string): Observable<IClaimLettersResponse>;
  getClaimLetterPdfLink(rallyId: string, claimLetter: IClaimLetter): string;
}

export class DocumentsService implements IDocumentsService {
  constructor(
    private baseApiService: IBaseApiService,
  ) {
    'ngInject';
  }

  public getHealthcareStatements(rallyId: string): Observable<IHealthcareStatementsResponse> {
    const url = documentUris.healthStatementList(rallyId, {claimType: ClaimType.Medical});
    const documentsCache = getCache(CacheName.Documents);
    const cacheKey = getCacheKey(url);
    const cachedData = documentsCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .do(rsp => documentsCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$)
      .map(rsp => {
        const statements = rsp.data.memberHealthStatements;
        for (const s of statements) {
          this.baseApiService.dateStringToMoment(s, 'startDate');
          this.baseApiService.dateStringToMoment(s, 'stopDate');
        }
        return rsp;
      });
  }

  public getHealthcareStatementPdfLink(rallyId: string, documentId: string): string {
    return documentUris.healthStatementPdf(rallyId, documentId);
  }

  public getClaimLetters(rallyId: string): Observable<IClaimLettersResponse> {
    const url = documentUris.claimLettersList(rallyId);
    const documentsCache = getCache(CacheName.Documents);
    const cacheKey = getCacheKey(url);
    const cachedData = documentsCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .do(rsp => documentsCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$)
      .map(rsp => {
        const claimLetters = rsp.data.memberClaimLetter;
        for (const letter of claimLetters) {
          this.baseApiService.dateStringToMoment(letter, 'stopDate');
        }
        return rsp;
      });
  }

  public getClaimLetterPdfLink(rallyId: string, claimLetter: IClaimLetter): string {
    const { documentId, documentVersion, documentSeries, wcagAccessible } = claimLetter;
    return documentUris.claimLetterPdf(rallyId, documentId, { documentVersion, documentSeries, wcagAccessible });
  }
}
