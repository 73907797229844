import { Moment } from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import recentClaimsTemplate from 'views/dashboard/recent-claims.html';
import { CoverageStatus, CoverageType } from '../../../api/api.interfaces';
import { IClaim, IClaimsSummaryResponse } from '../../../api/claims/claims.interfaces';
import { IClaimsService } from '../../../api/claims/claims.service';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IAngularMoment } from '../../../arcade.module.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../../util/feature-flag/feature-flag.interface';

export class RecentClaimsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = RecentClaimsController;
    this.templateUrl = recentClaimsTemplate;
  }
}

export class RecentClaimsController implements ng.IComponentController {
  public allClaimsUrl: string;
  public claims: IClaim[];
  public claimsRequest: Observable<IClaimsSummaryResponse>;
  public isActiveOrTermed: boolean;
  public isAdvantage: boolean;
  public lastUpdated: Moment;
  private profileSubscription: Subscription;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private claimsService: IClaimsService,
    private featureFlagService: IFeatureFlagService,
    private moment: IAngularMoment,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    this.$translatePartialLoader.addPart(Dictionary.RECENT_CLAIMS);
    this.$translatePartialLoader.addPart(Dictionary.COMMON);

    this.lastUpdated = this.moment();
    this.isAdvantage = this.featureFlagService.isAdvantageOn();

    this.claimsRequest = this.userService.getHeartbeat()
      .let(this.profileService.toCurrentProfile())
      .flatMap(profile => this.claimsService.getSummary(profile));
  }

  public $onInit(): void {
    this.initCoverage();
    this.initClaims();

    this.profileSubscription = this.profileService.profileChanged.subscribe(() => {
      this.initCoverage();
      this.initClaims();
    });
  }

  public $onDestroy(): void {
    this.profileSubscription.unsubscribe();
  }

  private initClaims(): void {
    this.claimsRequest.subscribe(rsp => {
      this.lastUpdated = rsp.arcadeDataUpdated || this.moment();
      this.claims = rsp.data.slice(0, 4);
    }, console.warn);
  }

  private initCoverage(): void {
    this.userService.getHeartbeat()
      .let(this.profileService.toCurrentProfile())
      .map(user => this.profileService.getCoverage(CoverageType.Medical, user.planCoverages))
      .subscribe(coverage => {
        if (coverage) {
          this.isActiveOrTermed = coverage.planPeriod.status !== CoverageStatus.Future;
        }
      }, console.warn);
  }
}
