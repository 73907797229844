import { Subscription } from 'rxjs/Subscription';
import { CoverageStatus } from 'scripts/api/api.interfaces';
import { LineOfBusiness, ProgramType } from 'scripts/api/profile/profile.interfaces';
import { IProfileService } from 'scripts/api/profile/profile.service';
import {
  AllProgramsPageCampaigns,
  CampaignCtaType,
  CampaignPlacementType,
  ICampaign,
} from 'scripts/api/targeting/targeting.interfaces';
import { ITargetingService, TargetingService } from 'scripts/api/targeting/targeting.service';
import { IUserService } from 'scripts/api/user/user.service';
import { ICarouselController } from 'scripts/ui/carousel/carousel.interfaces';
import { IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';
import { openSurvey } from 'scripts/util/iperceptions/iperceptions';
import { ILocaleService } from 'scripts/util/locale/locale.service';
import { documentsOverview, paymentsOverview, valueAddedServices } from 'scripts/util/resource/resource.constants';
import { IResourceService } from 'scripts/util/resource/resource.service';
import { getLink } from 'scripts/util/uri/uri';

export class PromosController implements ng.IComponentController {
  public promos: ICampaign[];
  public $carousel: ICarouselController;
  public withIncentives: boolean;
  public SHIPPromos: ICampaign[];
  private isProgramsPage: boolean;
  private localeSubscription: Subscription;

  constructor(
    private $filter: ng.IFilterService,
    private Environment: IEnvironmentConstants,
    private localeService: ILocaleService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    public targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
  }

  public $onInit(): void {
    // need to hardcode mock info for SHIP pre-effective members
    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .subscribe(rsp => {
        const { currentUser } = rsp.data;
        const isMR = currentUser.lineOfBusiness === LineOfBusiness.MR;
        const isSHIPPreEffective = currentUser.planCoverages.every(c => c.planPeriod.status === CoverageStatus.Future) &&
          currentUser.planCoverages.some(c => c.planFeatures.programType === ProgramType.Ship);

        if (isMR && isSHIPPreEffective) {
          this.SHIPPromos = [
            {
              campaignId: 'PlaceHolderValue',
              placementType: CampaignPlacementType.ArcadeCarousel,
              imageUrl: '/images/icons/icon-privacy-promo.svg',
              headline: 'Privacy Authorization',
              body: 'Allow UnitedHealthcare to share info with a person you name.',
              priority: 1002,
              colorPair: {
                background: 'white',
                foreground: 'black',
              },
              cta: {
                ctaType: CampaignCtaType.Url,
                ctaText: 'LEARN MORE',
                ctaValue: this.resourceService.get(documentsOverview),
              },
            },
            {
              campaignId: 'PlaceHolderValue',
              placementType: CampaignPlacementType.ArcadeCarousel,
              imageUrl: '/images/icons/icon-healthline-promo.svg',
              headline: 'Nurse Health line',
              body: 'Call toll-free to speak with a nurse about your health.',
              priority: 1001,
              colorPair: {
                background: 'white',
                foreground: 'black',
              },
              cta: {
                ctaType: CampaignCtaType.Url,
                ctaText: 'LEARN MORE',
                ctaValue: this.resourceService.get(valueAddedServices),
              },
            },
          ];

          if (currentUser.memberFeatures.premiumPaymentEligible &&
            this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_PRE_EFF_PREMIUM_PAYMENTS) {
            this.SHIPPromos.push({
              campaignId: 'PlaceHolderValue',
              placementType: CampaignPlacementType.ArcadeCarousel,
              imageUrl: '/images/icons/icon-eft-promo.svg',
              headline: 'Set Up Payments',
              body:
                'Sign up to pay your premium online.',
              priority: 1000,
              colorPair: {
                background: 'white',
                foreground: 'black',
              },
              cta: {
                ctaType: CampaignCtaType.Url,
                ctaText: 'LEARN MORE',
                ctaValue: this.resourceService.get(paymentsOverview),
              },
            });
          }
          this.promos = this.SHIPPromos;

        } else { // should only call campaigns if hardcoded values are not used
          this.setPromos();
        }
      }, console.warn);

    this.localeSubscription = this.localeService.localeChanged.subscribe(() => {
      this.$carousel.clearSlides();
      this.setPromos();
    });
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public openIperceptionsSurvey(): void {
    openSurvey();
  }

  public getLink(link: string): string {
    return getLink(link);
  }

  public isIncentiveValid(promo: ICampaign): boolean {
    return TargetingService.isIncentiveValid(promo);
  }

  private setPromos(): void {
    const allCampaignsOnPage = this.isProgramsPage ? [AllProgramsPageCampaigns] :
    [CampaignPlacementType.ArcadeDashboardQuickLinks, CampaignPlacementType.ArcadeCarousel];
    const campaignType = this.isProgramsPage ? CampaignPlacementType.ArcadeResourcesGeneralPromo : CampaignPlacementType.ArcadeCarousel;

    this.userService.getHeartbeat().flatMap(rsp => this.targetingService.getCampaigns(rsp.data.rallyId, allCampaignsOnPage))
      .filter(({data}) => !!(data && data.placements))
      .map(({data: {placements}}) => placements)
      .subscribe(campaigns => {
        this.withIncentives = !!campaigns[campaignType] && campaigns[campaignType].some(c =>
          !!(c.metadata && c.metadata.rewardValue)
          && this.isIncentiveValid(c));
        this.promos = this.$filter('orderBy')(
          campaigns[campaignType],
          'priority',
          true,
        );
      }, console.warn);
  }
}
