import ngRedux from 'ng-redux';
import { Observable } from 'rxjs/Observable';
import { changeSelectedProfile } from 'scripts/actions/profile-service-actions';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import { optumPharmacy } from 'scripts/util/resource/resource.constants';
import { profileUris } from 'scripts/util/uri/uri';
import { CacheName, RelationshipType } from '../../api/api.interfaces';
import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { ProfileChanged } from '../../util/constants/event.constants';
import { IResourceService } from '../../util/resource/resource.service';
import { CoverageStatus, CoverageType, CoverageTypeCode, IEmptyResponse, IFullName } from '../api.interfaces';
import { IBaseApiService } from '../api.module';
import { getCache, getCacheKey } from '../cache';
import { IClaim, IHealthcareClaimDetails } from '../claims/claims.interfaces';
import { IHeartbeatResponse } from '../user/user.interfaces';
import {
  IAutoSubmissionUpdatePreferences,
  IClaimsPreferences,
  IClaimsPreferencesResponse,
  ICommunicationPreferences,
  ICoverageInfo,
  IEftPreferences,
  IHealthcareCoveragesResponse,
  IMemberFeatures,
  IPcpPcdEligibility,
  IPlanCoverage,
  IPrimaryCareFpcResponse,
  IPrimaryCareInfo,
  IPrimaryCareResponse,
  IProducts,
  IProductsResponse,
  IProfileInfoForRallyPayResponse,
  IProfileResponse,
  IProfileUser,
  IProviderVideoKeyResponse,
  IReferralsResponse,
  ISundogResponse,
  IUserPreferencesResponse,
  LineOfBusiness,
  MembershipCategory,
  RxCarveOutProvider,
  RxCoverageType,
} from './profile.interfaces';

export interface IProfileService {
  profileChanged: Observable<IProfileUser>;
  get(rallyId: string, bypassCache?: boolean): Observable<IProfileResponse>;
  getActivateProviderVideoKey(profile: IProfileUser): Observable<IProviderVideoKeyResponse>;
  getClaimsPreferences(rallyId: string): Observable<IClaimsPreferencesResponse>;
  getCoverage(coverageType: CoverageType, coverages: IPlanCoverage[]): IPlanCoverage | undefined;
  getCurrentProfile(rallyId: string): Observable<IProfileUser>;
  getDecryptedProviderInfo(rallyId: string, encryptedPayload: string): Observable<ISundogResponse>;
  getFpcPrimaryCare(rallyId: string): Observable<IPrimaryCareFpcResponse>;
  getFullName(user: IFullName): string;
  getHealthcareCoverages(rallyId: string, showCarveoutClaims: boolean): Observable<IHealthcareCoveragesResponse>;
  getPcpPcdEligibility(memberFeatures: IMemberFeatures): IPcpPcdEligibility;
  getPrimaryCare(rallyId: string, isPcdEligible?: boolean): Observable<IPrimaryCareResponse>;
  getProducts(rallyId: string): Observable<IProductsResponse>;
  getProfileInfoForRallyPay(rallyId: string): Observable<IProfileInfoForRallyPayResponse>;
  getProviderVideoKey(profile: IProfileUser, claim: IClaim | IHealthcareClaimDetails): Observable<IProviderVideoKeyResponse>;
  getReferrals(rallyId: string): Observable<IReferralsResponse>;
  getRelationshipTypeText(currentUser: IProfileUser): string | IProfileUser['relationshipType'];
  getRxProvider(coverage: IPlanCoverage): string;
  getRxProviderMap(): {};
  getUserPreferences(rallyId: string): Observable<IUserPreferencesResponse>;
  hasAnActiveCoverageForCoverageType(coverageType: CoverageType, coverages: IPlanCoverage[], includeFuture?: boolean): boolean;
  hasFindCare(currentUser: IProfileUser): boolean;
  hasInstamed(currentUser: IProfileUser): boolean;
  isTermedForCoverageType(coverageType: CoverageType, coverages: IPlanCoverage[]): boolean | undefined;
  setAutoPaymentPreferences(rallyId: string, preferences: IAutoSubmissionUpdatePreferences): Observable<IEmptyResponse>;
  setClaimsPreferences(rallyId: string, preferences: IClaimsPreferences): Observable<IEmptyResponse>;
  setCommunicationPreferences(rallyId: string, preferences: ICommunicationPreferences): Observable<IEmptyResponse>;
  setCurrentProfile(profile: IProfileUser): void;
  setEftPreferences(rallyId: string, preferences: IEftPreferences): Observable<IEmptyResponse>;
  setPrimaryCare(rallyId: string, provider: IPrimaryCareInfo): Observable<IEmptyResponse>;
  toCurrentProfile(): (src: Observable<IHeartbeatResponse>) => Observable<IProfileUser>;
  toCurrentProfileWithHeartbeat(): (src: Observable<IHeartbeatResponse>) =>
    Observable<{heartbeat: IHeartbeatResponse, profile: IProfileUser}>;
  toProfile(): (src: Observable<IHeartbeatResponse>) => Observable<IProfileResponse>;
  toUserPreferences(): (src: Observable<IHeartbeatResponse>) => Observable<IUserPreferencesResponse>;
}

export class ProfileService implements IProfileService {
  public profileChanged: Observable<IProfileUser>;

  constructor(
    private $ngRedux: ngRedux.INgRedux,
    private $q: ng.IQService,
    private $rootScope: ng.IRootScopeService,
    private $state: ng.ui.IStateService,
    private baseApiService: IBaseApiService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private resourceService: IResourceService,
  ) {
    'ngInject';
    getCache(CacheName.ProfilePreferences).removeAll();
    getCache(CacheName.PrimaryCareInformation).removeAll();
    getCache(CacheName.FpcPrimaryCareInformation).removeAll();

    this.profileChanged = (Observable
      .fromEventPattern(h => $rootScope.$on(ProfileChanged, (_, profile) => h(profile)),
        (h, disposer) => disposer()) as Observable<IProfileUser>)
      .distinctUntilChanged()
      .share();
  }

  public get(rallyId: string, bypassCache?: boolean): Observable<IProfileResponse> {
    const url = profileUris.profile(rallyId);
    const cache = getCache(CacheName.Profile);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);

    if (cachedData && !bypassCache) {
      return Observable.of(cachedData);
    }

    return this.baseApiService.get(url)
      .map(rsp => {
        // We need rallyId whenever we set the currently viewed profile, so we set it equal to the rallyId from the
        // profile response if it doesn't exist on the targeted profile.
        rsp.data.currentUser.rallyId = rsp.data.currentUser.rallyId ? rsp.data.currentUser.rallyId : rsp.data.rallyId;
        if (rsp.data.dependents) {
          rsp.data.dependents.forEach(dependent => {
            dependent.rallyId = dependent.rallyId ? dependent.rallyId : rsp.data.rallyId;
          });
        }
        const coverages = rsp.data.currentUser.planCoverages;
        if (!!coverages) {
          rsp.data.currentUser.acos = coverages
            .map(coverage => coverage.additionalCoverageInfo && coverage.additionalCoverageInfo.aco)
            .filter(aco => !!aco);
        }
        this.baseApiService.dateStringToMoment(rsp, 'data', 'currentUser', 'userInfo', 'dob');
        this.baseApiService.dateStringToMoment(rsp, 'data', 'dependents', 'userInfo', 'dob');
        this.baseApiService.dateStringToMoment(rsp, 'data', 'currentUser', 'planCoverages', 'planPeriod', 'startDate');
        this.baseApiService.dateStringToMoment(rsp, 'data', 'currentUser', 'planCoverages', 'planPeriod', 'endDate');
        this.baseApiService.dateStringToMoment(rsp, 'data', 'dependents', 'planCoverages', 'planPeriod', 'startDate');
        this.baseApiService.dateStringToMoment(rsp, 'data', 'dependents', 'planCoverages', 'planPeriod', 'endDate');
        return rsp;
      })
      .do(rsp => cache.put(url, rsp));
  }

  public getProducts(rallyId: string): Observable<IProductsResponse> {
    const url = profileUris.products(rallyId);
    const cache = getCache(CacheName.Profile);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);

    // ARC-6812 we need to use timeStringToMoment when parsing from the API response, but dateStringToMoment when parsing from cache
    const nonCachedSrc$ = this.baseApiService.get(url)
      .map(rsp => {
        const products = rsp.data.products;
        for (const p in products) {
          if (products.hasOwnProperty(p)) {
            this.baseApiService.timeStringToMoment(products[p], 'contactInformation', 'hours', 'startTime');
            this.baseApiService.timeStringToMoment(products[p], 'contactInformation', 'hours', 'endTime');
          }
        }

        return rsp;
      })
      .do(rsp => cache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$)
      .map(rsp => {
        const products = rsp.data.products;
        for (const p in products) {
          if (products.hasOwnProperty(p)) {
            this.baseApiService.dateStringToMoment(products[p], 'contactInformation', 'hours', 'startTime');
            this.baseApiService.dateStringToMoment(products[p], 'contactInformation', 'hours', 'endTime');
          }
        }

        return rsp;
      });
  }

  public getProfileInfoForRallyPay(rallyId: string): Observable<IProfileInfoForRallyPayResponse> {
    const url = profileUris.profileInfoForRallyPay(rallyId);
    const cache = getCache(CacheName.Profile);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .do(rsp => {
        cache.put(url, rsp);
      });

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getCurrentProfile(rallyId: string): Observable<IProfileUser> {
    let selectedProfileDependentSeqNum;
    try {
      selectedProfileDependentSeqNum = sessionStorage.getItem('arcade.selectedProfileDependentSeqNum');
    } catch (e) {
      console.warn('Could not get selected profile dependent sequence number from session storage', e);
    }
    if (selectedProfileDependentSeqNum) {
      return this.get(rallyId).map(rsp => rsp.data).map(({currentUser, dependents}) => {
        return dependents.find(d => d.dependentSeqNum === selectedProfileDependentSeqNum) || currentUser;
      });
    }
    return this.get(rallyId).map(rsp => rsp.data.currentUser);
  }

  public getHealthcareCoverages(rallyId: string, showCarveoutClaims: boolean): Observable<IHealthcareCoveragesResponse> {
    const param = { showCarveoutClaims };
    const url = profileUris.healthcareCoverages(rallyId, param);
    const cache = getCache(CacheName.Profile);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .do(rsp => cache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getFpcPrimaryCare(rallyId: string): Observable<IPrimaryCareFpcResponse> {
    const url = profileUris.fpcPrimaryCare(rallyId);
    const cache = getCache(CacheName.FpcPrimaryCareInformation);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .do(rsp => cache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getPrimaryCare(rallyId: string, isPcdEligible?: boolean): Observable<IPrimaryCareResponse> {
    const param = { isPcdEligible };
    const url = profileUris.primaryCare(rallyId, param);
    const cache = getCache(CacheName.PrimaryCareInformation);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .do(rsp => cache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$)
      .map(rsp => {
        const { perMemberResults } = rsp.data;
        const perMemberResultsKeys = Object.keys(perMemberResults);
        for (const depSeqNum of perMemberResultsKeys) {
          this.baseApiService.dateStringToMoment(rsp, 'data', 'perMemberResults', depSeqNum, 'primaryCarePhysicians', 'effectiveDate');
        }

        return rsp;
      });
  }

  public getDecryptedProviderInfo(rallyId: string, encryptedPayload: string): Observable<ISundogResponse> {
    const param = { payload: encryptedPayload };
    const url = profileUris.decryptProvider(rallyId, param);
    const cache = getCache(CacheName.PrimaryCareInformation);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .do(rsp => cache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public setPrimaryCare(rallyId: string, provider: IPrimaryCareInfo): Observable<IEmptyResponse> {
    const url = profileUris.setPrimaryCare(rallyId);
    const request = this.baseApiService.post(url, provider).do(() => {
      getCache(CacheName.PrimaryCareInformation).removeAll();
    });
    return request;
  }

  public getReferrals(rallyId: string): Observable<IReferralsResponse> {
    const url = profileUris.referrals(rallyId);
    const cache = getCache(CacheName.Profile);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .do(rsp => cache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$)
      .map(rsp => {
        const { perMemberReferrals } = rsp.data;
        const perMemberReferralsKeys = Object.keys(perMemberReferrals);
        for (const depSeqNum of perMemberReferralsKeys) {
          this.baseApiService.dateStringToMoment(
            rsp, 'data', 'perMemberReferrals', depSeqNum, 'referrals', 'effectiveDateRange', 'startDate',
          );
          this.baseApiService.dateStringToMoment(
            rsp, 'data', 'perMemberReferrals', depSeqNum, 'referrals', 'effectiveDateRange', 'endDate',
          );
        }

        return rsp;
      });
  }

  public getUserPreferences(rallyId: string): Observable<IUserPreferencesResponse> {
    const url = profileUris.userPreferences(rallyId);
    const cache = getCache(CacheName.ProfilePreferences);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .do(rsp => cache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$)
      .map(rsp => {
        this.baseApiService.dateStringToMoment(rsp, 'data', 'autoSubmissionPreferences', 'startDate');
        this.baseApiService.dateStringToMoment(rsp, 'data', 'autoSubmissionPreferences', 'stopDate');

        return rsp;
      });
  }

  public setAutoPaymentPreferences(rallyId: string, preferences: IAutoSubmissionUpdatePreferences): Observable<IEmptyResponse> {
    const url = profileUris.autoPaymentPreferences(rallyId);
    const request = this.baseApiService.post(url, preferences).do(() => {
      getCache(CacheName.ProfilePreferences).removeAll();
    });
    return request;
  }

  public setCommunicationPreferences(rallyId: string, preferences: ICommunicationPreferences): Observable<IEmptyResponse> {
    const url = profileUris.paperlessPreferences(rallyId);
    const request = this.baseApiService.post(url, preferences).do(() => {
      getCache(CacheName.ProfilePreferences).removeAll();
    });
    return request;
  }

  public getClaimsPreferences(rallyId: string): Observable<IClaimsPreferencesResponse> {
    const url = profileUris.claimsPreferences(rallyId);
    const cache = getCache(CacheName.ProfilePreferences);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .do(rsp => cache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public setClaimsPreferences(rallyId: string, preferences: IClaimsPreferences): Observable<IEmptyResponse> {
    const url = profileUris.claimsPreferences(rallyId);
    const request = this.baseApiService.post(url, preferences).do(() => {
      getCache(CacheName.ProfilePreferences).removeAll();
    });
    return request;
  }

  public setEftPreferences(rallyId: string, preferences: IEftPreferences): Observable<IEmptyResponse> {
    const url = profileUris.eftPreferences(rallyId);
    const request = this.baseApiService.post(url, preferences).do(() => {
      getCache(CacheName.ProfilePreferences).removeAll();
    });
    return request;
  }

  public getProviderVideoKey(profile: IProfileUser, claim: IClaim | IHealthcareClaimDetails): Observable<IProviderVideoKeyResponse> {
    const params = {
      depSeqNum: profile.dependentSeqNum,
      providerFirstName: claim.providerName,
    };
    const url = profileUris.providerVideoKey(profile.rallyId, params);
    const cache = getCache(CacheName.Profile);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .do(rsp => cache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getActivateProviderVideoKey(profile: IProfileUser): Observable<IProviderVideoKeyResponse> {
    const params = {
      depSeqNum: profile.dependentSeqNum,
    };
    const url = profileUris.activateProviderVideoKey(profile.rallyId, params);
    const cache = getCache(CacheName.Profile);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .do(rsp => cache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public setCurrentProfile(profile: IProfileUser): void {
    sessionStorage.setItem('arcade.selectedProfileDependentSeqNum', profile.dependentSeqNum);

    // Inform the rest of the app of the change to the currently selected profile/account.
    this.$rootScope.$emit(ProfileChanged, profile);
    this.$ngRedux.dispatch(changeSelectedProfile(profile));
  }

  public toProfile(): (src: Observable<IHeartbeatResponse>) => Observable<IProfileResponse> {
    return src => src.flatMap(rsp => this.get(rsp.data.rallyId));
  }

  public toCurrentProfile(): (src: Observable<IHeartbeatResponse>) => Observable<IProfileUser> {
    return src => src.flatMap(rsp => this.getCurrentProfile(rsp.data.rallyId));
  }

  public toCurrentProfileWithHeartbeat(): (src: Observable<IHeartbeatResponse>) =>
    Observable<{heartbeat: IHeartbeatResponse, profile: IProfileUser}> {
    return src => src.flatMap(heartbeat => this.getCurrentProfile(heartbeat.data.rallyId), (heartbeat, profile) => ({
      heartbeat, profile,
    }));
  }

  public toUserPreferences(): (src: Observable<IHeartbeatResponse>) => Observable<IUserPreferencesResponse> {
    return src => src.flatMap(rsp => this.getUserPreferences(rsp.data.rallyId));
  }

  public getCoverage(coverageType: CoverageType, coverages: IPlanCoverage[]): IPlanCoverage | undefined {
    for (const coverage of coverages) {
      if (coverage.coverageType === coverageType) {
        return coverage;
      }
    }
  }

  public getRxProvider(coverage: IPlanCoverage): string {
    if (coverage.coverageType !== CoverageType.Rx || typeof coverage.rxCoverageInfo === 'undefined') {
      return;
    }

    return (coverage.rxCoverageInfo.coverageType === RxCoverageType.CRX) ?
      coverage.rxCoverageInfo.carveOutProvider : RxCarveOutProvider.Optum;
  }

  public getRxProviderMap(): {} {
    const config = this.Environment.CONFIG;
    return {
      [RxCarveOutProvider.Optum]: {
        logo: '/images/branding/optum-rx.svg',
        displayName: 'OptumRx',
        url: this.resourceService.get(optumPharmacy),
      },
      [RxCarveOutProvider.Caremark]: {
        logo: '/images/branding/cvs-caremark.svg',
        displayName: 'CVS Caremark',
        url: config.ARCADE_WEB_CAREMARK_URL,
      },
      [RxCarveOutProvider.ExpressScripts]: {
        logo: '/images/branding/express-scripts.svg',
        displayName: 'Express Scripts',
        url: config.ARCADE_WEB_EXPRESS_SCRIPTS_URL,
      },
      [RxCarveOutProvider.Walgreens]: {
        logo: '/images/branding/walgreens.svg',
        displayName: 'Walgreens',
        url: config.ARCADE_WEB_WALGREENS_URL,
      },
    };
  }

  public getFullName(user: IFullName): string {
    let fullName = '';
    if (user.firstName) {
      fullName = user.firstName + ' ';
    }
    if (user.firstName && user.middleName) {
      fullName += user.middleName + ' ';
    }
    // This is the only required field for a name, as we might send a group plan's name through the last name field.
    fullName += user.lastName;
    if (user.suffix) {
      fullName += ' ' + user.suffix;
    }
    return fullName;
  }

  public getPcpPcdEligibility(memberFeatures: IMemberFeatures): IPcpPcdEligibility {
    return {
      pcp: memberFeatures.pcpEligible,
      pcd: this.featureFlagService.isPcdOn() && memberFeatures.pcdEligible,
    };
  }

  public hasAnActiveCoverageForCoverageType(coverageType: CoverageType, coverages: IPlanCoverage[], includeFuture?: boolean): boolean {
    if (includeFuture) {
      return this.hasActiveCoverageForCoverageType(coverageType, coverages)
        || this.hasAFutureCoverageForCoverageType(coverageType, coverages) ;
    }
    return this.hasActiveCoverageForCoverageType(coverageType, coverages);
  }

  public isTermedForCoverageType(coverageType: CoverageType, coverages: IPlanCoverage[]): boolean | undefined {
    if (coverages.some(c => c.coverageType === coverageType)) {
      return this.getCoverage(coverageType, coverages).planPeriod.status === CoverageStatus.Termed;
    }
  }

  public hasFindCare(currentUser: IProfileUser): boolean {
    if (currentUser.lineOfBusiness === LineOfBusiness.MR) {
      return currentUser.memberFeatures.fpcEligible &&
        currentUser.planCoverages.some(cov => {
          return cov.planPeriod.status !== CoverageStatus.Termed && (cov.coverageTypeCode === CoverageTypeCode.MA ||
            cov.coverageTypeCode === CoverageTypeCode.MAPD || cov.coverageTypeCode === CoverageTypeCode.PDP);
        });
    } else {
      return currentUser.memberFeatures.fpcEligible &&
        (this.hasAnActiveCoverageForCoverageType(CoverageType.Medical, currentUser.planCoverages, true) ||
          this.hasAnActiveCoverageForCoverageType(CoverageType.Rx, currentUser.planCoverages, true));
    }
  }

  public hasInstamed(currentUser: IProfileUser): boolean {
    return currentUser.relationshipType === RelationshipType.Subscriber;
  }

  public getRelationshipTypeText(currentUser: IProfileUser): string | IProfileUser['relationshipType'] {
      if (currentUser.membershipCategory && currentUser.membershipCategory === MembershipCategory.EMPIRE
        && currentUser.relationshipType === RelationshipType.Subscriber) {
        return 'ENROLLEE';
      }
      return currentUser.relationshipType;
  }

  private hasActiveCoverageForCoverageType(coverageType: CoverageType, coverages: IPlanCoverage[]): boolean {
    let ret = false;
    if (coverages) {
      ret = coverages
        .filter(c => c.coverageType === coverageType)
        .some(c => CoverageStatus.Active === c.planPeriod.status);
    }
    return ret;
  }

  private hasAFutureCoverageForCoverageType(coverageType: CoverageType, coverages: IPlanCoverage[]): boolean {
    let ret = false;
    if (coverages) {
      ret = coverages
        .filter(c => c.coverageType === coverageType)
        .some(c => CoverageStatus.Future === c.planPeriod.status);
    }
    return ret;
  }

  public static getCoverageInfo(coverages: IPlanCoverage[], statuses: CoverageStatus[] = [CoverageStatus.Active]): ICoverageInfo {
    const coverageInfo: ICoverageInfo = {
      coverageTypes: {},
      coverageTypeCodes: {},
      numCoverages: 0,
    };
    for (const c of coverages) {
      const hasCoverage = statuses.indexOf(c.planPeriod.status) > -1;
      if (hasCoverage) {
        coverageInfo.coverageTypes[c.coverageType] = true;
        if (c.coverageTypeCode) {
          coverageInfo.coverageTypeCodes[c.coverageTypeCode] = true;
        }
        for (const subCoverageType of c.additionalCoverageTypes) {
          coverageInfo.coverageTypes[subCoverageType] = true;
        }
      }
    }
    coverageInfo.numCoverages = Object.keys(coverageInfo.coverageTypes).length;
    return coverageInfo;
  }

  public static getCoverageTypes(coverages: IPlanCoverage[], statuses: CoverageStatus[] = [CoverageStatus.Active]): CoverageType[] {
    return Object.keys(this.getCoverageInfo(coverages, statuses).coverageTypes) as CoverageType[];
  }

  public static isRxCarveOut(coverage: IPlanCoverage): boolean {
    return coverage.coverageType === CoverageType.Rx && typeof coverage.rxCoverageInfo !== 'undefined' &&
      coverage.rxCoverageInfo.isExternallyManaged;
  }

  public static isValueAddedServiceEligible(products: IProducts): boolean {
    return typeof (products.nurseHealthLine || products.silverSneakers || products.visionDiscount || products.atYourBest ||
      products.dime || products.dime2 || products.providerDiscountNetwork || products.myCarePath) !== 'undefined';
  }

  public static hasLedgerAccess(user: IProfileUser): boolean {
    return user.relationshipType === RelationshipType.Subscriber || user.relationshipType === RelationshipType.DomesticPartner;
  }

  public static isPlanMaOrMapd(user: IProfileUser): boolean {
    const coverageInfo = this.getCoverageInfo(user.planCoverages, [CoverageStatus.Active, CoverageStatus.Future]);
    return !!coverageInfo.coverageTypeCodes[CoverageTypeCode.MA] || !!coverageInfo.coverageTypeCodes[CoverageTypeCode.MAPD];
  }

  public static getMemberStatus(user: IProfileUser): CoverageStatus | string {
    // ARC-4174: For M&R if any coverage is active then user is considered active, for E&I use medical coverage status
    // ARC-5482: If there is a future coverage but not currently any active ones we consider the user as pre-effective
    if (user.lineOfBusiness === LineOfBusiness.MR) {
      if (user.planCoverages.some(c => c.planPeriod.status === CoverageStatus.Active)) {
        return CoverageStatus.Active;
      } else if (user.planCoverages.some(c => c.planPeriod.status === CoverageStatus.Future)) {
        return 'Pre-Effective';
      } else {
        return CoverageStatus.Termed;
      }
    } else {
      let medicalCoverage;
      for (const coverage of user.planCoverages) {
        if (coverage.coverageType === CoverageType.Medical) {
          medicalCoverage = coverage;
        }
      }

      return medicalCoverage ? medicalCoverage.planPeriod.status : undefined;
    }
  }
}
