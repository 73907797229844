import { IResponse } from '../api.interfaces';

export enum ActivateStepStatus {
  complete = 'COMPLETE',
  skipped = 'SKIPPED',
  supressed = 'SUPPRESSED',
  unstarted = 'UNSTARTED',
}

export enum ActivateGlobalStatus {
  complete = 'COMPLETE',
  started = 'STARTED',
}

export enum ActivatePlanKey { 'EI' = 'E&I', 'CDP' = 'CDP', 'DP' = 'DP', 'GATED' = 'Gated_E&I', 'NON-GATED-PCP' = 'NON-GATED_PCP_E&I'}

export interface IActivateStepsResponse extends IResponse<IActivatePlans> {}
export interface IActivateStepsResponseV4 extends IResponse<IActivateStepsV4> {}
export interface IActivateStatusResponse extends IResponse<IActivateStatus> {}

export interface IActivatePlans {
  linear?: IActivateSteps;
  additional?: IActivateSteps[];
}
export interface IActivateSteps {
  planKey: ActivatePlanKey;
  status: ActivateGlobalStatus;
  steps: IActivatePlanStep[];
  planVersion: number;
}

export interface IActivateStepsV4 {
  status: ActivateGlobalStatus;
  steps: IActivatePlanStep[];
  linear: boolean;
}

export interface IActivateStatus {
  plans: IActivatePlansData[];
}

export interface IActivatePlansData {
  planKey: ActivatePlanKey;
  steps: IActivatePlanStep[];
}

export interface IActivatePlanStep {
  sequence: number;
  stepId: string;
  stepName: string;
  stepState: ActivateStepStatus;
  bannerPriority?: number;
}
