import {IEnvironmentConstants} from 'scripts/util/constants/environment.interfaces';
import notificationTemplate from 'views/chrome/notification.html';
import { Dictionary } from '../../util/constants/i18n.constants';

export class NotificationComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = NotificationController;
    this.templateUrl = notificationTemplate;
  }
}

export class NotificationController implements ng.IComponentController {
  public dismissed: boolean;
  private notificationKey = 'arcade.notification.dismissed';

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private Environment: IEnvironmentConstants,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.NOTIFICATION);

    const value = this.$window.sessionStorage.getItem(this.notificationKey);
    this.dismissed = typeof value === 'string' ? JSON.parse(value) : false;
  }

  public dismiss(): void {
    this.dismissed = true;
    this.$window.sessionStorage.setItem(this.notificationKey, JSON.stringify(this.dismissed));
  }

  public getNotificationMessage(): string {
    return this.Environment.CONFIG.ARCADE_WEB_NOTIFICATION_MESSAGE;
  }
}
