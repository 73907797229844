import angular from 'angular';
import { IAccordionController, IAccordionItem } from './accordion.interfaces';

export class AccordionController implements IAccordionController {
  public items: IAccordionItem[];
  public isExpandMainControl: boolean = true;
  private interpolations?: string;
  private value?: string;

  constructor(
    private $sce: ng.ISCEService) {
    'ngInject';
    this.items = angular.copy(this.items) || [];
  }

  public toggleItem(index: number): void {
    this.items[index].$expanded = !this.items[index].$expanded;
    this.toggleMainControlIfNeeded();
  }

  public expandOrCollapseAll(): void {
    for (const item of this.items) {
      item.$expanded = this.isExpandMainControl;
    }
    this.isExpandMainControl = !this.isExpandMainControl;
  }

  public convertToTrustedHTML(item: IAccordionItem): string {
    if (item[this.value] && item[this.interpolations]) {
      const replacements = item[this.value].match(/{{(\S+)}}/g);
      if (replacements) {
        for (const replacement of replacements) {
          const replacementValue = replacement.replace(/{|}/g, '');
          if (item[this.interpolations][replacementValue]) {
            item[this.value] =
              item[this.value].replace(new RegExp(replacement, 'g'), decodeURIComponent(item[this.interpolations][replacementValue]));
          }
        }
      }
      return this.$sce.trustAsHtml(item[this.value]);
    }
    return item[this.value];
  }

  private toggleMainControlIfNeeded(): void {
    for (const item of this.items) {
      if (item.$expanded !== this.isExpandMainControl) {
        return;
      }
    }
    this.isExpandMainControl = !this.isExpandMainControl;
  }
}
