import jQuery from 'jquery';
import skipToMainTemplate from 'views/ui/skip-to-main.html';
import { ResetFocus } from '../../util/constants/event.constants';
import { SkipToMainController } from './skip-to-main.controller';

export class SkipToMainDirective implements ng.IDirective {
  public restrict = 'E';
  public replace = true;
  public templateUrl = skipToMainTemplate;
  public controller = SkipToMainController;
  public controllerAs = '$skipCtrl';

  constructor(private $rootScope: ng.IRootScopeService) {}
  public link = () => {
    this.$rootScope.$on(ResetFocus, () => {
      // insert a new hidden element, focus on it, then remove it so the next tab will focus on the skip button
      const jHiddenSpan = jQuery('<span tabindex="0"></span>');
      jQuery(document.body.children[0]).before(jHiddenSpan);
      jHiddenSpan.focus();
      jHiddenSpan.remove();
    });
  }

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($rootScope: ng.IRootScopeService) => {
      'ngInject';
      return new SkipToMainDirective($rootScope);
    };
    return directive;
  }
}
