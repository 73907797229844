import { IPopulation } from 'scripts/util/population/population.interfaces';

export enum PopulationActionTypes {
  SET_POPULATION = 'SET_POPULATION',
}

export interface IChangePopulation {
  type: PopulationActionTypes.SET_POPULATION;
  payload: IPopulation;
}

export function changePopulation(payload: IPopulation): IChangePopulation {
  return {
    type: PopulationActionTypes.SET_POPULATION,
    payload,
  };
}

export type PopulationAction = IChangePopulation;
