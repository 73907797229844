import classNames from 'classnames';
import React, { Component, createRef, KeyboardEvent, ReactElement, RefObject } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { DropdownButton } from './dropdown-button';
import { DropdownList } from './dropdown-list';
import { IDropdownOption } from './dropdown.interfaces';

let uniqueId = 0;

export interface IDropdownProps {
  classes?: string[];
  dictionary?: string;
  label?: string;
  nav?: boolean;
  onSelect: (option: IDropdownOption) => void;
  options: IDropdownOption[];
  selected?: IDropdownOption;
  toggleClass?: string;
}

interface IDropdownState {
  show: boolean;
}

export class Dropdown extends Component<IDropdownProps, IDropdownState> {
  private id: number;
  private dropdownElement: HTMLElement;
  private dropdownButtonRef: RefObject<HTMLButtonElement>;
  private firstDropdownOptionRef: RefObject<HTMLLIElement | HTMLAnchorElement>;

  constructor(props: IDropdownProps) {
    super(props);
    this.id = uniqueId++;
    this.state = { show: false };
    this.dropdownButtonRef = createRef<HTMLButtonElement>();
    this.firstDropdownOptionRef = createRef<HTMLLIElement | HTMLAnchorElement>();
  }

  public componentWillMount(): void {
    document.addEventListener('mousedown', this.handleMouseDown);
  }

  public componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleMouseDown);
  }

  public render(): ReactElement<IDropdownProps> {
    const { classes, dictionary = Dictionary.COMMON, label, nav, options, selected, toggleClass } = this.props;
    const { show } = this.state;
    const optionsId = `dropdown-options-${this.id}`;
    const toggleId = `dropdown-toggle-${this.id}`;
    return (
      <div
        className={classNames('dropdown', { 'text-only': nav }, classes)}
        onKeyDown={this.handleKeyDown}
        ref={this.setDropdownRef}
      >
        <DropdownButton
          dictionary={dictionary}
          label={nav ? label : selected.label}
          onClick={this.handleButtonClick}
          optionsId={optionsId}
          ref={this.dropdownButtonRef}
          show={show}
          toggleClass={toggleClass}
          toggleId={toggleId}
        />
        {show &&
          <DropdownList
            dictionary={dictionary}
            firstOptionRef={this.firstDropdownOptionRef}
            nav={nav}
            onSelect={this.handleListClick}
            options={options}
            optionsId={optionsId}
            selected={selected}
            toggleId={toggleId}
          />
        }
      </div>
    );
  }

  private focusOnDropdownButton(): void {
    if (this.dropdownButtonRef.current) {
      this.dropdownButtonRef.current.focus();
    }
  }

  private focusOnFirstDropdownOption(): void {
    if (this.firstDropdownOptionRef.current) {
      this.firstDropdownOptionRef.current.focus();
    }
  }

  private handleButtonClick = (): void => {
    this.toggleShow();
  }

  private handleListClick = (option: IDropdownOption): void => {
    const { onSelect } = this.props;
    onSelect(option);
    this.toggleShow();
  }

  private handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    const { show } = this.state;
    switch (event.keyCode) {
      case 40: // down arrow
        if (!show) {
          this.toggleShow();
          this.focusOnFirstDropdownOption();
        } else if (event.target === this.dropdownButtonRef.current) {
          this.focusOnFirstDropdownOption();
        }
        event.preventDefault();
        break;
      case 38: // up arrow
        if (event.target === this.firstDropdownOptionRef.current) {
          this.focusOnDropdownButton();
        } else if (show && event.target === this.dropdownButtonRef.current) {
          this.toggleShow();
          this.focusOnDropdownButton();
        }
        event.preventDefault();
        break;
      case 13: // return
      case 32: // space
        if (event.target !== this.dropdownButtonRef.current) {
          (event.target as HTMLElement).click();
          this.focusOnDropdownButton();
        } else {
          this.toggleShow();
          this.focusOnDropdownButton();
        }
        event.preventDefault();
        break;
      case 27: // escape
        if (show) {
          this.toggleShow();
        }
        this.focusOnDropdownButton();
        event.preventDefault();
        break;
    }
  }

  private handleMouseDown = (event: MouseEvent): void => {
    const { show } = this.state;
    if (show && !this.dropdownElement.contains(event.target as HTMLElement)) {
      this.toggleShow();
    }
  }

  private setDropdownRef = element => this.dropdownElement = element;

  private toggleShow(): void {
    const { show } = this.state;
    this.setState({ show: !show });
  }
}
