import moment from 'moment';

/**
 * Helper method for safely converting a string to a number without having to manually traverse
 * the tree for undefined leaves.
 */
export function stringToFloat<T>(parent: T, ...leaves: string[]): void {
  findLeafStem(parent, (obj: T, key: string, val: string) => {
    obj[key] = parseFloat(val);
  }, ...leaves);
}

/**
 * Helper method for safely converting a date string to a Moment object without having to manually traverse
 * the tree for undefined leaves.
 */
export function dateStringToMoment<T>(parent: T, ...leaves: string[]): void {
  findLeafStem(parent, (obj: T, key: string, val: string) => {
    obj[key] = moment(val);
  }, ...leaves);
}

/**
 * Helper method for safely converting a time string (e.g. '05:00:00PM') to a Moment object without having to manually traverse
 * the tree for undefined leaves.
 */
export function timeStringToMoment<T>(parent: T, ...leaves: string[]): void {
  findLeafStem(parent, (obj: T, key: string, val: string) => {
    obj[key] = moment(val, ['h:mm A']);
  }, ...leaves);
}

/**
 * Helper method to traverse an object with given child-keys (leaves) and find the existence
 * of the last leaf. If, at any point during the search, a leaf is not found, we quit silently.
 */
export function findLeafStem<T, U>(parent: T, callback: (obj: T, key: string, val: U) => void, ...leaves: string[]): void {
  let obj = parent;
  let key: string;
  let val: U;

  if (!obj) {
    return;
  }

  for (let i = 0; i < leaves.length; i++) {
    const leaf = leaves[i];
    const child = obj[leaf];

    if (child && typeof child === 'object' && child instanceof Array === false) {
      obj = child;
    } else if (child && child instanceof Array === true) {
      for (const item of child) {
        if (typeof item === 'object') {
          findLeafStem(item, callback, ...leaves.slice(i + 1));
        }
      }
      return;
    } else if (!child) {
      return;
    }
  }

  key = leaves[leaves.length - 1];
  val = obj[key];

  callback(obj, key, val);
}
