import idx from 'idx';
import { createSelector } from 'reselect';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';

const selectUser = (state: IReduxState) => state.user;

const selectHeartbeat = createSelector(
  selectUser,
  user => user.heartbeat,
);

export const selectHeartbeatData = createSelector(
  selectHeartbeat,
  heartbeat => heartbeat.data,
);

export const selectRallyId = createSelector(
  selectHeartbeatData,
  heartbeat => idx(heartbeat, _ => _.rallyId),
);

export const selectIsSuperUser = createSelector(
  selectHeartbeatData,
  heartbeat => idx(heartbeat, _ => _.isSuperUser),
);
