import { UserService } from '../../api/user/user.service';
import { IInternalRedirectStateParams } from './internal-redirect.interfaces';

export class InternalRedirectController {

  constructor(
    private userService: UserService,
    private $stateParams: IInternalRedirectStateParams,
  ) {
    'ngInject';
    this.userService.internalSSORedirect(this.$stateParams.deepLink);
  }
}
