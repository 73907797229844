import angular from 'angular';
import { getMoneyValue } from 'scripts/util/money/money';
import ClaimDetailsServicesTemplate from 'views/claims-and-accounts/claims/claim-details-services.html';
import {
  IAnyClaimDetails,
  IAnyServiceLine,
  IFinancialClaimDetails,
  IHealthcareClaimDetails,
} from '../../../../../api/claims/claims.interfaces';
import { ClaimsService } from '../../../../../api/claims/claims.service';
import { Dictionary } from '../../../../../util/constants/i18n.constants';
import { IClaimDetailsParams } from '../claim-details.component';

export class ClaimDetailsServicesComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  public bindings = {
    claimDetails: '<',
  };

  constructor() {
    this.controller = ClaimDetailsServicesController;
    this.templateUrl = ClaimDetailsServicesTemplate;
  }
}

export class ClaimDetailsServicesController implements ng.IComponentController {
  // Bindings
  public claimDetails: IAnyClaimDetails;
  public serviceLines: IAnyServiceLine[];
  public isHealthcare: boolean;

  constructor(
    private $stateParams: IClaimDetailsParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.CLAIM_DETAILS);
    this.isHealthcare = ClaimsService.getByClaim(this.claimDetails, true, false);
    this.serviceLines = this.claimDetails.serviceLines;
  }

  public getMoneyValue(amount: number, decimal?: boolean): string {
    return getMoneyValue(amount, decimal);
  }

}
