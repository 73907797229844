import accountSummaryExplanationTemplate from 'views/modals/account-summary-explanation.html';
import { AccountSummaryExplanationController } from './account-summary.controller';

export class AccountSummaryExplanationComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = AccountSummaryExplanationController;
    this.templateUrl = accountSummaryExplanationTemplate;
  }
}
