import { IHeartbeat } from 'scripts/api/user/user.interfaces';

export enum UserServiceActionTypes {
  GET_HEARTBEAT_ERROR = 'GET_HEARTBEAT_ERROR',
  GET_HEARTBEAT_LOADING = 'GET_HEARTBEAT_LOADING',
  GET_HEARTBEAT_SUCCESS = 'GET_HEARTBEAT_SUCCESS',
}

export interface IGetHeartbeatLoading {
  type: UserServiceActionTypes.GET_HEARTBEAT_LOADING;
}

export interface IGetHeartbeatSuccess {
  type: UserServiceActionTypes.GET_HEARTBEAT_SUCCESS;
  payload: IHeartbeat;
}

export interface IGetHeartbeatError {
  type: UserServiceActionTypes.GET_HEARTBEAT_ERROR;
}

export function getHeartbeatLoading(): IGetHeartbeatLoading {
  return {
    type: UserServiceActionTypes.GET_HEARTBEAT_LOADING,
  };
}

export function getHeartbeatSuccess(payload: IHeartbeat): IGetHeartbeatSuccess {
  return {
    type: UserServiceActionTypes.GET_HEARTBEAT_SUCCESS,
    payload,
  };
}

export function getHeartbeatError(): IGetHeartbeatError {
  return {
    type: UserServiceActionTypes.GET_HEARTBEAT_ERROR,
  };
}

export type UserServiceAction =  IGetHeartbeatLoading | IGetHeartbeatSuccess | IGetHeartbeatError;
