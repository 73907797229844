import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import infiniteScrollTemplate from 'views/ui/infinite-scroll.html';

export class InfiniteScrollComponent implements ng.IComponentOptions {
  public bindings = {
    allObjects: '<',
    remoteCtrl: '=',
    batchSize: '<?',
    loadThresholdPercent: '<?',
  };
  public controller: any;
  public controllerAs = '$scroll';
  public templateUrl: string;
  public transclude = true;

  constructor() {
    this.controller = InfiniteScrollController;
    this.templateUrl = infiniteScrollTemplate;
  }
}

export class InfiniteScrollController<T> implements ng.IController {
  public allObjects: T[];
  public displayedObjects: T[];
  public remoteCtrl: InfiniteScrollController<T>;
  private batchSize: number;
  private loadThresholdPercent: number;
  private eventSubscription: Subscription;
  private onChangesSubscription: Subscription;

  constructor(
    private $element: ng.IAugmentedJQuery,
    private $scope: ng.IScope,
    private $window: ng.IWindowService,
  ) {
    'ngInject';
    this.remoteCtrl = this;
    this.batchSize = this.batchSize || 10;
    this.loadThresholdPercent = this.loadThresholdPercent || 33;
  }

  public $onInit(): void {
    const scroll$ = Observable.fromEvent<Event>(this.$window, 'scroll');
    const resize$ = Observable.fromEvent<Event>(this.$window, 'resize');
    this.eventSubscription = Observable.merge(scroll$, resize$)
      .switchMap(() => {
        return Observable.timer(0, 250)
          .takeWhile(() => this.shouldLoadMore());
      })
      .throttleTime(250)
      .subscribe(() => {
        this.$scope.$apply(() => this.loadMore());
      });
  }

  public $onChanges(): void {
    if (this.onChangesSubscription) {
      this.onChangesSubscription.unsubscribe();
    }
    this.displayedObjects = this.getNextBatch(this.allObjects, 0);
    this.onChangesSubscription = Observable.timer(0, 250)
      .takeWhile(() => this.shouldLoadMore())
      .subscribe(() => {
        this.$scope.$apply(() => this.loadMore());
      });
  }

  public $onDestroy(): void {
    this.eventSubscription.unsubscribe();
    this.onChangesSubscription.unsubscribe();
  }

  private loadMore(): void {
    this.displayedObjects.push(...this.getNextBatch(this.allObjects, this.displayedObjects.length));
  }

  private getNextBatch(objects: T[], start: number): T[] {
    return (objects && objects instanceof Array) ? objects.slice(start, start += this.batchSize) : [];
  }

  private shouldLoadMore(): boolean {
    if (!this.displayedObjects || !this.allObjects || this.displayedObjects.length === this.allObjects.length) {
      return false;
    }
    const windowBottom = this.$window.innerHeight + this.$window.pageYOffset;
    const elementBottom = this.$element[0].offsetTop + this.$element[0].offsetHeight;
    return (windowBottom >= elementBottom * (1 - (this.loadThresholdPercent / 100)));
  }
}
