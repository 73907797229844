import customFaqTemplate from 'views/modals/custom-faq.html';
import { AccountType, ILedgerAccount } from '../../../api/ledger/ledger.interfaces';
import { ILedgerService } from '../../../api/ledger/ledger.service';
import { IClientConfig, IFaq } from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { IStateParams } from '../../../arcade.module.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';

export class CustomFaqComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = CustomFaqController;
    this.templateUrl = customFaqTemplate;
  }
}

export class CustomFaqController implements ng.IComponentController {
  public faqs: IFaq[];
  public accountType: AccountType;
  private clientConfig: IClientConfig;
  private accounts: ILedgerAccount[];

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $stateParams: IStateParams,
    private $sce: ng.ISCEService,
    private targetingService: ITargetingService,
    private userService: IUserService,
    private ledgerService: ILedgerService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_INFO);

    this.accountType = AccountType[this.$stateParams.id.toUpperCase()];

    userService.getHeartbeat()
      .flatMap(({data}) => targetingService.getClientConfig(data.rallyId)
        .do(clientConfig => this.clientConfig = clientConfig), heartbeat => heartbeat)
      .flatMap(({data}) => this.ledgerService.getAccounts(data.rallyId))
        .flatMap(({data}) => data)
        .first(account => account.isActive && account.accountType === this.accountType)
      .subscribe(account => {
        this.faqs = this.clientConfig.contentOverrides.customFaq && this.clientConfig.contentOverrides.customFaq[account.accountType];
      }, console.warn);
  }
}
