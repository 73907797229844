import { Subscription } from 'rxjs/Subscription';
import { IProfileService } from '../../../api/profile/profile.service';
import { AwardMediaType, IAdditionalProgressInfo, IPlanInfo } from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { IConfig, IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { ILocaleService } from '../../../util/locale/locale.service';
import { IIncentivesController } from './incentives.interfaces';

export class IncentivesController implements IIncentivesController {
  public incentives: IPlanInfo;
  public additionalInfo: IAdditionalProgressInfo;
  public percentageEarned: number;
  public config: IConfig;
  private localeSubscription: Subscription;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Environment: IEnvironmentConstants,
    private localeService: ILocaleService,
    private profileService: IProfileService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.INCENTIVES);

    this.config = Environment.CONFIG;

    this.setIncentives();
    this.localeSubscription = localeService.localeChanged.subscribe(() => {
      this.setIncentives();
    });
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  private setIncentives(): void {
    this.userService.getHeartbeat()
      .let(this.profileService.toCurrentProfile())
      .flatMap(rsp => this.targetingService.getIncentives(rsp.rallyId))
      .subscribe(({data}) => {
        this.incentives = data.planInfo;
        this.additionalInfo = data.additionalProgressInfo;
        this.percentageEarned = Math.round(this.incentives.earnedAmount / this.incentives.maxCap * 100);
      }, console.warn);
  }
}
