import planBalancesBannerTemplate from 'views/claims-and-accounts/plan-balances/plan-balances-banner.html';
import { AccountType } from '../../../api/ledger/ledger.interfaces';
import { ILedgerService, LedgerService } from '../../../api/ledger/ledger.service';
import { YesNo } from '../../../api/profile/profile.interfaces';
import { IProfileService, ProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IFeatureFlagService } from '../../../util/feature-flag/feature-flag.interface';

export class PlanBalancesBannerComponent implements ng.IComponentOptions {
  public templateUrl: string;
  public controller: any;

  constructor() {
    this.templateUrl = planBalancesBannerTemplate;
    this.controller = PlanBalancesBannerController;
  }
}

export class PlanBalancesBannerController implements ng.IComponentController {
  public showBanner: boolean;
  public showDirectDepositBtn: boolean;
  public showAutomaticPaymentsBtn: boolean;
  public bannerMessage: string;
  constructor(
    private featureFlagService: IFeatureFlagService,
    private ledgerService: ILedgerService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
  }

  public $onInit(): void {
    if (this.featureFlagService.isAutoPaymentOn() && this.featureFlagService.isDirectDepositOn()) {
      this.getPreferences();
    }
  }

  private getPreferences(): void {
    this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .takeWhile(currentUser => ProfileService.hasLedgerAccess(currentUser))
      .flatMap(currentUser => {
        return this.ledgerService.getAccounts(currentUser.rallyId);
      }, (currentUser, ledger) => ({ currentUser, accounts: ledger.data }))
      .takeWhile(({ accounts }) => accounts.some(account => {
        return this.checkIfValidAccountType(account.accountType);
      }))
      .flatMap(({ currentUser }) => {
        return this.profileService.getUserPreferences(currentUser.rallyId);
      })
      .map(rsp => rsp.data)
      .subscribe(({autoSubmissionPreferences, eftPreferences}) => {
        this.showDirectDepositBtn = eftPreferences && eftPreferences.prefStatusCode === YesNo.N;
        this.showAutomaticPaymentsBtn = autoSubmissionPreferences && autoSubmissionPreferences.autoSubmissionIndicator === YesNo.N;
        this.showBanner = this.showDirectDepositBtn || this.showAutomaticPaymentsBtn;
        if (this.showBanner) {
          this.setBannerMessage();
        }
      }, console.warn);
  }

  private setBannerMessage(): void {
    if (this.showDirectDepositBtn && this.showAutomaticPaymentsBtn) {
      this.bannerMessage = 'SIGN_UP_FOR_DD_AND_AUTO_PAY';
    } else if (this.showDirectDepositBtn) {
      this.bannerMessage = 'SIGN_UP_FOR_DD';
    } else {
      this.bannerMessage = 'SIGN_UP_FOR_AUTO_PAY';
    }
  }

  private checkIfValidAccountType(accountType: AccountType): boolean {
    return [
      AccountType.HRA,
      AccountType.HRASD,
      AccountType.HRAPD,
      AccountType.MRA,
      AccountType.HRAAP,
      AccountType.FSAHC,
      AccountType.FSADC,
      AccountType.FSALP,
      AccountType.DCSA,
      AccountType.HCSA,
    ].indexOf(accountType) > -1;
  }
}
