import sharedHeaderTemplate from 'views/chrome/shared-header.html';
import { SharedHeaderFooterController } from './shared-header-footer.controller';

export class SharedHeaderComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  public bindings: {[binding: string]: string};

  constructor() {
    this.controller = SharedHeaderFooterController;
    this.templateUrl = sharedHeaderTemplate;
    this.bindings = {
      authenticated: '<',
    };
  }
}
