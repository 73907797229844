import carouselTemplate from 'views/ui/carousel.html';
import mobileCarouselTemplate from 'views/ui/mobile-carousel.html';
import mobileIdCarouselTemplate from 'views/ui/mobile-id-carousel.html';
import slideTemplate from 'views/ui/slide.html';
import { CarouselController } from './carousel.controller';
import { ICarouselController, ISlideScope } from './carousel.interfaces';

export class CarouselDirective implements ng.IDirective {
  public restrict = 'E';
  public transclude = true;
  public replace = true;
  public scope = {};
  public controller = CarouselController;
  public controllerAs = '$carousel';
  public bindToController = {
    autoPlay: '<',
    remoteCtrl: '=?',
    slideMargin: '<?',
  };
  public templateUrl = (element: ng.IAugmentedJQuery, attrs: ng.IAttributes): string => {
    if (attrs.templateUrl) {
      return attrs.templateUrl;
    } else if (attrs.mobileId !== undefined && attrs.mobileId !== 'false') {
      return mobileIdCarouselTemplate;
    } else if (attrs.mobileOnly !== undefined && attrs.mobileOnly !== 'false') {
      return mobileCarouselTemplate;
    } else {
      return carouselTemplate;
    }
  }

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = () => new CarouselDirective();
    return directive;
  }
}

export class SlideDirective implements ng.IDirective {
  public restrict = 'E';
  public require = '^carousel';
  public transclude = true;
  public replace = true;
  public scope = {};
  public templateUrl = (element: ng.IAugmentedJQuery, attrs: ng.IAttributes): string => {
    return attrs.templateUrl || slideTemplate;
  }
  public link = (scope: ISlideScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, $carousel: ICarouselController) => {
    scope.index = (scope.$parent as any).$index;
    $carousel.addSlide(scope, element);
  }

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = () => new SlideDirective();
    return directive;
  }
}
