import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import accumulatorsSummaryMaxTemplate from 'views/dashboard/accumulators-summary-max.html';
import {
  BenefitNetwork,
  BenefitPaymentType,
  IUsefulAccumulator,
  IUsefulBenefits,
} from '../../../api/plans/plans.interfaces';
import { PlansService } from '../../../api/plans/plans.service';
import { ProfileService } from '../../../api/profile/profile.service';
import { IClientConfig } from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { UserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { ILocaleService } from '../../../util/locale/locale.service';
import { IStateService } from '../../../util/state/state.service';

export class AccumulatorsSummaryMaxComponent implements ng.IComponentOptions {
  public controller = AccumulatorsSummaryMaxController;
  public templateUrl = accumulatorsSummaryMaxTemplate;
}

export class AccumulatorsSummaryMaxController implements ng.IComponentController {
  public accumulators: IUsefulAccumulator[];
  public person: string;
  private clientConfig: IClientConfig;
  private clientConfigReq: Observable<IClientConfig>;
  private localeSubscription: Subscription;
  private profileSubscription: Subscription;
  private useSelectedProfile: boolean;

  constructor(
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private localeService: ILocaleService,
    private plansService: PlansService,
    private profileService: ProfileService,
    private stateService: IStateService,
    private targetingService: ITargetingService,
    private userService: UserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.useSelectedProfile = this.stateService.getUseSelectedProfile();

    this.accumulators = [];

    this.clientConfigReq = this.userService.getHeartbeat()
      .flatMap(({data}) => this.targetingService.getClientConfig(data.rallyId))
      .do(clientConfig => this.clientConfig = clientConfig);
  }

  public $onInit(): void {
    this.initAccumulators();

    if (this.useSelectedProfile) {
      this.profileSubscription = this.profileService.profileChanged.subscribe(profile => {
        this.initAccumulators();
      });
    }

    this.localeSubscription = this.localeService.localeChanged
      .flatMap(() => this.clientConfigReq)
      .subscribe(() => this.applyCustomLabels());
  }

  public $onDestroy(): void {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    this.localeSubscription.unsubscribe();
  }

  public initAccumulators(): void {
    const heartbeat = this.userService.getHeartbeat();
    const selectedUser = heartbeat.let(this.profileService.toCurrentProfile());
    const loggedInUser = heartbeat.let(this.profileService.toProfile()).map(profile => profile.data.currentUser);

    Observable.if(() => this.useSelectedProfile, selectedUser, loggedInUser)
      .flatMap(() => this.clientConfigReq, profile => profile)
      .do(profile => this.person = profile.userInfo.firstName)
      .flatMap(profile => this.plansService.getBenefitsWithAccumulators(profile.rallyId, profile.dependentSeqNum))
      .map(benefits => benefits.MEDICAL)
      .subscribe(usefulBenefits => {
        this.accumulators = this.getSupportedAccumulators(usefulBenefits);
        this.filterSuppressed();
        this.limitAccumulators();
        this.applyCustomLabels();
      }, console.warn);
  }

  private getSupportedAccumulators(usefulBenefits: IUsefulBenefits): IUsefulAccumulator[] {
    const accumulators = [];
    [BenefitNetwork.InNetwork, BenefitNetwork.OutOfNetwork].forEach(network => {
      [BenefitPaymentType.PreDeductibleAllowanceCombined, BenefitPaymentType.PreDeductibleAllowanceRoutineServices,
        BenefitPaymentType.PreDeductibleAllowanceEmergencyServices].forEach(benefitType => {
          if (usefulBenefits && usefulBenefits[network] && usefulBenefits[network][benefitType]) {
            accumulators.push(usefulBenefits[network][benefitType]);
          }
      });
    });
    return accumulators;
  }

  private filterSuppressed(): void {
    if (this.clientConfig.suppressions.accumulatorSuppression) {
      const accSuppression = this.clientConfig.suppressions.accumulatorSuppression;
      this.accumulators = this.accumulators.filter(({network, type}) =>
        !accSuppression.hasOwnProperty(network) || accSuppression[network].indexOf(type) === -1,
      );
    }
  }

  private limitAccumulators(): void {
    if (this.accumulators && this.accumulators.length > 0) {
      // use the first accumulator to determine how many accumulators should be shown
      switch (this.accumulators[0].type) {
        case BenefitPaymentType.PreDeductibleAllowanceCombined:
          this.accumulators = this.accumulators.slice(0, 1);
          break;
        case BenefitPaymentType.PreDeductibleAllowanceEmergencyServices:
        case BenefitPaymentType.PreDeductibleAllowanceRoutineServices:
          this.accumulators = this.accumulators.slice(0, 2);
          break;
      }
    }
  }

  private applyCustomLabels(): void {
    if (this.clientConfig.customLabels.customAccumulatorLabels) {
      const customLabels = this.clientConfig.customLabels.customAccumulatorLabels;
      this.accumulators.forEach(a => {
        a.customLabel = customLabels.hasOwnProperty(a.network) && customLabels[a.network].hasOwnProperty(a.type) ?
          customLabels[a.network][a.type] : null;
      });
    }
  }
}
