import { benefitsCoverage } from 'scripts/util/resource/resource.constants';
import { ClaimBalanceType } from '../../../api/claims/claims.interfaces';
import { ICustomGlossaryDefinitions } from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { ResourceService } from '../../../util/resource/resource.service';

export interface IClaimsAndAccountsGlossaryParams extends ng.ui.IStateParamsService {
  term: ClaimBalanceType;
}
export interface ILinkMapping {
  ALLOWED_AMOUNT: string;
  COINSURANCE: string;
  DEDUCTIBLE: string;
}
export class ClaimsAndAccountsModalController implements ng.IComponentController {
  public term: ClaimBalanceType;
  public customGlossary: ICustomGlossaryDefinitions | {};
  private linkMapping: ILinkMapping;
  constructor(
    private $stateParams: IClaimsAndAccountsGlossaryParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private resourceService: ResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.CLAIM);

    this.linkMapping = {
      ALLOWED_AMOUNT: this.resourceService.get(benefitsCoverage),
      COINSURANCE: this.resourceService.get(benefitsCoverage),
      DEDUCTIBLE: this.resourceService.get(benefitsCoverage),
    };
    this.userService.getHeartbeat()
      .flatMap(({data}) => this.targetingService.getClientConfig(data.rallyId))
      .subscribe(clientConfig => {
        this.customGlossary = clientConfig &&
          clientConfig.contentOverrides &&
          clientConfig.contentOverrides.customGlossaryDefinitions ||
          {};
      });
  }
    public getLink(term: string): string {
      return this.linkMapping[term];
    }
  public $onInit(): void {
    this.term = this.$stateParams.term;
  }

}
