import inactiveTemplate from 'views/modals/inactive.html';
import { InactiveController } from './inactive.controller';

export class InactiveComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;

  constructor() {
    this.controller = InactiveController;
    this.templateUrl = inactiveTemplate;
  }
}
