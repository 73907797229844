export interface ICostInfoController {
  copy: ICostCopyItem;
  urlEscapedDeeplink: string;
  isMR: boolean;
  costInfo: ICostInfo;
  totalNetworks: number;
  internalRedirect($event: ng.IAngularEvent, url: string): void;
}

export interface ICostInfo {
  [network: string]: ICostInfoItem;
}

export interface ICostInfoItem {
  amounts: INonZeroCostMap;
  bigNum: string;
  copy: string;
}

export interface INonZeroCostMap {
  [benefitPaymentType: string]: string;
}

export interface ICostInfoStateParams extends ng.ui.IStateParamsService {
  deepLink: string;
}

export enum CostId {
  csDoctor = 'costCSDoctor',
  csHospital = 'costCSHospital',
  csMental = 'costCSMental',
  csSpecialist = 'costCSSpecialist',
  csUrgent = 'costCSUrgent',
  doctor = 'costDoctor',
  emergency = 'costEmergency',
  equipment = 'costEquipment',
  inpatient = 'costInpatient',
  mrDoctor = 'costMRDoctor',
  mrSpecialist = 'costMRSpecialist',
  outpatient = 'costOutpatient',
  specialist = 'costSpecialist',
  urgent = 'costUrgent',
  virtual = 'costVirtual',
}

export interface ICostCopyItem {
  title: string;
  description: string;
  link: string;
}

export const CostCopy = {
  costDoctor: {
    title: 'PRIMARY_CARE',
    description: 'PRIMARY_CARE_DESC',
    link: 'FIND_DOCTOR',
  },
  costSpecialist: {
    title: 'SPECIALIST',
    description: 'SPECIALIST_DESC',
    link: 'FIND_SPECIALIST',
  },
  costUrgent: {
    title: 'URGENT_CARE',
    description: 'URGENT_CARE_DESC',
    link: 'FIND_URGENT_CARE',
  },
  costEmergency: {
    title: 'EMERGENCY_ROOM',
    description: 'EMERGENCY_ROOM_DESC',
    link: 'FIND_EMERGENCY_ROOM',
  },
  costVirtual: {
    title: 'VIRTUAL_VISIT',
    link: 'ACCESS_VIRTUAL_VISITS',
  },
  costMRDoctor: {
    title: 'OFFICE_VISIT_PCP',
    description: 'MR_PCP_SPECIALIST_DESC',
    link: 'FIND_PROVIDER',
  },
  costMRSpecialist: {
    title: 'OFFICE_VISIT_SPECIALIST',
    description: 'MR_PCP_SPECIALIST_DESC',
    link: 'FIND_SPECIALIST',
  },
  costOutpatient: {
    title: 'OUTPATIENT_SURGERY',
    description: '',
    link: 'FIND_PROVIDER',
  },
  costInpatient: {
    title: 'INPATIENT_HOSPITAL',
    description: 'INPATIENT_HOSPITAL_DESC',
    link: 'FIND_HOSPITAL',
  },
  costEquipment: {
    title: 'DURABLE_BENEFITS',
    description: 'DURABLE_BENEFITS_DESC',
    link: 'FIND_DURABLE',
  },
  costCSDoctor: {
    title: 'OFFICE_VISIT_PCP',
    link: 'FIND_PROVIDER',
  },
  costCSSpecialist: {
    title: 'OFFICE_VISIT_SPECIALIST',
    link: 'FIND_SPECIALIST',
  },
  costCSMental: {
    title: 'MENTAL_HEALTH_OUTPATIENT_SERVICES',
    link: 'FIND_MENTAL',
  },
  costCSUrgent: {
    title: 'URGENT_CARE_SERVICES',
    link: 'FIND_URGENT_CARE',
  },
  costCSHospital: {
    title: 'HOSPITAL_SERVICES',
    link: 'FIND_HOSPITAL',
  },
  amountCopay: 'AMOUNT_COPAY',
  amountCopayDeductible: 'AMOUNT_COPAY_DEDUCTIBLE',
  amountCoinsurance: 'AMOUNT_COINSURANCE',
  amountCoinsuranceDeductible: 'AMOUNT_COINSURANCE_DEDUCTIBLE',
  amountCopayAndCoinsurance: 'AMOUNT_COPAY_AND_COINSURANCE',
  amountCopayAndCoinsuranceDeductible: 'AMOUNT_COPAY_AND_COINSURANCE_DEDUCTIBLE',
  nothingAfterDeductible: 'NOTHING_AFTER_DEDUCTIBLE',
  noCopayOrCoinsurance: 'NO_COPAY_OR_COINSURANCE',
  zeroCopay: 'ZERO_COPAY',
  fiftyDollarsOrLess: 'FIFTY_DOLLARS_OR_LESS',
};
