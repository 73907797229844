import unauthorizedErrorTemplate from 'views/states/unauthorized-error.html';
import { IStateParams } from '../../arcade.module.interfaces';
import { Dictionary } from '../../util/constants/i18n.constants';

export class UnauthorizedErrorComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = UnauthorizedErrorController;
    this.templateUrl = unauthorizedErrorTemplate;
  }
}

export class UnauthorizedErrorController {
  public errorUID: string;
  public errorReason: string;
  constructor(
    private $stateParams: IStateParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ERRORS);
    this.errorUID = $stateParams.errorUID;
    this.errorReason = $stateParams.errorReason;
  }
}
