export enum MelodeonItemType {
  Content = 'content',
  Toggle = 'toggle',
}

export interface IMelodeonItemScope extends ng.IScope {
  $animating?: boolean;
}

export interface IMelodeonItem {
  element: ng.IAugmentedJQuery;
  scope: IMelodeonItemScope;
  itemType: MelodeonItemType;
}

export interface IMelodeonPair {
  content?: IMelodeonItem;
  toggle?: IMelodeonItem;
  $expanded?: boolean;
  $animating?: boolean;
}

export interface IMelodeonController {
  $scope: ng.IScope;
  animateTime: number;
  pairs: IMelodeonPair[];
  allExpanded: boolean;
  groupId: string;
  expandOnInit?: boolean;
  addToggle(scope: ng.IScope, element: ng.IAugmentedJQuery): number;
  removeToggle(index: number, element: ng.IAugmentedJQuery): void;
  addContent(scope: ng.IScope, element: ng.IAugmentedJQuery): number;
  removeContent(index: number, element: ng.IAugmentedJQuery): void;
  toggle(index: number): void;
  toggleAll(): void;
  focusToggle(i: number): void;
}
