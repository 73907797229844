import {
  automaticPaymentSettings,
  claimForms,
  claimLetters,
  directDepositSettings,
  secureClaimsAccount,
  statements,
} from 'scripts/util/resource/resource.constants';
import balanceTypeInfoTemplate from 'views/modals/balance-type-info.html';
import claimBalanceTypeInfoTemplate from 'views/modals/claim-balance-type-info.html';
import claimDeniedExplanationTemplate from 'views/modals/claim-denied-explanation.html';
import dcsaFaqTemplate from 'views/modals/dcsa-faq.html';
import dentalAccountExplanationTemplate from 'views/modals/dental-account-explanation.html';
import dentalDeductibleExplanationTemplate from 'views/modals/dental-deductible-explanation.html';
import dentalMaxExplanationTemplate from 'views/modals/dental-max-explanation.html';
import fsaFaqTemplate from 'views/modals/fsa-faq.html';
import hcsaFaqTemplate from 'views/modals/hcsa-faq.html';
import hraFaqTemplate from 'views/modals/hra-faq.html';
import hraHowItWorksTemplate from 'views/modals/hra-how-it-works.html';
import hraapAmountUsedTemplate from 'views/modals/hraap-amount-used.html';
import hraapFamilyTemplate from 'views/modals/hraap-family.html';
import hraapIndividualTemplate from 'views/modals/hraap-individual.html';
import hsaFaqTemplate from 'views/modals/hsa-faq.html';
import hsaHowItWorksTemplate from 'views/modals/hsa-how-it-works.html';
import markAsPaidExplanationTemplate from 'views/modals/mark-as-paid-explanation.html';
import medicalAccountExplanationTemplate from 'views/modals/medical-account-explanation.html';
import mrMedicalExplanationOfTermsTemplate from 'views/modals/mr-medical-explanation-of-terms.html';
import mraFaqTemplate from 'views/modals/mra-faq.html';
import orthoMaxExplanationTemplate from 'views/modals/ortho-max-explanation.html';
import { AmpPageTags as Tags } from '../../api/tracking/amplitude.interfaces';
import { IFeatureFlagService } from '../../util/feature-flag/feature-flag.interface';
import { IResourceService } from '../../util/resource/resource.service';

export class ClaimsAndAccountsRoutes {
  constructor($stateProvider: ng.ui.IStateProvider) {
    'ngInject';
    const parentState = 'authenticated.claimsAndAccounts';

    // PLEASE READ:
    // Values for state.data.name and state.data.tags should come from product (they are used for amplitude analytics).
    // If you haven't gotten specific values from product, please don't add anything at all.
    $stateProvider
      .state({
        name: `${parentState}.summary`,
        url: '/summary?{noClaimCache:bool}',
        views: {
          'sub-page': {
            template: '<claims-accounts-summary></claims-accounts-summary>',
          },
        },
        data: {
          name: 'ClaimsSmartSummary',
          tags: [Tags.claims],
          title: 'T_SUMMARY',
        },
        reloadOnSearch: false,
      })
      .state({
        name: `${parentState}.planBalances`,
        url: '/plan-balances?{accountsExpanded:bool}',
        views: {
          'sub-page': {
            template: '<plan-balances></plan-balances>',
          },
        },
        data: {
          name: 'PlanBalances',
          tags: [Tags.planBalances],
          title: 'T_PLAN_BALANCES',
        },
      })
      .state({
        name: `${parentState}.spendingAndCostSummary`,
        url: '/spending-and-cost-summary',
        views: {
          'sub-page': {
            template: '<spending-and-cost-summary></spending-and-cost-summary>',
          },
        },
        onEnter: (
          $window: ng.IWindowService,
          featureFlagService: IFeatureFlagService,
          resourceService: IResourceService,
        ) => {
          'ngInject';
          if (!featureFlagService.isMRSpendingAndCostSummaryOn()) {
            $window.location.replace(resourceService.get(secureClaimsAccount));
          }
        },
        data: {
          title: 'T_SPENDING_AND_COST_SUMMARY',
        },
      })
      .state({
        name: `${parentState}.claims`,
        url: '/claims?{filters:string}&{type:string}&{useSessionFilters:bool}&{page:int}',
        views: {
          'sub-page': {
            template: '<all-claims></all-claims>',
          },
        },
        onEnter: ($location: ng.ILocationService, $stateParams: ng.ui.IStateParamsService, $window: ng.IWindowService) => {
          if ($stateParams.useSessionFilters) {
            $location.search('useSessionFilters', null).replace();
            const storedFilter = $window.sessionStorage.getItem(`arcade.claim.filters`);
            if (storedFilter) {
              $window.sessionStorage.removeItem(`arcade.claim.filters`);
              $location.search('filters', storedFilter).replace();
            }
          }
        },
        data: {
          name: 'AllClaims',
          tags: [Tags.claims],
          title: 'T_CLAIMS',
        },
      })
      .state({
        name: `${parentState}.autoPayment`,
        url: '/auto-payment',
        views: {
          'sub-page': {
            template: '<auto-payment></auto-payment>',
          },
        },
        onEnter: (
          $state: ng.ui.IStateService,
          $window: ng.IWindowService,
          resourceService: IResourceService,
          featureFlagService: IFeatureFlagService,
        ) => {
          'ngInject';
          if (!featureFlagService.isAutoPaymentOn()) {
            $window.location.replace(resourceService.get(automaticPaymentSettings));
          }
        },
        data: {
          title: 'T_AUTO_PAYMENT',
        },
      })
      .state({
        name: `${parentState}.submitClaim`,
        url: '/submit-claim',
        views: {
          'sub-page': {
            template: '<submit-claim></submit-claim>',
          },
        },
        onEnter: ($window: ng.IWindowService, featureFlagService: IFeatureFlagService, resourceService: IResourceService) => {
          'ngInject';
          if (!featureFlagService.isSubmitClaimOn()) {
            $window.location.replace(resourceService.get(claimForms));
          }
        },
        data: {
          name: 'SubmitAClaimLanding',
          tags: [Tags.claims],
          title: 'T_SUBMIT_CLAIM',
        },
      })
      .state({
        name: `${parentState}.statements`,
        url: '/statements',
        views: {
          'sub-page': {
            template: '<statements wait-for-container></statements>',
          },
        },
        onEnter: ($window: ng.IWindowService, featureFlagService: IFeatureFlagService, resourceService: IResourceService) => {
          'ngInject';
          if (!featureFlagService.isStatementsOn()) {
            $window.location.replace(resourceService.get(statements));
          }
        },
        data: {
          name: 'ClaimStatements',
          tags: [Tags.claims],
          title: 'T_STATEMENTS',
        },
      })
      .state({
        name: `${parentState}.claimLetters`,
        url: '/claim-letters',
        views: {
          'sub-page': {
            template: '<claim-letters wait-for-container></claim-letters>',
          },
        },
        onEnter: ($window: ng.IWindowService, featureFlagService: IFeatureFlagService, resourceService: IResourceService) => {
          'ngInject';
          if (!featureFlagService.isClaimLettersOn()) {
            $window.location.replace(resourceService.get(claimLetters));
          }
        },
        data: {
          title: 'T_CLAIM_LETTERS',
        },
      })
      .state({
        name: `${parentState}.claimPaid`,
        url: '/claim-paid?{goTo:string}',
        onEnter: ($location: ng.ILocationService,
                  $state: ng.ui.IStateService,
                  $stateParams: ng.ui.IStateParamsService,
                  $timeout: ng.ITimeoutService,
                  $window: ng.IWindowService) => {
          'ngInject';
          const goToUrl = $stateParams.goTo ? $stateParams.goTo : $state.href(`${parentState}.summary`);
          if ($window.opener && $window.opener.location) {
            $window.opener.location.replace(goToUrl);
            $window.close();
          } else {
            return $timeout(() => $location.url(goToUrl).replace());
          }
        },
        data: {
          name: 'InstamedPaymentMade',
          tags: [Tags.payments],
        },
      })
      .state({
        name: `${parentState}.claimDetails`,
        url: '/claim-details?{claimKey:string}&{type:string}&{claimDetailsFrom:string}',
        views: {
          'sub-page': {
            template: '<claim-details wait-for-container></claim-details>',
          },
        },
        onEnter: ($location: ng.ILocationService,
                  $state: ng.ui.IStateService,
                  $stateParams: ng.ui.IStateParamsService,
                  $timeout: ng.ITimeoutService,
                  featureFlagService: IFeatureFlagService) => {
          'ngInject';
          const type = $stateParams.type || $location.search().type;
          if (!featureFlagService.isClaimDetailsOn(type)) {
            return $timeout(() => $state.go(`${parentState}.summary`, {location: 'replace'}));
          }
        },
        data: {
          name: 'ClaimDetails',
          tags: [Tags.claims],
          title: 'T_CLAIM_DETAILS',
        },
      })
      .state({
        name: `${parentState}.eobError`,
        url: '/eob-error?{claimType:string}&{claimKey:string}&{claimReference:string}&{errorUID:string}',
        onEnter: ($state: ng.ui.IStateService,
                  $stateParams: ng.ui.IStateParamsService,
                  $timeout: ng.ITimeoutService,
                  $window: ng.IWindowService) => {
          'ngInject';
          const goToState = 'modal.eobError';
          const opener: Window = $window.opener;
          const params = $stateParams;
          if (opener && opener.location) {
            const openerPath = opener.location.pathname;
            const openerSearch = opener.location.search || '';

            // Add query params from the opener window so they are included when the modal is opened
            openerSearch
              .substring(1)
              .split('&')
              .map(pair => pair.split('='))
              .filter(([key, value]) => key && value && !params[key])
              .forEach(([key, value]) => params[key] = decodeURIComponent(value));

            // Add the opener window's location as the fromState so the back button on the claim details page will work
            params.eobErrorFrom = openerPath + openerSearch;

            // Build the query param string for the error modal
            const queryParamString = '?' + Object.keys(params)
              .filter(p => params.hasOwnProperty(p) && params[p])
              .map(p => `${encodeURIComponent(p)}=${encodeURIComponent(params[p])}`)
              .join('&');

            opener.location.replace(openerPath + $state.href(goToState) + queryParamString);
            $window.close();
          } else {
            const smartSummaryUrl = $state.href(`${parentState}.summary`);
            params.eobErrorFrom = smartSummaryUrl;
            return $timeout(() => $window.location.replace(smartSummaryUrl + $state.href(goToState, params)));
          }
        },
      })
      .state({
        name: `${parentState}.directDeposit`,
        url: '/direct-deposit',
        views: {
          'sub-page': {
            template: '<direct-deposit></direct-deposit>',
          },
        },
        onEnter: (
          $state: ng.ui.IStateService,
          $window: ng.IWindowService,
          resourceService: IResourceService,
          featureFlagService: IFeatureFlagService,
        ) => {
          'ngInject';
          if (!featureFlagService.isDirectDepositOn()) {
            $window.location.replace(resourceService.get(directDepositSettings));
          }
        },
        data: {
          tags: [Tags.claims],
          title: 'T_DIRECT_DEPOSIT',
        },
      })
      .state({
        name: `modal.printClaims`,
        url: '/print-claims?{filters:string}&{type:string}&{page:int}',
        template: '<all-claims-print></all-claims-print>',
        data: {
          name: 'PrintAllClaims',
          tags: [Tags.claims],
          title: 'T_PRINT_CLAIMS',
        },
      })
      .state({
        name: 'modal.allClaimsFaq',
        url: '/claims-faq?{filters:string}&{type:string}&{page:int}',
        template: '<all-claims-faq></all-claims-faq>',
        data: {
          name: 'ClaimsFaq',
          tags: [Tags.claims, Tags.infoModal],
          title: 'T_FAQ_ALL_CLAIMS',
        },
      })
      .state({
        name: `modal.allClaimsRefineResults`,
        url: '/claims-refine-results?{filters:string}&{type:string}&{page:int}',
        template: '<all-claims-refine-results></all-claims-refine-results>',
        data: {
          name: 'AllClaimsRefine',
          tags: [Tags.claims],
          title: 'T_REFINE_RESULTS',
        },
      })
      .state({
        name: `modal.allClaimsSelectDates`,
        url: '/claims-select-dates?{filters:string}&{type:string}&{page:int}',
        template: '<all-claims-select-dates></all-claims-select-dates>',
        data: {
          name: 'AllClaimsDateSelector',
          tags: [Tags.claims],
          title: 'T_SELECT_DATES',
        },
      })
      .state({
        name: `modal.claimVideo`,
        url: '/claim-video?{claimId:string}&{claimKey:string}&{fromStateName:string}&{filters:string}&{type:string}&{page:int}',
        template: '<claim-video></claim-video>',
        onExit: ($location: ng.ILocationService) => {
          'ngInject';
          $location.search('claimId', null).replace();
          $location.search('fromStateName', null).replace();
        },
        data: {
          name: 'WatchClaimVideo',
          tags: [Tags.claims],
          title: 'T_CLAIM_VIDEO',
        },
      })
      .state({
        name: 'modal.claimBalanceTypeInfo',
        url: '/glossary/:term/?{filters:string}&{type: string}',
        templateUrl: claimBalanceTypeInfoTemplate,
        controller: 'claimsAndAccountsModalController as $ctrl',
        data: {
          name: 'ClaimBalanceDefinition',
          tags: [Tags.claims, Tags.infoModal],
          title: 'T_BALANCE_INFO',
        },
      })
      .state({
        name: 'modal.balanceTypeInfo',
        url: '/glossary/:accountType/:term/',
        templateUrl: balanceTypeInfoTemplate,
        controller: 'financialAccountsModalController as $ctrl',
        data: {
          name: 'FinancialBalanceDefinition',
          tags: [Tags.planBalances, Tags.infoModal],
          title: 'T_BALANCE_INFO',
        },
      })
      .state({
        name: 'modal.dcsaFaq',
        url: '/dcsa-faq?{accountType:string}',
        templateUrl: dcsaFaqTemplate,
        controller: 'financialAccountsModalController as $ctrl',
        data: {
          name: 'DcsaFaq',
          tags: [Tags.planBalances, Tags.infoModal],
          title: 'T_FAQ_DCSA',
        },
      })
      .state({
        name: 'modal.eobError',
        url: '/eob-error?{claimType:string}&{claimKey:string}&{claimReference:string}&{eobErrorFrom:string}&{errorUID:string}',
        template: '<eob-error></eob-error>',
        onExit: ($location: ng.ILocationService) => {
          'ngInject';
          $location.search('errorUID', null).replace();
          $location.search('eobErrorFrom', null).replace();
        },
        data: {
          name: 'modal.eobError',
        },
      })
      .state({
        name: 'modal.fsaFaq',
        url: '/fsa-faq?{accountType:string}',
        templateUrl: fsaFaqTemplate,
        controller: 'financialAccountsModalController as $ctrl',
        data: {
          name: 'FsaFaq',
          tags: [Tags.planBalances, Tags.fsa, Tags.infoModal],
          title: 'T_FAQ_FSA',
        },
      })
      .state({
        name: 'modal.hraHowItWorks',
        url: '/hra-how-it-works?{accountType:string}',
        templateUrl: hraHowItWorksTemplate,
        controller: 'financialAccountsModalController as $ctrl',
        data: {
          name: 'HraHowItWorks',
          tags: [Tags.planBalances, Tags.hra, Tags.infoModal],
          title: 'T_HOW_HRA_WORKS',
        },
      })
      .state({
        name: 'modal.hraFaq',
        url: '/hra-faq?{accountType:string}',
        templateUrl: hraFaqTemplate,
        controller: 'financialAccountsModalController as $ctrl',
        data: {
          name: 'HraFaq',
          tags: [Tags.planBalances, Tags.hra, Tags.infoModal],
          title: 'T_FAQ_HRA',
        },
      })
      .state({
        name: 'modal.hsaHowItWorks',
        url: '/hsa-how-it-works?{accountType:string}',
        templateUrl: hsaHowItWorksTemplate,
        controller: 'financialAccountsModalController as $ctrl',
        data: {
          name: 'HsaHowItWorks',
          tags: [Tags.planBalances, Tags.hsa, Tags.infoModal],
          title: 'T_HOW_HSA_WORKS',
        },
      })
      .state({
        name: 'modal.hsaFaq',
        url: '/hsa-faq?{accountType:string}',
        templateUrl: hsaFaqTemplate,
        controller: 'financialAccountsModalController as $ctrl',
        data: {
          name: 'HsaFaq',
          tags: [Tags.planBalances, Tags.hsa, Tags.infoModal],
          title: 'T_FAQ_HSA',
        },
      })
      .state({
        name: 'modal.hcsaFaq',
        url: '/hcsa-faq?{accountType:string}',
        templateUrl: hcsaFaqTemplate,
        controller: 'financialAccountsModalController as $ctrl',
        data: {
          name: 'HcsaFaq',
          tags: [Tags.planBalances, Tags.infoModal],
          title: 'T_FAQ_HCSA',
        },
      })
      .state({
        name: 'modal.mraFaq',
        url: '/mra-faq?{accountType:string}',
        templateUrl: mraFaqTemplate,
        controller: 'financialAccountsModalController as $ctrl',
        data: {
          name: 'MraFaq',
          tags: [Tags.planBalances, Tags.infoModal],
          title: 'T_FAQ_MRA',
        },
      })
      .state({
        name: 'modal.claimDeniedExplanation',
        url: '/claim-denied-explanation',
        templateUrl: claimDeniedExplanationTemplate,
        controller: 'claimDeniedExplanationController as $ctrl',
        data: {
          name: 'ClaimDeniedExplanation',
          tags: [Tags.claims, Tags.infoModal],
          title: 'T_CLAIM_DENIED_EXPLANATION',
        },
      })
      .state({
        name: 'modal.dentalDeductibleExplanation',
        url: '/dental-deductible-explanation',
        templateUrl: dentalDeductibleExplanationTemplate,
        data: {
          name: 'DentalDeductibleExplanation',
          tags: [Tags.planBalances, Tags.dental, Tags.infoModal],
          title: 'T_DENTAL_DEDUCTIBLE_EXPLANATION',
        },
      })
      .state({
        name: 'modal.markAsPaidExplanation',
        url: '/mark-as-paid-explanation?{claimKey:string}&{type:string}&{claimId:string}&{explanationFrom:string}&' +
          '{fromStateName:string}',
        templateUrl: markAsPaidExplanationTemplate,
        controller: 'markAsPaidExplanationController as $ctrl',
        onExit: ($location: ng.ILocationService) => {
          'ngInject';
          $location.search('claimId', null).replace();
          $location.search('explanationFrom', null).replace();
          $location.search('fromStateName', null).replace();
        },
        data: {
          name: 'MarkPaidDisclaimer',
          tags: [Tags.claims],
          title: 'T_MARK_AS_PAID_EXPLANATION',
        },
      })
      .state({
        name: 'modal.dentalMaxExplanation',
        url: '/dental-max-explanation',
        templateUrl: dentalMaxExplanationTemplate,
        data: {
          name: 'DentalAnnualMaxBenefitsExplanation',
          tags: [Tags.planBalances, Tags.dental, Tags.infoModal],
          title: 'T_DENTAL_MAX_EXPLANATION',
        },
      })
      .state({
        name: 'modal.dentalAccountExplanation',
        url: '/dental-account-explanation',
        templateUrl: dentalAccountExplanationTemplate,
        data: {
          name: 'DentalDeductible&MaxBenefitsExplanation',
          tags: [Tags.planBalances, Tags.dental, Tags.infoModal],
          title: 'T_DENTAL_ACCOUNT_EXPLANATION',
        },
      })
      .state({
        name: 'modal.medicalAccountExplanation',
        url: '/medical-account-explanation/:network/:paymentType',
        templateUrl: medicalAccountExplanationTemplate,
        controller: 'medicalAccountsModalController as $ctrl',
        data: {
          name: 'MedicalAccumulatorExplanation',
          tags: [Tags.planBalances, Tags.medical, Tags.infoModal],
          title: 'T_MEDICAL_ACCOUNT_EXPLANATION',
        },
      })
      .state({
        name: 'modal.orthoMaxExplanation',
        url: '/ortho-max-explanation',
        templateUrl: orthoMaxExplanationTemplate,
        controller: 'orthoMaxExplanationController as $ctrl',
        data: {
          name: 'DentalLifetimeOrthoMaxExplanation',
          tags: [Tags.planBalances, Tags.dental, Tags.infoModal],
          title: 'T_ORTHO_MAX_EXPLANATION',
        },
      })
      .state({
        name: 'modal.hraapIndividual',
        url: '/hraap-individual',
        templateUrl: hraapIndividualTemplate,
        data: {
          name: 'SplitHraIndividualAccessPointExplanation',
          tags: [Tags.planBalances, Tags.splitHra, Tags.infoModal],
          title: 'T_HRAAP_INDIVIDUAL',
        },
      })
      .state({
        name: 'modal.hraapAmountUsed',
        url: '/hraap-amount-used',
        templateUrl: hraapAmountUsedTemplate,
        controller: 'hraapController as $ctrl',
        data: {
          name: 'SplitHraIndividualAmountUsedExplanation',
          tags: [Tags.planBalances, Tags.splitHra, Tags.infoModal],
          title: 'T_HRAAP_AMOUNT_USED',
        },
      })
      .state({
        name: 'modal.hraapFamily',
        url: '/hraap-family',
        templateUrl: hraapFamilyTemplate,
        controller: 'hraapController as $ctrl',
        data: {
          name: 'SplitHraFamilyAccessPointExplanation',
          tags: [Tags.planBalances, Tags.splitHra, Tags.infoModal],
          title: 'T_HRAAP_FAMILY',
        },
      })
      .state({
        name: 'modal.editClaimNote',
        url: '/edit-claim-note?{claimNoteClaimKey:string}&{claimNoteType:string}',
        template: '<edit-claim-note></edit-claim-note>',
        controller: 'editClaimNoteController as $ctrl',
        onExit: ($location: ng.ILocationService) => {
          'ngInject';
          $location.search('claimNoteType', null).replace();
          $location.search('claimNoteClaimKey', null).replace();
        },
        data: {
          name: 'EditClaimNote',
          tags: [Tags.claims],
          title: 'T_EDIT_CLAIM_NOTE',
        },
      })
      .state({
        name: 'modal.accountFaq',
        url: '/account-faq/:id',
        template: '<custom-faq></custom-faq>',
        data: {
          name: 'CustomAccountFaq',
          tags: [Tags.planBalances, Tags.infoModal],
          title: 'T_FAQ_ACCOUNT',
        },
      })
      .state({
        name: 'modal.payNow',
        url: '/pay-now?{filters:string}&{type:string}&{useSessionFilters:bool}&{page:int}' +
        '&{claimId:string}&{fromStateName:string}&{claimPayKey:string}&{claimKey:string}',
        template: '<pay-now-modal></pay-now-modal>',
        onExit: ($location: ng.ILocationService) => {
          'ngInject';
          $location.search('claimId', null).replace();
          $location.search('fromStateName', null).replace();
          $location.search('claimPayKey', null).replace();
        },
        data: {
          name: 'PayNow',
          title: 'T_PAY_NOW',
        },
      })
      .state({
        name: 'modal.mrMedicalExplanationOfTerms',
        url: '/medical-explanation-of-terms',
        templateUrl: mrMedicalExplanationOfTermsTemplate,
        data: {
          title: 'T_MR_MEDICAL_EXPLANATION_OF_TERMS',
        },
      });
  }
}
