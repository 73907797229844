import allClaimsFaqTemplate from 'views/modals/all-claims-faq.html';
import { IClientConfig, IFaq } from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';

export class AllClaimsFaqComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = AllClaimsFaqController;
    this.templateUrl = allClaimsFaqTemplate;
  }
}

export class AllClaimsFaqController implements ng.IComponentController {
  public customizedFaq: IFaq[];
  public standardFaq: IFaq[];
  public useCustomizedFaq: boolean;

  constructor(
    private $state: ng.ui.IStateService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.FAQ);
    $translatePartialLoader.addPart(Dictionary.ALL_CLAIMS_FAQ);

    this.standardFaq = [{
      question: 'Q_PROCESS_CLAIM_TIME',
      answer: 'A_PROCESS_CLAIM_TIME',
    }, {
      question: 'Q_MAKE_ONLINE_PAYMENT',
      answer: 'A_MAKE_ONLINE_PAYMENT',
    }, {
      question: 'Q_FIND_CLAIMS',
      answer: 'A_FIND_CLAIMS',
    }, {
      question: 'Q_CLAIM_STATUS_IN_PROCESS',
      answer: 'A_CLAIM_STATUS_IN_PROCESS',
    }, {
      question: 'Q_CLAIM_STATUS_PROCESSED',
      answer: 'A_CLAIM_STATUS_PROCESSED',
    }, {
      question: 'Q_REDUCE_PAPERWORK',
      answer: 'A_REDUCE_PAPERWORK',
    }];

    this.userService.getHeartbeat()
      .flatMap(({data}) => this.targetingService.getClientConfig(data.rallyId))
      .filter(clientConfig => !!(clientConfig && clientConfig.contentOverrides && clientConfig.contentOverrides.customAllClaimsFAQ))
      .subscribe(clientConfig => {
        this.customizedFaq = clientConfig.contentOverrides.customAllClaimsFAQ;
        this.setCustomizedFaq(clientConfig);
      });
  }

  private setCustomizedFaq(clientConfig: IClientConfig): void {
    this.useCustomizedFaq = (clientConfig.contentOverrides.customAllClaimsFAQ !== undefined
      && clientConfig.contentOverrides.customAllClaimsFAQ.length > 0);
  }
}
