import { IClaimsInfoPacket, PostMessage, RallyPayReact } from '@rally/rallypay';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectId } from 'scripts/selectors/locale-selectors';
import { getProfile } from 'scripts/thunks/profile-service-thunks';
import CONFIG from 'scripts/util/constants/config';

interface IRallyPayProps {
  claimsInfoPacket: IClaimsInfoPacket;
  locale: string;
  onSubmit: PostMessage;
  token: string;
}

export const RallyPayWidget = ({ claimsInfoPacket, locale, onSubmit, token }: IRallyPayProps): ReactElement<IRallyPayProps> => (
  <RallyPayReact
    environment={CONFIG.ARCADE_WEB_RALLY_PAY_ENVIRONMENT}
    locale={locale}
    token={token}
    claimsInfoPacket={claimsInfoPacket}
    onSubmit={onSubmit}
  />
);

export const mapStateToProps = (state: IReduxState) => ({
  locale: selectId(state),
});

export default withProvider(connect(
  mapStateToProps, {
    getProfile,
  })(RallyPayWidget));
