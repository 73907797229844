import { MembershipCategory } from '../../api/profile/profile.interfaces';

export enum ContentOverrideCondition {
  CustomGlossary = 'CUSTOM_GLOSSARY',
  Expatriate = 'EXPATRIATE',
}

export interface IContentOverrideRules  {
  EXPATRIATE: (input: string, result: any) => string;
  CUSTOM_GLOSSARY: (input: string, result: any) => string;
}

export interface IContentOverrideConditions {
  EXPATRIATE: (membershipCategory: MembershipCategory[]) => boolean;
  CUSTOM_GLOSSARY: (glossary: Array<{[glossary: string]: {header: string, body: string}}>) => object;
}
