import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';

export class FeatureFlagDirective implements ng.IDirective {
  public restrict = 'A';
  public scope = false;

  constructor(private Environment: IEnvironmentConstants) {}

  public link = (scope, element, attrs) => {
    const prefix = 'ARCADE_FEATURES_';
    const requestedFeature = attrs.featureFlag;
    let featureEnabled = false;

    switch (this.Environment.FEATURE_FLAGS[prefix + requestedFeature]) {
      case 'yes':
      case 'true':
      case true:
      case '1':
      case 1:
        featureEnabled = true;
        break;
    }

    if ((!featureEnabled && attrs.showWhenFalse === undefined) || (featureEnabled && attrs.showWhenFalse !== undefined)) {
      element.remove();
      element = null;
    }
  }

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = (Environment: IEnvironmentConstants) => {
      'ngInject';
      return new FeatureFlagDirective(Environment);
    };
    return directive;
  }
}
