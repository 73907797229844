import 'amplitude-js/amplitude-snippet.min';
import { IAmplitude } from '../api/tracking/amplitude.interfaces';
import { IEnvironmentConstants } from '../util/constants/environment.interfaces';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    amplitude: IAmplitude;
  }
}

export class Amplitude {
  constructor(Environment: IEnvironmentConstants) {
    'ngInject';

    if (Environment.CONFIG.ARCADE_WEB_AMPLITUDE_API_KEY && window.amplitude) {
      window.amplitude.getInstance().init(Environment.CONFIG.ARCADE_WEB_AMPLITUDE_API_KEY, null, {
        logLevel: 'DEBUG',
        deviceIdFromUrlParam: true,
        includeGclid: true,
        includeReferrer: true,
        includeUtm: true,
        domain: window.location.hostname,
        forceHttps: Environment.CONFIG.ARCADE_WEB_ENVIRONMENT_NAME !== 'Local',
      });
    }
  }
}
