import { memberServicesPhone } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import pcpChangeUnavailableTemplate from 'views/modals/pcp-change-unavailable.html';
import { Product } from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';

export class PcpChangeUnavailableComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  constructor() {
    this.controller = PcpChangeUnavailableController;
    this.templateUrl = pcpChangeUnavailableTemplate;
  }
}
export class PcpChangeUnavailableController {
  public memberServicesPhone: IResource;
  public showCallYou: boolean;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_INFO);
    $translatePartialLoader.addPart(Dictionary.CONTACT_US);
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.PCP);

    this.memberServicesPhone = memberServicesPhone;
  }
  public $onInit(): void {
    this.userService.getHeartbeat()
      .flatMap(rsp => this.profileService.getProducts(rsp.data.rallyId))
      .subscribe(rsp => {
        this.showCallYou = !!rsp.data.products.easyConnect && rsp.data.products.easyConnect.productId === Product.EasyConnect;
      }, console.warn);
  }
}
