import {
  getClaimsPreferencesError,
  getClaimsPreferencesLoading,
  getClaimsPreferencesSuccess,
  getProductsError,
  getProductsLoading,
  getProductsSuccess,
  getProfileError,
  getProfileLoading,
  getProfileSuccess,
} from 'scripts/actions/profile-service-actions';
import { ProfileApi } from 'scripts/api/profile/profile-api';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { selectRallyId } from 'scripts/selectors/user-service-selectors';

export function getProfile(): ArcadeThunkAction<void> {
  return async (dispatch, getState) => {
    try {
      dispatch(getProfileLoading());
      const rallyId = selectRallyId(getState());
      const { data } = await ProfileApi.getProfile(rallyId);
      let selectedProfile = data.currentUser;
      let selectedProfileDependentSeqNum;
      try {
        selectedProfileDependentSeqNum = sessionStorage.getItem('arcade.selectedProfileDependentSeqNum');
      } catch (e) {
        console.warn('Could not get selected profile dependent sequence number from session storage', e);
      }
      if (selectedProfileDependentSeqNum) {
        selectedProfile = data.dependents.find(d => d.dependentSeqNum === selectedProfileDependentSeqNum) || selectedProfile;
      }
      dispatch(getProfileSuccess({profile: data, selectedProfile}));
    } catch (error) {
      dispatch(getProfileError());
    }
  };
}

export function getClaimsPreferences(): ArcadeThunkAction<void> {
  return async (dispatch, getState) => {
    try {
      dispatch(getClaimsPreferencesLoading());
      const rallyId = selectRallyId(getState());
      const { data } = await ProfileApi.getClaimsPreferences(rallyId);
      dispatch(getClaimsPreferencesSuccess(data));
    } catch (error) {
      dispatch(getClaimsPreferencesError());
    }
  };
}

export function getProducts(): ArcadeThunkAction<void> {
  return async (dispatch, getState) => {
    try {
      dispatch(getProductsLoading());
      const rallyId = selectRallyId(getState());
      const { data: { products } } = await ProfileApi.getProducts(rallyId);
      dispatch(getProductsSuccess(products));
    } catch (error) {
      dispatch(getProductsError());
    }
  };
}
