import { userUris } from 'scripts/util/uri/uri';
import { CacheName } from '../api.interfaces';
import HttpClient from '../http-client';
import { IHeartbeatResponse } from './user.interfaces';

function getHeartbeat(): Promise<IHeartbeatResponse> {
  const url = userUris.heartbeat();
  return HttpClient.get(url, { cacheName: CacheName.User });
}

export const UserApi = {
  getHeartbeat,
};
