import donutTemplate from 'views/ui/donut.html';

export class DonutComponent implements ng.IComponentOptions {
  public controller = DonutController;
  public bindings = {
    data: '<',
    diameter: '<?',
    donutWidth: '@?',
  };
  public templateUrl = donutTemplate;
  public transclude = true;
}

export class DonutController implements ng.IComponentController {
  public data: number[];
  public diameter: number;
  public donutWidth: number;
  public segments: string[];

  constructor() {
    this.diameter = this.diameter || 250;
    this.donutWidth = this.donutWidth || 40;
  }

  public $onChanges(changesObj: {[property: string]: angular.IChangesObject<number>}): void {
    if (changesObj.data.currentValue) {
      this.segments = this.generatePathData(this.data, this.diameter);
    }
  }

  private generatePathData(data: number[], diameter: number): string[] {
    const radius = diameter / 2;
    const total = data.reduce((prev, current) => current + prev, 0);
    let startAngle = -90;
    let endAngle = startAngle;

    // to avoid NaN errors that stem from dividing by zero
    if (total === 0) {
      return;
    }

    return data.map(val => {
      const percentage = val / total;
      // if the percentage is 100% (meaning this is the only non-zero data point) then set the angle to be just
      // shy of a full 360 so that the donut renders properly despite only having one section
      const angle = percentage === 1 ? 359.5 : 360 * percentage;

      startAngle = endAngle;
      endAngle = startAngle + angle;

      const x1 = this.round(radius + radius * Math.cos(Math.PI * startAngle / 180));
      const y1 = this.round(radius + radius * Math.sin(Math.PI * startAngle / 180));

      const x2 = this.round(radius + radius * Math.cos(Math.PI * endAngle / 180));
      const y2 = this.round(radius + radius * Math.sin(Math.PI * endAngle / 180));

      return `M ${radius}, ${radius} ` +
             `L ${x1}, ${y1} ` +
             `A ${radius}, ${radius} ` +
             `0 ${((endAngle - startAngle > 180) ? 1 : 0)}, 1 ${x2}, ${y2} z`;
    });
  }

  private round(num: number): number {
    const precision = Math.pow(10, 8);
    return Math.round(num * precision) / precision;
  }
}
