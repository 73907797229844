import angular from 'angular';
import CONFIG from './config';
import { IConfig, IEnvironmentConstants, IFeatureFlags } from './environment.interfaces';
import FEATURE_FLAGS from './feature-flag';

export class EnvironmentConstants implements IEnvironmentConstants {
  static get constants(): IEnvironmentConstants {
    return new EnvironmentConstants();
  }

  public CONFIG: IConfig;
  public FEATURE_FLAGS: IFeatureFlags;

  constructor() {
    this.CONFIG = CONFIG;
    this.FEATURE_FLAGS = FEATURE_FLAGS;
  }
}

angular
  .module('arcade.environment', [])
  .constant('Environment', EnvironmentConstants.constants);
