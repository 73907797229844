import { ITrackingEventRequest, TrackingClickType, TrackingTriggerType } from 'scripts/api/tracking/tracking.interfaces';
import { ITrackingService } from 'scripts/api/tracking/tracking.service';
import { IRootScope } from 'scripts/arcade.module.interfaces';
import { IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';
import { openSurvey } from 'scripts/util/iperceptions/iperceptions';
import { ILocaleService } from 'scripts/util/locale/locale.service';
import { formatCamelCasedTrackingString, formatTrackingString, getPlacement } from 'scripts/util/tracking/tracking-helper';
import {
  ISharedHeaderFooterActionEvent,
  ISharedHeaderFooterButtonAction,
  ISharedHeaderFooterEventsController,
  ISharedHeaderFooterTrackingEvent,
} from './shared-header-footer-events.interfaces';

export class SharedHeaderFooterEventsController implements ISharedHeaderFooterEventsController {
  constructor(
    private $location: ng.ILocationService,
    private $rootScope: IRootScope,
    private $window: ng.IWindowService,
    private localeService: ILocaleService,
    private Environment: IEnvironmentConstants,
    private trackingService: ITrackingService,
  ) {
    'ngInject';
  }

  public addSharedHeaderFooterListeners(sharedHeaderOrFooterElement: Element): void {
    // In order to listen for tracking and other generic events,
    // we need to add event listeners to the footer and header.

    // "arcade-tracking" emits custom tracking events. The
    // internal payload is on the detail property. It provides the
    // event that triggered the tracking event, along with a tracking
    // id and an array of features relevant to that event.

    // "arcade-action" emits all other events. Currently it's only
    // used for the iPerceptions link of the footer which is not
    // shown for M&R. It will also emit locale change events.

    // binding so that the this in the callback references the SharedHeaderFooterEventsController instead of the arcade-header/arcade-footer
    sharedHeaderOrFooterElement.addEventListener(
      'arcade-tracking',
      e => this.performSharedHeaderFooterArcadeTracking(e as ISharedHeaderFooterTrackingEvent),
    );
    sharedHeaderOrFooterElement.addEventListener(
      'arcade-action',
      e => this.performSharedHeaderFooterArcadeAction(e as ISharedHeaderFooterActionEvent),
    );
  }

  public performSharedHeaderFooterArcadeTracking(event: ISharedHeaderFooterTrackingEvent): void {
    const isLink = event.detail.href !== undefined;
    let isInternalRedirect;
    let isExternalLink;
    let anchor;
    if (isLink) {
      anchor = document.createElement('a');
      anchor.href = event.detail.href;
      isInternalRedirect = anchor.pathname.split('/').pop() === 'internal-redirect';
      isExternalLink = isInternalRedirect || anchor.hostname !== this.$window.location.hostname;
    }
    const sharedHeaderFooterTrackingEventRequest = this.getSharedHeaderFooterTrackingEventRequest(event);

    if (isLink && isExternalLink) {
      sharedHeaderFooterTrackingEventRequest.clickType = TrackingClickType.ExternalLink;
      this.setSharedHeaderFooterTrackingEventExternalLinkProps(sharedHeaderFooterTrackingEventRequest, anchor, isInternalRedirect);
      this.trackingService.queueEvent(sharedHeaderFooterTrackingEventRequest);
      this.trackingService.postEvents().subscribe();
    } else {
      sharedHeaderFooterTrackingEventRequest.clickType = isLink ? TrackingClickType.StateChange : TrackingClickType.PageEvent;
      this.trackingService.queueEvent(sharedHeaderFooterTrackingEventRequest);
    }
  }

  public performSharedHeaderFooterArcadeAction(event: ISharedHeaderFooterActionEvent): void {
    if (event.detail.action === ISharedHeaderFooterButtonAction.localeChanged) {
      this.localeService.set(event.detail.props.locale);
    } else if (event.detail.action === ISharedHeaderFooterButtonAction.openIPerceptionsSurvey) {
      openSurvey();
    } else if (event.detail.action === ISharedHeaderFooterButtonAction.toggleNav) {
      this.toggleNav(event);
    }
  }

  public toggleNav(event: ISharedHeaderFooterActionEvent): void {
      this.$rootScope.navOpen = event.detail.props.isNavOpen;
      this.$rootScope.$apply();
  }

  public setSharedHeaderFooterTrackingEventExternalLinkProps(
    event: ITrackingEventRequest,
    anchor: HTMLAnchorElement,
    isInternalRedirect: boolean,
  ): void {
    if (isInternalRedirect) {
      const decodedUrl = decodeURIComponent(anchor.href.split('deepLink=').pop());
      const redirectUrlAnchor = document.createElement('a');
      redirectUrlAnchor.href = decodedUrl;
      event.externalDomain = redirectUrlAnchor.hostname;
      event.externalUrl = decodedUrl.split('?')[0];
    } else {
      event.externalDomain = anchor.hostname;
      event.externalUrl = anchor.href.split('?')[0];
    }
  }

  private getSharedHeaderFooterTrackingEventRequest(event: ISharedHeaderFooterTrackingEvent): ITrackingEventRequest {
    const element = event.detail.event.target as HTMLElement;
    const featureList = event.detail.features.map(feature => formatCamelCasedTrackingString(feature));
    const placement = getPlacement(element);
    const actionName = formatTrackingString(event.detail.track);

    const trackingEvent = {
      trigger: TrackingTriggerType.Click,
      uri: this.$location.url(),
      serviceVersion: this.Environment.CONFIG.ARCADE_WEB_VERSION,
      featureList,
      actionName,
      placement,
    } as ITrackingEventRequest;

    return trackingEvent;
  }
}
