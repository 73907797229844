import ngRedux from 'ng-redux';
import { changePopulation } from 'scripts/actions/population-actions';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { IProfileUser } from '../../api/profile/profile.interfaces';
import { getPopulationByProfile, getPopulationByUri } from './population';
import { IPopulation } from './population.interfaces';

export interface IPopulationService {
  getPopulation(): IPopulation | undefined;
  setPopulationByProfile(profileUser: IProfileUser): IPopulation;
  setPopulationByUri(uri?: string): IPopulation;
}

export class PopulationService implements IPopulationService {
  constructor(private $ngRedux: ngRedux.INgRedux) {
    'ngInject';
  }

  public getPopulation(): IPopulation | undefined {
    return (this.$ngRedux.getState() as IReduxState).population;
  }

  public setPopulationByProfile(profileUser: IProfileUser): IPopulation {
    const population = getPopulationByProfile(profileUser);
    this.$ngRedux.dispatch(changePopulation(population));
    return population;
  }

  public setPopulationByUri(uri: string = ''): IPopulation {
    const population = getPopulationByUri(uri);
    this.$ngRedux.dispatch(changePopulation(population));
    return population;
  }
}
