import { createSelector } from 'reselect';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';

const selectClaims = (state: IReduxState) => state.claims;

export const selectSummary = createSelector(
  selectClaims,
  claims => claims.summary,
);

export const selectSummaryData = createSelector(
  selectSummary,
  summary => summary.data,
);

export const selectSummaryLoading = createSelector(
  selectSummary,
  summary => summary.loading,
);

export const selectSummaryError = createSelector(
  selectSummary,
  summary => summary.error,
);
