import { FilterFactory } from 'angular';
import { contentOverrideConditions, contentOverrideRules } from './content-override.constants';
import { ContentOverrideCondition } from './content-override.interfaces';

export class ContentOverrideFilter {
  public static Factory(): ng.Injectable<FilterFactory> {
    return () => {
      return ContentOverrideFilter.do;
    };
  }
  public static do(input: string, name: ContentOverrideCondition, ...state: any[]): string {
    const condition: (state: any[]) => any = contentOverrideConditions[name];
    const rule = contentOverrideRules[name];
    return rule(input, condition(state));
  }
}
