import { LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import CONFIG from 'scripts/util/constants/config';
import { ArcadeAuthType } from '../constants/environment.interfaces';
import { IPopulation } from '../population/population.interfaces';
import { getResource } from '../resource/resource';
import { IAlternativeUrls, IBaseUrls } from './uri.interfaces';

const baseUrls = getBaseUrls();
const baseApiUri = (baseUrls.api || '') + '/rest/[api]/v[version]/[method]';
const baseAuthUri = baseApiUri + '/u/[rallyId]';

export const activateUris = {
  steps: (rallyId: string) => {
    const stepsUri = baseAuthUri;
    return constructUri(stepsUri, 'edge-dashboard/activate-steps', '6', 'stepplans', { rallyId }, { allowCreation: 'false' });
  },
  stepsV4: (rallyId: string) => {
    const stepsUri = baseAuthUri;
    return constructUri(stepsUri, 'edge-dashboard/activate-steps', '4', 'stepplans', { rallyId }, { allowCreation: 'false' });
  },
};

export const claimUris = {
  alerts: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'alerts', { rallyId }, queryParams);
  },
  financial: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'financial', { rallyId }, queryParams);
  },
  financialCsv: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'csv/financial', { rallyId }, queryParams);
  },
  financialDetails: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'detail/financial', { rallyId }, queryParams);
  },
  financialDocument: (rallyId: string, documentType: string, documentKey: string, queryParams: object) => {
    const claimDocumentUri = baseAuthUri + '/type/[documentType]/key/[documentKey]';
    return constructUri(
      claimDocumentUri, 'edge-dashboard/claims', '1', 'document/financial', { rallyId, documentType, documentKey }, queryParams,
    );
  },
  healthcare: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'healthcare', { rallyId }, queryParams);
  },
  healthcareV3: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '3', 'healthcare', { rallyId }, queryParams);
  },
  healthcareCsv: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'csv/healthcare', { rallyId }, queryParams);
  },
  healthcareDetails: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'detail/healthcare', { rallyId }, queryParams);
  },
  healthcareDocument: (rallyId: string, eobDocument: string, queryParams: object) => {
    const claimDocumentUri = baseAuthUri + '/eob/[eobDocument]';
    return constructUri(claimDocumentUri, 'edge-dashboard/claims', '1', 'document/healthcare', { rallyId, eobDocument }, queryParams);
  },
  manage: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'manage', { rallyId });
  },
  note: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'note', { rallyId }, queryParams);
  },
  rallyPayClaimDetails: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'detail/rallypay', { rallyId }, queryParams);
  },
  summary: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'summary', { rallyId }, queryParams);
  },
  totals: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'totals', { rallyId }, queryParams);
  },
};

export const documentUris = {
  claimLetterPdf: (rallyId: string, documentId: string, queryParams: object) => {
    const claimLetterUri = baseAuthUri + '/id/[documentId]';
    return constructUri(claimLetterUri, 'edge-dashboard/documents', '1', 'claims/letter', { rallyId, documentId }, queryParams);
  },
  claimLettersList: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/documents', '1', 'claims/letter', { rallyId });
  },
  financialClaimsForm: (rallyId: string, documentType: string, documentKey: string, queryParams: object) => {
    const claimDocumentUri = baseAuthUri + '/type/[documentType]/key/[documentKey]';
    return constructUri(claimDocumentUri, 'edge-dashboard/documents', '1', 'claims/financial', { rallyId, documentType, documentKey },
      queryParams);
  },
  healthcareClaimsForm: (rallyId: string, documentType: string, documentKey: string, queryParams: object) => {
    const claimDocumentUri = baseAuthUri + '/type/[documentType]/key/[documentKey]';
    return constructUri(claimDocumentUri, 'edge-dashboard/documents', '1', 'claims/healthcare', { rallyId, documentType, documentKey },
      queryParams);
  },
  healthStatementList: (rallyId: string, queryParams?: object) => {
    const healthStatementUri = baseAuthUri + '/list';
    return constructUri(healthStatementUri, 'edge-dashboard/documents', '1', 'claims/healthStatement', { rallyId }, queryParams);
  },
  healthStatementPdf: (rallyId: string, documentId: string) => {
    const healthStatementUri = baseAuthUri + '/id/[documentId]';
    return constructUri(healthStatementUri, 'edge-dashboard/documents', '1', 'claims/healthStatement', { rallyId, documentId });
  },
};

export const ledgerUris = {
  balance: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/ledger', '1', 'balance', { rallyId });
  },
};

export const planUris = {
  benefits: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/plans', '1', 'benefits', { rallyId }, queryParams);
  },
  familyBenefits: (rallyId: string, queryParams: object) => {
    const familyBenefitsUri = baseAuthUri + '/family';
    return constructUri(familyBenefitsUri, 'edge-dashboard/plans', '2', 'benefits', { rallyId }, queryParams);
  },
  accumulator: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/plans', '1', 'accumulator', { rallyId }, queryParams);
  },
  familyAccumulator: (rallyId: string, queryParams: object) => {
    const familyAccumulatorsUri = baseAuthUri + '/family';
    return constructUri(familyAccumulatorsUri, 'edge-dashboard/plans', '2', 'accumulator', { rallyId }, queryParams);
  },
  idCards: (rallyId: string, cardType: string, queryParams?: object) => {
    const idCardsUri = baseAuthUri + '/cardType/[cardType]';
    return constructUri(idCardsUri, 'edge-dashboard/plans', '2', 'idcards', { rallyId, cardType }, queryParams);
  },
};

export const profileUris = {
  autoPaymentPreferences: (rallyId: string) => {
    const autoPaymentUri = baseAuthUri + '/autosubmission';
    return constructUri(autoPaymentUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  claimsPreferences: (rallyId: string) => {
    const claimsUri = baseAuthUri + '/claims';
    return constructUri(claimsUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  decryptProvider: (rallyId: string, queryParams: object) => {
    const decryptProviderUri = baseAuthUri + '/decrypt';
    return constructUri(decryptProviderUri, 'edge-dashboard/profile', '1', 'redirect', { rallyId }, queryParams);
  },
  eftPreferences: (rallyId: string) => {
    const eftUri = baseAuthUri + '/eft';
    return constructUri(eftUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  healthcareCoverages: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri + '/healthcarecoverages', 'edge-dashboard/profile', '1', 'profile', { rallyId }, queryParams);
  },
  paperlessPreferences: (rallyId: string) => {
    const paperlessUri = baseAuthUri + '/paperless';
    return constructUri(paperlessUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  fpcPrimaryCare: (rallyId: string) => {
    const primaryCareUri = baseAuthUri + '/primarycare/fpc';
    return constructUri(primaryCareUri, 'edge-dashboard/profile', '2', 'doctor', { rallyId });
  },
  primaryCare: (rallyId: string, queryParams?: object) => {
    const primaryCareUri = baseAuthUri + '/primarycare';
    return constructUri(primaryCareUri, 'edge-dashboard/profile', '2', 'doctor', { rallyId }, queryParams);
  },
  products: (rallyId: string) => {
    const productsUri = baseAuthUri + '/products';
    return constructUri(productsUri, 'edge-dashboard/profile', '1', 'profile', { rallyId });
  },
  profile: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/profile', '2', 'profile', { rallyId });
  },
  profileInfoForRallyPay: (rallyId: string) => {
    const profileInfoForRallyPayUri = baseAuthUri + '/token';
    return constructUri(profileInfoForRallyPayUri, 'edge-dashboard/profile', '1', 'rallypay', { rallyId });
  },
  providerVideoKey: (rallyId: string, queryParams: object) => {
    const providerVideoKeyUri = baseAuthUri + '/providervideokey';
    return constructUri(providerVideoKeyUri, 'edge-dashboard/profile', '1', 'profile', { rallyId }, queryParams);
  },
  activateProviderVideoKey: (rallyId: string, queryParams: object) => {
    const providerVideoKeyUri = baseAuthUri + '/activatevideokey';
    return constructUri(providerVideoKeyUri, 'edge-dashboard/profile', '1', 'video', { rallyId }, queryParams);
  },
  referrals: (rallyId: string) => {
    const referralsUri = baseAuthUri + '/referrals';
    return constructUri(referralsUri, 'edge-dashboard/profile', '1', 'doctor', { rallyId });
  },
  setPrimaryCare: (rallyId: string) => {
    const primaryCareUri = baseAuthUri + '/primarycare';
    return constructUri(primaryCareUri, 'edge-dashboard/profile', '1', 'doctor', { rallyId });
  },
  userPreferences: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/profile', '2', 'preferences', { rallyId });
  },
};

export const targetingUris = {
  alerts: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/targeting', '1', 'alerts', { rallyId });
  },
  campaigns: (rallyId: string, queryParams: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/targeting', '2', 'campaigns', { rallyId }, queryParams);
  },
  clientConfig: (configId: string, queryParams: object) => {
    const clientConfigUri = baseApiUri + '/[configId]/configuration';
    return constructUri(clientConfigUri, 'edge-dashboard/targeting', '2', 'client', { configId }, queryParams);
  },
  csFaqCustomizations: (planName: string) => {
    const csFaqCustomizationsUri = baseApiUri + '/[planName]/custom';
    return constructUri(csFaqCustomizationsUri, 'edge-dashboard/targeting', '1', 'client', { planName });
  },
  incentives: (rallyId: string) => {
    const incentivesUri = baseAuthUri + '/summary';
    return constructUri(incentivesUri, 'edge-dashboard/targeting', '1', 'incentives', { rallyId });
  },
  realTimeOfferCount: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri + '/count', 'edge-dashboard/targeting', '2', 'realtimeoffers', { rallyId }, queryParams);
  },
  recommendations: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/targeting', '1', 'realtimeoffers', { rallyId });
  },
};

export const trackingUris = {
  events: () => {
    return constructUri(baseApiUri, 'tracking', '1', 'events');
  },
};

export const userUris = {
  heartbeat: () => {
    const userUri = baseApiUri + '/heartbeat';
    return constructUri(userUri, 'user', '1', 'session');
  },
  idp: (idp: string, queryParams?: object) => {
    const idpUri = baseApiUri + '/idp/[idp]';
    // This needs to use no encoding because the entire redirect url needs to be encoded at once (this is only a part of it).
    return constructUri(idpUri, 'user', '1', 'session', { idp }, queryParams, constructParamsNoEncoding);
  },
  idpLogoutPage: (population: IPopulation) => {
    if (CONFIG.ARCADE_WEB_DEFAULT_AUTH === ArcadeAuthType.Optum) {
      const resource = population.lineOfBusiness === LineOfBusiness.MR ?
        ({myUhcBaseUrl}) => `${myUhcBaseUrl}/member/logout.html` :
        ({myUhcLegacyBaseUrl}) => `${myUhcLegacyBaseUrl}${CONFIG.ARCADE_WEB_MYUHC_LOGOUT_PATH}`;
      return getResource(resource, population);
    } else {
      const redirectUrl = parse(userUris.idp('rally', { product: 'arcade' })).href;
      const rallyLogoutPage = CONFIG.ARCADE_WEB_RALLY_AUTH_URL +
        CONFIG.ARCADE_WEB_RALLY_AUTH_LOGOUT_PATH;
      const sessionCreationEndpoint = encodeURIComponent(redirectUrl);
      return `${rallyLogoutPage}/?redirect=${sessionCreationEndpoint}`;
    }
  },
  idpRefresh: () => {
    const idpRefreshUri = baseApiUri + '/idp/[idp]/refresh';
    let authType = 'rally';

    if (CONFIG.ARCADE_WEB_DEFAULT_AUTH === ArcadeAuthType.Optum) {
      authType = 'myuhc';
    }

    return constructUri(idpRefreshUri, 'user', '1', 'session', { idp: authType });
  },
  info: () => {
    const uri = baseApiUri + '/info';
    return constructUri(uri, 'user', '1', 'session');
  },
  logout: () => {
    const logoutUri = baseApiUri + '/logout';
    return constructUri(logoutUri, 'user', '1', 'session');
  },
  arachneLogout: () => {
    return CONFIG.ARCADE_WEB_RALLY_AUTH_URL + '/auth/v1/logout';
  },
  prelogin: () => {
    const uri = baseApiUri + '/prelogin';
    return constructUri(uri, 'user', '1', 'session');
  },
  token: (url: string) => {
    const uri = baseApiUri + '/token';
    return constructUri(uri, 'user', '1', 'federation', {}, { deeplink: url });
  },
  sundog: (rallyId: string) => {
    const sundogUri = baseAuthUri + '/sundogdental';
    return constructUri(sundogUri, 'user', '1', 'redirect', { rallyId });
  },
  rallyPayToken: () => {
    const userUri = baseApiUri + '/token';
    return constructUri(userUri, 'user', '1', 'rallypay');
  },
};

const sanitizedUrls = {
  connect: sanitizeLink(CONFIG.ARCADE_WEB_RALLY_CONNECT_URL),
  engage: sanitizeLink(CONFIG.ARCADE_WEB_RALLY_ENGAGE_URL),
  rewards: sanitizeLink(CONFIG.ARCADE_WEB_RALLY_REWARDS_URL),
  survey: sanitizeLink(CONFIG.ARCADE_WEB_RALLY_SURVEY_URL),
};

export function parse(href: string): HTMLAnchorElement {
  const uri = document.createElement('a');
  uri.href = href;

  // IE fix for converting relative to absolute paths
  if (uri.host === '') {
    uri.href = uri.href;
  }

  return uri;
}

export function getLink(link: string): string {
  if ((link.indexOf(sanitizedUrls.connect) === -1) &&
    (link.indexOf(sanitizedUrls.engage) === -1) &&
    (link.indexOf(sanitizedUrls.rewards) === -1) &&
    (link.indexOf(sanitizedUrls.survey) === -1)) {
    return link;
  }
  return '/internal-redirect?deepLink=' + encodeURIComponent(link);
}

export function isLinkToConnect(link: string): boolean {
  return link && link.indexOf(sanitizedUrls.connect) !== -1;
}

export function isLinkToArcade(link: string): boolean {
  return link && link.indexOf(baseUrls.web) !== -1;
}

// UI Router does some custom url encoding where / becomes ~2F and ~ becomes ~~
// source here: https://github.com/angular-ui/ui-router/blob/legacy/release/angular-ui-router.js#L1332
// (actually that is a different version, but same idea. anyway...)
// this function exists to reverse it
//   https://physics.stackexchange.com/questions/285270/minimum-angular-speed-of-earth-for-a-body-to-escape-its-gravity
export function angularMinimumEscape(uriComponent: string): string {
  return decodeURIComponent(uriComponent).replace(/(~~|~2F)/g, m => ({ '~~': '~', '~2F': '/' }[m]));
}

export function constructUri(
  uri: string,
  api: string,
  version: string,
  method: string,
  addUriParams?: object,
  addQueryParams?: object,
  queryParameterBuilder: (queryParams: object) => string = constructParams,
): string {

  for (const key in addUriParams) {
    if (addUriParams.hasOwnProperty(key)) {
      addUriParams[key] = encodeURIComponent(addUriParams[key]);
    }
  }

  const params = {
    api: arguments[1],
    version: arguments[2],
    method: arguments[3],
    ...addUriParams,
  };

  if ((uri.match(/\[/g) || []).length === Object.keys(params).length && arguments.length >= 4) {
    for (const param in params) {
      if (params.hasOwnProperty(param)) {
        uri = uri.replace('[' + param + ']', params[param]);
      }
    }
    return uri += addQueryParams ? queryParameterBuilder(addQueryParams) : '';
  } else {
    throw new Error('Not enough parameters supplied to construct uri.');
  }
}

export function getBaseUrls(): IBaseUrls {
  const locationUri = parse(window.location.href).host;
  let webUrl;
  let apiUrl;
  if (locationUri === parse(CONFIG.ARCADE_WEB_BASE_URL).host) {
    webUrl = CONFIG.ARCADE_WEB_BASE_URL;
    apiUrl = CONFIG.ARCADE_WEB_BASE_API_URL;
  } else if (CONFIG.ARCADE_WEB_ALTERNATIVE_BASE_URLS) {
    for (const ALTERNATIVE_URL of readAlternativeUrls()) {
      if (locationUri === parse(ALTERNATIVE_URL.ARCADE_WEB_BASE_URL).host) {
        webUrl = ALTERNATIVE_URL.ARCADE_WEB_BASE_URL;
        apiUrl = ALTERNATIVE_URL.ARCADE_WEB_BASE_API_URL;
        break;
      }
    }
  }
  return { api: apiUrl, web: webUrl };
}

function readAlternativeUrls(): IAlternativeUrls[] {
  return CONFIG.ARCADE_WEB_ALTERNATIVE_BASE_URLS.split(';').map((urlPair: string): IAlternativeUrls => {
    const urls = urlPair.split(',');
    return {
      ARCADE_WEB_BASE_URL: urls[0].trim(),
      ARCADE_WEB_BASE_API_URL: urls[1].trim(),
    };
  });
}

function constructParams(queryParams: object): string {
  let queryString = '';
  for (const param in queryParams) {
    if (queryParams.hasOwnProperty(param) && queryParams[param]) {
      queryString ? queryString += '&' : queryString += '?';
      if (queryParams[param] instanceof Array) {
        queryString += queryParams[param].map(val => `${param}=${encodeURIComponent(val)}`).join('&');
      } else {
        queryString += `${param}=${encodeURIComponent(queryParams[param])}`;
      }
    }
  }
  return queryString;
}

function constructParamsNoEncoding(queryParams: object): string {
  let queryString = '';
  for (const param in queryParams) {
    if (queryParams.hasOwnProperty(param) && queryParams[param]) {
      queryString ? queryString += '&' : queryString += '?';
      if (queryParams[param] instanceof Array) {
        queryString += queryParams[param].map(val => `${param}=${val}`).join('&');
      } else {
        queryString += `${param}=${queryParams[param]}`;
      }
    }
  }
  return queryString;
}

function sanitizeLink(link: string): string {
  return link.replace('http://', '').replace('https://', '').replace('www.', '');
}
