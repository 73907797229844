import angular from 'angular';
import ngRedux from 'ng-redux';
import { Observable } from 'rxjs';
import { getHeartbeatLoading, getHeartbeatSuccess } from 'scripts/actions/user-service-actions';
import { getBaseUrls } from 'scripts/util/population/population';
import { userUris } from 'scripts/util/uri/uri';
import { IRootScope } from '../../arcade.module.interfaces';
import { ArcadeAuthType, IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { HeartbeatSuccess } from '../../util/constants/event.constants';
import { IFeatureFlagService } from '../../util/feature-flag/feature-flag.interface';
import { IPopulationService } from '../../util/population/population.service';
import { CacheName } from '../api.interfaces';
import { IBaseApiService } from '../api.module';
import { destroyAll, getCache, getCacheKey } from '../cache';
import { LineOfBusiness } from '../profile/profile.interfaces';
import { ITrackingService } from '../tracking/tracking.service';
import { IHeartbeatResponse, IPreLoginResponse, ITokenResponse } from './user.interfaces';

export interface IUserService {
  logout(arcadeOnly?: boolean): Observable<any>;
  prelogin(redirectUrl: string): Observable<IPreLoginResponse>;
  getHeartbeat(preferCache?: boolean): Observable<IHeartbeatResponse>;
  getInfo(): Observable<IHeartbeatResponse>;
  getRallyPayToken(): Observable<ITokenResponse>;
  getToken(url: string): Observable<ITokenResponse>;
  internalSSORedirect(url: string): void;
  internalSimpleRedirect(url: string): void;
  removeSession(): void;
  disableHeartbeat(): void;
  enableHeartbeat(): void;
}

export class UserService implements IUserService {
  private activityInterval;
  private heartbeatTimeout;
  private heartbeatActive;
  private heartbeatRequestId;

  constructor(
    private $interval: ng.IIntervalService,
    private $ngRedux: ngRedux.INgRedux,
    private $rootScope: IRootScope,
    private $timeout: ng.ITimeoutService,
    private $window: ng.IWindowService,
    private $location: ng.ILocationService,
    private $sce: ng.ISCEService,
    private $state: angular.ui.IStateService,
    private baseApiService: IBaseApiService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private populationService: IPopulationService,
    private trackingService: ITrackingService,
  ) {
    'ngInject';
    this.heartbeatActive = true;
    this.checkForArachneSession();
  }

  public logout(arcadeOnly?: boolean): Observable<any> {
    const url = userUris.logout();
    const arcadeLogoutRequest = this.baseApiService.get(url);
    this.removeSession();

    if (this.featureFlagService.isAdvantageOn() && !arcadeOnly) {
      const arachneLogoutUrl = userUris.arachneLogout();
      const arachneLogoutRequest = this.baseApiService.post(arachneLogoutUrl);
      return Observable.zip(arcadeLogoutRequest, arachneLogoutRequest);
    } else {
      return arcadeLogoutRequest;
    }
  }

  public prelogin(redirectUrl: string): Observable<IPreLoginResponse> {
    const url = userUris.prelogin();
    const request = this.baseApiService.post(url, {redirectUrl});
    this.removeSession();
    return request;
  }

  public getHeartbeat(preferCache: boolean = true): Observable<IHeartbeatResponse> {
    const url = userUris.heartbeat();
    const cache = getCache(CacheName.User);
    const cacheKey = getCacheKey(url);
    const cachedData = cache.get(cacheKey);
    const nonCachedSrc$ = Observable.of(undefined)
      .do(() => this.$ngRedux.dispatch(getHeartbeatLoading()))
      .flatMap(() => this.baseApiService.get(url))
      .do(rsp => {
        this.$ngRedux.dispatch(getHeartbeatSuccess(rsp.data));
        this.resetHeartbeatTimer(rsp);
        if (rsp.config.$$id !== this.heartbeatRequestId) {
          this.extendOptumSession();
          this.heartbeatRequestId = rsp.config.$$id;
        }

        // If rallyId is different from the one in cache, assume a new user and destroy everything in cache
        if (cachedData && cachedData.data.rallyId !== rsp.data.rallyId) {
          this.removeSession();
          this.$window.location.reload();
        } else {
          cache.put(url, rsp);
        }
      });

    if (this.activityInterval === undefined && this.heartbeatActive) {
      this.monitorActivity();
    }

    const getHeartbeat$ = Observable.if(() => preferCache && cachedData, Observable.of(cachedData), nonCachedSrc$)
      .do(rsp => this.$rootScope.activeSession = true, err => {
        const pop = this.populationService.getPopulation() || {} as any;
        this.$timeout(() => {
          if (!this.$state.is('login')) {
            this.$state.go('login', {
              redirect: this.$location.absUrl(),
              lob: pop.lineOfBusiness,
              membershipCategory: pop.membershipCategory,
            });
          }
        });
      });

    return Observable.if(() => this.heartbeatActive, getHeartbeat$, Observable.empty());
  }

  public getInfo(): Observable<IHeartbeatResponse> {
    const url = userUris.info();
    return this.baseApiService.get(url);
  }

  public getToken(passedUrl: string): Observable<ITokenResponse> {
    const url = userUris.token(passedUrl);
    return this.baseApiService.get(url);
  }

  public getRallyPayToken(): Observable<ITokenResponse> {
    const url = userUris.rallyPayToken();
    return this.baseApiService.get(url);
  }

  public internalSSORedirect(url: string): void {
    const postEvents$ = this.trackingService.postEvents();
    const ssoRedirect$ = this.getToken(url)
      .do(rsp => {
        const authUrl = this.Environment.CONFIG.ARCADE_WEB_RALLY_AUTH_URL;
        const form = document.createElement('form');
        const tokenInput = document.createElement('input');

        form.method = 'POST';
        form.action = this.$sce.trustAsResourceUrl(authUrl + '/sso/v1/direct/uhcDigital');
        tokenInput.type = 'hidden';
        tokenInput.name = 'sso-token';
        tokenInput.value = rsp.data.token;
        form.appendChild(tokenInput);
        document.body.appendChild(form);
        form.submit();
      }, err => {
        // if the response was a 403, redirect them to the unauthorized page, otherwise go to the internal error page
        if (err.status === 403) {
          this.$timeout(() => {
            const data = err.data !== undefined ? {
              errorUID: err.data.correlationId,
              errorReason: err.data.code,
            } : undefined;
            this.$state.go('unauthenticated.unauthorizedError', data);
          });
        } else {
          // using $state.go doesn't trigger the onEnter function for navigating back to the previous location
          const internalErrorUrl = this.$state.href('unauthenticated.internalError', {errorUID: err.data.correlationId});
          this.$window.location.href = internalErrorUrl;
        }
      });

    postEvents$.finally(() => ssoRedirect$.subscribe()).subscribe();
  }

  public internalSimpleRedirect(url: string): void {
    const postEvents$ = this.trackingService.postEvents();

    postEvents$.finally(() => this.$window.location.href = url).subscribe();
  }

  public removeSession(): void {
    sessionStorage.clear();
    destroyAll();
    this.$interval.cancel(this.activityInterval);
    this.activityInterval = undefined;
  }

  public disableHeartbeat(): void {
    this.heartbeatActive = false;
  }

  public enableHeartbeat(): void {
    this.heartbeatActive = true;
  }

  private monitorActivity(): void {
    let isActive: boolean = true;
    const lifespan = this.Environment.CONFIG.ARCADE_WEB_ACTIVITY_TIMEOUT_MS;

    angular.element(window.document.body).on('click', () => {
      isActive = true;
    });

    this.activityInterval = this.$interval(() => {
      if (isActive) {
        this.getHeartbeat(false).subscribe();
        isActive = false;
      }
    }, lifespan);
  }

  private handleInactivity(heartbeatRsp: IHeartbeatResponse): void {
    this.getInfo().subscribe(infoRsp => {
      const heartbeatExpires = new Date(heartbeatRsp.data.expiresAt.toString()).getTime();
      const infoExpires = new Date(infoRsp.data.expiresAt.toString()).getTime();

      if (infoExpires > heartbeatExpires) {
        this.resetHeartbeatTimer(infoRsp);
      } else {
        this.$timeout(() => {
          if (this.$state.current.name.split('.')[0] === 'modal') {
            this.$location.url('/dashboard/modal/inactive');
          } else {
            this.$state.go('modal.inactive');
          }
        });
      }
    }, console.warn);
  }

  private resetHeartbeatTimer(rsp: IHeartbeatResponse): void {
    const heartbeat = rsp.data;
    const expiresFromNow = new Date(heartbeat.expiresAt.toString()).getTime() - new Date(rsp.data.serverTime.toString()).getTime();
    const timer = expiresFromNow - this.Environment.CONFIG.ARCADE_WEB_HEARTBEAT_BUFFER_MS;

    this.$rootScope.$emit(HeartbeatSuccess, rsp.data);
    this.$timeout.cancel(this.heartbeatTimeout);
    this.heartbeatTimeout = this.$timeout(() => {
      this.handleInactivity(rsp);
    }, timer);
  }

  private extendOptumSession(): void {
    if (this.Environment.CONFIG.ARCADE_WEB_DEFAULT_AUTH === ArcadeAuthType.Optum) {
      const pop = this.populationService.getPopulation() || {} as any;
      if (pop.lineOfBusiness !== LineOfBusiness.MR) {
        const url = getBaseUrls(pop.lineOfBusiness, pop.membershipCategory).myUhcBaseUrl +
          this.Environment.CONFIG.ARCADE_WEB_MYUHC_EXTEND_SESSION_PATH;
        const hiddenImage = document.createElement('img');
        hiddenImage.setAttribute('src', url);
        hiddenImage.style.display = 'none';
        document.body.appendChild(hiddenImage);
      }
    }
  }

  private checkForArachneSession(): void {
    if (this.featureFlagService.isAdvantageOn()) {
      this.getInfo().subscribe(() => undefined, err => {
        if (err.status === 401) {
          this.createArachneSession();
        }
      });
    }
  }

  private createArachneSession(): void {
    const createSessionUrl = userUris.idp('arachne');
    this.baseApiService.get(createSessionUrl).subscribe(() => undefined, err => {
      console.warn('arachne session creation failed');
      this.$state.go('unauthenticated.internalError');
    });
  }
}
