import { Observable } from 'rxjs/Observable';
import { getMoneyValue, getPercentValue, hasNonZeroDecimals } from 'scripts/util/money/money';
import { CoverageType, CoverageTypeCode } from '../../../api/api.interfaces';
import { BenefitServiceType, IBenefitAmountService, IBenefitService } from '../../../api/plans/plans.interfaces';
import { IPlansService } from '../../../api/plans/plans.service';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { CostIdCodes } from '../../dashboard/costs/costs.interfaces';
import { CostCopy, INonZeroCostMap } from './cost-info.interfaces';

export class CostInfoService {
  public getCostsForService: (serviceID: string, coverageTypeCode?: CoverageTypeCode) => Observable<IBenefitService>;

  constructor(
    private plansService: IPlansService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';

    this.getCostsForService = (serviceId: string, coverageTypeCode?: CoverageTypeCode) => {
      const serviceIdCode = CostIdCodes[serviceId];
      return this.userService.getHeartbeat()
        .let(this.profileService.toProfile())
        .flatMap(profile => this.plansService.getBenefits(profile.data.currentUser.rallyId, undefined, coverageTypeCode))
        .flatMap(benefits => benefits.data.benefits)
        // If coverageTypeCode was passed (M&R), then only one coverage will be returned. Otherwise (E&I), find the medical coverage.
        .first(({coverageType}) => coverageTypeCode ? true : coverageType === CoverageType.Medical)
        .flatMap(medicalCoverage => medicalCoverage.services)
        .first(({serviceCode}) => serviceCode.code === serviceIdCode);
    };
  }

  public chooseCostInfoCopy(nonZeroCostMap: INonZeroCostMap, isMR?: boolean, isVirtual?: boolean): string {
    if (isVirtual) {
      return this.chooseVirtualVisitCostInfoCopy(nonZeroCostMap);
    }
    if (nonZeroCostMap[BenefitServiceType.DeductibleAmount]) {
      if (nonZeroCostMap[BenefitServiceType.CopayAmount]) {
        if (nonZeroCostMap[BenefitServiceType.CoinsurancePercent]) {
          return CostCopy.amountCopayAndCoinsuranceDeductible;
        } else {
          return CostCopy.amountCopayDeductible;
        }
      } else {
        if (nonZeroCostMap[BenefitServiceType.CoinsurancePercent]) {
          return CostCopy.amountCoinsuranceDeductible;
        } else {
          return CostCopy.nothingAfterDeductible;
        }
      }
    } else {
      if (nonZeroCostMap[BenefitServiceType.CopayAmount]) {
        if (nonZeroCostMap[BenefitServiceType.CoinsurancePercent]) {
          return CostCopy.amountCopayAndCoinsurance;
        } else {
          return CostCopy.amountCopay;
        }
      } else {
        if (nonZeroCostMap[BenefitServiceType.CoinsurancePercent]) {
          return CostCopy.amountCoinsurance;
        } else {
          return isMR ? CostCopy.zeroCopay : CostCopy.noCopayOrCoinsurance;
        }
      }
    }
  }

  public formatCost = (cost: IBenefitAmountService): string => {
    if (cost) {
      if (cost.type === BenefitServiceType.CoinsurancePercent) {
        return getPercentValue(parseFloat(cost.amount));
      } else {
        const costAmountFloat = parseFloat(cost.amount);
        if (hasNonZeroDecimals(costAmountFloat)) {
          return getMoneyValue(costAmountFloat, true);
        } else {
          return getMoneyValue(costAmountFloat, false);
        }
      }
    } else {
      return '?';
    }
  }

  public getNonZeroCostMap(costs: IBenefitAmountService[]): INonZeroCostMap {
    const nonZeroCostMap = {} as INonZeroCostMap;
    for (const costItem of costs) {
      if (parseFloat(costItem.amount) > 0) {
        nonZeroCostMap[costItem.type] = this.formatCost(costItem);
      }
    }
    return nonZeroCostMap;
  }

  public getBigNum(amounts: INonZeroCostMap, isMR?: boolean): string {
    if (amounts[BenefitServiceType.CopayAmount]) {
      return amounts[BenefitServiceType.CopayAmount];
    } else if (amounts[BenefitServiceType.CoinsurancePercent]) {
      return amounts[BenefitServiceType.CoinsurancePercent];
    } else {
      return isMR && !amounts[BenefitServiceType.DeductibleAmount] ? '$0' : '';
    }
  }

  public requiresCopayDisclaimer(costInfoCopy: string): boolean {
    return costInfoCopy === CostCopy.amountCopayDeductible || costInfoCopy === CostCopy.amountCopayAndCoinsuranceDeductible;
  }

  private chooseVirtualVisitCostInfoCopy(nonZeroCostMap: INonZeroCostMap): string {
    if (nonZeroCostMap[BenefitServiceType.CopayAmount] && !nonZeroCostMap[BenefitServiceType.CoinsurancePercent]) {
      return CostCopy.amountCopay;
    } else if (
        !nonZeroCostMap[BenefitServiceType.CopayAmount]
        && !nonZeroCostMap[BenefitServiceType.CoinsurancePercent]
        && !nonZeroCostMap[BenefitServiceType.DeductibleAmount]) {
        return CostCopy.noCopayOrCoinsurance;
    } else {
      return CostCopy.fiftyDollarsOrLess;
    }
  }
}
