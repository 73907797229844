import { UserServiceActionTypes } from 'scripts/actions/user-service-actions';
import { IHeartbeat } from 'scripts/api/user/user.interfaces';
import { ArcadeAction, IStateData } from 'scripts/reducers/reducer.interfaces';

export interface IUserState {
  heartbeat: IStateData<IHeartbeat>;
}

export const initialState: IUserState = {
  heartbeat: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

export default (state: IUserState = initialState, action: ArcadeAction): IUserState => {
  switch (action.type) {
    case UserServiceActionTypes.GET_HEARTBEAT_SUCCESS:
      return {
        ...state,
        heartbeat: {
          data: action.payload,
          error: false,
          loading: false,
        },
      };
    case UserServiceActionTypes.GET_HEARTBEAT_LOADING:
      return {
        ...state,
        heartbeat: {
          ...state.heartbeat,
          loading: true,
        },
      };
    case UserServiceActionTypes.GET_HEARTBEAT_ERROR:
      return {
        ...state,
        heartbeat: {
          data: undefined,
          error: true,
          loading: false,
        },
      };
    default:
      return state;
  }
};
