import { Moment } from 'moment';
import printHeaderTemplate from 'views/ui/print-header.html';
import { IArcadeClient } from '../../api/profile/profile.interfaces';
import { IProfileService } from '../../api/profile/profile.service';
import { IUserService } from '../../api/user/user.service';
import { IAngularMoment } from '../../arcade.module.interfaces';

export class PrintHeaderComponent implements ng.IComponentOptions {
  public controller = PrintHeaderController;
  public templateUrl = printHeaderTemplate;
}

export class PrintHeaderController implements ng.IComponentController {
  public today: Moment;
  public client: IArcadeClient;

  constructor(
    private userService: IUserService,
    private profileService: IProfileService,
    private moment: IAngularMoment,
  ) {
    'ngInject';
    this.today = this.moment();

    this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .subscribe(({data}) => this.client = data.clientInfo);
  }
}
