import { dateStringToMoment, timeStringToMoment } from 'scripts/util/conversion/conversion';
import { profileUris } from 'scripts/util/uri/uri';
import { CacheName } from '../api.interfaces';
import HttpClient from '../http-client';
import { IClaimsPreferencesResponse, IProductsResponse, IProfileResponse } from './profile.interfaces';

function getProfile(rallyId: string): Promise<IProfileResponse> {
  const url = profileUris.profile(rallyId);
  return HttpClient.get<IProfileResponse>(url, { cacheName: CacheName.Profile }).then(rsp => {
    // We need rallyId whenever we set the currently viewed profile, so we set it equal to the rallyId from the
    // profile response if it doesn't exist on the targeted profile.
    rsp.data.currentUser.rallyId = rsp.data.currentUser.rallyId ? rsp.data.currentUser.rallyId : rsp.data.rallyId;
    if (rsp.data.dependents) {
      rsp.data.dependents.forEach(dependent => {
        dependent.rallyId = dependent.rallyId ? dependent.rallyId : rsp.data.rallyId;
      });
    }
    const coverages = rsp.data.currentUser.planCoverages;
    if (!!coverages) {
      rsp.data.currentUser.acos = coverages
        .map(coverage => coverage.additionalCoverageInfo && coverage.additionalCoverageInfo.aco)
        .filter(aco => !!aco);
    }
    dateStringToMoment(rsp, 'data', 'currentUser', 'userInfo', 'dob');
    dateStringToMoment(rsp, 'data', 'dependents', 'userInfo', 'dob');
    dateStringToMoment(rsp, 'data', 'currentUser', 'planCoverages', 'planPeriod', 'startDate');
    dateStringToMoment(rsp, 'data', 'currentUser', 'planCoverages', 'planPeriod', 'endDate');
    dateStringToMoment(rsp, 'data', 'dependents', 'planCoverages', 'planPeriod', 'startDate');
    dateStringToMoment(rsp, 'data', 'dependents', 'planCoverages', 'planPeriod', 'endDate');
    return rsp;
  });
}

function getClaimsPreferences(rallyId: string): Promise<IClaimsPreferencesResponse> {
  const url = profileUris.claimsPreferences(rallyId);
  return HttpClient.get<IClaimsPreferencesResponse>(url, {cacheName: CacheName.ProfilePreferences });
}

function getProducts(rallyId: string): Promise<IProductsResponse> {
  const url = profileUris.products(rallyId);
  return HttpClient.get<IProductsResponse>(url, { cacheName: CacheName.Profile }).then(rsp => {
    const { products } = rsp.data;
    for (const p in products) {
      if (products.hasOwnProperty(p)) {
        timeStringToMoment(products[p], 'contactInformation', 'hours', 'startTime');
        timeStringToMoment(products[p], 'contactInformation', 'hours', 'endTime');
      }
    }
    return rsp;
  });
}

export const ProfileApi = {
  getClaimsPreferences,
  getProducts,
  getProfile,
};
