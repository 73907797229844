import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';

export enum TargetingServiceActionTypes {
  GET_CLIENT_CONFIG_ERROR = 'GET_CLIENT_CONFIG_ERROR',
  GET_CLIENT_CONFIG_LOADING = 'GET_CLIENT_CONFIG_LOADING',
  GET_CLIENT_CONFIG_SUCCESS = 'GET_CLIENT_CONFIG_SUCCESS',
}

export interface IGetClientConfigLoading {
  type: TargetingServiceActionTypes.GET_CLIENT_CONFIG_LOADING;
}

export interface IGetClientConfigSuccess {
  type: TargetingServiceActionTypes.GET_CLIENT_CONFIG_SUCCESS;
  payload: IClientConfig;
}

export interface IGetClientConfigError {
  type: TargetingServiceActionTypes.GET_CLIENT_CONFIG_ERROR;
}

export function getClientConfigLoading(): IGetClientConfigLoading {
  return {
    type: TargetingServiceActionTypes.GET_CLIENT_CONFIG_LOADING,
  };
}

export function getClientConfigSuccess(payload: IClientConfig): IGetClientConfigSuccess {
  return {
    type: TargetingServiceActionTypes.GET_CLIENT_CONFIG_SUCCESS,
    payload,
  };
}

export function getClientConfigError(): IGetClientConfigError {
  return {
    type: TargetingServiceActionTypes.GET_CLIENT_CONFIG_ERROR,
  };
}

export type TargetingServiceAction = IGetClientConfigLoading | IGetClientConfigSuccess | IGetClientConfigError;
