import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { manageOptumBankAccount } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import planBalancesMessageTemplate from 'views/claims-and-accounts/plan-balances/plan-balances-message.html';
import { CoverageType, RelationshipType } from '../../../../api/api.interfaces';
import { AccountType } from '../../../../api/ledger/ledger.interfaces';
import { ILedgerService, LedgerService } from '../../../../api/ledger/ledger.service';
import { IPlansService } from '../../../../api/plans/plans.service';
import { IProfileService, ProfileService } from '../../../../api/profile/profile.service';
import { IClientConfig } from '../../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../../api/targeting/targeting.service';
import { IUserService } from '../../../../api/user/user.service';
import { Dictionary } from '../../../../util/constants/i18n.constants';
import { ILocaleService } from '../../../../util/locale/locale.service';
import { IResourceService } from '../../../../util/resource/resource.service';

export class PlanBalancesMessageComponent implements ng.IComponentOptions {
  public templateUrl: string;
  public controller: any;

  constructor() {
    this.templateUrl = planBalancesMessageTemplate;
    this.controller = PlanBalancesMessageController;
  }
}

export class PlanBalancesMessageController implements ng.IComponentController {
  public customMessage: string;
  public hasActiveCera: boolean;
  public hasHsaAccount: boolean;
  public prorateReasonCode: string;
  public manageOptumBankAccount: IResource;
  public startDate: moment.Moment;
  private clientConfigReq: Observable<IClientConfig>;
  private localeSubscription: Subscription;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private ledgerService: ILedgerService,
    private localeService: ILocaleService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.PLAN_BALANCES);
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.manageOptumBankAccount = manageOptumBankAccount;

    this.clientConfigReq = this.userService.getHeartbeat()
      .flatMap(({data}) => this.targetingService.getClientConfig(data.rallyId))
      .do(clientConfig => this.customMessage = clientConfig.customMessaging.customMessagePlanBalances);
  }

  public $onInit(): void {
    this.clientConfigReq.subscribe(() => undefined, console.warn);

    this.localeSubscription = this.localeService.localeChanged
      .flatMap(() => this.clientConfigReq)
      .subscribe(() => undefined, console.warn);

    this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .flatMap(currentUser => this.plansService.getAccumulators(currentUser.rallyId, currentUser.dependentSeqNum),
        (currentUser, {data: accumulators}) => ({currentUser, accumulators}))
      .subscribe(({currentUser, accumulators}) => {
        const medicalCoverage = this.profileService.getCoverage(CoverageType.Medical, currentUser.planCoverages);
        const medicalAccumulatorsWithProrateReasonCode = accumulators.benefits.filter(b => b.coverageType === CoverageType.Medical)
          .filter(b => b.prorateReasonCode);
        if (medicalCoverage && medicalAccumulatorsWithProrateReasonCode.length > 0) {
          this.startDate = medicalCoverage.planPeriod.startDate;
          this.prorateReasonCode = medicalAccumulatorsWithProrateReasonCode[0].prorateReasonCode + '_MESSAGE';
        }
      }, console.warn);

    this.setHasActiveCera();
    this.setHasHsaAccount();
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  private setHasActiveCera(): void {
    this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .takeWhile(currentUser => ProfileService.hasLedgerAccess(currentUser))
      .flatMap(currentUser => this.ledgerService.getAccounts(currentUser.rallyId))
      .map(rsp => rsp.data)
      .map(accounts => accounts.some(account => account.isActive && account.accountType === AccountType.CERA))
      .defaultIfEmpty(false)
      .subscribe(hasActiveCera => this.hasActiveCera = hasActiveCera, console.warn);
  }

  private setHasHsaAccount(): void {
    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .takeWhile(currentUser => ProfileService.hasLedgerAccess(currentUser))
      .flatMap(currentUser => this.ledgerService.getAccounts(currentUser.rallyId))
      .map(rsp => rsp.data)
      .subscribe(accounts => {
        this.hasHsaAccount = accounts.some(account => LedgerService.isHsaAccount(account));
      }, console.warn);
  }
}
