import { AnyClaimType } from '../../api/claims/claims.interfaces';
import { ClaimsService } from '../../api/claims/claims.service';
import { IEnvironmentConstants } from '../constants/environment.interfaces';
import { IFeatureFlagService } from './feature-flag.interface';

// Why do I exist?
// Constants (which we use for Environment/feature flags) are nearly impossible to test
// Services are mockable and spyOn-able
export class FeatureFlagService implements IFeatureFlagService {
  constructor(private Environment: IEnvironmentConstants) {
    'ngInject';
  }

  public isActivateStepsOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_ACTIVATE_STEPS;
  }

  public isActivateStepsV6On(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_ACTIVATE_STEPS_V6;
  }

  public isActivateVideoOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_ACTIVATE_VIDEO;
  }

  public isAdvantageOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_ADVANTAGE;
  }

  public isAllClaimsKeywordFilterOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_ALL_CLAIMS_KEYWORD_FILTER;
  }

  public isAutoPaymentOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_AUTO_PAYMENT;
  }

  public isClaimDetailsBreakdownOn(claimType: AnyClaimType): boolean {
    return ClaimsService.getByType(claimType, true , false) ?
      true : !!this.Environment.FEATURE_FLAGS[`ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_${claimType}`];
  }

  public isClaimDetailsOn(claimType: AnyClaimType): boolean {
    // ARC-5293 only HealthCare type is enabled currently
    return ClaimsService.getByType(claimType, true , false) ?
    true : !!this.Environment.FEATURE_FLAGS[`ARCADE_FEATURES_CLAIM_DETAILS_${claimType}`];
  }

  public isClaimLettersOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_CLAIM_LETTERS;
  }

  public isDirectDepositOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_DIRECT_DEPOSIT;
  }

  public isDocumentsClaimsEobOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_DOCUMENTS_CLAIMS_EOB;
  }

  public isDocumentsScreenReaderLinkOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_DOCUMENTS_SCREEN_READER_LINK;
  }

  public isEiRecommendationsOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_EI_RECOMMENDATIONS;
  }

  public isFpcPcpForGatedOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_FPC_PCP_FOR_GATED;
  }

  public isGenesysChatOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_GENESYS_CHAT;
  }

  public isGenesysChatOnForClaims(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_GENESYS_CHAT_CLAIMS;
  }

  public isHealthcareClaimsV3On(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_HEALTHCARE_CLAIMS_V3;
  }

  public isIAQHPServiceEndingBannerOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_IAQHP_SERVICE_ENDING_BANNER;
  }

  public isMRAccountSummaryOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_ACCOUNT_SUMMARY;
  }

  public isMRRXSpendingAndCostSummaryOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_RX_SPENDING_AND_COST_SUMMARY;
  }

  public isMRSpendingAndCostSummaryOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_SPENDING_AND_COST_SUMMARY;
  }

  public isNotificationOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_NOTIFICATION;
  }

  public isPayNowModalOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PAY_NOW_MODAL;
  }

  public isPcdOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PCD;
  }

  public isPcpForPolarisOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PCP_POLARIS;
  }

  public isPcpGroupOrFacilityNameOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PCP_GROUP_OR_FACILITY_NAME;
  }

  public isPcpNonGatedOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PCP_NON_GATED;
  }

  public isPcpThroughFpcOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PCP_THROUGH_FPC;
  }

  public isOptumRxPharmacyRedesignOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_OPTUM_RX_PHARMACY_REDESIGN;
  }

  public isPremiumPaymentsForExchangeOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_EXCHANGE_PREMIUM_PAYMENTS;
  }

  public isQuickLinksOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_QUICK_LINKS;
  }

  public isRallyRxOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_RALLY_RX;
  }

  public isReduxLoggingOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_REDUX_LOGGING;
  }

  public isReferralRequiredOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_REFERRAL_REQUIRED;
  }

  public isSharedHeaderFooterPhaseTwoOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_SHARED_HEADER_AND_FOOTER_PHASE_TWO;
  }

  public isStatementsOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_STATEMENTS;
  }

  public isSubmitClaimOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_SUBMIT_CLAIM;
  }

  public isTierTwoEnabled(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_TIER_TWO;
  }

  public isVideoBannerOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_VIDEO_BANNER;
  }
}
