import quickLinksTemplate from 'views/dashboard/quick-links.html';
import { QuickLinksController } from './quick-links.controller';

export class QuickLinksComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;

  constructor() {
    this.controller = QuickLinksController;
    this.templateUrl = quickLinksTemplate;
  }
}
