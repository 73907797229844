import promoTilesTemplate from 'views/programs/promo-tiles.html';
import { PromoTilesController } from './promo-tiles.controller';

export class PromoTilesComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  public bindings: any;

  constructor() {
    this.controller = PromoTilesController;
    this.templateUrl = promoTilesTemplate;
    this.bindings = {
      isProgramsPage: '<',
    };
  }
}
