import superUserBannerTemplate from 'views/chrome/super-user-banner.html';
import { LineOfBusiness } from '../../api/profile/profile.interfaces';
import { IUserService } from '../../api/user/user.service';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IPopulationService } from '../../util/population/population.service';

export class SuperUserBannerComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = SuperUserBannerController;
    this.templateUrl = superUserBannerTemplate;
  }
}

export class SuperUserBannerController implements ng.IComponentController {
  public isSuperUser: boolean;
  public isMR: boolean;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private populationService: IPopulationService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.SUPER_USER_BANNER);

    userService.getHeartbeat().subscribe(rsp => {
      this.isSuperUser = rsp.data.isSuperUser;
    });

    const pop = populationService.getPopulation();
    this.isMR = pop.lineOfBusiness === LineOfBusiness.MR;
  }
}
