import { Observable } from 'rxjs/Observable';
import { formatTrackingString, getFeatureList, getPlacement } from 'scripts/util/tracking/tracking-helper';
import { ITrackingEventRequest, TrackingTriggerType } from '../../api/tracking/tracking.interfaces';
import { ITrackingService } from '../../api/tracking/tracking.service';
import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IWaitForContainerController, IWaitForController, IWaitForTrackData } from './wait-for.interfaces';

export class WaitForController implements IWaitForController {
  public container: IWaitForContainerController;
  public errorMessage: string;
  public originalTemplate: string;
  public trackData: IWaitForTrackData;
  public trackLabel: string;
  public waitForThing: Observable<any>;
  public waitForClass: string;
  private timeConstructed: number;

  constructor(
    private $compile: ng.ICompileService,
    private $element: ng.IAugmentedJQuery,
    private $location: ng.ILocationService,
    private $scope: ng.IScope,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Environment: IEnvironmentConstants,
    private trackingService: ITrackingService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ERRORS);

    this.timeConstructed = new Date().getTime();
    this.errorMessage = this.errorMessage || 'WAIT_FOR_FAILURE_MESSAGE';
  }

  public trackFailure(element: ng.IAugmentedJQuery): void {
    const event = this.getTrackingEventRequest(element, TrackingTriggerType.SectionFailed);
    if (event) {
      this.trackingService.queueEvent(event);
    }
  }

  public trackSuccess(element: ng.IAugmentedJQuery): void {
    const event = this.getTrackingEventRequest(element, TrackingTriggerType.SectionLoad);
    if (event) {
      this.trackingService.queueEvent(event);
    }
  }

  public tryAgain(): void {
    if (this.container) {
      this.$element.contents().replaceWith(this.$compile(this.originalTemplate)(this.$scope));
      this.container.reload();
    } else {
      window.location.reload(true);
    }
  }

  private getTrackingEventRequest(element: ng.IAugmentedJQuery, trigger: TrackingTriggerType): ITrackingEventRequest {
    const featureList = getFeatureList(element[0]);
    const data = (this.trackData && typeof this.trackData === 'object') ? this.trackData : {};
    data.rspTime = new Date().getTime() - this.timeConstructed;

    if (featureList.length > 0) {
      return {
        trigger,
        uri: this.$location.url(),
        serviceVersion: this.Environment.CONFIG.ARCADE_WEB_VERSION,
        featureList: WaitForController.customizeFeatureList(featureList, element),
        actionName: formatTrackingString(this.trackLabel),
        placement: getPlacement(element[0]),
        data,
      };
    } else {
      return undefined;
    }
  }

  private static customizeFeatureList(featureList: string[], element: ng.IAugmentedJQuery): string[] {
    const waitForAttr = element.attr('wait-for');
    const waitForParts = waitForAttr.split('.');
    const varName = waitForParts[waitForParts.length - 1];
    const waitForFeature = 'wait-for-' + varName.replace(/([A-Z])/g, $1 => '-' + $1.toLowerCase());

    featureList.push(waitForFeature);
    return featureList;
  }
}

export class WaitForContainerController implements IWaitForContainerController {
  constructor(private $compile: ng.ICompileService, private $element: ng.IAugmentedJQuery, private $scope: ng.IScope) {
    'ngInject';
  }

  public reload(): void {
    this.$compile(this.$element)(this.$scope);
  }
}
