import { connectPlans } from 'scripts/util/resource/resource.constants';
import { LineOfBusiness } from '../../api/profile/profile.interfaces';
import { IStateParams } from '../../arcade.module.interfaces';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IPopulationService } from '../../util/population/population.service';
import { IResourceService } from '../../util/resource/resource.service';

export class InternalErrorController {
  public authenticated: boolean;
  public errorUID: string;
  public isMR: boolean;
  public connectLink: string;

  constructor(
    private $stateParams: IStateParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private populationService: IPopulationService,
    private resourceService: IResourceService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ERRORS);
    this.authenticated = !!$stateParams.refresh;
    this.errorUID = $stateParams.errorUID;

    const pop = populationService.getPopulation();
    this.isMR = pop && pop.lineOfBusiness === LineOfBusiness.MR;

    this.connectLink = resourceService.get(connectPlans);
  }
}
