import financialAccountsTemplate from 'views/claims-and-accounts/plan-balances/financial-accounts.html';
import { FinancialAccountsController } from './financial-accounts.controller';

export class FinancialAccountsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  public bindings = {
    showCurrentYear: '<',
    setYearOptions: '&',
  };
  public transclude = true;

  constructor() {
    this.controller = FinancialAccountsController;
    this.templateUrl = financialAccountsTemplate;
  }
}
