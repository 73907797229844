import { ICsvField } from './download.interfaces';

export class DownloadService {
  public static downloadCsv(content: string, fileName: string): void {
    DownloadService.download(content, fileName, 'text/csv;encoding:utf-8');
  }

  public static getCsvContent<T>(fields: Array<ICsvField<T>>, objects: T[]): string {
    const header = fields.map(field => field.header).join(',');
    const body = objects.map(object => {
      return fields.map(({getValue}) => {
        const str = getValue(object) || '';
        return (str.indexOf(',') > -1 || str.indexOf('\n') > -1) ? `"${str.replace(/"/g, '""')}"` : str;
      }).join(',');
    }).join('\n');
    return [header, body].join('\n');
  }

  private static download(content: string, fileName: string, mimeType: string = 'application/octet-stream'): void {
    const anchorElement = document.createElement('a');

    if (navigator.msSaveBlob) { // IE
      navigator.msSaveBlob(new Blob([content], {type: mimeType}), fileName);
    } else if (URL && 'download' in anchorElement) { // HTML5 A[download]
      anchorElement.href = URL.createObjectURL(new Blob([content], {type: mimeType}));
      anchorElement.setAttribute('download', fileName);
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    } else { // Fallback
      window.open(`data:${mimeType},${encodeURIComponent(content)}`);
    }
  }
}
