import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import headerContainerTemplate from 'views/chrome/header-container.html';
import { IOnboardingStateParams } from '../header/onboarding-banner.component';

export class HeaderContainerComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;
  public bindings: {[binding: string]: string};

  constructor() {
    this.controller = HeaderContainerController;
    this.templateUrl = headerContainerTemplate;
    this.bindings = {
      authenticated: '<',
    };
  }
}

export class HeaderContainerController implements ng.IComponentController {
  get showOnboardingBanner(): boolean {
    return this.$stateParams.onboardingProfileUpdated;
  }
  public authenticated: boolean;
  public showNotification: boolean;

  constructor(
    public $state: ng.ui.IStateService,
    private $stateParams: IOnboardingStateParams,
    private featureFlagService: IFeatureFlagService,
  ) {
    'ngInject';
    this.authenticated = (this.authenticated === undefined) ? true : this.authenticated;
  }
  public $onInit(): void {
    this.showNotification = this.featureFlagService.isNotificationOn();
  }

  public isOnDashboard(): boolean {
    return this.$state.current.name === 'authenticated.dashboard' ||
    (this.$stateParams.path && this.$stateParams.path.indexOf('/dashboard') > -1);
  }

  public showVideoBanner(): boolean {
    return this.featureFlagService.isVideoBannerOn() && this.isOnDashboard();
  }
}
