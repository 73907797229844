import { Observable } from 'rxjs';
import { ledgerUris } from 'scripts/util/uri/uri';
import { CacheName } from '../api.interfaces';
import { IBaseApiService } from '../api.module';
import { getCache, getCacheKey } from '../cache';
import { AccountType, IGetAccountsResponse, ILedgerAccount } from './ledger.interfaces';

export interface ILedgerService {
  getAccounts(rallyId: string): Observable<IGetAccountsResponse>;
}

export class LedgerService implements ILedgerService {

  constructor(
    private baseApiService: IBaseApiService,
  ) {
    'ngInject';
  }

  public getAccounts(rallyId: string): Observable<IGetAccountsResponse> {
    const url = ledgerUris.balance(rallyId);
    const ledgerCache = getCache(CacheName.Ledger);
    const cacheKey = getCacheKey(url);
    const cachedData = ledgerCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url)
      .map(rsp => {
        const accounts = rsp.data;
        for (const a of accounts) {
          this.baseApiService.stringToFloat(a, 'balance', 'availableAmount', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'currentAmount', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'paidAmount', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'investmentAmount', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'totalBalance', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'carryOverAmount', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'accountFunding', 'annualElections', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'accountFunding', 'employeeContribution', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'accountFunding', 'employerContribution', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'accountFunding', 'beginningAmount', 'value');
          this.baseApiService.stringToFloat(a, 'accessPointInformation', 'familyAccessPointInformation', 'familyAccessPoint');
          this.baseApiService.stringToFloat(a, 'accessPointInformation', 'familyAccessPointInformation', 'familyAccessPointAmountApplied');
          this.baseApiService.stringToFloat(a, 'accessPointInformation', 'individualAccessPointInformation', 'individualAccessPoint');
          this.baseApiService.stringToFloat(
            a,
            'accessPointInformation',
            'individualAccessPointInformation',
            'individualMaxReimbursementAmount',
          );
          this.baseApiService.stringToFloat(a, 'accessPointInformation', 'individualAccessPointInformation',
            'individualAccessPointAccumulators', 'individualAccessPointApplied');
          this.baseApiService.stringToFloat(a, 'accessPointInformation', 'individualAccessPointInformation',
            'individualAccessPointAccumulators', 'individualMaxReimbursementAmountUsed');
        }
        return rsp;
      })
      .do(rsp => ledgerCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$)
      .map(rsp => {
        const accounts = rsp.data;
        for (const a of accounts) {
          this.baseApiService.dateStringToMoment(a, 'accountPeriod', 'startDate');
          this.baseApiService.dateStringToMoment(a, 'accountPeriod', 'endDate');
        }

        return rsp;
      });
  }

  public static isDcsaAccount(account: ILedgerAccount): boolean {
    return AccountType.DCSA === account.accountType;
  }

  public static isFsaAccount(account: ILedgerAccount): boolean {
    return LedgerService.isFsaAccountType(account.accountType);
  }

  public static isJpmcAccount(account: ILedgerAccount): boolean {
    return LedgerService.isJpmcAccountType(account.accountType);
  }

  public static isFsaHcAccount(account: ILedgerAccount): boolean {
    return AccountType.FSAHC === account.accountType;
  }
  public static isFsaDcAccount(account: ILedgerAccount): boolean {
    return AccountType.FSADC === account.accountType;
  }

  public static isHraAccount(account: ILedgerAccount): boolean {
    return LedgerService.isHraAccountType(account.accountType);
  }

  public static isHsaAccount(account: ILedgerAccount): boolean {
    return AccountType.HSA === account.accountType;
  }

  public static isHcsaAccount(account: ILedgerAccount): boolean {
    return AccountType.HCSA === account.accountType;
  }

  public static isMraAccount(account: ILedgerAccount): boolean {
    return AccountType.MRA === account.accountType;
  }

  public static isJpmcAccountType(accountType: AccountType): boolean {
    return [AccountType.DCSA, AccountType.HCSA, AccountType.MRA].indexOf(accountType) > -1;
  }

  public static isFsaAccountType(accountType: AccountType): boolean {
    return [AccountType.FSADC, AccountType.FSAHC, AccountType.FSALP].indexOf(accountType) > -1;
  }

  public static isJPMCFsaAccountType(accountType: AccountType): boolean {
    return [AccountType.HCSA, AccountType.DCSA].indexOf(accountType) > -1;
  }
  public static isJPMCHraAccountType(accountType: AccountType): boolean {
    return [AccountType.MRA].indexOf(accountType) > -1;
  }
  public static isHraAccountType(accountType: AccountType): boolean {
    return [AccountType.HRA, AccountType.HRAAP, AccountType.HRAPD, AccountType.HRASD].indexOf(accountType) > -1;
  }
}
