import idx from 'idx';
import { createSelector, Selector } from 'reselect';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';

const selectProfile = (state: IReduxState) => state.profile.profile;

const selectSelectedProfile = (state: IReduxState) => state.profile.selectedProfile;

const selectClaimsPreferencesData = (state: IReduxState) => state.profile.claimsPreferences.data;

const selectProducts = (state: IReduxState) => state.profile.products;

const selectProfileData = createSelector(
  selectProfile,
  profile => profile.data,
);

const selectCurrentUser = createSelector(
  selectProfileData,
  profile => idx(profile, _ => _.currentUser),
);

export const selectClientInfo = createSelector(
  selectProfileData,
  profile => idx(profile, _ => _.clientInfo),
);

export const selectDependents = createSelector(
  selectProfileData,
  profile => idx(profile, _ => _.dependents),
);

const commonProfileUserSelectors = [selectCurrentUser, selectSelectedProfile]
  .map((selectProfileUser: Selector<IReduxState, IProfileUser>) => {
    const selectDependentSeqNum = createSelector(
      selectProfileUser,
      profileUser => idx(profileUser, _ => _.dependentSeqNum),
    );

    const selectUserInfo = createSelector(
      selectProfileUser,
      profileUser => idx(profileUser, _ => _.userInfo),
    );

    const selectFirstName = createSelector(
      selectUserInfo,
      userInfo => idx(userInfo, _ => _.firstName),
    );

    const selectLastName = createSelector(
      selectUserInfo,
      userInfo => idx(userInfo, _ => _.lastName),
    );

    return {
      selectProfile: selectProfileUser,
      selectDependentSeqNum,
      selectUserInfo,
      selectFirstName,
      selectLastName,
    };
  });

// currentUser - is the user that is logged in
// selectedUser - is the user that is selected using the account selector on the dashboard, it may be the same as the currentUser
export const [currentUser, selectedUser] = commonProfileUserSelectors;

export const selectMarkAsPaid = createSelector(
  selectClaimsPreferencesData,
  claimsPreferences => idx(claimsPreferences, _ => _.markAsPaid),
);

export const selectShowMarkAsPaidDisclaimer = createSelector(
  selectMarkAsPaid,
  markAsPaid => idx(markAsPaid, _ => _.showDisclaimer),
);

export const selectProductsData = createSelector(
  selectProducts,
  products => products.data,
);
