import jQuery from 'jquery';
import 'jquery';
import contentHopperTemplate from 'views/ui/content-hopper.html';
import { ContentHopperController } from './content-hopper.controller';
import { IHopSpotScope } from './content-hopper.interfaces';

export class ContentHopperDirective implements ng.IDirective {
  public restrict = 'A';
  public transclude = true;
  public templateUrl = contentHopperTemplate;
  public scope = {};
  public controller = ContentHopperController;
  public controllerAs = '$contentHopper';
  public bindToController = {
    top: '<',
  };

  public static Factory(): ng.IDirectiveFactory {
    return () => new ContentHopperDirective();
  }
}

export class HopSpotDirective implements ng.IDirective {
  public restrict = 'A';
  public require = '^contentHopper';
  public scope = {
    text: '@hopSpot',
  };

  private counter: number = 0;

  constructor(private $timeout: ng.ITimeoutService) {}
  public link = (scope: IHopSpotScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, $contentHopper: ContentHopperController) => {
    const jElement = jQuery(element);
    const jWindow = jQuery(window);
    const id = this.counter;
    let prevScrollTop = 0;
    let timer;

    this.counter ++;
    $contentHopper.addSpot(scope, element, id);

    const scrollEvent = () => {
      const scrollTop = jWindow.scrollTop();
      const windowBottom = scrollTop + jWindow.height();
      const elementTop = jElement.offset().top;

      if (!$contentHopper.isScrolling) {
        $contentHopper.isScrolling = true;
        scope.$apply();
      }
      this.$timeout.cancel(timer);
      timer = this.$timeout(() => {
        $contentHopper.isScrolling = false;
      }, 1000);

      $contentHopper.updateYPos(id, elementTop);

      if (scrollTop > prevScrollTop && elementTop < windowBottom) {
        // Scroll down, mark as visited
        $contentHopper.addVisited(id);
      } else if (scrollTop <= prevScrollTop && elementTop >= windowBottom) {
        // Scroll up, mark as new
        $contentHopper.removeVisited(id);
      }

      prevScrollTop = scrollTop;
    };

    timer = this.$timeout(scrollEvent, 1000);
    jWindow.on('scroll', scrollEvent);

    element.on('$destroy', () => {
      $contentHopper.removeSpot(id);
      jWindow.off('scroll', scrollEvent);
      this.$timeout.cancel(timer);
    });
  }

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($timeout: ng.ITimeoutService) => {
      'ngInject';
      return new HopSpotDirective($timeout);
    };
    return directive;
  }
}
