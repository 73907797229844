import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import CONFIG from 'scripts/util/constants/config';
import { IActivateService } from '../../api/activate/activate.service';
import { CoverageStatus, CoverageType, CoverageTypeCode, RelationshipType } from '../../api/api.interfaces';
import { IBenefit } from '../../api/plans/plans.interfaces';
import { IPlansService } from '../../api/plans/plans.service';
import {
  Aco,
  FundingType,
  IMemberFeatures,
  IPlanCoverage,
  IPrimaryCareUserInfo,
  IProfileUser,
  LineOfBusiness, MembershipCategory,
  PcpType,
  ProgramType,
} from '../../api/profile/profile.interfaces';
import { IProfileService, ProfileService } from '../../api/profile/profile.service';
import { ITargetingService } from '../../api/targeting/targeting.service';
import { IUserService } from '../../api/user/user.service';
import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { IFeatureFlagService } from '../../util/feature-flag/feature-flag.interface';
import { IOnboardingStatusService } from '../onboarding/onboarding-status.service';
import { IResourceService } from './../../util/resource/resource.service';
import { IFitbitStatusService } from './fitbit/fitbit-status.service';

export class DashboardController implements ng.IComponentController {
  public incentivesRequest: Observable<any>;
  public isSHIPPreEffective: boolean;
  public isTermedCS: boolean;
  public isTermedMedicalAndDental: boolean;
  public pcpRequest: Observable<any>;
  public showActivateSteps: boolean;
  public showBannerAndSingleStepsSection: boolean;
  public showCosts: boolean = true;
  public showDentalSummary: boolean;
  public showDrugCosts: boolean;
  public showEIMedicalSummary: boolean;
  public showFitbitNotification: boolean;
  public showIncentives: boolean;
  public showMRMedicalSummary: boolean;
  public showMRRxSpendingCostSummary: boolean;
  public showOnboardingNotification: boolean;
  public showPcpPcd: boolean;
  public showPromos: boolean;
  public showQuickLinks: boolean = true;
  public showRecentClaims: boolean;
  public ssoTrackingLink: string;
  private lineOfBusiness: LineOfBusiness;
  private profileSubscription: Subscription;

  constructor(
    private Environment: IEnvironmentConstants,
    private activateService: IActivateService,
    private featureFlagService: IFeatureFlagService,
    private fitbitStatusService: IFitbitStatusService,
    private onboardingStatusService: IOnboardingStatusService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    this.ssoTrackingLink = CONFIG.ARCADE_WEB_RALLY_AUTH_URL + '/sso/v1/vendor/MyUhc/completed';
  }

  public $onInit(): void {
    let medicalCoverage;
    this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .do(({data: {currentUser}}) => {
        medicalCoverage = this.profileService.getCoverage(CoverageType.Medical, currentUser.planCoverages);
        const hideCostsPolicies = this.Environment.CONFIG.ARCADE_WEB_HIDE_COSTS_POLICIES.split(',');
        if (hideCostsPolicies.indexOf(currentUser.primaryPolicyNumber) !== -1) {
          this.showCosts = false;
        }
        this.lineOfBusiness = currentUser.lineOfBusiness;
        this.isTermedCS = this.lineOfBusiness === LineOfBusiness.CS && medicalCoverage.planPeriod.status === CoverageStatus.Termed;
        const isTermedDental = this.profileService.isTermedForCoverageType(CoverageType.Dental, currentUser.planCoverages);
        this.isTermedMedicalAndDental = medicalCoverage.planPeriod.status === CoverageStatus.Termed &&
          (isTermedDental !== undefined ? isTermedDental : true);
      })
      .flatMap(profile => Observable.if(
        () => this.lineOfBusiness !== LineOfBusiness.MR && medicalCoverage && medicalCoverage.planPeriod.status !== CoverageStatus.Termed,
        this.plansService.getBenefits(profile.data.currentUser.rallyId)
          .flatMap(benefits => benefits.data.benefits)
          .first(({coverageType}) => coverageType === CoverageType.Medical),
        Observable.of(false),
      ))
      .subscribe(rsp => {
        this.showCosts = (this.showCosts === false ?
          false : this.lineOfBusiness !== LineOfBusiness.EI || (rsp && (rsp as IBenefit).services.length >= 4));
      }, () => {
        this.showCosts = false;
      });

    this.setShowSectionBooleans();
    this.profileSubscription = this.profileService.profileChanged.subscribe(() => {
      this.setShowSectionBooleans();
    });
  }

  public $onDestroy(): void {
    this.profileSubscription.unsubscribe();
  }

  private setShowSectionBooleans(): void {
    const heartbeat = this.userService.getHeartbeat();
    const currentUser$ = heartbeat.let(this.profileService.toProfile()).map(rsp => rsp.data.currentUser);
    const currentProfile$ = heartbeat.let(this.profileService.toCurrentProfile());

    Observable.zip(currentUser$, currentProfile$)
      .subscribe(([currentUser, {dependentSeqNum, planCoverages, lineOfBusiness, memberFeatures}]: IProfileUser[]) => {
        const isLoggedInUserSelected = currentUser.dependentSeqNum === dependentSeqNum;
        this.setShowPcpPcd(memberFeatures, currentUser, isLoggedInUserSelected);
        if (currentUser.lineOfBusiness === LineOfBusiness.EI && isLoggedInUserSelected) {
          this.setShowIncentives();
        } else {
          this.showIncentives = false;
          this.incentivesRequest = Observable.of(false);
        }
        const medicalCoverage = this.profileService.getCoverage(CoverageType.Medical, planCoverages);
        const hasActiveOrFutureMedical = this.profileService.hasAnActiveCoverageForCoverageType(CoverageType.Medical, planCoverages, true);
        if (!hasActiveOrFutureMedical) {
          this.showCosts = false;
        }
        // this is necessary to show dedicated promos and hide additional links for pre-effective SHIP members.
        this.isSHIPPreEffective = currentUser.planCoverages.every(c => c.planPeriod.status === CoverageStatus.Future) &&
          currentUser.planCoverages.some(c => c.planFeatures.programType === ProgramType.Ship);
        this.showQuickLinks = hasActiveOrFutureMedical || lineOfBusiness === LineOfBusiness.MR;
        this.showEIMedicalSummary = !!medicalCoverage && lineOfBusiness === LineOfBusiness.EI;
        this.showMRMedicalSummary = this.getShowMRAccountSummary(medicalCoverage, lineOfBusiness);
        this.showPromos = isLoggedInUserSelected && !this.isTermedCS && (
          (this.isSHIPPreEffective) ||
          (this.profileService.hasAnActiveCoverageForCoverageType(CoverageType.Medical, planCoverages) ||
          (lineOfBusiness === LineOfBusiness.MR && ProfileService.getCoverageInfo(planCoverages).numCoverages > 0))
        );
        this.showRecentClaims = (isLoggedInUserSelected || !medicalCoverage || !medicalCoverage.planFeatures.phiRestricted)
          && lineOfBusiness === LineOfBusiness.EI;
        this.setShowDrugCosts(currentUser);
        this.setMRRxSpendingCostSummary(currentUser);
      }, console.warn);

    currentProfile$
      .filter(currentProfile => currentProfile.lineOfBusiness === LineOfBusiness.EI)
      .flatMap(currentProfile => this.plansService.getBenefitsWithAccumulators(currentProfile.rallyId, currentProfile.dependentSeqNum))
      .subscribe(usefulBenefits => {
        this.showDentalSummary = !!(usefulBenefits.DENTAL && usefulBenefits.DENTAL.inNetwork);
      }, console.warn);

    Observable.zip(this.onboardingStatusService.get(), this.fitbitStatusService.get(), this.getShowActivateSingleSteps())
    .subscribe(([showOnboardingBanner, showFitbitBanner, showActivateSteps]) => {
      this.showOnboardingNotification = showOnboardingBanner;
      this.showFitbitNotification = showFitbitBanner;
      this.showActivateSteps = showActivateSteps;
      this.showBannerAndSingleStepsSection = this.showFitbitNotification || this.showOnboardingNotification || this.showActivateSteps;
    });
  }

  private setShowIncentives(): void {
    const incentives$: Observable<boolean> = this.userService.getHeartbeat()
      .flatMap(rsp => this.targetingService.getIncentives(rsp.data.rallyId))
      .filter(({data}) => !!(data && data.planInfo))
      .map(({data: {planInfo}}) => planInfo)
      .first()
      .map(planInfo => planInfo.numberOfIncentives > 0);

    const engage$: Observable<boolean> = this.userService.getHeartbeat()
      .flatMap(rsp => this.profileService.getProducts(rsp.data.rallyId))
      .map(rsp => rsp.data.products.hasOwnProperty('rallyEngage'));

    this.incentivesRequest = Observable.if(() => this.featureFlagService.isAdvantageOn(), Observable.of(false), engage$)
      .flatMap(hasEngage => Observable.if(() => hasEngage, incentives$, Observable.of(false)));
    this.incentivesRequest.subscribe(showIncentives => {
      this.showIncentives = showIncentives;
    }, () => this.showIncentives = false);
  }

  private setShowDrugCosts(profileUser: IProfileUser): void {
    const hasActiveIndividualDrugCoverage = profileUser.planCoverages.some(cov => {
      return (cov.coverageTypeCode === CoverageTypeCode.MAPD || cov.coverageTypeCode === CoverageTypeCode.PDP) &&
        cov.planFeatures.fundingArrangementType === FundingType.Individual && // ARC-3745: hide for GROUP funding type
        cov.planPeriod.status === CoverageStatus.Active;
    });
    this.showDrugCosts = profileUser.lineOfBusiness === LineOfBusiness.MR && hasActiveIndividualDrugCoverage;
  }

  private setShowPcpPcd(memberFeatures: IMemberFeatures, currentUser: IProfileUser, isLoggedInUserSelected: boolean): void {
    const pcpPcdEligibility = this.profileService.getPcpPcdEligibility(memberFeatures);
    const isCS = currentUser.lineOfBusiness === LineOfBusiness.CS;
    const isMR = currentUser.lineOfBusiness === LineOfBusiness.MR;
    const isCdp = currentUser.acos.some(aco => aco === Aco.CDP);
    const showPolaris = currentUser.membershipCategory !== MembershipCategory.OXFORD || this.featureFlagService.isPcpForPolarisOn();
    const hidePcpPolicies = this.Environment.CONFIG.ARCADE_WEB_HIDE_PCP_SECTION_POLICIES.split(',');
    const isSuppressedPolicy = hidePcpPolicies.indexOf(currentUser.primaryPolicyNumber) !== -1;

    const showPcpPcd = !isSuppressedPolicy &&
      !isMR &&
      showPolaris &&
      (pcpPcdEligibility.pcp || (pcpPcdEligibility.pcd && isLoggedInUserSelected) || isCS || isCdp || (
        (this.featureFlagService.isPcpNonGatedOn() && this.featureFlagService.isPcpThroughFpcOn()) &&
        currentUser.relationshipType === RelationshipType.Subscriber &&
        isLoggedInUserSelected)
      );

    const hasSavedPcpOrPcd$ = this.userService.getHeartbeat()
      .flatMap(heartbeat => this.profileService.getPrimaryCare(heartbeat.data.rallyId, pcpPcdEligibility.pcd))
      .map(pcpInfo => pcpInfo.data.perMemberResults)
      .map(perMemberPcpInfo => this.hasSavedPcpOrPcd(perMemberPcpInfo[currentUser.dependentSeqNum]));

    this.pcpRequest = Observable.if(() => this.isTermedMedicalAndDental, hasSavedPcpOrPcd$, Observable.of(showPcpPcd));
    this.pcpRequest.subscribe(hasSavedPcpOrPcd => {
      this.showPcpPcd = hasSavedPcpOrPcd;
    }, err => {
      console.warn(err);
      this.showPcpPcd = false;
    });
  }

  private setMRRxSpendingCostSummary(profileUser: IProfileUser): void {
    const blackListedPolicyNumbers = this.Environment.CONFIG.ARCADE_WEB_MR_RX_SUPPRESSION_GROUP_NUMBERS.split(',');
    const isMR = profileUser.lineOfBusiness === LineOfBusiness.MR;
    this.showMRRxSpendingCostSummary = isMR && profileUser.planCoverages.some(cov =>
      (cov.coverageTypeCode === CoverageTypeCode.MAPD || cov.coverageTypeCode === CoverageTypeCode.PDP) &&
      cov.planPeriod.status === CoverageStatus.Active &&
      !cov.planFeatures.lisLevel &&
      blackListedPolicyNumbers.indexOf(cov.policyNumber) === -1,
    );
  }

  private getShowMRAccountSummary(medicalCoverage: IPlanCoverage, lineOfBusiness: LineOfBusiness): boolean {
    const ffOn = this.featureFlagService.isMRAccountSummaryOn();
    const isMr = lineOfBusiness === LineOfBusiness.MR;
    const applicableCoverage = [CoverageTypeCode.MA, CoverageTypeCode.MAPD, CoverageTypeCode.SSP];
    const hasCoverage = !!medicalCoverage && applicableCoverage.indexOf(medicalCoverage.coverageTypeCode) > -1;
    return ffOn && isMr && hasCoverage;
  }

  private hasSavedPcpOrPcd(pcpUserInfo: IPrimaryCareUserInfo): boolean {
    if (pcpUserInfo && pcpUserInfo.primaryCarePhysicians) {
      const pcpTypeArray = this.featureFlagService.isPcpGroupOrFacilityNameOn()
        ? [PcpType.Physician, PcpType.Dental, PcpType.Facility, PcpType.Group]
        : [PcpType.Physician, PcpType.Dental];

      return pcpUserInfo.primaryCarePhysicians.some(pcp => pcp.status === CoverageStatus.Active &&
        pcpTypeArray.indexOf(pcp.pcpType) > -1);
    }
    return false;
  }

  private getShowActivateSingleSteps(): Observable<boolean> {
    let activateStep$: Observable<boolean>;

    if (this.featureFlagService.isActivateStepsV6On()) {
      activateStep$ = this.userService.getHeartbeat()
        .let(this.profileService.toCurrentProfile())
        .flatMap(profile => this.activateService.getSteps(profile.rallyId))
        .map(rsp => !!rsp.data.additional ? !!rsp.data.additional.length : false);
    } else {
      activateStep$ = this.userService.getHeartbeat()
        .let(this.profileService.toCurrentProfile())
        .flatMap(profile => this.activateService.getStepsV4(profile.rallyId))
        .map(rsp => !!rsp.data.steps.length);
    }

    return Observable.if(() => this.featureFlagService.isActivateStepsOn(), activateStep$, Observable.of(false))
      .catch(() => Observable.of(false));
  }
}
