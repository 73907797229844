import spendingAndCostSummaryTemplate from 'views/claims-and-accounts/spending-and-cost-summary/spending-and-cost-summary.html';

export class SpendingAndCostSummaryComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = SpendingAndCostSummaryController;
    this.templateUrl = spendingAndCostSummaryTemplate;
  }
}

export class SpendingAndCostSummaryController {
  constructor() {
    'ngInject';
  }
}
