import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { IDropdownOption } from 'scripts/ui/dropdown/dropdown.interfaces';
import { getMoneyValue } from 'scripts/util/money/money';
import { askAQuestion } from 'scripts/util/resource/resource.constants';
import { IResourceService } from 'scripts/util/resource/resource.service';
import claimTemplate from 'views/ui/claim.html';
import {
  AnyClaimType,
  ClaimStatus,
  ClaimType,
  ClaimVideoEligibility,
  IAnyClaim,
  IClaim,
  IFinancialClaim,
} from '../../api/claims/claims.interfaces';
import { ClaimsService, IClaimsService } from '../../api/claims/claims.service';
import { AccountType } from '../../api/ledger/ledger.interfaces';
import { IProfileUser } from '../../api/profile/profile.interfaces';
import { IProfileService } from '../../api/profile/profile.service';
import { IClientConfig } from '../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../api/targeting/targeting.service';
import { IUserService } from '../../api/user/user.service';
import { IFilesConstant } from '../../util/constants/files.constant';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../util/feature-flag/feature-flag.interface';

export class ClaimComponent implements ng.IComponentOptions {
  public controller = ClaimController;
  public templateUrl = claimTemplate;
  public bindings = {
    claim: '<',
  };
}

export interface IClaimSummaryBindings extends ng.IOnChangesObject {
  claim: ng.IChangesObject<IAnyClaim>;
}

export class ClaimController implements ng.IComponentController {
  // Bindings
  public claim: IAnyClaim;

  public hasSecureMessage: boolean;
  public menuLinks: IDropdownOption[];
  public currentUser: IProfileUser;
  public showMerpLabel: boolean;
  private currentUserReq: Observable<IProfileUser>;
  private clientConfigReq: Observable<IClientConfig>;

  constructor(
    public $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private claimsService: IClaimsService,
    private featureFlagService: IFeatureFlagService,
    private Files: IFilesConstant,
    private resourceService: IResourceService,
    private profileService: IProfileService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.CLAIM);

    this.currentUserReq = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .do(currentUser => this.currentUser = currentUser);

    this.clientConfigReq = this.userService.getHeartbeat()
      .flatMap(({data}) => this.targetingService.getClientConfig(data.rallyId))
      .do(clientConfig => this.showMerpLabel = clientConfig && clientConfig.suppressions.showMERPLabel);
  }

  public $onInit(): void {
    this.currentUserReq.subscribe(() => undefined, console.warn);

    this.clientConfigReq.subscribe(() => undefined, console.warn);

    this.userService.getHeartbeat()
      .flatMap(({ data }) => this.profileService.getProducts(data.rallyId))
      .map(({ data }) => data.products)
      .subscribe(products => {
        this.hasSecureMessage = !!products.secureMessage;
        this.setLinks(this.claim);
      }, err => {
        this.setLinks(this.claim);
        console.warn(err);
      });
  }

  public isHealthCareClaim(claim: IAnyClaim): boolean {
    return ClaimsService.getByClaim(claim, true, false);
  }

  public hasANote(claim: IClaim): boolean {
    return claim.claimManagementInfo && claim.claimManagementInfo.claimNote;
  }

  public getContentTemplate(claim: IAnyClaim): string {
    return ClaimsService.getByClaim(claim, 'health-claim', 'financial-claim');
  }

  public getYouMayOweAmount(claim: IClaim): number | undefined {
    return claim.claimManagementInfo.markPaid ? 0 : ClaimsService.getYouMayOweAmount(claim);
  }

  public getYouPaidAmount(claim: IClaim): number {
    return ClaimsService.getYouPaidAmount(claim);
  }

  public showMarkPaid(claim: IClaim): boolean {
    const youMayOwe  = ClaimsService.getYouMayOweAmount(claim);
    const claimNotInProcess = claim.claimStatus !== ClaimStatus.InProcess;
    return youMayOwe && claimNotInProcess;
  }

  public getMoneyValue(amount: number, decimal?: boolean): string {
    return getMoneyValue(amount, decimal);
  }

  public getClaimUrl(claim: IAnyClaim): string {
    return this.claimsService.getClaimDetailsUrl(claim);
  }

  public getExplanationOfBenefitsLink(currentUser: IProfileUser, claim: IAnyClaim): string {
    return this.claimsService.getExplanationOfBenefitsLink(currentUser, claim);
  }

  public getIcon(type: AnyClaimType): string {
    switch (type) {
      case ClaimType.Medical:
        return this.Files.getIcon('icon-circle-medical-2');
      case ClaimType.Dental:
        return this.Files.getIcon('icon-circle-dental-2');
      case ClaimType.Rx:
        return this.Files.getIcon('icon-circle-pharmacy-2');
      case AccountType.DCSA:
      case AccountType.FSADC:
      case AccountType.FSAHC:
      case AccountType.FSALP:
      case AccountType.HCSA:
      case AccountType.HRA:
      case AccountType.HRAAP:
      case AccountType.HRAPD:
      case AccountType.HRASD:
      case AccountType.MRA:
        return this.Files.getIcon('icon-provider-financial');
    }
  }

  public getAccountTypeAbbreviation(accountType: AccountType): string {
    return ClaimsService.getClaimAccountAbbreviation(accountType);
  }

  public showLastServiceDate(claim: IAnyClaim): boolean {
    const {serviceDate, lastServiceDate} = claim;
    if (!lastServiceDate) {
      return false;
    }
    const serviceMoment = moment.isMoment(serviceDate) ? serviceDate : moment(serviceDate);
    const lastServiceMoment = moment.isMoment(lastServiceDate) ? lastServiceDate : moment(lastServiceDate);
    return !serviceMoment.isSame(lastServiceMoment, 'day');
  }

  public showVideoExplanation(claim: IClaim): boolean {
    return !this.featureFlagService.isAdvantageOn() &&
      [ClaimVideoEligibility.Eligible, ClaimVideoEligibility.FirstEligible].indexOf(claim.claimVideoEligibility) > -1;
  }

  public claimIsNotInProcess(claim: IClaim): boolean {
    return claim.claimStatus !== ClaimStatus.InProcess;
  }

  public selectOptionMenuLinks(): (option: IDropdownOption, $event: ng.IAngularEvent) => void {
    return (option, $event) => option.action && option.action($event);
  }

  private setLinks(claim: IAnyClaim): void {
    this.menuLinks = [
      {
        value: this.getExplanationOfBenefitsLink(this.currentUser, claim),
        label: 'VIEW_EXPLANATION_BENEFITS',
        condition: !!(claim as IClaim).eobDocument,
        target: '_blank',
        noRel: true,
      },
      {
        value: this.resourceService.get(askAQuestion),
        label: 'ASK_QUESTION',
        condition: this.hasSecureMessage,
        target: '_blank',
      },
      {
        value: this.$state.href('modal.editClaimNote'),
        label: this.hasANote(claim as IClaim) ? 'VIEW_EDIT_NOTE' : 'MAKE_A_NOTE',
        condition: this.isHealthCareClaim(claim),
        action: ($event: ng.IAngularEvent) => {
          $event.preventDefault();
          this.$timeout(() => this.$state.go('modal.editClaimNote', {
            claimNoteClaimKey: claim.claimKey,
            claimNoteType: (claim as IClaim).claimType || (claim as IFinancialClaim).accountType,
          }));
        },
      },
    ];
  }
}
