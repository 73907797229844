import { getHeartbeatError, getHeartbeatLoading, getHeartbeatSuccess } from 'scripts/actions/user-service-actions';
import { UserApi } from 'scripts/api/user/user-api';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';

export function getHeartbeat(): ArcadeThunkAction<void> {
  return async dispatch => {
    try {
      dispatch(getHeartbeatLoading());
      const { data } = await UserApi.getHeartbeat();
      dispatch(getHeartbeatSuccess(data));
    } catch (error) {
      dispatch(getHeartbeatError());
    }
  };
}
