import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import accumulatorsSummaryTemplate from 'views/dashboard/accumulators-summary.html';
import { BenefitNetwork, BenefitPaymentType, IUsefulBenefits } from '../../../api/plans/plans.interfaces';
import { PlansService } from '../../../api/plans/plans.service';
import { ProfileService } from '../../../api/profile/profile.service';
import { IClientConfig } from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { UserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { ILocaleService } from '../../../util/locale/locale.service';
import { IStateService } from '../../../util/state/state.service';

export class AccumulatorsSummaryComponent implements ng.IComponentOptions {
  public controller = AccumulatorsSummaryController;
  public templateUrl = accumulatorsSummaryTemplate;
}

export interface IAccumulatorType {
  network: BenefitNetwork;
  type: BenefitPaymentType;
  customLabel?: string;
}

export interface IAccumulatorsSection {
  header?: string;
  accumulators: IAccumulatorType[];
  customLabel?: string;
}

// Scenarios: at most, we'll only ever show up to 4 defined benefit types

export const FamilyTieredBenefits: IAccumulatorsSection[] = [{
  header: 'DEDUCTIBLES',
  accumulators: [
    { network: BenefitNetwork.Tier1, type: BenefitPaymentType.IndividualDeductible },
    { network: BenefitNetwork.InNetwork, type: BenefitPaymentType.IndividualDeductible },
  ],
}, {
  accumulators: [
    { network: BenefitNetwork.Tier1, type: BenefitPaymentType.FamilyDeductible },
    { network: BenefitNetwork.InNetwork, type: BenefitPaymentType.FamilyDeductible },
  ],
}];

export const FamilyOopBenefits: IAccumulatorsSection[] = [{
  header: 'DEDUCTIBLES',
  accumulators: [
    { network: BenefitNetwork.InNetwork, type: BenefitPaymentType.IndividualDeductible },
    { network: BenefitNetwork.InNetwork, type: BenefitPaymentType.FamilyDeductible },
  ],
}, {
  header: 'OOP_MAX',
  accumulators: [
    { network: BenefitNetwork.InNetwork, type: BenefitPaymentType.IndividualOop },
    { network: BenefitNetwork.InNetwork, type: BenefitPaymentType.FamilyOop },
  ],
}];

export const FamilyBenefits: IAccumulatorsSection[] = [{
  header: 'DEDUCTIBLES',
  accumulators: [
    { network: BenefitNetwork.InNetwork, type: BenefitPaymentType.IndividualDeductible },
    { network: BenefitNetwork.InNetwork, type: BenefitPaymentType.FamilyDeductible },
  ],
}, {
  accumulators: [
    { network: BenefitNetwork.OutOfNetwork, type: BenefitPaymentType.IndividualDeductible },
    { network: BenefitNetwork.OutOfNetwork, type: BenefitPaymentType.FamilyDeductible },
  ],
}];

export const IndividualTieredBenefits: IAccumulatorsSection[] = [{
  header: 'DEDUCTIBLES',
  accumulators: [
    { network: BenefitNetwork.Tier1, type: BenefitPaymentType.IndividualDeductible },
    { network: BenefitNetwork.InNetwork, type: BenefitPaymentType.IndividualDeductible },
  ],
}, {
  header: 'OOP_MAX',
  accumulators: [
    { network: BenefitNetwork.Tier1, type: BenefitPaymentType.IndividualOop },
    { network: BenefitNetwork.InNetwork, type: BenefitPaymentType.IndividualOop },
  ],
}];

export const IndividualBenefits: IAccumulatorsSection[] = [{
  header: 'DEDUCTIBLES',
  accumulators: [
    { network: BenefitNetwork.InNetwork, type: BenefitPaymentType.IndividualDeductible },
    { network: BenefitNetwork.OutOfNetwork, type: BenefitPaymentType.IndividualDeductible },
  ],
}, {
  header: 'OOP_MAX',
  accumulators: [
    { network: BenefitNetwork.InNetwork, type: BenefitPaymentType.IndividualOop },
    { network: BenefitNetwork.OutOfNetwork, type: BenefitPaymentType.IndividualOop },
  ],
}];

export class AccumulatorsSummaryController implements ng.IComponentController {
  public request: Observable<any>;
  public accumulators: IUsefulBenefits;
  public sections: IAccumulatorsSection[];
  public person: string;
  private clientConfig: IClientConfig;
  private clientConfigReq: Observable<IClientConfig>;
  private localeSubscription: Subscription;
  private profileSubscription: Subscription;
  private useSelectedProfile: boolean;

  constructor(
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private localeService: ILocaleService,
    private plansService: PlansService,
    private profileService: ProfileService,
    private stateService: IStateService,
    private targetingService: ITargetingService,
    private userService: UserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.useSelectedProfile = this.stateService.getUseSelectedProfile();

    this.clientConfigReq = this.userService.getHeartbeat()
      .flatMap(({data}) => this.targetingService.getClientConfig(data.rallyId))
      .do(clientConfig => this.clientConfig = clientConfig);
  }

  public $onInit(): void {
    this.initAccumulators();

    if (this.useSelectedProfile) {
      this.profileSubscription = this.profileService.profileChanged.subscribe(profile => {
        this.initAccumulators();
      });
    }

    this.localeSubscription = this.localeService.localeChanged
      .flatMap(() => this.clientConfigReq)
      .subscribe(() => this.applyCustomLabels());
  }

  public $onDestroy(): void {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    this.localeSubscription.unsubscribe();
  }

  public initAccumulators(): void {
    const heartbeat = this.userService.getHeartbeat();
    const selectedUser = heartbeat.let(this.profileService.toCurrentProfile());
    const loggedInUser = heartbeat.let(this.profileService.toProfile()).map(profile => profile.data.currentUser);

    this.request = Observable.if(() => this.useSelectedProfile, selectedUser, loggedInUser)
      .flatMap(() => this.clientConfigReq, profile => profile)
      .do(profile => this.person = profile.userInfo.firstName)
      .flatMap(profile => this.plansService.getBenefitsWithAccumulators(profile.rallyId, profile.dependentSeqNum))
      .map(benefits => benefits.MEDICAL)
      .do(accumulators => {
        const isTieredPlan = accumulators.isTieredPlan && !accumulators.isCspGspPlan && accumulators.hasOwnProperty(BenefitNetwork.Tier1);
        const isFamilyPlan = accumulators.isFamilyPlan;
        const hasOopType = accumulators.hasOwnProperty(BenefitNetwork.InNetwork) &&
          (accumulators[BenefitNetwork.InNetwork].hasOwnProperty(BenefitPaymentType.IndividualOop) ||
          accumulators[BenefitNetwork.InNetwork].hasOwnProperty(BenefitPaymentType.FamilyOop));

        // Accumulator Display Priority
        // Family Plan: Tiered > OOP Max > Out-Of-Network
        // Individual Plan: Tiered > Out-of-Network
        if (isFamilyPlan && isTieredPlan) {
          this.sections = FamilyTieredBenefits;
        } else if (isFamilyPlan && !isTieredPlan && hasOopType) {
          this.sections = FamilyOopBenefits;
        } else if (isFamilyPlan && !isTieredPlan && !hasOopType) {
          this.sections = FamilyBenefits;
        } else if (!isFamilyPlan && isTieredPlan) {
          this.sections = IndividualTieredBenefits;
        } else {
          this.sections = IndividualBenefits;
        }

        this.filterSuppressed();
        this.applyCustomLabels();
      });

    this.request.subscribe(accumulators => this.accumulators = accumulators);
  }

  public hasAccumulators(accumulators: IAccumulatorType[]): boolean {
    return accumulators
      .filter(a => typeof this.accumulators[a.network] !== 'undefined')
      .filter(a => typeof this.accumulators[a.network][a.type] !== 'undefined')
      .filter(a => typeof this.accumulators[a.network][a.type].max !== 'undefined')
      .length > 0;
  }

  private filterSuppressed(): void {
    if (this.clientConfig && this.clientConfig.suppressions.accumulatorSuppression) {
      const accSuppression = this.clientConfig.suppressions.accumulatorSuppression;
      this.sections.forEach(section => {
        section.accumulators = section.accumulators.filter(({network, type}) =>
          !accSuppression.hasOwnProperty(network) || accSuppression[network].indexOf(type) === -1,
        );
      });
    }
  }

  private applyCustomLabels(): void {
    if (this.clientConfig && this.clientConfig.customLabels.customAccumulatorLabels) {
      const customLabels = this.clientConfig.customLabels.customAccumulatorLabels;
      this.sections.forEach(section => {
        section.accumulators.forEach(a => {
          a.customLabel = customLabels.hasOwnProperty(a.network) && customLabels[a.network].hasOwnProperty(a.type) ?
            customLabels[a.network][a.type] : null;
          section.customLabel = a.customLabel ? a.customLabel : section.customLabel;
        });
      });
    }
  }
}
