import quickLinksTemplate from 'views/dashboard/quick-links.html';
import { MrQuickLinksController } from './mr-quick-links.controller';

export class MrQuickLinksComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;

  constructor() {
    this.controller = MrQuickLinksController;
    this.templateUrl = quickLinksTemplate;
  }
}
