import angular from 'angular';
import { Observable } from 'rxjs/Observable';
import { WaitForContainerController, WaitForController } from './wait-for.controller';

export class WaitForContainerDirective implements ng.IDirective {
  public restrict = 'A';
  public scope = false;
  public controller = WaitForContainerController;
  public controllerAs = '$waitForContainer';

  public static Factory(): ng.IDirectiveFactory {
    return () => new WaitForContainerDirective();
  }
}

export class WaitForDirective implements ng.IDirective {
  public restrict = 'A';
  public require = ['waitFor', '?^^waitForContainer'];
  public controller = WaitForController;
  public controllerAs = '$waitFor';
  public terminal = true;
  public bindToController = {
    errorMessage: '@',
    trackData: '@',
    trackLabel: '@',
    waitForThing: '<waitFor',
    waitForClass: '@',
  };

  constructor(private $compile: ng.ICompileService, private $timeout: ng.ITimeoutService) {}
  public compile = tElement => {
    const originalTemplate = tElement.html();
    const spinnerTemplate = '' +
      '<div class="loading-dots">' +
      '<span class="loading-dot"></span>' +
      '<span class="loading-dot"></span>' +
      '<span class="loading-dot"></span>' +
      '<span class="sr-only" translate>LOADING</span>' +
      '</div>';

    return {
      pre: (scope, element, attrs, controllers) => {
        const $waitFor = controllers[0];
        const $waitForContainer = controllers[1];
        $waitFor.container = $waitForContainer;
        $waitFor.originalTemplate = $waitFor.originalTemplate || originalTemplate;
        if (attrs.hideUntilSuccess !== undefined) {
          element.addClass('hide');
        }

        const successCallback = () => {
          this.$timeout(() => {
            if (attrs.onlyRenderIf === undefined || attrs.onlyRenderIf === 'true') {
              element.removeClass('hide');
              element.empty().append(this.$compile($waitFor.originalTemplate)(scope));
              element.removeClass($waitFor.waitForClass || '').children().addClass('fade-in');
              if (attrs.notifyOnLoadText) {
                const srNotification = `<span aria-live="assertive" aria-label="${attrs.notifyOnLoadText}"></span>`;
                element.append(srNotification);
              }
              if ($waitFor.trackLabel) {
                try {
                  $waitFor.trackData = scope.$eval($waitFor.trackData);
                } catch (error) {
                  $waitFor.trackData = {};
                }
                $waitFor.trackSuccess(element);
              }
            } else if (attrs.hideUntilSuccess !== undefined) {
              element.addClass('hide');
            }
          });
        };

        const failureCallback = () => {
          const failureTemplate = '' +
            '<div class="wait-for-failure" track-feature="$track.features.requestFailure">' +
            '<p translate="' + $waitFor.errorMessage + '"></p>' +
            '<button class="wait-for-failure-retry-btn" ' +
            'ng-click="$waitFor.tryAgain()" ' +
            'track="try-again" ' +
            'translate="TRY_AGAIN"></button>' +
            '</div>';
          element.empty().append(this.$compile(failureTemplate)(scope));
          element.addClass($waitFor.waitForClass || '').children().addClass('fade-in');
          if ($waitFor.trackLabel) {
            $waitFor.trackFailure(element);
          }
        };

        scope.$watch('$waitFor.waitForThing', () => {
          element.html(this.$compile(spinnerTemplate)(scope));
          Observable.defer(() => $waitFor.waitForThing || Observable.empty())
            .subscribe(successCallback, failureCallback);
        });
      }, post: angular.noop,
    };
  }

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($compile: ng.ICompileService, $timeout: ng.ITimeoutService) => {
      'ngInject';
      return new WaitForDirective($compile, $timeout);
    };
    return directive;
  }
}
