import { IClaimsPreferences, IProducts, IProfile, IProfileUser } from 'scripts/api/profile/profile.interfaces';

export enum ProfileServiceActionTypes {
  GET_PROFILE_ERROR = 'GET_PROFILE_ERROR',
  GET_PROFILE_LOADING = 'GET_PROFILE_LOADING',
  GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS',
  CHANGE_SELECTED_PROFILE = 'CHANGE_SELECTED_PROFILE',
  GET_CLAIMS_PREFERENCES_ERROR = 'GET_CLAIMS_PREFERENCES_ERROR',
  GET_CLAIMS_PREFERENCES_LOADING = 'GET_CLAIMS_PREFERENCES_LOADING',
  GET_CLAIMS_PREFERENCES_SUCCESS = 'GET_CLAIMS_PREFERENCES_SUCCESS',
  GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR',
  GET_PRODUCTS_LOADING = 'GET_PRODUCTS_LOADING',
  GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS',
}

export interface IGetProfileLoading {
  type: ProfileServiceActionTypes.GET_PROFILE_LOADING;
}

export interface IGetProfileSuccess {
  type: ProfileServiceActionTypes.GET_PROFILE_SUCCESS;
  payload: {profile: IProfile, selectedProfile: IProfileUser};
}

export interface IGetProfileError {
  type: ProfileServiceActionTypes.GET_PROFILE_ERROR;
}

export interface IChangeSelectedProfile {
  type: ProfileServiceActionTypes.CHANGE_SELECTED_PROFILE;
  payload: IProfileUser;
}

export interface IGetClaimsPreferencesLoading {
  type: ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_LOADING;
}

export interface IGetClaimsPreferencesSuccess {
  type: ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_SUCCESS;
  payload: IClaimsPreferences;
}

export interface IGetClaimsPreferencesError {
  type: ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_ERROR;
}

export interface IGetProductsLoading {
  type: ProfileServiceActionTypes.GET_PRODUCTS_LOADING;
}

export interface IGetProductsSuccess {
  type: ProfileServiceActionTypes.GET_PRODUCTS_SUCCESS;
  payload: IProducts;
}

export interface IGetProductsError {
  type: ProfileServiceActionTypes.GET_PRODUCTS_ERROR;
}

export function getProfileLoading(): IGetProfileLoading {
  return {
    type: ProfileServiceActionTypes.GET_PROFILE_LOADING,
  };
}

export function getProfileSuccess(payload: {profile: IProfile, selectedProfile: IProfileUser}): IGetProfileSuccess {
  return {
    type: ProfileServiceActionTypes.GET_PROFILE_SUCCESS,
    payload,
  };
}

export function getProfileError(): IGetProfileError {
  return {
    type: ProfileServiceActionTypes.GET_PROFILE_ERROR,
  };
}

export function changeSelectedProfile(payload: IProfileUser): IChangeSelectedProfile {
  return {
    type: ProfileServiceActionTypes.CHANGE_SELECTED_PROFILE,
    payload,
  };
}

export function getClaimsPreferencesLoading(): IGetClaimsPreferencesLoading {
  return {
    type: ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_LOADING,
  };
}

export function getClaimsPreferencesSuccess(payload: IClaimsPreferences): IGetClaimsPreferencesSuccess {
  return {
    type: ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_SUCCESS,
    payload,
  };
}

export function getClaimsPreferencesError(): IGetClaimsPreferencesError {
  return {
    type: ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_ERROR,
  };
}

export function getProductsLoading(): IGetProductsLoading {
  return {
    type: ProfileServiceActionTypes.GET_PRODUCTS_LOADING,
  };
}

export function getProductsSuccess(payload: IProducts): IGetProductsSuccess {
  return {
    type: ProfileServiceActionTypes.GET_PRODUCTS_SUCCESS,
    payload,
  };
}

export function getProductsError(): IGetProductsError {
  return {
    type: ProfileServiceActionTypes.GET_PRODUCTS_ERROR,
  };
}

export type ProfileServiceAction = IGetProfileLoading | IGetProfileSuccess | IGetProfileError | IChangeSelectedProfile
  | IGetClaimsPreferencesLoading | IGetClaimsPreferencesSuccess | IGetClaimsPreferencesError | IGetProductsLoading
  | IGetProductsSuccess | IGetProductsError;
