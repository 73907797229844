import { IClaimsInfoPacket } from '@rally/rallypay';
import moment from 'moment';
import { ISelectedFilters } from 'scripts/ui/filter/filter.interfaces';
import { ICurrencyAmount, IFullName, IResponse } from '../api.interfaces';
import { AccountType } from '../ledger/ledger.interfaces';

export enum ClaimType {
  All = 'ALL',
  Dental = 'DENTAL',
  Medical = 'MEDICAL',
  Rx = 'RX',
}

export type AnyClaimType = ClaimType | AccountType;

export enum ClaimStatus {
  Denied = 'DENIED',
  InProcess = 'IN_PROCESS',
  Processed = 'PROCESSED',
}

export enum ClaimVideoEligibility {
  Eligible = 'ELIGIBLE',
  FirstEligible = 'FIRST_ELIGIBLE',
  NotEligible = 'NOT_ELIGIBLE',
}

export enum ClaimBalanceType {
  AmountBilled = 'AMOUNT_BILLED',
  PlanPaid = 'PLAN_PAID',
  YouMayOwe = 'YOU_MAY_OWE',
  YouPaid = 'YOU_PAID',
}

export enum ClaimPaymentType {
  Check = 'CHECK',
  Online = 'ONLINE',
  SpendingAccount = 'SPENDING_ACCOUNT',
  Unknown = 'UNKNOWN',
}

export enum ClaimDocumentType {
  EOB = 'EOB',
  FsaForm = 'FSAFORM',
  HraForm = 'HRAFORM',
  Unknown = 'UNKNOWN',
}

export enum ClaimTransactionType {
  ChargeBack = 'CHARGEBACK',
  Refund = 'REFUND',
  Return = 'RETURN',
  Sale = 'SALE',
  Unknown = 'UNKNOWN',
}

export enum ClaimServiceType {
  ChildCare = 'CHILD_CARE',
  Dental = 'DENTAL',
  DependentCare = 'DEPENDENT_CARE',
  Expense = 'EXPENSE',
  Institutional = 'INSTITUTIONAL',
  Interest = 'INTEREST',
  Medical = 'MEDICAL',
  Pharmacy = 'PHARMACY',
  Vision = 'VISION',
  Unknown = 'UNKNOWN',
}

export interface IClaimBalance {
  copay?: ICurrencyAmount;
  deductible?: ICurrencyAmount;
  healthPlanDiscount: ICurrencyAmount;
  healthPlanPays: ICurrencyAmount;
  onlinePaidAmount?: ICurrencyAmount;
  other?: ICurrencyAmount;
  patientResponsibility?: ICurrencyAmount;
  planPaidAmount?: ICurrencyAmount;
  totalBilledAmount: ICurrencyAmount;
  youMayOweAmount?: ICurrencyAmount;
}

export interface IHealthcareClaimDetailsBalance {
  claimCoinsurance?: ICurrencyAmount;
  claimMemberNotCovered?: ICurrencyAmount;
  claimTotalNotCovered?: ICurrencyAmount;
  copay?: ICurrencyAmount;
  deductible?: ICurrencyAmount;
  fsaPaidToProvider?: ICurrencyAmount;
  fsaPaidToYou?: ICurrencyAmount;
  healthPlanDiscount: ICurrencyAmount;
  healthPlanPay: ICurrencyAmount;
  hraPaidToYou?: ICurrencyAmount;
  patientResponsibility: ICurrencyAmount;
  totalBilledAmount: ICurrencyAmount;
  medicarePaid?: ICurrencyAmount;
  otherInsurancePaid?: ICurrencyAmount;
  youMayOweAmount?: ICurrencyAmount;
  hraPaidToProvider?: ICurrencyAmount;
}

export interface IFinancialClaimBalance {
  amountSubmitted: ICurrencyAmount;
  amountPaid: ICurrencyAmount;
  amountPending: ICurrencyAmount;
  paidWithDebitCard: boolean;
}

export interface IFinancialClaimDetailsBalance {
  amountPending: ICurrencyAmount;
  amountPlanPaid: ICurrencyAmount;
  amountSubmitted: ICurrencyAmount;
  paidWithDebitCard: boolean;
}

export interface IClaimTotals {
  balance: IClaimBalance;
  claimType: ClaimType;
  timestamp: moment.Moment;
}

export interface IClawbackIndicators {
  isClawbackClaim: boolean;
  isClawbackReversal: boolean;
}

export interface IClaimManagementInfo {
  claimNote: boolean;
  isMerp: boolean;
  isSaved: boolean;
  markPaid: boolean;
  youOwe?: boolean;
}

export interface IManageClaimData {
  claimKey: string;
  manageClaim: {
    claimNote?: string;
    isSaved?: boolean;
    lastServiceDate: string;
    markPaid?: boolean;
    providerName?: string;
    serviceDate: string;
  };
}

export interface IServiceRecipient extends IFullName {
  dependentSeqNbr: string;
}

export interface IClaimDates {
  datePaid?: moment.Moment;
  dateProcessed?: moment.Moment;
  dateReceived?: moment.Moment;
  lastServiceDate: moment.Moment;
  serviceStartDate: moment.Moment;
}

export interface IClaimPaymentType {
  checkIssueDate?: moment.Moment;
  confirmationNumber?: string;
  draftNumber: string;
  payeeType?: IDescriptionCode;
  payment: ICurrencyAmount;
  paymentType: string;
  postedDate?: moment.Moment;
  transactionType?: ClaimTransactionType;
}

export interface IServiceLine {
  lastServiceDate: moment.Moment;
  procedures: IDescriptionCode[];
  providedServiceInfo?: IDescriptionCode;
  revenues: IDescriptionCode[];
  serviceAdjudication: IServiceAdjudication;
  serviceDate: moment.Moment;
  serviceInfo?: IDescriptionCode;
  serviceRemarks: IDescriptionCode[];
  toothNumber?: string;
}

export interface IFinancialServiceLine {
  providedServiceType: ClaimServiceType;
  startDate: moment.Moment;
  stopDate: moment.Moment;
  fsaPaid?: ICurrencyAmount;
  hraPaid?: ICurrencyAmount;
  amountPending?: ICurrencyAmount;
  amountSubmitted?: ICurrencyAmount;
  draftNumber?: string;
  claimPaidDate?: moment.Moment;
  serviceCode?: IDescriptionCode;
}

export type IAnyServiceLine = IServiceLine | IFinancialServiceLine;

export interface IDescriptionCode {
  description: string;
  code: string;
}

export interface IServiceAdjudication {
  allowedAmount?: ICurrencyAmount;
  chargedAmount?: ICurrencyAmount;
  coinsurance?: ICurrencyAmount;
  copay?: ICurrencyAmount;
  deductible?: ICurrencyAmount;
  memberNotCoveredAmount?: ICurrencyAmount;
  patientResponsibility?: ICurrencyAmount;
  planPaidAmount?: ICurrencyAmount;
  providerWriteOff?: ICurrencyAmount;
}

export interface IClaim {
  balance: IClaimBalance;
  claimId: string;
  claimKey?: string;
  claimManagementInfo: IClaimManagementInfo;
  claimReference?: string;
  claimStatus: ClaimStatus;
  claimType: ClaimType;
  claimVideoEligibility?: ClaimVideoEligibility;
  clawbackIndicators: IClawbackIndicators;
  claimPayKey: string;
  draftNumber: string;
  eobDocument?: string;
  lastServiceDate: moment.Moment;
  processedDate: moment.Moment;
  providerName?: string;
  providerHash?: string;
  serviceDate: moment.Moment;
  serviceRecipient: IServiceRecipient;
}

export interface IClaimError {
  claimType: ClaimType;
  depSeqNbr?: string;
}

export interface IAllClaimSummaries {
  claims: IClaim[];
  errors: IClaimError[];
}

export interface IFinancialClaim {
  balance: IFinancialClaimBalance;
  claimKey?: string;
  claimNumber: string;
  claimReference?: string;
  claimStatus: ClaimStatus;
  accountType: AccountType;
  lastServiceDate: moment.Moment;
  processedDate: moment.Moment;
  providerName?: string;
  serviceDate: moment.Moment;
  serviceRecipient: IServiceRecipient;
}

export type IAnyClaim = IClaim | IFinancialClaim;

export interface IHealthcareClaimDetails {
  balance: IHealthcareClaimDetailsBalance;
  claimDates: IClaimDates;
  claimId: string;
  claimKey?: string;
  claimManagementInfo: IClaimManagementInfo;
  claimPayKey: string;
  claimPaymentTypes?: IClaimPaymentType[];
  claimReference?: string;
  claimStatus: ClaimStatus;
  claimType: ClaimType;
  claimVideoEligibility?: ClaimVideoEligibility;
  clawbackIndicators: IClawbackIndicators;
  draftNumber: string;
  eobDocument?: string;
  medicationName?: string;
  prescriptionNumber?: string;
  providerName?: string;
  serviceLines?: IServiceLine[];
  serviceRecipient: IServiceRecipient;
  wcagAccessible?: IWcagAccessible;
}

export interface IFinancialClaimDetails {
  accountType: AccountType;
  balance: IFinancialClaimDetailsBalance;
  claimDates: IClaimDates;
  claimId: string;
  claimStatus: ClaimStatus;
  documents: IFinancialClaimDetailsDocuments;
  draftNumber?: string;
  providerName?: string;
  serviceLines?: IFinancialServiceLine[];
  serviceRecipient: IServiceRecipient;
  wcagAccessible?: IWcagAccessible;
}

export interface IFinancialClaimDetailsDocuments {
  EOB?: string[];
  FSAFORM?: string[];
  HRAFORM?: string[];
  UNKNOWN?: string[];
}

export interface IWcagAccessible {
  eob: boolean;
  fsaForm: boolean;
  hraForm: boolean;
}

export interface IClaimNote {
  note?: string;
  claimKey: string;
}

export interface IGetMatchingClaimParams {
  claimId: string;
  claimKey?: string;
  fromStateName: string;
  type?: ClaimType;
  filters?: string;
}

export interface IFinancialClaimsResponse extends IResponse<IFinancialClaim[]> {}

export type IAnyClaimDetails = IHealthcareClaimDetails | IFinancialClaimDetails;

export interface IClaimTotalsResponse extends IResponse<IClaimTotals[]> {}

export interface IClaimsSummaryResponse extends IResponse<IClaim[]> {}

export interface IClaimsNeedAttentionResponse extends IResponse<IClaim[]> {}

export interface IClaimsAllResponse extends IResponse<IClaim[]> {}

export interface IAllClaimSummariesResponse extends IResponse<IAllClaimSummaries> {}

export interface IClaimsManageResponse extends IResponse<undefined> {}

export interface IClaimActionResponse extends IResponse<undefined> {}

export interface IClaimNoteResponse extends IResponse<IClaimNote> {}

export interface IHealthcareClaimDetailsResponse extends IResponse<IHealthcareClaimDetails> {}

export interface IFinancialClaimDetailsResponse extends IResponse<IFinancialClaimDetails> {}

export interface IRallyPayClaimDetailsResponse extends IResponse<IClaimsInfoPacket> {}
