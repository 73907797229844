import { IClaim } from 'scripts/api/claims/claims.interfaces';

export enum ClaimsServiceActionTypes {
  GET_SUMMARY_ERROR = 'GET_SUMMARY_ERROR',
  GET_SUMMARY_LOADING = 'GET_SUMMARY_LOADING',
  GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS',
  SAVE_CLAIM_SUCCESS = 'SAVE_CLAIM_SUCCESS',
}

export interface IGetSummaryLoading {
  type: ClaimsServiceActionTypes.GET_SUMMARY_LOADING;
}

export interface IGetSummarySuccess {
  type: ClaimsServiceActionTypes.GET_SUMMARY_SUCCESS;
  payload: IClaim[];
}

export interface IGetSummaryError {
  type: ClaimsServiceActionTypes.GET_SUMMARY_ERROR;
}

export interface ISaveClaimSuccess {
  type: ClaimsServiceActionTypes.SAVE_CLAIM_SUCCESS;
  payload: string;
}

export function getSummaryLoading(): IGetSummaryLoading {
  return {
    type: ClaimsServiceActionTypes.GET_SUMMARY_LOADING,
  };
}

export function getSummarySuccess(payload: IClaim[]): IGetSummarySuccess {
  return {
    type: ClaimsServiceActionTypes.GET_SUMMARY_SUCCESS,
    payload,
  };
}

export function getSummaryError(): IGetSummaryError {
  return {
    type: ClaimsServiceActionTypes.GET_SUMMARY_ERROR,
  };
}

export function saveClaimSuccess(claimKey: string): ISaveClaimSuccess {
  return {
    type: ClaimsServiceActionTypes.SAVE_CLAIM_SUCCESS,
    payload: claimKey,
  };
}

export type ClaimsServiceAction =  IGetSummaryLoading | IGetSummarySuccess | IGetSummaryError | ISaveClaimSuccess;
