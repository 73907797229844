export enum LocaleCountry {
  Haiti = 'HT',
  Philippines = 'PH',
  Taiwan = 'TW',
  UnitedStates = 'US',
  Vietnam = 'VN',
}

export enum LocaleLanguage {
  ChineseTraditional = 'zh',
  English = 'en',
  HaitianCreole = 'ht',
  Ilocano = 'il',
  Korean = 'ko',
  Spanish = 'es',
  Tagalog = 'tl',
  Vietnamese = 'vi',
}

export interface ILocale {
  id: string;
  language: LocaleLanguage;
  country: LocaleCountry;
}
