import { ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';

export enum LedgerServiceActionTypes {
  GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR',
  GET_ACCOUNTS_LOADING = 'GET_ACCOUNTS_LOADING',
  GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS',
}

export interface IGetAccountsLoading {
  type: LedgerServiceActionTypes.GET_ACCOUNTS_LOADING;
}

export interface IGetAccountsSuccess {
  type: LedgerServiceActionTypes.GET_ACCOUNTS_SUCCESS;
  payload: ILedgerAccount[];
}

export interface IGetAccountsError {
  type: LedgerServiceActionTypes.GET_ACCOUNTS_ERROR;
}

export function getAccountsLoading(): IGetAccountsLoading {
  return {
    type: LedgerServiceActionTypes.GET_ACCOUNTS_LOADING,
  };
}

export function getAccountsSuccess(payload: ILedgerAccount[]): IGetAccountsSuccess {
  return {
    type: LedgerServiceActionTypes.GET_ACCOUNTS_SUCCESS,
    payload,
  };
}

export function getAccountsError(): IGetAccountsError {
  return {
    type: LedgerServiceActionTypes.GET_ACCOUNTS_ERROR,
  };
}

export type LedgerServiceAction = IGetAccountsLoading | IGetAccountsSuccess | IGetAccountsError;
