import React, { AnchorHTMLAttributes, forwardRef, ReactElement, Ref } from 'react';

export interface IAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  noRel?: boolean;
  ref?: Ref<HTMLAnchorElement>;
}

function RawAnchor(props: IAnchorProps): ReactElement<IAnchorProps> {
  const { noRel, ref, target, ...rest } = props;
  const isExternalLink = target === '_blank' && !noRel;
  return (
    <a
      ref={ref}
      rel={isExternalLink ? 'noopener noreferrer' : undefined}
      target={target}
      {...rest}
    />
  );
}

export const Anchor = forwardRef((props: IAnchorProps, ref: Ref<HTMLAnchorElement>) => <RawAnchor ref={ref} {...props} />);
