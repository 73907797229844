import accordionTemplate from 'views/ui/accordion.html';
import { AccordionController } from './accordion.controller';

export class AccordionDirective implements ng.IDirective {
  public restrict = 'E';
  public replace = true;
  public scope = {};
  public templateUrl = accordionTemplate;
  public controller = AccordionController;
  public controllerAs = '$accordion';
  public bindToController = {
    items: '=',
    label: '@',
    value: '@',
    interpolations: '@',
  };

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = () => new AccordionDirective();
    return directive;
  }
}

export class AccordionItemDirective implements ng.IDirective {
  public restrict = 'A';
  public require = '^accordion';
  public scope = {
    item: '=accordionItem',
    animateTime: '&',
  };

  constructor(private $timeout: ng.ITimeoutService) {}
  public link = (scope, element, attrs, $accordion) => {
    const yPadding = parseInt(getComputedStyle(element[0]).paddingTop, 10) +
      parseInt(getComputedStyle(element[0]).paddingBottom, 10);

    element
      .css('padding-top', 0)
      .css('padding-bottom', 0);

    scope.$watch('item.$expanded', $expanded => {
      const animateTime = attrs.animateTime || 500;
      if ($expanded) {
        element.addClass('accordion-expanded')
          .attr('aria-hidden', false)
          .css('display', 'block')
          .css('transition', 'height ' + animateTime + 'ms, padding ' + animateTime + 'ms');
        const expandedHeight = element[0].scrollHeight + yPadding + 'px';
        element.css('height', expandedHeight);
      } else {
        element.removeClass('accordion-expanded')
          .attr('aria-hidden', true)
          .css('height', 0);
        this.$timeout(() => {
          element.css('display', 'none');
        }, animateTime);
      }
    });
  }

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($timeout: ng.ITimeoutService) => {
      'ngInject';
      return new AccordionItemDirective($timeout);
    };
    return directive;
  }
}
