import { Observable } from 'rxjs/Observable';
import { getMoneyValue, getPercentValue, hasNonZeroDecimals } from 'scripts/util/money/money';
import { drugCostEstimator, drugCostEstimatorOptum, drugCoverage, pharmacyLocator } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import drugCostsTemplate from 'views/dashboard/drug-costs.html';
import { CoverageTypeCode, LinkTarget } from '../../../api/api.interfaces';
import {
  DrugCoverageState,
  DrugCoverageType,
  DrugTierType,
  IBenefit,
  IDrugBenefits,
  IDrugInformation,
} from '../../../api/plans/plans.interfaces';
import { IPlansService } from '../../../api/plans/plans.service';
import { FundingType, IPlanCoverage, LisLevel } from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';

export class DrugCostsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;

  constructor() {
    this.controller = DrugCostsController;
    this.templateUrl = drugCostsTemplate;
  }
}

export interface IDrugCost {
  title: string;
  type: DrugTierType;
  items: IDrugCostItem[];
  show?: boolean;
}

export interface IDrugCostItem {
  type: DrugCoverageType;
  cost: string;
  copay: boolean;
}

export class DrugCostsController {
  public drugCoverage: IResource;
  public pharmacyLocator: IResource;
  public request: Observable<{coverage: IPlanCoverage, benefit: IBenefit}>;
  public drugCostLink: string;
  public drugCostLinkTarget: string;
  public drugCoverageState: DrugCoverageState;
  public costs: IDrugCost[];
  public hasCosts: boolean;
  public isLisLevel4: boolean;
  public isFundingTypeIndividual: boolean;
  public drugCostTypeMap: {[drugCoverageType: string]: boolean} = {};
  public drugCostTypeCopyMap: {[drugCoverageType: string]: string};
  public drugCostTypeModalMap: {[drugCoverageType: string]: string};
  public orderedDrugCoverageTypes: DrugCoverageType[];

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.COSTS);

    this.drugCoverage = drugCoverage;
    this.pharmacyLocator = pharmacyLocator;

    this.orderedDrugCoverageTypes = [DrugCoverageType.Preferred, DrugCoverageType.Standard, DrugCoverageType.PreferredMailOrder];
    this.drugCostTypeModalMap = {
      [DrugCoverageType.Preferred]: 'modal.preferredRetailPharmacy',
      [DrugCoverageType.Standard]: 'modal.standardRetailPharmacy',
      [DrugCoverageType.PreferredMailOrder]: 'modal.preferredMailServicePharmacy',
    };
    this.drugCostTypeCopyMap = {
      [DrugCoverageType.Preferred]: 'PREFERRED_RETAIL_PHARMACY',
      [DrugCoverageType.Standard]: 'STANDARD_RETAIL_PHARMACY',
      [DrugCoverageType.PreferredMailOrder]: 'PREFERRED_MAIL_SERVICE_PHARMACY',
    };

    this.costs = [{
      title: 'TIER_1',
      type: DrugTierType.Tier1,
      items: [],
    }, {
      title: 'TIER_2',
      type: DrugTierType.Tier2,
      items: [],
    }, {
      title: 'TIER_3',
      type: DrugTierType.Tier3,
      items: [],
    }, {
      title: 'TIER_4',
      type: DrugTierType.Tier4,
      items: [],
    }, {
      title: 'TIER_5',
      type: DrugTierType.Tier5,
      items: [],
    }, {
      title: 'COVERED_GENERIC_DRUGS',
      type: DrugTierType.GenericDrugs,
      items: [],
    }, {
      title: 'ALL_OTHER_COVERED_DRUGS',
      type: DrugTierType.OtherDrugs,
      items: [],
    }];

    this.request = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(profile => profile.data.currentUser)
      .flatMap(currentUser => currentUser.planCoverages, (currentUser, coverage) => ({currentUser, coverage}))
      .filter(({coverage}) => coverage.coverageTypeCode === CoverageTypeCode.MAPD || coverage.coverageTypeCode === CoverageTypeCode.PDP)
      .flatMap(({currentUser, coverage}) =>
        this.plansService.getBenefits(currentUser.rallyId, currentUser.dependentSeqNum, coverage.coverageTypeCode),
        ({coverage}, benefitsRsp) => ({coverage, benefitsRsp}))
      .flatMap(({benefitsRsp, coverage}) => benefitsRsp.data.benefits, ({coverage}, benefit) => ({coverage, benefit}))
      .first();
  }

  public $onInit(): void {
    this.request.subscribe(({coverage, benefit: {drugBenefits}}) => {
      this.initCosts(drugBenefits);
      this.isFundingTypeIndividual = coverage.planFeatures.fundingArrangementType === FundingType.Individual;
      this.setDrugCostLink();
      if (coverage.planFeatures.lisLevel === LisLevel.FOUR && this.drugCoverageState === DrugCoverageState.Initial) {
        this.isLisLevel4 = true;
        // in this special case we don't actually display any user-specifc drug cost data so here we
        // manually set drugCostTypeMap and cost show booleans, and override getNumDrugCoverageTypes
        this.drugCostTypeMap = {[DrugTierType.GenericDrugs]: true, [DrugTierType.OtherDrugs]: true};
        for (let i = 0; i < 5; i++) {
          this.costs[i].show = false;
        }
        this.costs[5].show = this.costs[6].show = true;
        this.getNumDrugCoverageTypes = () => 0;
      }
    }, console.warn);
  }

  public getNumDrugCoverageTypes(): number {
    return this.orderedDrugCoverageTypes.reduce((sum, coverageType) => sum + (this.drugCostTypeMap[coverageType] ? 1 : 0), 0);
  }

  public getNumCostsShown(): number {
    return this.costs.reduce((sum, cost) => sum + (cost.show ? 1 : 0), 0);
  }

  private initCosts(drugBenefits: IDrugBenefits): void {
    this.costs.filter(cost => drugBenefits.hasOwnProperty(cost.type)).forEach(cost => {
      cost.show = this.hasCosts = true;
      this.drugCoverageState = drugBenefits[cost.type].drugCoverageState || this.drugCoverageState;
      const drugTypeInfomations = drugBenefits[cost.type].drugTypeInformations as IDrugInformation[];
      this.orderedDrugCoverageTypes.forEach(drugCoverageType => {
        const costItem = { type: drugCoverageType, cost: undefined, copay: false } as IDrugCostItem;
        for (const drugTypeInformation of drugTypeInfomations) {
          if (drugCoverageType === drugTypeInformation.drugCoverageType) {
            this.drugCostTypeMap[drugCoverageType] = true;
            costItem.cost = this.getDrugCost(drugTypeInformation);
            costItem.copay = !!drugTypeInformation.drugCopay;
            cost.items.push(costItem);
            break;
          }
        }
      });
    });
  }

  private getDrugCost(drugTypeInformation: IDrugInformation): string {
    if (drugTypeInformation.drugCoinsurance) {
      return getPercentValue(parseFloat(drugTypeInformation.drugCoinsurance));
    }
    if (drugTypeInformation.drugCopay) {
      const drugCopayFloat = parseFloat(drugTypeInformation.drugCopay);
      if (hasNonZeroDecimals(drugCopayFloat)) {
        return getMoneyValue(drugCopayFloat, true);
      } else {
        return getMoneyValue(drugCopayFloat, false);
      }
    }
  }

  private setDrugCostLink(): void {
    if (this.isFundingTypeIndividual) {
      this.drugCostLink = this.resourceService.get(drugCostEstimator);
      this.drugCostLinkTarget = LinkTarget.Self;
    } else {
      this.drugCostLink = this.resourceService.get(drugCostEstimatorOptum);
      this.drugCostLinkTarget = LinkTarget.Blank;
    }
  }
}
