import subNavTemplate from 'views/chrome/sub-nav.html';
import { SubNavController } from './sub-nav.controller';

export class SubNavComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  public transclude: any;

  constructor() {
    this.controller = SubNavController;
    this.templateUrl = subNavTemplate;
    this.transclude = {
      label: 'span',
      links: 'div',
    };
  }
}
