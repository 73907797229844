import promoPanelsTemplate from 'views/programs/promo-panels.html';

export class PromoPanelsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = PromoPanelsController;
    this.templateUrl = promoPanelsTemplate;
  }
}

import { Subscription } from 'rxjs/Subscription';
import { getLink } from 'scripts/util/uri/uri';
import {
  AllProgramsPageCampaigns,
  CampaignPlacementType,
  ICampaign,
} from '../../../api/targeting/targeting.interfaces';
import { ITargetingService, TargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { ILocaleService } from '../../../util/locale/locale.service';

export class PromoPanelsController implements ng.IComponentController {
  public promoGrid: ICampaign[][];
  public withIncentives: boolean;
  private localeSubscription: Subscription;

  constructor(
    private $filter: ng.IFilterService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private localeService: ILocaleService,
    public targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.PROGRAMS);
  }

  public $onInit(): void {
    this.setPromoPanels();
    this.localeSubscription = this.localeService.localeChanged.subscribe(() => {
      this.setPromoPanels();
    });
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public getLink(link: string): string {
    return getLink(link);
  }

  public isIncentiveValid(promo: ICampaign): boolean {
    return TargetingService.isIncentiveValid(promo);
  }

  private setPromoPanels(): void {
    this.userService.getHeartbeat().flatMap(rsp => this.targetingService.getCampaigns(rsp.data.rallyId, [AllProgramsPageCampaigns]))
      .filter(({data}) => !!(data && data.placements))
      .map(({data: {placements}}) => placements)
      .subscribe(campaigns => {
        const programs = !!campaigns[CampaignPlacementType.ArcadeResourcesPrograms]
          ? this.$filter('orderBy')(campaigns[CampaignPlacementType.ArcadeResourcesPrograms], 'priority', true)
          : [];
        this.withIncentives = programs.some(c =>
          !!(c.metadata && c.metadata.rewardValue)
          && this.isIncentiveValid(c));
        if (programs) {
          this.promoGrid = [];
          let i = 0;
          while (i < programs.length / 3) {
            this.promoGrid.push(programs.slice(3 * i, 3 * (i + 1)));
            i++;
          }
        }
      });
  }
}
