import classNames from 'classnames';
import React, { Component, forwardRef, ReactElement, Ref } from 'react';
import { Translation } from 'react-i18next';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

interface IDropdownButtonProps {
  dataTrackId?: string;
  dictionary: string;
  label: string;
  onClick: () => void;
  optionsId: string;
  show: boolean;
  toggleClass?: string;
  toggleId: string;
}

interface IButtonProps extends IDropdownButtonProps {
  forwardedRef: Ref<HTMLButtonElement>;
}

class Button extends Component<IButtonProps> {
  public render(): ReactElement<IButtonProps> {
    const {
      dictionary,
      label,
      onClick,
      optionsId,
      show,
      toggleClass = 'dropdown-toggle',
      toggleId,
      forwardedRef,
      ...rest
    } = this.props;
    return (
      <button
        aria-controls={optionsId}
        aria-expanded={show}
        aria-haspopup="true"
        className={classNames(toggleClass, { 'dropdown-open': show })}
        data-testid="toggle-button"
        id={toggleId}
        onClick={onClick}
        ref={forwardedRef}
        {...rest}
      >
        <Translation ns={[dictionary, Dictionary.COMMON]}>
          {t => (
            <span>
              <span className="sr-only">{t(`${Dictionary.COMMON}:SELECT_OPTION`)}:</span>
              {t(`${dictionary}:${label}`)}
            </span>
          )}
        </Translation>
        <i className="icon-triangle-down dropdown-arrow" aria-hidden="true" />
      </button>
    );
  }
}

const ButtonWithTracking = withClickTracking(Button, 'toggle');

export const DropdownButton = forwardRef(
  (props: IDropdownButtonProps, ref: Ref<HTMLButtonElement>) => {
    const { label } = props;
    return <ButtonWithTracking forwardedRef={ref} dataTrackId={`toggle-${label}`} {...props} />;
  },
);
