// tslint:disable:max-classes-per-file
import * as AccountInfo from 'i18n/en-US/account-info.json';
import * as AccountSelector from 'i18n/en-US/account-selector.json';
import * as AccountSummary from 'i18n/en-US/account-summary.json';
import * as Accumulators from 'i18n/en-US/accumulators.json';
import * as ActivateVideoTranscript from 'i18n/en-US/activate-video-transcript.json';
import * as ActivateVideo from 'i18n/en-US/activate-video.json';
import * as AdditionalLinks from 'i18n/en-US/additional-links.json';
import * as AllClaimsFaq from 'i18n/en-US/all-claims-faq.json';
import * as AllClaims from 'i18n/en-US/all-claims.json';
import * as AutoPayment from 'i18n/en-US/auto-payment.json';
import * as ClaimDetails from 'i18n/en-US/claim-details.json';
import * as ClaimHelpMenu from 'i18n/en-US/claim-help-menu.json';
import * as ClaimLetters from 'i18n/en-US/claim-letters.json';
import * as ClaimVideo from 'i18n/en-US/claim-video.json';
import * as Claim from 'i18n/en-US/claim.json';
import * as ClaimsAndAccounts from 'i18n/en-US/claims-and-accounts.json';
import * as ClaimsNeedAttention from 'i18n/en-US/claims-need-attention.json';
import * as Common from 'i18n/en-US/common.json';
import * as CompareCare from 'i18n/en-US/compare-care.json';
import * as ContactUs from 'i18n/en-US/contact-us.json';
import * as ContentHopper from 'i18n/en-US/content-hopper.json';
import * as Costs from 'i18n/en-US/costs.json';
import * as DateSelector from 'i18n/en-US/date-selector.json';
import * as DcsaFaq from 'i18n/en-US/dcsa-faq.json';
import * as DirectDeposit from 'i18n/en-US/direct-deposit.json';
import * as EditClaimNote from 'i18n/en-US/edit-claim-note.json';
import * as Errors from 'i18n/en-US/errors.json';
import * as ExpirationWarning from 'i18n/en-US/expiration-warning.json';
import * as Faq from 'i18n/en-US/faq.json';
import * as Filter from 'i18n/en-US/filter.json';
import * as Footer from 'i18n/en-US/footer.json';
import * as FsaFaq from 'i18n/en-US/fsa-faq.json';
import * as GuidedSearch from 'i18n/en-US/guided-search.json';
import * as HcsaFaq from 'i18n/en-US/hcsa-faq.json';
import * as Header from 'i18n/en-US/header.json';
import * as HowItWorks from 'i18n/en-US/how-it-works.json';
import * as HraFaq from 'i18n/en-US/hra-faq.json';
import * as HsaFaq from 'i18n/en-US/hsa-faq.json';
import * as IdCards from 'i18n/en-US/id-cards.json';
import * as Inactive from 'i18n/en-US/inactive.json';
import * as Incentives from 'i18n/en-US/incentives.json';
import * as LinkBar from 'i18n/en-US/link-bar.json';
import * as MraFaq from 'i18n/en-US/mra-faq.json';
import * as Notification from 'i18n/en-US/notification.json';
import * as Onboarding from 'i18n/en-US/onboarding.json';
import * as PageTitles from 'i18n/en-US/page-titles.json';
import * as PayNowModal from 'i18n/en-US/pay-now-modal.json';
import * as Pcp from 'i18n/en-US/pcp.json';
import * as Pharmacy from 'i18n/en-US/pharmacy.json';
import * as PlanBalances from 'i18n/en-US/plan-balances.json';
import * as PremiumPayments from 'i18n/en-US/premium-payments.json';
import * as Programs from 'i18n/en-US/programs.json';
import * as QuickLinks from 'i18n/en-US/quick-links.json';
import * as RecentClaims from 'i18n/en-US/recent-claims.json';
import * as Recommendations from 'i18n/en-US/recommendations.json';
import * as Resources from 'i18n/en-US/resources.json';
import * as Statements from 'i18n/en-US/statements.json';
import * as SuperUserBanner from 'i18n/en-US/super-user-banner.json';
import * as TipsToSave from 'i18n/en-US/tips-to-save.json';
import * as Wellness from 'i18n/en-US/wellness.json';
import { IRootScope } from '../arcade.module.interfaces';
import { Dictionary, LocaleKey } from '../util/constants/i18n.constants';

export class Translate {
  constructor($translateProvider: ng.translate.ITranslateProvider) {
    'ngInject';
    $translateProvider.useLoader('$translatePartialLoader', {
      urlTemplate: '/i18n/{lang}/{part}.json',
    });
    $translateProvider.preferredLanguage('en-US');
    $translateProvider.useLocalStorage();
    $translateProvider.storageKey(LocaleKey);
    $translateProvider.useLoaderCache('$templateCache');
    ($translateProvider as any).keepContent(true);

    // http://angular-translate.github.io/docs/#/guide/19_security
    // The recommended sanitation strategy for translations is to use 'sanitize' or 'escape'.
    // We're using null so that we can render returned HTML, and because we have full control over the
    // translations that are returned.
    $translateProvider.useSanitizeValueStrategy(null);
  }
}

export class TranslateEvents {
  constructor($rootScope: IRootScope, $translate: ng.translate.ITranslateService, $translateLocalStorage: ng.translate.IStorage) {
    'ngInject';
    $rootScope.$on('$translatePartialLoaderStructureChanged', () => {
      $translate.refresh();
    });
    $rootScope.$on('$translateChangeEnd', () => {
      $rootScope.locale = $translateLocalStorage.get(LocaleKey);
    });
  }
}

interface IJsonFiles {
  filename: string;
  content: any;
}

export class EnglishStrings {
  private static jsonFiles: IJsonFiles[] = [{
    filename: `${Dictionary.ACCOUNT_INFO}.json`,
    content: AccountInfo,
  }, {
    filename: `${Dictionary.ACCOUNT_SELECTOR}.json`,
    content: AccountSelector,
  }, {
    filename: `${Dictionary.ACCOUNT_SUMMARY}.json`,
    content: AccountSummary,
  }, {
    filename: `${Dictionary.ACCUMULATORS}.json`,
    content: Accumulators,
  }, {
    filename: `${Dictionary.ACTIVATE_VIDEO}.json`,
    content: ActivateVideo,
  }, {
    filename: `${Dictionary.ACTIVATE_VIDEO_TRANSCRIPT}.json`,
    content: ActivateVideoTranscript,
  }, {
    filename: `${Dictionary.ADDITIONAL_LINKS}.json`,
    content: AdditionalLinks,
  }, {
    filename: `${Dictionary.ALL_CLAIMS}.json`,
    content: AllClaims,
  }, {
    filename: `${Dictionary.ALL_CLAIMS_FAQ}.json`,
    content: AllClaimsFaq,
  }, {
    filename: `${Dictionary.AUTO_PAYMENT}.json`,
    content: AutoPayment,
  }, {
    filename: `${Dictionary.CLAIM}.json`,
    content: Claim,
  }, {
    filename: `${Dictionary.CLAIM_DETAILS}.json`,
    content: ClaimDetails,
  }, {
    filename: `${Dictionary.CLAIM_HELP_MENU}.json`,
    content: ClaimHelpMenu,
  }, {
    filename: `${Dictionary.CLAIM_LETTERS}.json`,
    content: ClaimLetters,
  }, {
    filename: `${Dictionary.CLAIM_VIDEO}.json`,
    content: ClaimVideo,
  }, {
    filename: `${Dictionary.CLAIMS_AND_ACCOUNTS}.json`,
    content: ClaimsAndAccounts,
  }, {
    filename: `${Dictionary.CLAIMS_NEED_ATTENTION}.json`,
    content: ClaimsNeedAttention,
  }, {
    filename: `${Dictionary.COMMON}.json`,
    content: Common,
  }, {
    filename: `${Dictionary.COMPARE_CARE}.json`,
    content: CompareCare,
  }, {
    filename: `${Dictionary.CONTACT_US}.json`,
    content: ContactUs,
  }, {
    filename: `${Dictionary.CONTENT_HOPPER}.json`,
    content: ContentHopper,
  }, {
    filename: `${Dictionary.COSTS}.json`,
    content: Costs,
  }, {
    filename: `${Dictionary.DATE_SELECTOR}.json`,
    content: DateSelector,
  }, {
    filename: `${Dictionary.DCSA_FAQ}.json`,
    content: DcsaFaq,
  }, {
    filename: `${Dictionary.DIRECT_DEPOSIT}.json`,
    content: DirectDeposit,
  }, {
    filename: `${Dictionary.ERRORS}.json`,
    content: Errors,
  }, {
    filename: `${Dictionary.EDIT_CLAIM_NOTE}.json`,
    content: EditClaimNote,
  }, {
    filename: `${Dictionary.EXPIRATION_WARNING}.json`,
    content: ExpirationWarning,
  }, {
    filename: `${Dictionary.FAQ}.json`,
    content: Faq,
  }, {
    filename: `${Dictionary.FILTER}.json`,
    content: Filter,
  }, {
    filename: `${Dictionary.FOOTER}.json`,
    content: Footer,
  }, {
    filename: `${Dictionary.FSA_FAQ}.json`,
    content: FsaFaq,
  }, {
    filename: `${Dictionary.GUIDED_SEARCH}.json`,
    content: GuidedSearch,
  }, {
    filename: `${Dictionary.HEADER}.json`,
    content: Header,
  }, {
    filename: `${Dictionary.HRA_FAQ}.json`,
    content: HraFaq,
  }, {
    filename: `${Dictionary.HSA_FAQ}.json`,
    content: HsaFaq,
  }, {
    filename: `${Dictionary.HCSA_FAQ}.json`,
    content: HcsaFaq,
  }, {
    filename: `${Dictionary.HOW_IT_WORKS}.json`,
    content: HowItWorks,
  }, {
    filename: `${Dictionary.ID_CARDS}.json`,
    content: IdCards,
  }, {
    filename: `${Dictionary.INACTIVE}.json`,
    content: Inactive,
  }, {
    filename: `${Dictionary.INCENTIVES}.json`,
    content: Incentives,
  }, {
    filename: `${Dictionary.LINK_BAR}.json`,
    content: LinkBar,
  }, {
    filename: `${Dictionary.MRA_FAQ}.json`,
    content: MraFaq,
  }, {
    filename: `${Dictionary.NOTIFICATION}.json`,
    content: Notification,
  }, {
    filename: `${Dictionary.ONBOARDING}.json`,
    content: Onboarding,
  }, {
    filename: `${Dictionary.PAY_NOW_MODAL}.json`,
    content: PayNowModal,
  }, {
    filename: `${Dictionary.PAGE_TITLES}.json`,
    content: PageTitles,
  }, {
    filename: `${Dictionary.PCP}.json`,
    content: Pcp,
  }, {
    filename: `${Dictionary.PHARMACY}.json`,
    content: Pharmacy,
  }, {
    filename: `${Dictionary.PLAN_BALANCES}.json`,
    content: PlanBalances,
  }, {
    filename: `${Dictionary.PREMIUM_PAYMENTS}.json`,
    content: PremiumPayments,
  }, {
    filename: `${Dictionary.PROGRAMS}.json`,
    content: Programs,
  }, {
    filename: `${Dictionary.QUICK_LINKS}.json`,
    content: QuickLinks,
  }, {
    filename: `${Dictionary.RECOMMENDATIONS}.json`,
    content: Recommendations,
  }, {
    filename: `${Dictionary.RECENT_CLAIMS}.json`,
    content: RecentClaims,
  }, {
    filename: `${Dictionary.RESOURCES}.json`,
    content: Resources,
  }, {
    filename: `${Dictionary.SUPER_USER_BANNER}.json`,
    content: SuperUserBanner,
  }, {
    filename: `${Dictionary.TIPS_TO_SAVE}.json`,
    content: TipsToSave,
  }, {
    filename: `${Dictionary.WELLNESS}.json`,
    content: Wellness,
  }];

  constructor($templateCache: ng.ITemplateCacheService) {
    'ngInject';
    EnglishStrings.jsonFiles.forEach(json => {
      $templateCache.put(`/i18n/en-US/${json.filename}`, json.content);
    });
  }
}
