import { ILocale } from 'scripts/util/locale/locale.interfaces';

export enum LocaleActionTypes {
  CHANGE_LOCALE = 'CHANGE_LOCALE',
}

export interface IChangeLocale {
  type: LocaleActionTypes.CHANGE_LOCALE;
  payload: ILocale;
}

export function changeLocale(payload: ILocale): IChangeLocale {
  return {
    type: LocaleActionTypes.CHANGE_LOCALE,
    payload,
  };
}

export type LocaleAction = IChangeLocale;
