import angular from 'angular';
import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import allClaimsRefineResultsTemplate from 'views/modals/all-claims-refine-results.html';
import { AnyClaimType, ClaimType, IAnyClaim, IClaim } from '../../../api/claims/claims.interfaces';
import { ClaimsService } from '../../../api/claims/claims.service';
import { IProfileService } from '../../../api/profile/profile.service';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { IDropdownOption, ISortDropdownOption } from '../../../ui/dropdown/dropdown.interfaces';
import { FilterStateKey, IGenericFilter, ISelectedFilters } from '../../../ui/filter/filter.interfaces';
import { IFilterService } from '../../../ui/filter/filter.service';
import { IModalController } from '../../../ui/modal/modal.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';
import { AllClaimsService } from '../../claims-and-accounts/claims/all-claims.service';

export class AllClaimsRefineResultsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = AllClaimsRefineResultsController;
    this.templateUrl = allClaimsRefineResultsTemplate;
  }
}

export class AllClaimsRefineResultsController implements ng.IComponentController {
  public $modal: IModalController;
  public claims: IAnyClaim[];
  public filters: Array<IGenericFilter<IClaim, any>>;
  public request: Observable<IAnyClaim[]>;
  public selectedSortByOption: IDropdownOption;
  public sortByOptions: IDropdownOption[];
  public type: AnyClaimType;
  private selectedFilters: ISelectedFilters<any>;

  constructor(
    private $element: ng.IAugmentedJQuery,
    private $scope: ng.IScope,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private claimsService: ClaimsService,
    private filterService: IFilterService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ALL_CLAIMS);

    this.type = this.filterService.getStateValue(FilterStateKey.Type) || ClaimType.Medical;
    this.initFilters(this.type);
    this.initSort(this.type);
    this.selectedFilters = this.filterService.getStateValue() || AllClaimsService.getDefaultSelectedFilters(this.type);
    this.selectedSortByOption = AllClaimsService.getSelectedSortOption(this.sortByOptions, this.selectedFilters.sort);

    const period = AllClaimsService.getPeriod(this.filters, this.selectedFilters);

    this.request = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .flatMap(profile => this.claimsService.getAll(profile, this.type, period).map(rsp => rsp.data),
        (profile, claims) => ({profile, claims}))
      .flatMap(({profile}) => this.targetingService.getClientConfig(profile.rallyId), ({claims}, clientConfig) => ({claims, clientConfig}))
      .do(({claims, clientConfig}) => AllClaimsService.setIsMerp(claims, this.type, clientConfig.suppressions.showMERPLabel))
      .map(({claims}) => claims);
  }

  public $onInit(): void {
    // Watch for the element to become not visible. This is necessary because this modal is only used on mobile. We hide the modal
    // with css. But if the user has the modal open and changes their viewport size to desktop, then the modal should close.
    this.$scope.$watch(() => this.$element.children().is(':visible'), isVisible => !isVisible && this.$modal.close());

    this.request.subscribe(claims => {
      this.filterService.setSelectedFilterValues(this.filters, this.selectedFilters, claims);
      this.claims = this.filterService.sort(claims, AllClaimsService.getSort(this.selectedFilters.sort));
    }, console.warn);
  }

  public initFilters(type: AnyClaimType): void {
    this.filters = AllClaimsService.getFilters(type);
  }

  public initSort(type: AnyClaimType): void {
    this.sortByOptions = AllClaimsService.getSortByOptions(type);
  }

  public handleFilters(filterName: string, selectedValues: any[]): void {
    for (const filter of this.filters) {
      if (filter.name === filterName) {
        filter.selectedValues = selectedValues;
        break;
      }
    }
    this.selectedFilters.values[filterName] = selectedValues;
  }

  public changeSortBy(): (sortBy: IDropdownOption) => void {
    return (sortBy: ISortDropdownOption) => {
      this.selectedSortByOption = sortBy;
      this.selectedFilters.sort.by = sortBy.value;
      this.selectedFilters.sort.reverse = sortBy.reverse;
    };
  }
  public updateFilters(): void {
    // this is to make sure that date range & year drop down dont have contradictory logic. Date range takes priority.
    if (this.selectedFilters && this.selectedFilters.values && this.selectedFilters.values.hasOwnProperty('by-date-range')) {
      const startDate = moment(this.selectedFilters.values['by-date-range'][0]);
      const endDate = moment(this.selectedFilters.values['by-date-range'][1]);
      if (this.selectedFilters.values.hasOwnProperty('year')) {
        const newYears: string [] = [];
        for (const year of (this.selectedFilters.values.year as string[])) {
          if (startDate.year() <= Number(year) &&  Number(year) <= endDate.year()) {
            newYears.push(year);
          }
        }
        this.selectedFilters.values.year = newYears.length > 0 ? newYears : undefined;
      }
    }
    this.filterService.updateStateValue(this.selectedFilters).subscribe();
    this.$timeout(() => {
      this.$modal.close();
    });
  }

  public resetFilters(): void {
    this.filterService.updateStateValue(undefined).subscribe();
    this.selectedFilters = AllClaimsService.getDefaultSelectedFilters(this.type);
    this.selectedSortByOption = AllClaimsService.getSelectedSortOption(this.sortByOptions, this.selectedFilters.sort);
    this.$scope.$broadcast('allClaims.resetFilters');
  }

  public areFiltersValid(): boolean {
    return this.filters.every(filter => filter.isValid ? filter.isValid() : true);
  }
}
