import { Subscription } from 'rxjs/Subscription';
import {
  benefitsCoverage,
  benefitsCoverageMedical,
  claimAccountBalances,
  connect,
  connectEditPcp,
  coordinationBenefits,
  exchangeMonthlyPremiumPayment,
  prescriptions,
  uhcCommunityPlan,
} from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { getLink } from 'scripts/util/uri/uri';
import faqTemplate from 'views/help/faq.html';
import { CoverageStatus, CoverageType, LinkTarget } from '../../../api/api.interfaces';
import { IPlansService } from '../../../api/plans/plans.service';
import { LineOfBusiness, MembershipCategory } from '../../../api/profile/profile.interfaces';
import { IProfileService, ProfileService } from '../../../api/profile/profile.service';
import { HelpFaqType } from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../../util/feature-flag/feature-flag.interface';
import { ILocaleService } from '../../../util/locale/locale.service';
import { IResourceService } from '../../../util/resource/resource.service';

export class FaqComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = FaqController;
    this.templateUrl = faqTemplate;
  }
}

export interface IQuestion {
  question: string;
  answer: string;
  getLink?: () => string;
  config?: IQuestionConfig;
  isPreTranslated?: boolean;
  preTranslatedQuestionKey?: string;
}

export interface IQuestionConfig {
  link?: string;
  target?: LinkTarget;
}

export class FaqController implements ng.IComponentController {
  public mostCommonQuestions: IQuestion[];
  public mostCommonQuestionsCS: IQuestion[];
  public findingDentalCareQuestions: IQuestion[];
  public findingMedicalCareQuestions: IQuestion[];
  public isDhmo: boolean;
  public isTermed: boolean;
  public connectEditPcp: IResource;
  private localeSubscription: Subscription;

  constructor(
    private $state: ng.ui.IStateService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private localeService: ILocaleService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,

  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.FAQ);

    this.mostCommonQuestions = [{
      question: 'Q_VERIFY_SERVICE',
      answer: 'A_VERIFY_SERVICE',
      getLink: () => this.resourceService.get(benefitsCoverage),
    }, {
      question: 'Q_UPDATE_COB',
      answer: 'A_UPDATE_COB',
      getLink: () => this.resourceService.get(coordinationBenefits),
    }, {
      question: 'Q_VERIFY_PAID_CLAIM',
      answer: 'A_VERIFY_PAID_CLAIM',
      getLink: () => this.$state.href('authenticated.claimsAndAccounts.claims'),
    }, {
      question: 'Q_DEDUCTIBLE_OOP_MAX',
      answer: 'A_DEDUCTIBLE_OOP_MAX',
      getLink: () => this.resourceService.get(claimAccountBalances),
    }, {
      question: 'Q_REPLACEMENT_ID_CARD',
      answer: 'A_REPLACEMENT_ID_CARD',
    }];

    this.findingMedicalCareQuestions = [{
      question: 'Q_ER_VS_URGENT_CARE',
      answer: 'A_ER_VS_URGENT_CARE',
    }, {
      question: 'Q_MAMMOGRAM',
      answer: 'A_MAMMOGRAM',
      getLink: () => getLink(this.resourceService.get(connect)),
    }, {
      question: 'Q_NOT_LISTED',
      answer: 'A_NOT_LISTED',
      getLink: () => this.resourceService.get(benefitsCoverageMedical),
    }, {
      question: 'Q_EMERGENCY_COVERAGE',
      answer: 'A_EMERGENCY_COVERAGE',
      getLink: () => this.resourceService.get(benefitsCoverageMedical),
    }, {
      question: 'Q_NETWORK_REFERRAL',
      answer: 'A_NETWORK_REFERRAL',
      getLink: () => this.resourceService.get(benefitsCoverageMedical),
    }, {
      question: 'Q_SHARED_SAVINGS',
      answer: 'A_SHARED_SAVINGS',
    }];

    this.mostCommonQuestionsCS = [{
      question: 'Q_BROWSERS',
      answer: 'A_BROWSERS',
    }, {
      question: 'Q_UPDATE_NAME_ADDRESS',
      answer: 'A_UPDATE_NAME_ADDRESS',
    }, {
      question: 'Q_PRIOR_AUTHORIZATION',
      answer: 'A_PRIOR_AUTHORIZATION',
    }, {
      question: 'Q_PRIOR_AUTHORIZATION_APPROVAL',
      answer: 'A_PRIOR_AUTHORIZATION_APPROVAL',
    }, {
      question: 'Q_LOST_ID_CARD',
      answer: 'A_LOST_ID_CARD',
    }, {
      question: 'Q_CHANGE_PCP',
      answer: 'A_CHANGE_PCP_DSNP',
    }, {
      question: 'Q_BILL_QUESTION',
      answer: 'A_BILL_QUESTION',
    }, {
      question: 'Q_USE_MEMBER_ID',
      answer: 'A_USE_MEMBER_ID_DSNP',
    }];

    this.findingDentalCareQuestions = [{
      question: 'Q_SELECT_PCD',
      answer: 'A_SELECT_PCD',
    }, {
      question: 'Q_CHANGE_PCD',
      answer: 'A_CHANGE_PCD',
    }, {
      question: 'Q_DENTAL_SPECIALIST_REFERRAL',
      answer: 'A_DENTAL_SPECIALIST_REFERRAL',
    }, {
      question: 'Q_FAMILY_MEMBER_OWN_PCD',
      answer: 'A_FAMILY_MEMBER_OWN_PCD',
    }, {
      question: 'Q_DENTAL_EMERGENCY',
      answer: 'A_DENTAL_EMERGENCY',
    }];
  }

  public $onInit(): void {
    const currentUser$ = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(profile => profile.data.currentUser);

    currentUser$
      .filter(currentUser => currentUser.lineOfBusiness === LineOfBusiness.CS)
      .subscribe(currentUser => {
        const isTermedCS = this.profileService.isTermedForCoverageType(CoverageType.Medical, currentUser.planCoverages);

        const primaryCustomerIdsForWhichToHidePriorAuthQuestions =
          this.Environment.CONFIG.ARCADE_WEB_CS_HIDE_PRIOR_AUTH_FAQ_PRIMARY_CUSTOMER_IDS.trim().split(',');
        const { primaryCustomerId } = currentUser.userInfo;
        if (primaryCustomerIdsForWhichToHidePriorAuthQuestions.indexOf(primaryCustomerId) !== -1) {
          const priorAuthQuestionsToHide = ['Q_PRIOR_AUTHORIZATION', 'Q_PRIOR_AUTHORIZATION_APPROVAL'];
          this.mostCommonQuestionsCS = this.mostCommonQuestionsCS.filter(q => priorAuthQuestionsToHide.indexOf(q.question) === -1);
        }

        if (!currentUser.memberFeatures.isDsnp) {
          // add 3 Medicaid/CS only questions that are not included in the DSNP FAQ.
          const CSOnlyQuestions = [
            {
              question: 'Q_MEDICAID_COVERAGE',
              answer: 'A_MEDICAID_COVERAGE',
            }, {
              question: 'Q_MEDICAID_FAILURE_TO_RE_ENROLL',
              answer: 'A_MEDICAID_FAILURE_TO_RE_ENROLL',
            }, {
              question: 'Q_MEDICAID_RE_ENROLL',
              answer: 'A_MEDICAID_RE_ENROLL',
              getLink: () => getLink(this.resourceService.get(uhcCommunityPlan)),
            },
          ];
          this.setLink(CSOnlyQuestions[2]);

          this.mostCommonQuestionsCS.splice(2, 0, ...CSOnlyQuestions);

          // the below questions have different answers for DSNP and Medicaid. Updating the answer to Medicaid if the user is not DSNP
          const useMemberIdQuestionToUpdate = this.mostCommonQuestionsCS.filter(q => q.question === 'Q_USE_MEMBER_ID')[0];
          useMemberIdQuestionToUpdate.answer = 'A_USE_MEMBER_ID_MEDICAID';
          useMemberIdQuestionToUpdate.getLink = () => getLink(this.resourceService.get(connectEditPcp));

          const changePcpQuestionToUpdate = this.mostCommonQuestionsCS.filter(q => q.question === 'Q_CHANGE_PCP')[0];
          changePcpQuestionToUpdate.answer = 'A_CHANGE_PCP_MEDICAID';
          changePcpQuestionToUpdate.getLink = () => getLink(this.resourceService.get(connectEditPcp));

          this.setLink(useMemberIdQuestionToUpdate);
          this.setLink(changePcpQuestionToUpdate);
        }
        if (isTermedCS) {
          const questionsToHideForAllTermedCS = ['Q_LOST_ID_CARD', 'Q_USE_MEMBER_ID', 'Q_CHANGE_PCP', 'Q_MEDICAID_COVERAGE'];
          this.mostCommonQuestionsCS = this.mostCommonQuestionsCS.filter(q => questionsToHideForAllTermedCS.indexOf(q.question) === -1);
        }
      }, console.warn);

    currentUser$
      .filter(currentUser => currentUser.lineOfBusiness !== LineOfBusiness.CS)
      .flatMap(currentUser => this.plansService.getBenefits(currentUser.rallyId))
      .flatMap(benefits => benefits.data.benefits)
      .filter(({coverageType}) => coverageType === CoverageType.Medical)
      .take(1)
      .subscribe(medicalBenefit => {
        if (medicalBenefit.planFeatures.isTieredPlan) {
          this.findingMedicalCareQuestions.unshift({
            question: 'Q_TIER_ONE',
            answer: 'A_TIER_ONE',
          });
        }
      }, console.warn);

    currentUser$
      .filter(currentUser => currentUser.lineOfBusiness !== LineOfBusiness.CS)
      .map(currentUser => ({
        currentUser,
        activeCoverages: ProfileService.getCoverageInfo(currentUser.planCoverages,
          [CoverageStatus.Active, CoverageStatus.Future]).coverageTypes,
      }))
      .do(({activeCoverages}) => {
        if (activeCoverages[CoverageType.Medical]) {
          const question = {
            question: 'Q_IN_OR_OUT_OF_NETWORK',
            answer: 'A_IN_OR_OUT_OF_NETWORK',
            getLink: () => getLink(this.resourceService.get(connect)),
          };
          this.setLink(question);
          this.mostCommonQuestions.push(question);
          this.findingMedicalCareQuestions.push(question);
        } else {
          this.findingMedicalCareQuestions = this.findingMedicalCareQuestions.filter(q => q.question !== 'Q_MAMMOGRAM');
        }
      })
      .filter(({activeCoverages}) => activeCoverages[CoverageType.Rx])
      .flatMap(({currentUser}) => this.profileService.getProducts(currentUser.rallyId))
      .map(productsRsp => productsRsp.data.products)
      .subscribe(products => {
        if (products.rx) {
          const question = {
            question: 'Q_PRESCRIPTIONS',
            answer: 'A_PRESCRIPTIONS',
            getLink: () => this.resourceService.get(prescriptions),
          };
          this.setLink(question);
          // Inserts prescriptions q&a as the second to last question
          this.mostCommonQuestions.splice(this.mostCommonQuestions.length - 1, 0, question);
        }
      }, console.warn);

    currentUser$
      .filter(currentUser => currentUser.lineOfBusiness !== LineOfBusiness.CS)
      .flatMap(currentUser => this.targetingService.getClientConfig(currentUser.rallyId))
      .filter(clientConfig => !!(clientConfig && clientConfig.contentOverrides && clientConfig.contentOverrides.helpFAQTypes))
      .map(clientConfig => clientConfig.contentOverrides.helpFAQTypes)
      .subscribe(helpFAQTypes => {
        if (helpFAQTypes.indexOf(HelpFaqType.JPMC) > -1) {
          this.findingMedicalCareQuestions = this.findingMedicalCareQuestions.filter(q => q.question !== 'Q_ER_VS_URGENT_CARE');
          this.findingMedicalCareQuestions.unshift({question: 'Q_ER_VS_URGENT_CARE_JPMC', answer: 'A_ER_VS_URGENT_CARE_JPMC'});
          this.findingMedicalCareQuestions = this.findingMedicalCareQuestions.filter(q => q.question !== 'Q_SHARED_SAVINGS');
          this.mostCommonQuestions = this.mostCommonQuestions.filter(q => q.question !== 'Q_DEDUCTIBLE_OOP_MAX');
          this.mostCommonQuestions.splice(3, 0, {question: 'Q_DEDUCTIBLE_COINSURANCE_MAX', answer: 'A_DEDUCTIBLE_COINSURANCE_MAX'});
          this.mostCommonQuestions.push({question: 'Q_CLAIM_ACCOUNT_QUESTION', answer: 'A_CLAIM_ACCOUNT_QUESTION'});
          this.mostCommonQuestions.push({question: 'Q_EARNED_MRA_FUNDS', answer: 'A_EARNED_MRA_FUNDS'});
          this.mostCommonQuestions.push({question: 'Q_RELATED_MRA_FUNDS', answer: 'A_RELATED_MRA_FUNDS'});
          this.mostCommonQuestions.push({question: 'Q_HEALTH_DATA_DOCTOR', answer: 'A_HEALTH_DATA_DOCTOR'});
          this.mostCommonQuestions.push({question: 'Q_ADD_DROP_COVERAGE', answer: 'A_ADD_DROP_COVERAGE'});
          this.mostCommonQuestions.push({question: 'Q_PHARMACY_AMOUNTS', answer: 'A_PHARMACY_AMOUNTS'});
          const hospitalFacilityInNetworkQuestion = {
            question: 'Q_HOSPITAL_FACILITY_IN_NETWORK',
            answer: 'A_HOSPITAL_FACILITY_IN_NETWORK',
            getLink: () => getLink(this.resourceService.get(connect)),
          };
          this.setLink(hospitalFacilityInNetworkQuestion);
          this.findingMedicalCareQuestions.push(hospitalFacilityInNetworkQuestion);
        }
      });

    currentUser$
      .filter(currentUser => currentUser.lineOfBusiness === LineOfBusiness.EI)
      .map(currentUser => currentUser.memberFeatures)
      .subscribe(memberFeatures => {
        const pcpPcdEligibility = this.profileService.getPcpPcdEligibility(memberFeatures);
        this.isDhmo = pcpPcdEligibility.pcd;
      });

    currentUser$
      .filter(currentUser => currentUser.lineOfBusiness === LineOfBusiness.EI
        && currentUser.membershipCategory === MembershipCategory.EMPIRE)
      .subscribe(() => {
        const additionalEmpireCommonQuestions = [{
          question: 'Q_EMPIRE_TRAVEL',
          answer: 'A_EMPIRE_TRAVEL',
        }, {
          question: 'Q_CONTACT_CANCER_RESOURCE_SERVICES',
          answer: 'A_CONTACT_CANCER_RESOURCE_SERVICES',
        }, {
          question: 'Q_EMPIRE_DIABETIC_PHARMACY',
          answer: 'A_EMPIRE_DIABETIC_PHARMACY',
        }, {
          question: 'Q_CONTACT_OSTOMY',
          answer: 'A_CONTACT_OSTOMY',
        }];

        const findingMedicalCareQuestionsToSuppress = [
          'Q_ER_VS_URGENT_CARE',
          'Q_MAMMOGRAM',
          'Q_EMERGENCY_COVERAGE',
          'Q_NETWORK_REFERRAL',
          'Q_SHARED_SAVINGS',
        ];

        this.mostCommonQuestions = this.mostCommonQuestions.filter(q => q.question !== 'Q_REPLACEMENT_ID_CARD');
        this.mostCommonQuestions = [...this.mostCommonQuestions, ...additionalEmpireCommonQuestions];

        this.findingMedicalCareQuestions = this.findingMedicalCareQuestions
          .filter(q => findingMedicalCareQuestionsToSuppress.indexOf(q.question) === -1);

        const notListedQuestionToUpdate = this.findingMedicalCareQuestions.filter(q => q.question === 'Q_NOT_LISTED')[0];
        notListedQuestionToUpdate.answer = 'A_NOT_LISTED_EMPIRE';

        this.findingMedicalCareQuestions.push({
          question: 'Q_BASIC_PROVIDER_DISCOUNT_PROGRAM',
          answer: 'A_BASIC_PROVIDER_DISCOUNT_PROGRAM',
          getLink: () => getLink(this.resourceService.get(connect)),
        });
      });

    currentUser$
      .filter(currentUser => currentUser.lineOfBusiness === LineOfBusiness.EI
        && (currentUser.membershipCategory === MembershipCategory.EXCHANGE
          || currentUser.membershipCategory === MembershipCategory.PUBLIC_EXCHANGE)
        && currentUser.memberFeatures.premiumPaymentEligible)
      .subscribe(() => {
        const exchangeMonthlyPremiumQuestion = {
          question: 'Q_EXCHANGE_MONTHLY_PREMIUM',
          answer: 'A_EXCHANGE_MONTHLY_PREMIUM',
          getLink: () => getLink(this.resourceService.get(exchangeMonthlyPremiumPayment)),
        };
        this.mostCommonQuestions.push(exchangeMonthlyPremiumQuestion);
      });

    this.setCsCustomFaqs();
    this.setLinks();

    this.localeSubscription = this.localeService.localeChanged.subscribe(locale => {
      this.setCsCustomFaqs();
      this.setLinks();
    });
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  private setCsCustomFaqs(): void {
    const currentUser$ = this.userService.getHeartbeat()
      .let(this.profileService.toProfile())
      .map(profile => profile.data.currentUser);
    const csPlanNamesWithFaqCustomizations = this.Environment.CONFIG.ARCADE_WEB_CS_CUSTOM_FAQ_POLICIES.trim().split(',');

    currentUser$
      .filter(currentUser => currentUser.lineOfBusiness === LineOfBusiness.CS
        && csPlanNamesWithFaqCustomizations.indexOf(currentUser.primaryPolicyNumber) !== -1)
      .flatMap(currentUser => this.targetingService.getCsFaqCustomizations(currentUser.primaryPolicyNumber))
      .map(rsp => rsp.data.contentOverrides.customFaq)
      .subscribe(customFaqs => {
        customFaqs.forEach(customFaq => {
          const mostCommonQuestionToUpdate = this.mostCommonQuestionsCS
            .find(q => q.question === customFaq.questionKey
              || (q.preTranslatedQuestionKey && q.preTranslatedQuestionKey === customFaq.questionKey));
          if (mostCommonQuestionToUpdate) {
            mostCommonQuestionToUpdate.question = customFaq.question;
            mostCommonQuestionToUpdate.answer = customFaq.answer;
            mostCommonQuestionToUpdate.preTranslatedQuestionKey = customFaq.questionKey;
            mostCommonQuestionToUpdate.isPreTranslated = true;
          }
        });
      });
  }

  private setLinks(): void {
    this.mostCommonQuestions.forEach(q => this.setLink(q));
    this.findingDentalCareQuestions.forEach(q => this.setLink(q));
    this.findingMedicalCareQuestions.forEach(q => this.setLink(q));
  }

  private setLink(question: IQuestion): void {
    if (question.getLink) {
      question.config = question.config || {};
      question.config.link = question.getLink();
    }
  }
}
