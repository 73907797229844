import { Observable } from 'rxjs/Observable';
import pcdConfirmationTemplate from 'views/modals/pcd-confirmation.html';
import { CoverageStatus } from '../../../api/api.interfaces';
import {
  IPrimaryCareInfo,
  IPrimaryCareResponse,
  ISundogInfo,
  ISundogResponse,
  PcpType,
  ProviderType,
} from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IModalController } from '../../../ui/modal/modal.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';

export interface IPcdConfirmationParams extends ng.ui.IStateParamsService {
  payload: string;
}

export class PcdConfirmationComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = PcdConfirmationController;
    this.templateUrl = pcdConfirmationTemplate;
  }
}

export class PcdConfirmationController {
  public $modal: IModalController;
  public currentAndNewPcdRequest: Observable<[IPrimaryCareResponse, ISundogResponse]>;
  public currentPcdName: string;
  public currentPcdId: string;
  public newPcdInfo: ISundogInfo;
  public postInProgress: boolean;
  public showFailureMessage: boolean;

  constructor(
    private $state: ng.ui.IStateService,
    private $stateParams: IPcdConfirmationParams,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ERRORS);
    $translatePartialLoader.addPart(Dictionary.PCP);

    const newPcdRequest = this.userService.getHeartbeat()
      .flatMap(heartbeat => this.profileService.getDecryptedProviderInfo(heartbeat.data.rallyId, this.$stateParams.payload));

    const currentPcdRequest = this.userService.getHeartbeat()
      .flatMap(heartbeat => this.profileService.getPrimaryCare(heartbeat.data.rallyId, true))
      .catch(() =>  Observable.of(undefined));

    this.currentAndNewPcdRequest = Observable.zip(currentPcdRequest, newPcdRequest);
  }

  public $onInit(): void {
    this.currentAndNewPcdRequest.subscribe(([perMemberPcpInfo, newPcdRsp]) => {
      this.newPcdInfo = newPcdRsp.data;
      const currentProviderInfo = perMemberPcpInfo.data.perMemberResults[this.newPcdInfo.dependentSequenceNumber];
      if (currentProviderInfo && currentProviderInfo.primaryCarePhysicians) {
        for (const provider of currentProviderInfo.primaryCarePhysicians) {
          if (provider.status === CoverageStatus.Active && provider.pcpType === PcpType.Dental) {
            this.currentPcdName = this.profileService.getFullName(provider);
            this.currentPcdId = provider.providerId;
            break;
          }
        }
      }
    });
  }

  public $onDestroy(): void {
    // remove query string from url
    window.history.replaceState('dashboard', '', window.location.pathname);
  }

  public cancelChange(): void {
    this.$modal.close();
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  public changePcd(): void {
    if (this.newPcdInfo) {
      const providerInfo: IPrimaryCareInfo = {
        dependentSequenceNumber: this.newPcdInfo.dependentSequenceNumber,
        providerType: ProviderType.Dental,
        providerId: parseInt(this.newPcdInfo.provider.id, 10),
      };
      this.postInProgress = true;
      this.userService.getHeartbeat()
        .flatMap(heartbeat => this.profileService.setPrimaryCare(heartbeat.data.rallyId, providerInfo))
        .subscribe(() => this.$modal.close(), err => this.handlePostFailure(err));
    }
  }

  public customCloseModal(): void {
    this.$state.go('authenticated.dashboard');
  }

  private handlePostFailure(err: any): void {
    this.postInProgress = false;
    this.showFailureMessage = true;
    this.$timeout(() => {
      this.showFailureMessage = false;
    }, 5000);
    console.warn(err);
  }
}
